
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { LayoutGrid, Plus, Trash2, Edit } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "@/components/ui/use-toast";
import { supabase, type Service } from "@/lib/supabase";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

const serviceSchema = z.object({
  title: z.string().min(2, {
    message: "Il titolo deve avere almeno 2 caratteri",
  }),
  description: z.string().min(10, {
    message: "La descrizione deve avere almeno 10 caratteri",
  }),
  icon: z.string().min(1, {
    message: "L'icona è obbligatoria",
  }),
  display_order: z.number().int().min(0),
});

// Elenco di emoji per i servizi
const serviceIcons = [
  "🏋️‍♂️", "💪", "🧘‍♀️", "🏃‍♂️", "🤸‍♀️", "🏊‍♂️", "⚽", "🚴‍♀️", 
  "🥗", "🍎", "🥑", "🏆", "🔥", "⏱️", "📊", "💯", "🎯", "💓", 
  "🧠", "💤", "🌱", "🌞", "🌈", "🎓", "🧩", "🏅", "🎖️", "🎪"
];

interface ServicesTabProps {
  services: Service[];
  isLoading: boolean;
  onSaved: () => Promise<void>;
}

const ServicesTab = ({ services, isLoading, onSaved }: ServicesTabProps) => {
  const [localLoading, setLocalLoading] = useState(false);
  const [serviceDialogOpen, setServiceDialogOpen] = useState(false);
  const [currentService, setCurrentService] = useState<Service | null>(null);
  
  const serviceForm = useForm({
    resolver: zodResolver(serviceSchema),
    defaultValues: {
      title: "",
      description: "",
      icon: "🏋️‍♂️",
      display_order: 0,
    },
  });
  
  const openServiceDialog = (service: Service | null = null) => {
    setCurrentService(service);
    if (service) {
      serviceForm.reset({
        title: service.title,
        description: service.description,
        icon: service.icon,
        display_order: service.display_order,
      });
    } else {
      const maxOrder = services.length > 0 
        ? Math.max(...services.map(s => s.display_order)) 
        : -1;
      
      serviceForm.reset({
        title: "",
        description: "",
        icon: "🏋️‍♂️",
        display_order: maxOrder + 1,
      });
    }
    setServiceDialogOpen(true);
  };
  
  const saveService = async (values) => {
    setLocalLoading(true);
    try {
      if (currentService) {
        const { error } = await supabase
          .from("services")
          .update(values)
          .eq("id", currentService.id);
        
        if (error) throw error;
        
        toast({
          title: "Successo",
          description: "Servizio aggiornato con successo",
        });
      } else {
        const { error } = await supabase
          .from("services")
          .insert([values]);
        
        if (error) throw error;
        
        toast({
          title: "Successo",
          description: "Servizio aggiunto con successo",
        });
      }
      
      setServiceDialogOpen(false);
      await onSaved();
    } catch (error) {
      console.error("Errore nel salvataggio:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore nel salvataggio",
        variant: "destructive",
      });
    } finally {
      setLocalLoading(false);
    }
  };
  
  const deleteService = async (id) => {
    if (!confirm("Sei sicuro di voler eliminare questo servizio?")) return;
    
    setLocalLoading(true);
    try {
      const { error } = await supabase
        .from("services")
        .delete()
        .eq("id", id);
      
      if (error) throw error;
      
      toast({
        title: "Successo",
        description: "Servizio eliminato con successo",
      });
      
      await onSaved();
    } catch (error) {
      console.error("Errore nell'eliminazione:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore nell'eliminazione",
        variant: "destructive",
      });
    } finally {
      setLocalLoading(false);
    }
  };
  
  return (
    <>
      <Card className="border-0 shadow-md">
        <CardHeader className="border-b bg-gray-50 flex flex-row items-center justify-between">
          <CardTitle className="text-xl flex items-center gap-2">
            <LayoutGrid size={18} className="text-primary" />
            Servizi
          </CardTitle>
          <Button 
            onClick={() => openServiceDialog()} 
            size="sm"
            className="flex items-center gap-1"
          >
            <Plus size={16} />
            <span>Aggiungi</span>
          </Button>
        </CardHeader>
        <CardContent className="p-6">
          {services && services.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {services.map((service) => (
                <div 
                  key={service.id}
                  className="border rounded-lg p-4 relative bg-white shadow-sm"
                >
                  <div className="absolute top-2 right-2 flex gap-1">
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => openServiceDialog(service)}
                    >
                      <Edit size={14} />
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => deleteService(service.id)}
                    >
                      <Trash2 size={14} />
                    </Button>
                  </div>
                  
                  <div className="flex items-start gap-3 mb-2">
                    <div className="text-3xl">{service.icon}</div>
                    <div>
                      <h3 className="font-medium">{service.title}</h3>
                      <div className="text-xs text-gray-400 mt-1">
                        Ordine: {service.display_order}
                      </div>
                    </div>
                  </div>
                  
                  <p className="text-sm text-gray-600 pl-10">
                    {service.description}
                  </p>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <LayoutGrid size={48} className="mx-auto text-gray-300 mb-4" />
              <h3 className="text-lg font-medium mb-2">Nessun servizio</h3>
              <p className="text-gray-500 mb-4">Aggiungi i servizi che offri</p>
              <Button onClick={() => openServiceDialog()}>
                <Plus size={16} className="mr-2" />
                Aggiungi Servizio
              </Button>
            </div>
          )}
        </CardContent>
      </Card>

      <Dialog open={serviceDialogOpen} onOpenChange={setServiceDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {currentService ? "Modifica Servizio" : "Aggiungi Servizio"}
            </DialogTitle>
          </DialogHeader>
          
          <Form {...serviceForm}>
            <form onSubmit={serviceForm.handleSubmit(saveService)} className="space-y-4">
              <FormField
                control={serviceForm.control}
                name="title"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Titolo</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <FormField
                control={serviceForm.control}
                name="description"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Descrizione</FormLabel>
                    <FormControl>
                      <Textarea {...field} rows={4} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <FormField
                control={serviceForm.control}
                name="icon"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Icona</FormLabel>
                    <FormControl>
                      <div className="space-y-2">
                        <div className="flex items-center gap-2">
                          <div className="text-3xl">{field.value}</div>
                          <Select
                            value={field.value}
                            onValueChange={field.onChange}
                          >
                            <SelectTrigger className="flex-1">
                              <SelectValue />
                            </SelectTrigger>
                            <SelectContent>
                              <div className="grid grid-cols-8 gap-2 p-2">
                                {serviceIcons.map((icon) => (
                                  <div 
                                    key={icon}
                                    className={`
                                      text-2xl cursor-pointer p-2 rounded-md hover:bg-primary/10
                                      ${field.value === icon ? 'bg-primary/20' : ''}
                                    `}
                                    onClick={() => field.onChange(icon)}
                                  >
                                    {icon}
                                  </div>
                                ))}
                              </div>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <FormField
                control={serviceForm.control}
                name="display_order"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Ordine di visualizzazione</FormLabel>
                    <FormControl>
                      <Input 
                        type="number" 
                        min={0} 
                        {...field} 
                        onChange={(e) => field.onChange(parseInt(e.target.value))}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <DialogFooter>
                <Button 
                  type="button" 
                  variant="outline" 
                  onClick={() => setServiceDialogOpen(false)}
                >
                  Annulla
                </Button>
                <Button type="submit" disabled={isLoading || localLoading}>
                  {isLoading || localLoading ? "Salvataggio..." : "Salva"}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ServicesTab;
