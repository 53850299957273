import { useState, useEffect } from "react";
import { supabase, type HomeSettings, type Testimonial, type FAQ, type Package, type Course, type Service } from "@/lib/supabase";
import LoadingLogo from "@/components/LoadingLogo";
import Hero from "@/components/sections/HeroSection";
import Services from "@/components/sections/ServiceSection";
import PackagesSection from "@/components/sections/PackagesSection";
import CoursesSection from "@/components/sections/CoursesSection";
import About from "@/components/sections/AboutSection";
import Testimonials from "@/components/sections/TestimonialsSection";
import FAQSection from "@/components/sections/FAQSection";
import Contact from "@/components/sections/ContactSection";
import Footer from "@/components/sections/FooterSection";

const Home = () => {
  const [homeSettings, setHomeSettings] = useState<HomeSettings | null>(null);
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const [faqs, setFaqs] = useState<FAQ[]>([]);
  const [packages, setPackages] = useState<Package[]>([]);
  const [courses, setCourses] = useState<Course[]>([]);
  const [services, setServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const {
          data: settingsData
        } = await supabase.from('home_settings').select('*').single();
        if (settingsData) {
          console.log("Home settings caricati:", settingsData);
          console.log("URL immagine about:", settingsData.about_image_url);
          setHomeSettings(settingsData);
        }
        
        const {
          data: testimonialsData
        } = await supabase.from('testimonials').select('*').order('created_at', {
          ascending: false
        });
        if (testimonialsData) {
          setTestimonials(testimonialsData);
        }
        
        const {
          data: faqsData
        } = await supabase.from('faqs').select('*').order('order', {
          ascending: true
        });
        if (faqsData) {
          setFaqs(faqsData);
        }
        
        const {
          data: packagesData
        } = await supabase.from('packages').select('*').eq('visible_on_site', true).order('price', {
          ascending: true
        });
        if (packagesData) {
          setPackages(packagesData);
        }
        
        const {
          data: coursesData
        } = await supabase.from('courses').select('*').eq('visible_on_site', true);
        if (coursesData) {
          setCourses(coursesData);
        }
        
        const {
          data: servicesData
        } = await supabase.from('services').select('*').order('display_order', {
          ascending: true
        });
        if (servicesData) {
          setServices(servicesData as Service[]);
        }
        
        // Simuliamo un piccolo ritardo per mostrare il loading
        setTimeout(() => {
          setLoading(false);
        }, 1000);
        
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);

  if (loading) {
    return <LoadingLogo onLoadingComplete={() => setLoading(false)} />;
  }

  return (
    <div className="min-h-screen">
      <Hero settings={homeSettings} loading={loading} />
      <Services services={services} loading={loading} />
      <PackagesSection packages={packages} loading={loading} />
      <CoursesSection courses={courses} loading={loading} />
      <About content={homeSettings} loading={loading} />
      <Testimonials testimonials={testimonials} loading={loading} />
      <FAQSection faqs={faqs} loading={loading} />
      <Contact loading={loading} />
      <Footer />
    </div>
  );
};

export default Home;
