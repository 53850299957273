import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Client, ExtendedClientPackage } from "@/lib/supabase";
import { User, Activity } from "lucide-react";
import PackageHoursProgress from "./PackageHoursProgress";

interface ClientInfoTabProps {
  client: Client;
  clientPackages?: ExtendedClientPackage[];
}

const ClientInfoTab = ({ client, clientPackages = [] }: ClientInfoTabProps) => {
  return (
    <div className="animate-fade-in">
      {clientPackages && clientPackages.length > 0 && (
        <PackageHoursProgress clientPackages={clientPackages} />
      )}
      
      <Card className="shadow-sm">
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <User size={20} className="text-primary" />
            Informazioni Cliente
          </CardTitle>
          <CardDescription>
            Dati personali e preferenze del cliente
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-medium mb-3 flex items-center gap-2">
                  <User size={18} className="text-blue-500" />
                  Dati Personali
                </h3>
                <div className="space-y-3 bg-gray-50 dark:bg-gray-900 p-4 rounded-lg">
                  <div className="flex justify-between">
                    <span className="text-muted-foreground">Nome:</span>
                    <span className="font-medium">{client.first_name}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-muted-foreground">Cognome:</span>
                    <span className="font-medium">{client.last_name}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-muted-foreground">Età:</span>
                    <span className="font-medium">{client.age} anni</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-muted-foreground">Genere:</span>
                    <span className="font-medium">{client.gender === 'male' ? 'Uomo' : client.gender === 'female' ? 'Donna' : 'Altro'}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-muted-foreground">Email:</span>
                    <span className="font-medium">{client.email || "-"}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-muted-foreground">Telefono:</span>
                    <span className="font-medium">{client.telefono || "-"}</span>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="space-y-6">
              <div>
                <h3 className="text-lg font-medium mb-3 flex items-center gap-2">
                  <Activity size={18} className="text-green-500" />
                  Attività e Interessi
                </h3>
                <div className="space-y-3 bg-gray-50 dark:bg-gray-900 p-4 rounded-lg">
                  <div className="flex justify-between">
                    <span className="text-muted-foreground">Sport:</span>
                    <div className="flex flex-wrap justify-end gap-1 max-w-[200px]">
                      {client.sports && client.sports.length > 0 ? client.sports.map((sport, index) => (
                        <Badge key={index} variant="outline" className="whitespace-nowrap">
                          {sport}
                        </Badge>
                      )) : <span>Nessuno</span>}
                    </div>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-muted-foreground">Frequenza:</span>
                    <span className="font-medium">{client.training_frequency}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-muted-foreground">Condizione:</span>
                    <span className="font-medium">{client.general_condition}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ClientInfoTab;
