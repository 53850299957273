
import { Outlet } from "react-router-dom";
import Navbar from "@/components/Navbar";
import { useLocation } from "react-router-dom";
import AdminSidebar from "@/components/AdminSidebar";
import { useIsMobile } from "@/hooks/use-mobile";
import { Menu, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useState, useEffect } from "react";

const Layout = () => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith("/admin");
  const isMobile = useIsMobile();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isMobile && sidebarOpen) {
        const sidebar = document.getElementById('admin-sidebar');
        const menuButton = document.getElementById('menu-button');
        
        if (sidebar && 
            !sidebar.contains(event.target as Node) && 
            menuButton && 
            !menuButton.contains(event.target as Node)) {
          setSidebarOpen(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobile, sidebarOpen]);

  useEffect(() => {
    if (isMobile) {
      setSidebarOpen(false);
    }
  }, [location.pathname, isMobile]);

  return (
    <div className="min-h-screen flex flex-col bg-gray-50 w-full">
      {!isAdminRoute && <Navbar />}
      
      <div className="flex flex-1 w-full">
        {isAdminRoute && (
          <>
            {/* Overlay fuori dal contenitore della sidebar */}
            {isMobile && sidebarOpen && (
              <div 
                className="fixed inset-0 bg-black/40 z-30" 
                onClick={() => setSidebarOpen(false)}
              />
            )}
            
            <div 
              id="admin-sidebar"
              className={`${isMobile 
                ? `fixed z-40 transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full'}`
                : 'relative z-10 w-64 flex-shrink-0'} 
                transition-transform duration-300 ease-in-out`}
            >
              <AdminSidebar onCloseSidebar={() => setSidebarOpen(false)} />
            </div>
            
            {isMobile && (
              <Button 
                id="menu-button"
                variant="outline"
                size="icon" 
                className="fixed left-4 top-4 z-40 shadow-md bg-white"
                onClick={() => setSidebarOpen(!sidebarOpen)}
              >
                {sidebarOpen ? <X size={20} /> : <Menu size={20} />}
                <span className="sr-only">Toggle menu</span>
              </Button>
            )}
          </>
        )}
        
        <main className={`flex-1 transition-all duration-300 ${
          isAdminRoute ? 'p-2 md:p-6 pt-16 md:pt-6' : 'p-0'
        } w-full ${
          isAdminRoute && !isMobile ? 'md:ml-0' : ''
        } ${
          // Aggiungiamo padding laterale ridotto solo su mobile
          isMobile ? 'px-2' : ''
        }`}>
          <div className={`${isAdminRoute ? 'container mx-auto' : 'w-full'}`}>
            {/* Utilizziamo la proprietà key per forzare il remount del componente quando cambia l'URL */}
            <div key={location.pathname} className="animate-fade-in">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Layout;
