
import { supabase } from "@/lib/supabase";
import { Client, ExtendedClientPackage, ExtendedClientCourse, ExtendedPayment, Package, Course } from "@/lib/supabase";
import { WorkoutTemplate } from "@/types/workout";
import { ClientWorkout } from "@/components/clients/ClientWorkoutsTab";
import { parseScheduleData, parseClosurePeriodsData } from "@/utils/courseUtils";
import { 
  fetchClientWorkouts, 
  fetchWorkoutTemplate, 
  fetchAllWorkoutTemplates 
} from "@/lib/clients";

export async function fetchClientData(id: string): Promise<Client | null> {
  console.log("ClientDetail: cercando cliente con ID:", id);
  const isUuid = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i.test(id);
  
  if (!isUuid) {
    throw new Error("ID cliente non valido");
  }
  
  const { data, error } = await supabase.from('clients').select('*').eq('id', id).single();
  
  if (error) {
    console.error("Errore nel recupero del cliente per ID:", error);
    throw error;
  }
  
  console.log("Cliente trovato per ID:", data);
  return data;
}

export async function fetchClientPackages(clientId: string): Promise<ExtendedClientPackage[]> {
  try {
    const { data, error } = await supabase.from('client_packages').select(`
      *,
      packages:package_id (*)
    `).eq('client_id', clientId);
    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Errore nel recupero dei pacchetti del cliente:', error);
    return [];
  }
}

export async function fetchClientCourses(clientId: string): Promise<ExtendedClientCourse[]> {
  try {
    const { data, error } = await supabase.from('client_courses').select(`
      *,
      courses:course_id (*)
    `).eq('client_id', clientId);
    if (error) throw error;
    const processedCourses = data?.map(course => ({
      ...course,
      courses: {
        ...course.courses,
        schedule: parseScheduleData(course.courses.schedule),
        closure_periods: course.courses.closure_periods ? parseClosurePeriodsData(course.courses.closure_periods) : null
      }
    })) as ExtendedClientCourse[];
    return processedCourses || [];
  } catch (error) {
    console.error('Errore nel recupero dei corsi del cliente:', error);
    return [];
  }
}

export async function fetchClientWorkoutsData(clientId: string): Promise<ClientWorkout[]> {
  try {
    const workoutsData = await fetchClientWorkouts(clientId);
    
    if (!workoutsData || workoutsData.length === 0) {
      return [];
    }

    const workoutPromises = workoutsData.map(async (workout) => {
      const templateData = await fetchWorkoutTemplate(workout.workout_id);
      
      return {
        ...workout,
        workoutTemplate: templateData
      };
    });
    
    const workoutsWithTemplates = await Promise.all(workoutPromises);
    return workoutsWithTemplates as unknown as ClientWorkout[];
  } catch (error) {
    console.error('Errore nel recupero degli allenamenti del cliente:', error);
    return [];
  }
}

export async function fetchClientPayments(clientId: string): Promise<ExtendedPayment[]> {
  try {
    const { data, error } = await supabase.from('payments').select(`
      *,
      packageInfo:package_id (name),
      course:course_id (name)
    `).eq('client_id', clientId).order('date', {
      ascending: false
    });
    if (error) throw error;
    return data as ExtendedPayment[] || [];
  } catch (error) {
    console.error('Errore nel recupero dei pagamenti del cliente:', error);
    return [];
  }
}

export async function fetchAvailablePackages(): Promise<Package[]> {
  try {
    const { data, error } = await supabase.from('packages').select('*').order('created_at', {
      ascending: false
    });
    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Errore nel recupero dei pacchetti disponibili:', error);
    return [];
  }
}

export async function fetchAvailableCourses(): Promise<Course[]> {
  try {
    const { data, error } = await supabase.from('courses').select('*').order('created_at', {
      ascending: false
    });
    if (error) throw error;
    const processedCourses = data?.map(course => ({
      ...course,
      schedule: parseScheduleData(course.schedule),
      closure_periods: course.closure_periods ? parseClosurePeriodsData(course.closure_periods) : []
    })) || [];
    return processedCourses;
  } catch (error) {
    console.error('Errore nel recupero dei corsi disponibili:', error);
    return [];
  }
}

export async function fetchAvailableWorkouts(): Promise<WorkoutTemplate[]> {
  try {
    const templates = await fetchAllWorkoutTemplates();
    return templates as unknown as WorkoutTemplate[];
  } catch (error) {
    console.error('Errore nel recupero degli allenamenti disponibili:', error);
    return [];
  }
}
