
import { UseFormReturn } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { TrainingFrequency } from "@/lib/supabase";
import { ClientFormValues } from "@/hooks/use-clients";

interface TrainingInfoFieldsProps {
  form: UseFormReturn<ClientFormValues>;
}

const TrainingInfoFields = ({ form }: TrainingInfoFieldsProps) => {
  return (
    <>
      <div className="space-y-2 col-span-2">
        <Label htmlFor="sports">Sport Praticati (separati da virgole)</Label>
        <Input 
          id="sports" 
          {...form.register("sports")}
        />
      </div>
      
      <div className="space-y-2">
        <Label htmlFor="general_condition">Condizione Generale</Label>
        <Select 
          defaultValue={form.getValues("general_condition") || "Sufficiente"}
          onValueChange={(value) => form.setValue("general_condition", value)}
        >
          <SelectTrigger id="general_condition">
            <SelectValue placeholder="Seleziona condizione" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="Non sufficiente">Non sufficiente</SelectItem>
            <SelectItem value="Sufficiente">Sufficiente</SelectItem>
            <SelectItem value="Buona">Buona</SelectItem>
            <SelectItem value="Ottima">Ottima</SelectItem>
            <SelectItem value="Pro">Pro</SelectItem>
          </SelectContent>
        </Select>
      </div>
      
      <div className="space-y-2">
        <Label htmlFor="training_frequency">Frequenza Allenamento</Label>
        <Select 
          defaultValue={form.getValues("training_frequency")}
          onValueChange={(value) => form.setValue("training_frequency", value as TrainingFrequency)}
        >
          <SelectTrigger id="training_frequency">
            <SelectValue placeholder="Seleziona frequenza" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="sedentario">Sedentario</SelectItem>
            <SelectItem value="1 volta settimana">1 volta a settimana</SelectItem>
            <SelectItem value="2 volte">2 volte a settimana</SelectItem>
            <SelectItem value="3 volte">3 volte a settimana</SelectItem>
          </SelectContent>
        </Select>
        {form.formState.errors.training_frequency && (
          <p className="text-sm text-red-500">{form.formState.errors.training_frequency.message}</p>
        )}
      </div>
    </>
  );
};

export default TrainingInfoFields;
