import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Card } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import Loading from "@/components/Loading";
import { ChevronLeft, Calendar, MessageSquare } from "lucide-react";
import ClientProfileCard from "@/components/clients/ClientProfileCard";
import ClientPackagesTab from "@/components/clients/ClientPackagesTab";
import ClientCoursesTab from "@/components/clients/ClientCoursesTab";
import ClientWorkoutsTab from "@/components/clients/ClientWorkoutsTab";
import ClientSessionsTab from "@/components/clients/session-tabs/ClientSessionsTab";
import ClientPaymentsTab from "@/components/clients/ClientPaymentsTab";
import ClientInfoTab from "@/components/clients/ClientInfoTab";
import ClientFormDialog from "@/components/clients/ClientFormDialog";
import PackageDialog from "@/components/clients/PackageDialog";
import CourseDialog from "@/components/clients/CourseDialog";
import WorkoutDialog from "@/components/clients/WorkoutDialog";
import PaymentDialog from "@/components/clients/PaymentDialog";
import WorkoutDetailsDialog from "@/components/workouts/WorkoutDetailsDialog";
import { useClientDetail } from "@/hooks/use-client-detail";
import { useClientInfo } from "@/hooks/use-client-info";
import { useIsMobile } from "@/hooks/use-mobile";
const ClientDetail = () => {
  const {
    slug
  } = useParams<{
    slug: string;
  }>();
  const location = useLocation();
  const navigate = useNavigate();
  const clientId = location.state?.clientId;
  const {
    isClientView,
    isAdmin
  } = useClientInfo();
  const isMobile = useIsMobile();
  const {
    client,
    isLoading,
    clientPackages,
    availablePackages,
    selectedPackageId,
    setSelectedPackageId,
    isPackageDialogOpen,
    setIsPackageDialogOpen,
    clientCourses,
    availableCourses,
    selectedCourseId,
    setSelectedCourseId,
    isCourseDialogOpen,
    setIsCourseDialogOpen,
    clientWorkouts,
    availableWorkouts,
    selectedWorkoutId,
    setSelectedWorkoutId,
    isWorkoutDialogOpen,
    setIsWorkoutDialogOpen,
    isWorkoutDetailsDialogOpen,
    setIsWorkoutDetailsDialogOpen,
    selectedWorkout,
    payments,
    isPaymentDialogOpen,
    setIsPaymentDialogOpen,
    newPayment,
    isEditDialogOpen,
    setIsEditDialogOpen,
    isSaving,
    formData,
    handleInputChange,
    handleSelectChange,
    handleImageUpload,
    handleSaveClient,
    handleAssignPackage,
    handleEnrollCourse,
    handleAssignWorkout,
    handleDeletePackage,
    handleDeleteCourse,
    handleDeleteWorkout,
    handleViewWorkout,
    handleAddPayment
  } = useClientDetail(slug, clientId);
  const handleNavigateToCalendar = () => {
    navigate('/admin/calendar', {
      state: {
        clientId: client?.id
      }
    });
  };
  if (isLoading) {
    return <Loading />;
  }
  if (!client) {
    return <div>
        <div className="text-center py-12">
          <h1 className="text-2xl font-bold mb-4">Cliente non trovato</h1>
          <p className="text-muted-foreground">Il cliente richiesto non esiste o non è accessibile.</p>
        </div>
      </div>;
  }
  const clientFullName = `${client.first_name} ${client.last_name}`;
  return <div className="animate-fade-in">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6">
        <div className="flex items-center gap-2">
          
          <div>
            {isClientView ? <h1 className="text-2xl font-bold">{clientFullName}</h1> : <>
                <h1 className="text-2xl font-bold">{clientFullName}</h1>
                <p className="text-muted-foreground mt-1">Gestisci i dettagli e monitora le attività</p>
              </>}
          </div>
        </div>
        <div className="flex gap-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="outline" size="icon" className="shadow-sm">
                  <MessageSquare size={18} />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Invia messaggio</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          
          <Button variant="outline" className="gap-2 shadow-sm" onClick={() => setIsEditDialogOpen(true)}>
            <span>Modifica</span>
          </Button>
          
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button variant="outline" size="icon" className="shadow-sm" onClick={handleNavigateToCalendar}>
                  <Calendar size={18} />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>Vai al calendario</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <div className="md:col-span-1">
          <ClientProfileCard client={client} />
        </div>
        
        <div className="md:col-span-3">
          <Tabs defaultValue="info" className="w-full">
            <div className="tabs-container relative">
              <TabsList className="mb-4">
                <TabsTrigger value="info" className="flex items-center gap-1 text-xs sm:text-sm">
                  <span>Info</span>
                </TabsTrigger>
                <TabsTrigger value="packages" className="flex items-center gap-1 text-xs sm:text-sm">
                  <span>Pacchetti</span>
                </TabsTrigger>
                <TabsTrigger value="courses" className="flex items-center gap-1 text-xs sm:text-sm">
                  <span>Corsi</span>
                </TabsTrigger>
                <TabsTrigger value="workouts" className="flex items-center gap-1 text-xs sm:text-sm">
                  <span>Allenamenti</span>
                </TabsTrigger>
                <TabsTrigger value="sessions" className="flex items-center gap-1 text-xs sm:text-sm">
                  <span>Sessioni</span>
                </TabsTrigger>
                <TabsTrigger value="payments" className="flex items-center gap-1 text-xs sm:text-sm">
                  <span>Pagamenti</span>
                </TabsTrigger>
              </TabsList>
              
              <div className="fade-left sm:hidden"></div>
              <div className="fade-right sm:hidden"></div>
            </div>
            
            <TabsContent value="info">
              <ClientInfoTab client={client} clientPackages={clientPackages} />
            </TabsContent>
            
            <TabsContent value="packages">
              <ClientPackagesTab clientPackages={clientPackages} onAddPackage={() => setIsPackageDialogOpen(true)} onDeletePackage={handleDeletePackage} />
            </TabsContent>

            <TabsContent value="courses">
              <ClientCoursesTab clientCourses={clientCourses} onAddCourse={() => setIsCourseDialogOpen(true)} onDeleteCourse={handleDeleteCourse} />
            </TabsContent>

            <TabsContent value="workouts">
              <ClientWorkoutsTab clientId={client.id} clientWorkouts={clientWorkouts} onAddWorkout={() => setIsWorkoutDialogOpen(true)} onDeleteWorkout={handleDeleteWorkout} onViewWorkout={handleViewWorkout} />
            </TabsContent>

            <TabsContent value="sessions">
              <ClientSessionsTab clientId={client.id} />
            </TabsContent>

            <TabsContent value="payments">
              <ClientPaymentsTab payments={payments} onAddPayment={() => setIsPaymentDialogOpen(true)} />
            </TabsContent>
          </Tabs>
        </div>
      </div>
      
      <ClientFormDialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen} client={client} onSave={handleSaveClient} isSaving={isSaving} />
      
      <PackageDialog open={isPackageDialogOpen} onOpenChange={setIsPackageDialogOpen} availablePackages={availablePackages} selectedPackageId={selectedPackageId} onSelectPackage={setSelectedPackageId} onAssignPackage={handleAssignPackage} />
      
      <CourseDialog open={isCourseDialogOpen} onOpenChange={setIsCourseDialogOpen} availableCourses={availableCourses} selectedCourseId={selectedCourseId} onSelectCourse={setSelectedCourseId} onEnrollCourse={handleEnrollCourse} />
      
      <WorkoutDialog open={isWorkoutDialogOpen} onOpenChange={setIsWorkoutDialogOpen} availableWorkouts={availableWorkouts} selectedWorkoutId={selectedWorkoutId} onSelectWorkout={setSelectedWorkoutId} onAssignWorkout={handleAssignWorkout} />
      
      <PaymentDialog open={isPaymentDialogOpen} onOpenChange={setIsPaymentDialogOpen} newPayment={newPayment} onInputChange={handleInputChange} availablePackages={availablePackages} availableCourses={availableCourses} onAddPayment={handleAddPayment} />

      <WorkoutDetailsDialog isOpen={isWorkoutDetailsDialogOpen} onOpenChange={setIsWorkoutDetailsDialogOpen} workout={selectedWorkout} />
    </div>;
};
export default ClientDetail;