import { supabase } from "@/integrations/supabase/client";
import type { Database } from "@/integrations/supabase/types";

export type HomeSettings = Database['public']['Tables']['home_settings']['Row'];
export type Testimonial = Database['public']['Tables']['testimonials']['Row'];
export type FAQ = Database['public']['Tables']['faqs']['Row'];
export type Package = Database['public']['Tables']['packages']['Row'];
export type Course = Database['public']['Tables']['courses']['Row'];
export type Service = Database['public']['Tables']['services']['Row'];
export type Client = Database['public']['Tables']['clients']['Row'];
export type Notification = Database['public']['Tables']['notifications']['Row'];
export type Payment = Database['public']['Tables']['payments']['Row'];
export type CalendarEvent = Database['public']['Tables']['calendar_events']['Row'];
export type ClientPackage = Database['public']['Tables']['client_packages']['Row'];
export type ClientCourse = Database['public']['Tables']['client_courses']['Row'];
export type ClientWorkoutDB = {
  id: string;
  client_id: string;
  workout_id: string;
  assigned_date: string | null;
  status: string;
  note: string | null;
  created_at: string | null;
};

export type ExtendedClientPackage = ClientPackage & {
  packages: Package;
};

export type ExtendedClientCourse = ClientCourse & {
  courses: Course & {
    schedule: any[];
    closure_periods?: any[] | null;
  };
};

export type ExtendedPayment = Payment & {
  packageInfo?: {
    name: string;
  };
  course?: {
    name: string;
  };
};

export type CalendarSettings = {
  id?: string;
  created_at?: string;
  updated_at?: string;
  user_id?: string;
  startHour: number;   // Mapping di start_hour
  endHour: number;     // Mapping di end_hour
  workDays: number[];  // Mapping di work_days
};

export type SiteSettings = {
  id: string;
  site_name: string;
  logo_url: string | null;
  contact_email: string;
  contact_phone: string;
  primary_font?: string;
  heading_color?: string;
  paragraph_color?: string;
  button_color?: string;
  created_at: string;
  updated_at: string;
};

export function dbToCalendarSettings(dbSettings: Database['public']['Tables']['calendar_settings']['Row']): CalendarSettings {
  return {
    id: dbSettings.id,
    created_at: dbSettings.created_at,
    updated_at: dbSettings.updated_at,
    user_id: dbSettings.user_id,
    startHour: dbSettings.start_hour,
    endHour: dbSettings.end_hour,
    workDays: dbSettings.work_days
  };
}

export function calendarSettingsToDb(settings: CalendarSettings): Partial<Database['public']['Tables']['calendar_settings']['Insert']> {
  return {
    start_hour: settings.startHour,
    end_hour: settings.endHour,
    work_days: settings.workDays,
    user_id: settings.user_id
  };
}

export type CourseScheduleItem = {
  day: string;
  time?: string;
  startTime?: string;
  endTime?: string;
};

export type CourseClosureItem = {
  start_date?: string;
  end_date?: string;
  reason?: string;
  startDate?: string;
  endDate?: string;
};

export type Gender = 'male' | 'female' | 'other';
export type TrainingFrequency = 'sedentario' | '1 volta settimana' | '2 volte' | '3 volte';

export async function ensureBucketExists(bucketName: string): Promise<boolean> {
  try {
    console.log(`Verificando se il bucket '${bucketName}' esiste...`);
    
    const { data, error } = await supabase.storage.listBuckets();
    
    if (error) {
      console.error("Errore durante il controllo dei bucket:", error);
      return false;
    }
    
    const bucketExists = data.some(b => b.name === bucketName);
    
    console.log(`Bucket '${bucketName}' ${bucketExists ? 'trovato' : 'non trovato'}`);
    return bucketExists;
  } catch (error) {
    console.error("Errore durante la verifica del bucket:", error);
    return false;
  }
}

export { supabase };
