
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Plus } from "lucide-react";
import Loading from "@/components/Loading";
import WorkoutList from "@/components/workouts/WorkoutList";
import WorkoutDialogs from "@/components/workouts/WorkoutDialogs";
import { useWorkouts } from "@/hooks/use-workouts";

const WorkoutsPage = () => {
  const {
    templates,
    isLoading,
    workoutTemplate,
    setWorkoutTemplate,
    isAddDialogOpen,
    setIsAddDialogOpen,
    isEditDialogOpen,
    setIsEditDialogOpen,
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
    isViewDialogOpen,
    setIsViewDialogOpen,
    selectedTemplateId,
    setSelectedTemplateId,
    resetForm,
    saveWorkout,
    handleEditWorkout,
    handleDeleteWorkout,
    loadTemplate,
    openDeleteDialog,
    viewWorkoutDetails
  } = useWorkouts();

  if (isLoading) {
    return <Loading />;
  }

  return <div className="animate-fade-in">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-6">
        <div>
          <h1 className="text-2xl font-bold tracking-tight">Allenamenti</h1>
          
        </div>
        <Button onClick={() => {
        resetForm();
        setIsAddDialogOpen(true);
      }}>
          <Plus className="mr-2 h-4 w-4" />
          Crea Allenamento
        </Button>
      </div>

      <Card className="shadow-sm">

        <CardContent>
          <WorkoutList templates={templates || []} onEdit={loadTemplate} onDelete={openDeleteDialog} onView={viewWorkoutDetails} onCreateNew={() => {
          resetForm();
          setIsAddDialogOpen(true);
        }} />
        </CardContent>
      </Card>

      <WorkoutDialogs isAddDialogOpen={isAddDialogOpen} setIsAddDialogOpen={setIsAddDialogOpen} isEditDialogOpen={isEditDialogOpen} setIsEditDialogOpen={setIsEditDialogOpen} isDeleteDialogOpen={isDeleteDialogOpen} setIsDeleteDialogOpen={setIsDeleteDialogOpen} isViewDialogOpen={isViewDialogOpen} setIsViewDialogOpen={setIsViewDialogOpen} workoutTemplate={workoutTemplate} setWorkoutTemplate={setWorkoutTemplate} resetForm={resetForm} saveWorkout={saveWorkout} handleEditWorkout={handleEditWorkout} handleDeleteWorkout={handleDeleteWorkout} setSelectedTemplateId={setSelectedTemplateId} />
    </div>;
};

export default WorkoutsPage;
