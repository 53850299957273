
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Edit, Trash2, Phone, MessageCircle, UserPlus } from "lucide-react";
import { Client } from "@/lib/supabase";
import ClientPackageHoursBadge from "./ClientPackageHoursBadge";
import ClientCoursesBadge from "./ClientCoursesBadge";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/lib/auth";

interface ClientsTableProps {
  clients: Client[];
  onEdit: (client: Client) => void;
  onDelete: (client: Client) => void;
}

const ClientsTable = ({
  clients,
  onEdit,
  onDelete
}: ClientsTableProps) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const { user } = useAuth();
  const [creatingAccount, setCreatingAccount] = useState<string | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    const checkIsAdmin = async () => {
      if (!user) return;
      try {
        const {
          data,
          error
        } = await supabase.from('profiles').select('role').eq('id', user.id).single();
        if (!error && data) {
          setIsAdmin(data.role === 'admin');
        }
      } catch (error) {
        console.error("Errore nel controllo del ruolo admin:", error);
      }
    };
    checkIsAdmin();
  }, [user]);

  const handleViewClient = (clientId: string) => {
    navigate(`/admin/clients/${clientId}`);
  };

  const formatPhoneForWhatsApp = (phone: string) => {
    if (!phone) return '';
    const cleanPhone = phone.replace(/[\s\-\(\)]/g, '');
    if (cleanPhone.startsWith('0')) {
      return `+39${cleanPhone}`;
    }
    if (!cleanPhone.startsWith('+')) {
      return `+39${cleanPhone}`;
    }
    return cleanPhone;
  };

  const createClientAccount = async (client: Client) => {
    if (!client.email) {
      toast({
        title: "Errore",
        description: "Il cliente deve avere un'email per creare un account",
        variant: "destructive"
      });
      return;
    }

    try {
      setCreatingAccount(client.id);

      const { data: usersList, error: checkError } = await supabase.auth.admin.listUsers().catch(() => ({
        data: null,
        error: null
      }));

      const existingUser = usersList?.users?.find(u => u.email === client.email);
      if (existingUser) {
        const { error: updateError } = await supabase.from('clients').update({
          auth_user_id: existingUser.id
        }).eq('id', client.id);
        
        if (updateError) throw updateError;

        // Invia email di reset password all'account esistente
        const { error: resetError } = await supabase.auth.resetPasswordForEmail(client.email, {
          redirectTo: window.location.origin + '/reset-password',
        });
        
        if (resetError) {
          console.error("Errore nell'invio dell'email di reset password:", resetError);
          throw resetError;
        }
        
        toast({
          title: "Account collegato",
          description: `L'account esistente per ${client.email} è stato collegato al cliente ${client.first_name} ${client.last_name}. Un'email di reset password è stata inviata.`
        });
        return;
      }

      // Crea un nuovo account con una password casuale
      const { data, error } = await supabase.auth.signUp({
        email: client.email,
        password: Math.random().toString(36).slice(-12), // Password temporanea casuale
        options: {
          data: {
            client_registration: "true",
            first_name: client.first_name,
            last_name: client.last_name,
            existing_client_id: client.id,
            admin_created: "true"
          }
        }
      });
      
      if (error) throw error;
      
      const { error: updateError } = await supabase.from('clients').update({
        auth_user_id: data.user?.id
      }).eq('id', client.id);
      
      if (updateError) throw updateError;
      
      // Invia email di reset password
      const { error: resetError } = await supabase.auth.resetPasswordForEmail(client.email, {
        redirectTo: window.location.origin + '/reset-password',
      });
      
      if (resetError) {
        console.error("Errore nell'invio dell'email di reset password:", resetError);
        throw resetError;
      }
      
      toast({
        title: "Account creato",
        description: `Account creato per ${client.first_name} ${client.last_name}. Un'email è stata inviata con le istruzioni per impostare la password.`
      });
    } catch (error: any) {
      console.error("Errore durante la creazione dell'account:", error);
      toast({
        title: "Errore",
        description: error.message || "Errore durante la creazione dell'account",
        variant: "destructive"
      });
    } finally {
      setCreatingAccount(null);
    }
  };

  const filteredClients = isAdmin ? clients : clients.filter(client => client.auth_user_id === user?.id);

  return (
    <div className="overflow-x-auto">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[50px]">Avatar</TableHead>
            <TableHead>Nome</TableHead>
            <TableHead className="w-[150px]">Telefono</TableHead>
            <TableHead>Ore Residue</TableHead>
            <TableHead>Corsi</TableHead>
            {isAdmin && <TableHead className="text-right">Azioni</TableHead>}
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredClients.map(client => {
            const firstName = client.first_name || '';
            const lastName = client.last_name || '';
            const firstInitial = firstName.length > 0 ? firstName[0] : '?';
            const lastInitial = lastName.length > 0 ? lastName[0] : '?';
            return <TableRow key={client.id} className="border-b hover:bg-muted/50 transition-colors">
              <TableCell>
                <Avatar 
                  className="h-10 w-10 cursor-pointer" 
                  onClick={() => handleViewClient(client.id)}
                  style={{ 
                    borderWidth: '2px', 
                    borderStyle: 'solid',
                    borderColor: client.color_code || 'transparent' 
                  }}
                >
                  {client.avatar_url ? <AvatarImage src={client.avatar_url} alt={firstName} /> : <AvatarFallback>{firstInitial}{lastInitial}</AvatarFallback>}
                </Avatar>
              </TableCell>
              <TableCell className="font-medium">
                <div onClick={() => handleViewClient(client.id)} className="cursor-pointer hover:text-primary transition-colors underline">
                  {firstName} {lastName}
                </div>
                <div className="text-xs text-muted-foreground">{client.email || ""}</div>
                {client.auth_user_id && <div className="text-xs text-green-600 font-medium mt-1">
                    Account attivo
                  </div>}
              </TableCell>
              <TableCell>
                {client.telefono ? <div className="flex flex-col gap-2">
                    <a href={`tel:${client.telefono}`} className="flex items-center gap-1 hover:text-primary transition-colors">
                      <Phone className="h-3 w-3" /> 
                      <span>{client.telefono}</span>
                    </a>
                    <a href={`https://wa.me/${formatPhoneForWhatsApp(client.telefono)}`} target="_blank" rel="noopener noreferrer" className="flex items-center gap-1 text-green-600 hover:text-green-700 transition-colors">
                      <MessageCircle className="h-3 w-3" /> 
                      <span className="text-xs">WhatsApp</span>
                    </a>
                  </div> : "-"}
              </TableCell>
              <TableCell>
                <ClientPackageHoursBadge clientId={client.id} />
              </TableCell>
              <TableCell>
                <ClientCoursesBadge clientId={client.id} />
              </TableCell>
              {isAdmin && <TableCell className="text-right">
                  <div className="flex justify-end gap-2">
                    {client.email && !client.auth_user_id && <Button variant="outline" size="icon" onClick={() => createClientAccount(client)} disabled={creatingAccount === client.id} title="Crea account utente">
                        <UserPlus className="h-4 w-4" />
                      </Button>}
                    <Button variant="ghost" size="icon" onClick={() => onEdit(client)} title="Modifica cliente">
                      <Edit className="h-4 w-4" />
                    </Button>
                    <Button variant="ghost" size="icon" onClick={() => onDelete(client)} title="Elimina cliente">
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </div>
                </TableCell>}
            </TableRow>;
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default ClientsTable;
