import { useState, useEffect } from "react";
import { Search, FileDown, Plus, Euro, X } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import Loading from "@/components/Loading";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";

interface Payment {
  id: string;
  created_at: string;
  client_id: string;
  amount: number;
  date: string;
  package_id?: string;
  course_id?: string;
  description?: string;
  client?: {
    first_name: string;
    last_name: string;
  };
  package?: {
    name: string;
  };
  course?: {
    name: string;
  };
}

interface Client {
  id: string;
  first_name: string;
  last_name: string;
}

interface Package {
  id: string;
  name: string;
}

interface Course {
  id: string;
  name: string;
}

interface PaymentFormData {
  client_id: string;
  amount: string;
  date: string;
  package_id?: string;
  course_id?: string;
  description?: string;
}

const emptyFormData: PaymentFormData = {
  client_id: "",
  amount: "",
  date: new Date().toISOString().split("T")[0],
  package_id: "",
  course_id: "",
  description: "",
};

const Payments = () => {
  const [payments, setPayments] = useState<Payment[]>([]);
  const [filteredPayments, setFilteredPayments] = useState<Payment[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [formData, setFormData] = useState<PaymentFormData>(emptyFormData);
  const [clients, setClients] = useState<Client[]>([]);
  const [packages, setPackages] = useState<Package[]>([]);
  const [courses, setCourses] = useState<Course[]>([]);
  const { toast } = useToast();

  const fetchPayments = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from("payments")
        .select(`
          *,
          client:client_id(first_name, last_name),
          package:package_id(name),
          course:course_id(name)
        `)
        .order("date", { ascending: false });

      if (error) {
        throw error;
      }

      setPayments(data || []);
      setFilteredPayments(data || []);
    } catch (error) {
      console.error("Error fetching payments:", error);
      toast({
        title: "Errore",
        description: "Impossibile caricare i pagamenti",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchClients = async () => {
    try {
      const { data, error } = await supabase
        .from("clients")
        .select("id, first_name, last_name")
        .order("last_name", { ascending: true });

      if (error) {
        throw error;
      }

      setClients(data || []);
    } catch (error) {
      console.error("Error fetching clients:", error);
      toast({
        title: "Errore",
        description: "Impossibile caricare i clienti",
        variant: "destructive",
      });
    }
  };

  const fetchPackages = async () => {
    try {
      const { data, error } = await supabase
        .from("packages")
        .select("id, name")
        .order("name", { ascending: true });

      if (error) {
        throw error;
      }

      setPackages(data || []);
    } catch (error) {
      console.error("Error fetching packages:", error);
      toast({
        title: "Errore",
        description: "Impossibile caricare i pacchetti",
        variant: "destructive",
      });
    }
  };

  const fetchCourses = async () => {
    try {
      const { data, error } = await supabase
        .from("courses")
        .select("id, name")
        .order("name", { ascending: true });

      if (error) {
        throw error;
      }

      setCourses(data || []);
    } catch (error) {
      console.error("Error fetching courses:", error);
      toast({
        title: "Errore",
        description: "Impossibile caricare i corsi",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    fetchPayments();
    fetchClients();
    fetchPackages();
    fetchCourses();
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredPayments(payments);
    } else {
      const lowercaseQuery = searchQuery.toLowerCase();
      const filtered = payments.filter(
        (payment) =>
          payment.client?.first_name.toLowerCase().includes(lowercaseQuery) ||
          payment.client?.last_name.toLowerCase().includes(lowercaseQuery) ||
          `${payment.client?.first_name} ${payment.client?.last_name}`
            .toLowerCase()
            .includes(lowercaseQuery) ||
          payment.amount.toString().includes(lowercaseQuery) ||
          payment.package?.name.toLowerCase().includes(lowercaseQuery) ||
          payment.course?.name.toLowerCase().includes(lowercaseQuery) ||
          (payment.description &&
            payment.description.toLowerCase().includes(lowercaseQuery))
      );
      setFilteredPayments(filtered);
    }
  }, [searchQuery, payments]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (name: string, value: string) => {
    setFormData((prev) => ({ ...prev, [name]: value || undefined }));
  };

  const resetForm = () => {
    setFormData(emptyFormData);
  };

  const handleAddPayment = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const newPayment = {
        client_id: formData.client_id,
        amount: parseFloat(formData.amount),
        date: new Date(formData.date).toISOString(),
        package_id: formData.package_id || null,
        course_id: formData.course_id || null,
        description: formData.description || null,
      };

      const { error } = await supabase.from("payments").insert([newPayment]);

      if (error) {
        throw error;
      }

      setIsAddDialogOpen(false);
      resetForm();
      await fetchPayments();

      toast({
        title: "Successo",
        description: "Pagamento aggiunto con successo",
      });
    } catch (error) {
      console.error("Error adding payment:", error);
      toast({
        title: "Errore",
        description: "Impossibile aggiungere il pagamento",
        variant: "destructive",
      });
    }
  };

  const formatDate = (dateString: string) => {
    try {
      return format(new Date(dateString), "dd MMMM yyyy", { locale: it });
    } catch (error) {
      return "Data non valida";
    }
  };

  const formatAmount = (amount: number) => {
    return new Intl.NumberFormat("it-IT", {
      style: "currency",
      currency: "EUR",
    }).format(amount);
  };

  const exportToCSV = () => {
    try {
      const csvData = [
        ["Data", "Cliente", "Importo", "Pacchetto", "Corso", "Descrizione"],
        ...filteredPayments.map((payment) => [
          formatDate(payment.date),
          `${payment.client?.first_name} ${payment.client?.last_name}`,
          formatAmount(payment.amount),
          payment.package?.name || "",
          payment.course?.name || "",
          payment.description || "",
        ]),
      ]
        .map((row) => row.map((cell) => `"${cell}"`).join(","))
        .join("\n");

      const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute(
        "download",
        `pagamenti_${format(new Date(), "yyyyMMdd")}.csv`
      );
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error exporting to CSV:", error);
      toast({
        title: "Errore",
        description: "Impossibile esportare i dati",
        variant: "destructive",
      });
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="space-y-8 animate-fade-in">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
        <div>
          <h1 className="text-3xl font-bold tracking-tight">Pagamenti</h1>
        </div>

        <div className="flex gap-2">
          <Button variant="outline" onClick={exportToCSV}>
            <FileDown className="mr-2 h-4 w-4" />
            Esporta CSV
          </Button>
          <Button onClick={() => setIsAddDialogOpen(true)}>
            <Plus className="mr-2 h-4 w-4" />
            Nuovo Pagamento
          </Button>
        </div>
      </div>

      <div className="flex items-center gap-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Cerca pagamenti..."
            className="pl-10"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {searchQuery && (
            <button
              className="absolute right-3 top-1/2 -translate-y-1/2"
              onClick={() => setSearchQuery("")}
            >
              <X className="h-4 w-4 text-muted-foreground" />
            </button>
          )}
        </div>
      </div>

      <Card>
        <CardHeader className="px-6">
          <div className="flex items-center justify-between">
            <div>
              <CardTitle>Registro Pagamenti</CardTitle>
              <CardDescription>
                {filteredPayments.length} pagament
                {filteredPayments.length === 1 ? "o" : "i"} registrat
                {filteredPayments.length === 1 ? "o" : "i"}
              </CardDescription>
            </div>
            <Euro className="h-5 w-5 text-muted-foreground" />
          </div>
        </CardHeader>
        <CardContent className="px-6">
          {filteredPayments.length > 0 ? (
            <div className="overflow-x-auto">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Data</TableHead>
                    <TableHead>Cliente</TableHead>
                    <TableHead>Importo</TableHead>
                    <TableHead>Pacchetto</TableHead>
                    <TableHead>Corso</TableHead>
                    <TableHead>Descrizione</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredPayments.map((payment) => (
                    <TableRow key={payment.id}>
                      <TableCell>{formatDate(payment.date)}</TableCell>
                      <TableCell>
                        {payment.client ? (
                          `${payment.client.first_name} ${payment.client.last_name}`
                        ) : (
                          <span className="text-muted-foreground italic">
                            Cliente rimosso
                          </span>
                        )}
                      </TableCell>
                      <TableCell className="font-medium">
                        {formatAmount(payment.amount)}
                      </TableCell>
                      <TableCell>
                        {payment.package ? (
                          payment.package.name
                        ) : (
                          <span className="text-muted-foreground">-</span>
                        )}
                      </TableCell>
                      <TableCell>
                        {payment.course ? (
                          payment.course.name
                        ) : (
                          <span className="text-muted-foreground">-</span>
                        )}
                      </TableCell>
                      <TableCell>
                        {payment.description ? (
                          payment.description
                        ) : (
                          <span className="text-muted-foreground">-</span>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          ) : (
            <div className="text-center py-8">
              <p className="text-muted-foreground">
                {searchQuery
                  ? "Nessun pagamento trovato con questi criteri di ricerca"
                  : "Nessun pagamento registrato"}
              </p>
            </div>
          )}
        </CardContent>
      </Card>

      <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Aggiungi Nuovo Pagamento</DialogTitle>
            <DialogDescription>
              Inserisci i dettagli del nuovo pagamento.
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleAddPayment}>
            <div className="grid grid-cols-2 gap-4 py-4">
              <div className="space-y-2">
                <Label htmlFor="client_id">Cliente</Label>
                <Select
                  value={formData.client_id}
                  onValueChange={(value) => handleSelectChange("client_id", value)}
                >
                  <SelectTrigger id="client_id">
                    <SelectValue placeholder="Seleziona cliente" />
                  </SelectTrigger>
                  <SelectContent>
                    {clients.map((client) => (
                      <SelectItem key={client.id} value={client.id}>
                        {client.first_name} {client.last_name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <Label htmlFor="amount">Importo (€)</Label>
                <Input
                  id="amount"
                  name="amount"
                  type="number"
                  step="0.01"
                  min="0"
                  value={formData.amount}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="date">Data</Label>
                <Input
                  id="date"
                  name="date"
                  type="date"
                  value={formData.date}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="package_id">Pacchetto (opzionale)</Label>
                <Select
                  value={formData.package_id}
                  onValueChange={(value) => handleSelectChange("package_id", value)}
                >
                  <SelectTrigger id="package_id">
                    <SelectValue placeholder="Seleziona pacchetto" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="">Nessun pacchetto</SelectItem>
                    {packages.map((pkg) => (
                      <SelectItem key={pkg.id} value={pkg.id}>
                        {pkg.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2">
                <Label htmlFor="course_id">Corso (opzionale)</Label>
                <Select
                  value={formData.course_id}
                  onValueChange={(value) => handleSelectChange("course_id", value)}
                >
                  <SelectTrigger id="course_id">
                    <SelectValue placeholder="Seleziona corso" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="">Nessun corso</SelectItem>
                    {courses.map((course) => (
                      <SelectItem key={course.id} value={course.id}>
                        {course.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <div className="space-y-2 col-span-2">
                <Label htmlFor="description">Descrizione (opzionale)</Label>
                <Textarea
                  id="description"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  rows={3}
                />
              </div>
            </div>
            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => {
                  resetForm();
                  setIsAddDialogOpen(false);
                }}
              >
                Annulla
              </Button>
              <Button type="submit">Aggiungi Pagamento</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Payments;
