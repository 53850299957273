
import { supabase } from "@/lib/supabase";
import { useAuth } from "@/lib/auth";

interface SupabaseUploaderOptions {
  onUploadComplete: (url: string) => void;
  onUploadStart: () => void;
  onUploadError: (error: Error) => void;
  bucketName?: string;
  userRequired?: boolean;
  userId?: string | undefined;
}

/**
 * Funzione di utilità per il caricamento di file su Supabase Storage
 * NOTA: questa NON è un componente React e non deve usare hooks
 */
export default function SupabaseUploader({
  onUploadComplete,
  onUploadStart,
  onUploadError,
  bucketName = 'avatars',
  userRequired = true,
  userId
}: SupabaseUploaderOptions) {
  // Carica l'immagine su Supabase Storage
  const upload = async (file: File): Promise<void> => {
    try {
      // Verifica autenticazione se richiesta
      if (userRequired && !userId) {
        throw new Error("Devi essere autenticato per caricare file");
      }
      
      console.log(`Avvio caricamento su bucket '${bucketName}', dimensione file: ${file.size} bytes`);
      onUploadStart();
      
      // Genera un nome file univoco
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random().toString(36).substring(2, 15)}.${fileExt}`;
      const filePath = `${fileName}`;

      // Carica il file
      const { data, error } = await supabase.storage
        .from(bucketName)
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: false
        });

      if (error) {
        console.error("Errore durante l'upload:", error);
        throw error;
      }

      console.log("Upload completato con successo, data:", data);
      
      // Ottieni l'URL pubblico
      const { data: publicUrl } = supabase.storage
        .from(bucketName)
        .getPublicUrl(filePath);

      console.log("URL pubblico:", publicUrl.publicUrl);
      
      if (!publicUrl.publicUrl) {
        throw new Error("URL pubblico non disponibile");
      }
      
      onUploadComplete(publicUrl.publicUrl);
    } catch (error: any) {
      console.error('Errore durante il caricamento:', error);
      
      // Gestione specifica dell'errore di RLS
      if (error.message && error.message.includes('row-level security')) {
        onUploadError(new Error(`Errore di autorizzazione: non hai il permesso di caricare file nel bucket "${bucketName}". Controlla di essere autenticato e che le policy RLS siano correttamente configurate.`));
      } else {
        onUploadError(new Error(`Impossibile caricare l'immagine: ${error.message || error}`));
      }
    }
  };

  return {
    upload
  };
}
