
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { HelpCircle, Plus, Trash2, Edit } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "@/components/ui/use-toast";
import { supabase } from "@/lib/supabase";
import { Textarea } from "@/components/ui/textarea";

const faqSchema = z.object({
  question: z.string().min(5, {
    message: "La domanda deve avere almeno 5 caratteri",
  }),
  answer: z.string().min(5, {
    message: "La risposta deve avere almeno 5 caratteri",
  }),
  order: z.number().int().min(0),
});

interface FAQTabProps {
  faqs: any[];
  isLoading: boolean;
  onSaved: () => Promise<void>;
}

const FAQTab = ({ faqs, isLoading, onSaved }: FAQTabProps) => {
  const [localLoading, setLocalLoading] = useState(false);
  const [faqDialogOpen, setFaqDialogOpen] = useState(false);
  const [currentFaq, setCurrentFaq] = useState(null);
  
  const faqForm = useForm({
    resolver: zodResolver(faqSchema),
    defaultValues: {
      question: "",
      answer: "",
      order: 0,
    },
  });
  
  const openFaqDialog = (faq = null) => {
    setCurrentFaq(faq);
    if (faq) {
      faqForm.reset(faq);
    } else {
      const maxOrder = faqs.length > 0 
        ? Math.max(...faqs.map(f => f.order)) 
        : -1;
      
      faqForm.reset({
        question: "",
        answer: "",
        order: maxOrder + 1,
      });
    }
    setFaqDialogOpen(true);
  };
  
  const saveFaq = async (values) => {
    setLocalLoading(true);
    try {
      if (currentFaq) {
        const { error } = await supabase
          .from("faqs")
          .update(values)
          .eq("id", currentFaq.id);
        
        if (error) throw error;
        
        toast({
          title: "Successo",
          description: "FAQ aggiornata con successo",
        });
      } else {
        const { error } = await supabase
          .from("faqs")
          .insert([values]);
        
        if (error) throw error;
        
        toast({
          title: "Successo",
          description: "FAQ aggiunta con successo",
        });
      }
      
      setFaqDialogOpen(false);
      await onSaved();
    } catch (error) {
      console.error("Errore nel salvataggio:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore nel salvataggio",
        variant: "destructive",
      });
    } finally {
      setLocalLoading(false);
    }
  };
  
  const deleteFaq = async (id) => {
    if (!confirm("Sei sicuro di voler eliminare questa FAQ?")) return;
    
    setLocalLoading(true);
    try {
      const { error } = await supabase
        .from("faqs")
        .delete()
        .eq("id", id);
      
      if (error) throw error;
      
      toast({
        title: "Successo",
        description: "FAQ eliminata con successo",
      });
      
      await onSaved();
    } catch (error) {
      console.error("Errore nell'eliminazione:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore nell'eliminazione",
        variant: "destructive",
      });
    } finally {
      setLocalLoading(false);
    }
  };
  
  return (
    <>
      <Card className="border-0 shadow-md">
        <CardHeader className="border-b bg-gray-50 flex flex-row items-center justify-between">
          <CardTitle className="text-xl flex items-center gap-2">
            <HelpCircle size={18} className="text-primary" />
            FAQ
          </CardTitle>
          <Button 
            onClick={() => openFaqDialog()} 
            size="sm"
            className="flex items-center gap-1"
          >
            <Plus size={16} />
            <span>Aggiungi</span>
          </Button>
        </CardHeader>
        <CardContent className="p-6">
          {faqs && faqs.length > 0 ? (
            <div className="space-y-4">
              {faqs.map((faq) => (
                <div 
                  key={faq.id}
                  className="border rounded-lg p-4 relative bg-white shadow-sm"
                >
                  <div className="absolute top-2 right-2 flex gap-1">
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => openFaqDialog(faq)}
                    >
                      <Edit size={14} />
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => deleteFaq(faq.id)}
                    >
                      <Trash2 size={14} />
                    </Button>
                  </div>
                  
                  <h3 className="font-medium mb-2 pr-16">
                    {faq.question}
                  </h3>
                  <p className="text-sm text-gray-600">
                    {faq.answer}
                  </p>
                  <div className="mt-2 text-xs text-gray-400">
                    Ordine: {faq.order}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <HelpCircle size={48} className="mx-auto text-gray-300 mb-4" />
              <h3 className="text-lg font-medium mb-2">Nessuna FAQ</h3>
              <p className="text-gray-500 mb-4">Aggiungi domande frequenti per aiutare i tuoi clienti</p>
              <Button onClick={() => openFaqDialog()}>
                <Plus size={16} className="mr-2" />
                Aggiungi FAQ
              </Button>
            </div>
          )}
        </CardContent>
      </Card>

      <Dialog open={faqDialogOpen} onOpenChange={setFaqDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {currentFaq ? "Modifica FAQ" : "Aggiungi FAQ"}
            </DialogTitle>
          </DialogHeader>
          
          <Form {...faqForm}>
            <form onSubmit={faqForm.handleSubmit(saveFaq)} className="space-y-4">
              <FormField
                control={faqForm.control}
                name="question"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Domanda</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <FormField
                control={faqForm.control}
                name="answer"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Risposta</FormLabel>
                    <FormControl>
                      <Textarea {...field} rows={4} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <FormField
                control={faqForm.control}
                name="order"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Ordine</FormLabel>
                    <FormControl>
                      <Input 
                        type="number" 
                        min={0} 
                        {...field} 
                        onChange={(e) => field.onChange(parseInt(e.target.value))}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <DialogFooter>
                <Button 
                  type="button" 
                  variant="outline" 
                  onClick={() => setFaqDialogOpen(false)}
                >
                  Annulla
                </Button>
                <Button type="submit" disabled={isLoading || localLoading}>
                  {isLoading || localLoading ? "Salvataggio..." : "Salva"}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FAQTab;
