
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { Edit, BookOpen, Trash2, CalendarDays, Info, CheckCircle, XCircle } from "lucide-react";
import { Course } from "@/lib/supabase";
import { ScrollArea } from "@/components/ui/scroll-area";
import CourseDetailsDialog from "./CourseDetailsDialog";

interface CoursesListProps {
  courses: Course[];
  onAddCourse: () => void;
  onEditCourse: (id: string) => void;
  onDeleteCourse: (id: string) => void;
}

const CoursesList = ({ courses, onAddCourse, onEditCourse, onDeleteCourse }: CoursesListProps) => {
  const [selectedCourse, setSelectedCourse] = useState<Course | null>(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  
  const handleOpenDetails = (course: Course) => {
    setSelectedCourse(course);
    setDetailsOpen(true);
  };
  
  const handleCloseDetails = () => {
    setDetailsOpen(false);
    setTimeout(() => setSelectedCourse(null), 200);
  };
  
  return (
    <Card className="shadow-sm border-muted">
      <CardHeader className="pb-3">
        <CardTitle className="text-lg font-medium">Tutti i Corsi</CardTitle>
      </CardHeader>
      <CardContent>
        {courses.length > 0 ? (
          <div className="w-full">
            <ScrollArea className="w-full">
              <div className="min-w-[800px] md:min-w-full">
                <table className="w-full text-sm">
                  <thead>
                    <tr className="border-b">
                      <th className="text-left font-medium py-3 px-4">Nome</th>
                      <th className="text-left font-medium py-3 px-4">Max</th>
                      <th className="text-left font-medium py-3 px-4">Prezzo</th>
                      <th className="text-left font-medium py-3 px-4">Stato</th>
                      <th className="text-right font-medium py-3 px-4">Azioni</th>
                    </tr>
                  </thead>
                  <tbody>
                    {courses.map((course) => (
                      <tr key={course.id} className="border-b hover:bg-muted/50 transition-colors">
                        <td className="py-3 px-4 font-medium">{course.name}</td>
                        <td className="py-3 px-4">{course.max_participants}</td>
                        <td className="py-3 px-4">{course.price ? `€${course.price}` : '-'}</td>
                        <td className="py-3 px-4">
                          <div className="flex space-x-2">
                            <Badge variant={course.visible_on_site ? "default" : "secondary"} className="flex items-center gap-1">
                              {course.visible_on_site ? <CheckCircle className="h-3 w-3" /> : <XCircle className="h-3 w-3" />}
                              <span className="hidden md:inline">{course.visible_on_site ? 'Pubblicato' : 'Bozza'}</span>
                            </Badge>
                            {course.add_to_calendar && (
                              <Badge variant="outline" className="bg-amber-50 text-amber-800 border-amber-200">
                                <CalendarDays className="h-3 w-3" />
                              </Badge>
                            )}
                          </div>
                        </td>
                        <td className="py-3 px-4 text-right space-x-1">
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => handleOpenDetails(course)}
                            className="h-8 w-8"
                          >
                            <Info className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => onEditCourse(course.id)}
                            className="h-8 w-8"
                          >
                            <Edit className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => onDeleteCourse(course.id)}
                            className="h-8 w-8 text-destructive"
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </ScrollArea>
          </div>
        ) : (
          <div className="text-center py-12">
            <BookOpen className="mx-auto h-12 w-12 text-zinc-300" />
            <p className="mt-4 text-muted-foreground">Nessun corso disponibile</p>
            <Button 
              variant="outline" 
              className="mt-4"
              onClick={onAddCourse}
            >
              Crea il tuo primo corso
            </Button>
          </div>
        )}
      </CardContent>
      
      <CourseDetailsDialog 
        course={selectedCourse} 
        isOpen={detailsOpen} 
        onClose={handleCloseDetails} 
      />
    </Card>
  );
};

export default CoursesList;
