
import { createRoot } from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { AuthProvider } from './lib/auth';

// Utilizziamo un handler per gli errori globali
window.addEventListener('error', (event) => {
  console.error('Errore globale catturato:', event.error);
});

// Assicuriamo che il componente root esista
const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Elemento root non trovato!");
}

createRoot(rootElement).render(
  <AuthProvider>
    <App />
  </AuthProvider>
);
