
import { Progress } from "@/components/ui/progress";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Hourglass } from "lucide-react";
import { ExtendedClientPackage } from "@/lib/supabase";
import { useEffect, useState } from "react";

interface PackageHoursProgressProps {
  clientPackages: ExtendedClientPackage[];
}

const PackageHoursProgress = ({ clientPackages }: PackageHoursProgressProps) => {
  const [hoursRemaining, setHoursRemaining] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [activePackages, setActivePackages] = useState<ExtendedClientPackage[]>([]);

  useEffect(() => {
    // Trova i pacchetti attivi (con ore rimanenti > 0)
    const activePkgs = clientPackages.filter(pkg => {
      const packageData = pkg.packages;
      const remaining = packageData.hours - (pkg.hours_used || 0);
      return remaining > 0;
    });

    // Ordina per data di acquisto (il più recente prima)
    activePkgs.sort((a, b) => {
      const dateA = a.purchase_date ? new Date(a.purchase_date).getTime() : 0;
      const dateB = b.purchase_date ? new Date(b.purchase_date).getTime() : 0;
      return dateB - dateA;
    });

    setActivePackages(activePkgs);

    if (activePkgs.length > 0) {
      // Calcola il totale delle ore acquistate e delle ore rimanenti
      let totalPackageHours = 0;
      let totalRemainingHours = 0;
      
      activePkgs.forEach(pkg => {
        const packageData = pkg.packages;
        const remaining = packageData.hours - (pkg.hours_used || 0);
        
        totalPackageHours += packageData.hours;
        totalRemainingHours += remaining;
      });
      
      setHoursRemaining(totalRemainingHours);
      setTotalHours(totalPackageHours);
      setProgressPercentage(Math.round((totalRemainingHours / totalPackageHours) * 100));
    } else {
      setHoursRemaining(0);
      setTotalHours(0);
      setProgressPercentage(0);
    }
  }, [clientPackages]);

  if (activePackages.length === 0) {
    return null; // Non mostrare nulla se non ci sono pacchetti attivi
  }

  return (
    <Card className="shadow-sm mb-6">
      <CardHeader className="pb-2">
        <CardTitle className="text-lg flex items-center gap-2">
          <Hourglass size={20} className="text-primary" />
          Ore Rimanenti: {activePackages.length > 1 
            ? `${hoursRemaining} ore (pacchetti multipli)` 
            : activePackages[0].packages.name}
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <Progress value={progressPercentage} className="h-3" />
          <div className="flex justify-between text-sm">
            <span className="font-medium">{hoursRemaining} ore rimaste</span>
            <span className="text-muted-foreground">su {totalHours} ore totali</span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PackageHoursProgress;
