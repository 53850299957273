
import React, { useState, useEffect } from "react";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { AlertCircle, Loader2 } from "lucide-react";
import { calculateRemainingHours, hasEnoughHours } from "@/utils/courseUtils";

interface SessionBookingDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onClose: () => void;
  onConfirm: (clientId: string, packageId: string, title: string, remainingHours: number) => void;
  selectedDate?: Date | null;
  selectedHour?: number | null;
}

interface ClientOption {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
}

interface PackageOption {
  id: string;
  name: string;
  hoursTotal: number;
  hoursUsed: number;
  hoursRemaining: number;
}

const SessionBookingDialog: React.FC<SessionBookingDialogProps> = ({
  open,
  onOpenChange,
  onClose,
  onConfirm,
  selectedDate,
  selectedHour,
}) => {
  const [clients, setClients] = useState<ClientOption[]>([]);
  const [packages, setPackages] = useState<PackageOption[]>([]);
  const [selectedClient, setSelectedClient] = useState<string>("");
  const [selectedPackage, setSelectedPackage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { toast } = useToast();

  // Formatta la data e l'ora selezionate
  const formattedDateTime = selectedDate && selectedHour !== null
    ? `${format(selectedDate, "EEEE d MMMM yyyy", { locale: it })} alle ore ${selectedHour}:00`
    : "";

  // Carica la lista dei clienti
  useEffect(() => {
    if (open) {
      fetchClients();
    } else {
      // Reset degli stati quando la modale si chiude
      setSelectedClient("");
      setSelectedPackage("");
      setPackages([]);
      setIsSubmitting(false);
    }
  }, [open]);

  // Carica i pacchetti quando viene selezionato un cliente
  useEffect(() => {
    if (selectedClient) {
      fetchClientPackages(selectedClient);
    } else {
      setPackages([]);
      setSelectedPackage("");
    }
  }, [selectedClient]);

  // Funzione per recuperare i clienti
  const fetchClients = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from("clients")
        .select("id, first_name, last_name")
        .order("last_name");

      if (error) throw error;

      const clientOptions = (data || []).map(client => ({
        id: client.id,
        firstName: client.first_name,
        lastName: client.last_name,
        fullName: `${client.last_name} ${client.first_name}`
      }));

      setClients(clientOptions);
    } catch (error) {
      console.error("Errore nel caricamento dei clienti:", error);
      toast({
        title: "Errore",
        description: "Impossibile caricare la lista dei clienti",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Funzione per recuperare i pacchetti di un cliente
  const fetchClientPackages = async (clientId: string) => {
    setIsLoading(true);
    console.log("Recupero pacchetti per il cliente:", clientId);
    
    try {
      const { data, error } = await supabase
        .from("client_packages")
        .select(`
          id, 
          client_id,
          package_id,
          hours_used,
          packages (
            id,
            name,
            hours
          )
        `)
        .eq("client_id", clientId);

      if (error) throw error;

      console.log("Pacchetti recuperati:", data);

      // Filtra i pacchetti con ore residue
      const packageOptions = (data || [])
        .filter(pkg => {
          if (!pkg.packages) {
            console.log("Pacchetto senza riferimento:", pkg);
            return false;
          }
          
          const hoursTotal = pkg.packages.hours;
          const hoursUsed = pkg.hours_used || 0;
          
          // Verifica se ci sono ore sufficienti nel pacchetto (almeno 1)
          return hasEnoughHours(hoursTotal, hoursUsed, 1);
        })
        .map(pkg => {
          const hoursTotal = pkg.packages.hours;
          const hoursUsed = pkg.hours_used || 0;
          const hoursRemaining = calculateRemainingHours(hoursTotal, hoursUsed);
          
          return {
            id: pkg.id,
            name: `${pkg.packages.name} (${hoursRemaining}h rimanenti)`,
            hoursTotal,
            hoursUsed,
            hoursRemaining
          };
        });

      console.log("Opzioni di pacchetti con ore disponibili:", packageOptions);
      setPackages(packageOptions);
      
      // Reset del pacchetto selezionato
      setSelectedPackage("");
      
      // Notifica se non ci sono pacchetti disponibili
      if (packageOptions.length === 0) {
        toast({
          title: "Attenzione",
          description: "Il cliente selezionato non ha pacchetti con ore disponibili",
          variant: "warning"
        });
      }
    } catch (error) {
      console.error("Errore nel caricamento dei pacchetti:", error);
      toast({
        title: "Errore",
        description: "Impossibile caricare i pacchetti del cliente",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Gestisce la conferma della prenotazione
  const handleConfirm = async () => {
    if (!selectedClient || !selectedPackage) {
      toast({
        title: "Errore",
        description: "Seleziona un cliente e un pacchetto",
        variant: "destructive"
      });
      return;
    }

    // Trova il cliente e il pacchetto selezionati
    const client = clients.find(c => c.id === selectedClient);
    const packageOption = packages.find(p => p.id === selectedPackage);

    if (!client || !packageOption) {
      toast({
        title: "Errore",
        description: "Cliente o pacchetto non validi",
        variant: "destructive"
      });
      return;
    }
    
    // Verifica che ci siano ore sufficienti
    if (packageOption.hoursRemaining < 1) {
      toast({
        title: "Ore insufficienti",
        description: "Il pacchetto selezionato non ha ore sufficienti per prenotare una sessione",
        variant: "destructive"
      });
      return;
    }

    console.log("Conferma prenotazione:", {
      cliente: client,
      pacchetto: packageOption,
      oreRimanenti: packageOption.hoursRemaining - 1 // Sottraiamo l'ora che stiamo per prenotare
    });

    setIsSubmitting(true);

    try {
      // Crea il titolo dell'evento (nome del cliente)
      const title = client.fullName;

      // Conferma la prenotazione e passa le ore rimanenti aggiornate
      await onConfirm(
        selectedClient, 
        selectedPackage, 
        title, 
        packageOption.hoursRemaining - 1 // Ore rimanenti dopo la prenotazione
      );
    } catch (error) {
      console.error("Errore durante la prenotazione:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore durante la prenotazione della sessione",
        variant: "destructive"
      });
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Prenotazione Sessione</DialogTitle>
          <DialogDescription>
            {formattedDateTime ? (
              <>Prenota una sessione per <span className="font-medium">{formattedDateTime}</span></>
            ) : (
              "Seleziona un cliente e un pacchetto per prenotare una sessione"
            )}
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="client" className="col-span-4">
              Cliente
            </Label>
            <Select
              value={selectedClient}
              onValueChange={setSelectedClient}
              disabled={isLoading || isSubmitting}
            >
              <SelectTrigger id="client" className="col-span-4">
                <SelectValue placeholder="Seleziona un cliente" />
              </SelectTrigger>
              <SelectContent>
                {clients.map(client => (
                  <SelectItem key={client.id} value={client.id}>
                    {client.fullName}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          {selectedClient && (
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="package" className="col-span-4">
                Pacchetto
              </Label>
              <Select
                value={selectedPackage}
                onValueChange={setSelectedPackage}
                disabled={isLoading || packages.length === 0 || isSubmitting}
              >
                <SelectTrigger id="package" className="col-span-4">
                  <SelectValue placeholder={packages.length === 0 ? "Nessun pacchetto disponibile" : "Seleziona un pacchetto"} />
                </SelectTrigger>
                <SelectContent>
                  {packages.map(pkg => (
                    <SelectItem key={pkg.id} value={pkg.id}>
                      {pkg.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              
              {selectedClient && packages.length === 0 && !isLoading && (
                <div className="col-span-4 flex items-start gap-2 text-yellow-600 text-sm mt-1">
                  <AlertCircle className="h-4 w-4 mt-0.5" />
                  <span>Il cliente non ha pacchetti con ore disponibili</span>
                </div>
              )}
            </div>
          )}
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={onClose} disabled={isSubmitting}>
            Annulla
          </Button>
          <Button 
            onClick={handleConfirm} 
            disabled={!selectedClient || !selectedPackage || isLoading || isSubmitting}
          >
            {isSubmitting ? (
              <span className="flex items-center gap-2">
                <Loader2 className="h-4 w-4 animate-spin" />
                Elaborazione...
              </span>
            ) : (
              "Prenota"
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SessionBookingDialog;
