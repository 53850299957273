
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "@/lib/auth";
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import Loading from "./Loading";

// Rotte che sono accessibili SOLO agli amministratori
const ADMIN_ONLY_PATHS = [
  "/admin/dashboard",
  "/admin/clients",
  "/admin/packages",
  "/admin/courses", 
  "/admin/payments",
  "/admin/notifications",
  "/admin/home-options",
  "/admin/workouts"
];

// Rotte che sono accessibili sia agli amministratori che ai clienti
const SHARED_PATHS = [
  "/admin/calendar"
];

// Controlla se è un percorso di dettaglio cliente
const isClientDetailPath = (path: string) => path.includes("/admin/clients/");

const AdminRoute = () => {
  const { user, isLoading } = useAuth(); // Usiamo isLoading invece di loading
  const location = useLocation();
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [isRoleLoading, setIsRoleLoading] = useState(true);
  const [clientId, setClientId] = useState<string | null>(null);

  useEffect(() => {
    const checkUserRole = async () => {
      if (!user) {
        setIsRoleLoading(false);
        return;
      }
      
      try {
        const { data, error } = await supabase
          .from('profiles')
          .select('role')
          .eq('id', user.id)
          .single();
          
        if (error) throw error;
        
        const admin = data?.role === 'admin';
        setIsAdmin(admin);
        
        // Se non è admin, recupera l'ID cliente per verificare l'accesso al dettaglio cliente
        if (!admin) {
          const { data: clientData, error: clientError } = await supabase
            .from('clients')
            .select('id')
            .eq('auth_user_id', user.id)
            .single();
            
          if (!clientError && clientData) {
            setClientId(clientData.id);
          }
        }
      } catch (error) {
        console.error("Errore nella verifica del ruolo:", error);
        setIsAdmin(false);
      } finally {
        setIsRoleLoading(false);
      }
    };
    
    setIsRoleLoading(true);
    checkUserRole();
  }, [user]);

  if (isLoading || isRoleLoading) {
    return <Loading />;
  }

  // Se l'utente non è autenticato, reindirizza alla pagina di login
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Percorso attuale
  const currentPath = location.pathname;
  
  // Se è un percorso di dettaglio cliente
  if (isClientDetailPath(currentPath)) {
    // Estrai l'ID cliente dal percorso
    const urlClientId = currentPath.split('/').pop();
    
    // Gli amministratori possono accedere a tutti i dettagli
    if (isAdmin) {
      return <Outlet />;
    }
    
    // I clienti possono accedere solo ai propri dettagli
    if (clientId && urlClientId === clientId) {
      return <Outlet />;
    }
    
    // Altrimenti reindirizza alla dashboard
    return <Navigate to="/admin/calendar" replace />;
  }
  
  // Per le rotte solo admin
  if (ADMIN_ONLY_PATHS.some(path => currentPath.startsWith(path))) {
    // Gli admin possono accedere a tutte le rotte admin
    if (isAdmin) {
      return <Outlet />;
    } 
    
    // Caso speciale: se è il percorso /admin/clients e non è admin, ma è un cliente
    // reindiriziamo al suo profilo personale
    if (currentPath === "/admin/clients" && !isAdmin && clientId) {
      return <Navigate to={`/admin/clients/${clientId}`} replace />;
    }
    
    // Per altre rotte admin, non accessibili ai clienti, reindirizza al calendario
    return <Navigate to="/admin/calendar" replace />;
  }
  
  // Per le rotte condivise
  if (SHARED_PATHS.some(path => currentPath.startsWith(path))) {
    return <Outlet />;
  }
  
  // Per default, gli admin vanno alla dashboard, i clienti al calendario
  if (currentPath === "/admin") {
    if (isAdmin) {
      return <Navigate to="/admin/dashboard" replace />;
    } else {
      return <Navigate to="/admin/calendar" replace />;
    }
  }
  
  // Per sicurezza, se il percorso non è riconosciuto, reindirizza gli utenti non admin al calendario
  if (!isAdmin) {
    return <Navigate to="/admin/calendar" replace />;
  }
  
  // Permetti l'accesso per default agli admin
  return <Outlet />;
};

export default AdminRoute;
