
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Package } from "@/lib/supabase";

interface PackageDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  availablePackages: Package[];
  selectedPackageId: string;
  onSelectPackage: (id: string) => void;
  onAssignPackage: () => void;
}

const PackageDialog = ({
  open,
  onOpenChange,
  availablePackages,
  selectedPackageId,
  onSelectPackage,
  onAssignPackage
}: PackageDialogProps) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Assegna Pacchetto</DialogTitle>
          <DialogDescription>
            Seleziona un pacchetto da assegnare al cliente
          </DialogDescription>
        </DialogHeader>
        
        <div className="py-4">
          <Label htmlFor="package-select">Pacchetto</Label>
          <Select value={selectedPackageId} onValueChange={onSelectPackage}>
            <SelectTrigger id="package-select">
              <SelectValue placeholder="Seleziona un pacchetto" />
            </SelectTrigger>
            <SelectContent>
              {availablePackages.map(pkg => (
                <SelectItem key={pkg.id} value={pkg.id}>
                  {pkg.name} - {pkg.hours} ore {pkg.price ? `(€${pkg.price})` : ''}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>Annulla</Button>
          <Button onClick={onAssignPackage} disabled={!selectedPackageId}>Assegna</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PackageDialog;
