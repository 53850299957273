
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Dumbbell, Eye, Trash2 } from "lucide-react";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { ClientWorkoutDB } from "@/lib/supabase";
import { WorkoutTemplate } from "@/types/workout";
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/lib/auth";

export interface ClientWorkout extends ClientWorkoutDB {
  workoutTemplate: WorkoutTemplate | null;
}

interface ClientWorkoutsTabProps {
  clientId: string;
  clientWorkouts: ClientWorkout[];
  onAddWorkout: () => void;
  onDeleteWorkout: (workoutId: string) => void;
  onViewWorkout: (workout: WorkoutTemplate) => void;
}

const ClientWorkoutsTab = ({
  clientId,
  clientWorkouts,
  onAddWorkout,
  onDeleteWorkout,
  onViewWorkout
}: ClientWorkoutsTabProps) => {
  const { user } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);

  // Verifica se l'utente è un amministratore
  useEffect(() => {
    const checkUserRole = async () => {
      if (!user) return;
      try {
        const { data, error } = await supabase.from('profiles').select('role').eq('id', user.id).single();
        if (error) throw error;
        setIsAdmin(data?.role === 'admin');
      } catch (error) {
        console.error("Errore nella verifica del ruolo:", error);
        setIsAdmin(false);
      }
    };
    checkUserRole();
  }, [user]);

  const formatDate = (dateString: string | null) => {
    if (!dateString) return "Data non disponibile";
    return format(new Date(dateString), "d MMMM yyyy", {
      locale: it
    });
  };

  return <Card className="shadow-sm">
      <CardHeader className="flex flex-row items-center justify-between">
        <div>
          <CardTitle className="flex items-center gap-2">
            <Dumbbell size={20} className="text-primary" />
            Allenamenti assegnati
          </CardTitle>
          <CardDescription>Elenco degli allenamenti assegnati</CardDescription>
        </div>
        {isAdmin && <Button size="sm" className="gap-2 shadow-sm" onClick={onAddWorkout}>
            <Dumbbell size={16} />
            <span>Assegna allenamento</span>
          </Button>}
      </CardHeader>
      <CardContent>
        {clientWorkouts.length > 0 ? <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Nome</TableHead>
                  <TableHead>Difficoltà</TableHead>
                  <TableHead>Target</TableHead>
                  <TableHead>Durata</TableHead>
                  <TableHead>Data assegnazione</TableHead>
                  <TableHead>Esercizi</TableHead>
                  <TableHead className="text-right">Azioni</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {clientWorkouts.map(workoutItem => <TableRow key={workoutItem.id}>
                    <TableCell className="font-medium">
                      {workoutItem.workoutTemplate?.name || "Allenamento senza nome"}
                    </TableCell>
                    <TableCell>
                      <Badge variant="outline" className={workoutItem.workoutTemplate?.difficulty === "principiante" ? "bg-green-100 text-green-800" : workoutItem.workoutTemplate?.difficulty === "intermedio" ? "bg-yellow-100 text-yellow-800" : workoutItem.workoutTemplate?.difficulty === "avanzato" ? "bg-red-100 text-red-800" : "bg-gray-100 text-gray-800"}>
                        {workoutItem.workoutTemplate?.difficulty || "N/D"}
                      </Badge>
                    </TableCell>
                    <TableCell>{workoutItem.workoutTemplate?.target || "Non specificato"}</TableCell>
                    <TableCell>{workoutItem.workoutTemplate?.duration || "Non specificata"}</TableCell>
                    <TableCell>{formatDate(workoutItem.assigned_date)}</TableCell>
                    <TableCell>{workoutItem.workoutTemplate?.exercises?.length || 0} esercizi</TableCell>
                    <TableCell className="text-right">
                      <div className="flex justify-end gap-2">
                        <Button 
                          variant="ghost" 
                          size="icon" 
                          onClick={() => workoutItem.workoutTemplate && onViewWorkout(workoutItem.workoutTemplate)} 
                          disabled={!workoutItem.workoutTemplate} 
                          title={workoutItem.workoutTemplate ? "Visualizza dettagli" : "Dettagli non disponibili"}
                        >
                          <Eye className="h-4 w-4 text-primary" />
                        </Button>
                        {isAdmin && <Button variant="ghost" size="icon" className="text-destructive hover:text-destructive hover:bg-red-50" onClick={() => onDeleteWorkout(workoutItem.id)} title="Elimina">
                            <Trash2 className="h-4 w-4" />
                          </Button>}
                      </div>
                    </TableCell>
                  </TableRow>)}
              </TableBody>
            </Table>
          </div> : <div className="text-center py-12 border border-dashed rounded-lg">
            <Dumbbell size={36} className="mx-auto text-muted-foreground mb-2 opacity-50" />
            <h3 className="text-lg font-medium mb-1">Nessun allenamento assegnato</h3>
            <p className="text-muted-foreground">
              {isAdmin ? "Assegna un allenamento al cliente" : "Nessun allenamento assegnato"}
            </p>
          </div>}
      </CardContent>
    </Card>;
};

export default ClientWorkoutsTab;
