
import { z } from "zod";

export const clientSchema = z.object({
  first_name: z.string().min(1, "Il nome è obbligatorio"),
  last_name: z.string().min(1, "Il cognome è obbligatorio"),
  age: z.union([
    z.number().min(0),
    z.string().transform(val => val === '' ? null : Number(val))
  ]).nullable(),
  height: z.union([
    z.number().min(0),
    z.string().transform(val => val === '' ? null : Number(val))
  ]).nullable(),
  weight: z.union([
    z.number().min(0),
    z.string().transform(val => val === '' ? null : Number(val))
  ]).nullable(),
  gender: z.enum(["male", "female", "other"]),
  sports: z.union([z.string(), z.array(z.string())]).optional(),
  training_frequency: z.enum(["sedentario", "1 volta settimana", "2 volte", "3 volte"]),
  general_condition: z.string().optional(),
  avatar_url: z.string().nullable().optional(),
  email: z.string().email("Email non valida").nullable().optional(),
  telefono: z.string().nullable().optional(),
});

export type ClientFormValues = z.infer<typeof clientSchema>;
