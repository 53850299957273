
import { useReducer, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/hooks/use-toast";
import { Client } from "@/lib/supabase";
import { WorkoutTemplate } from "@/types/workout";
import { ClientFormValues } from "@/hooks/use-clients";

import { clientDetailReducer, initialClientDetailState } from "./client-detail/reducer";
import * as dataFetching from "./client-detail/data-fetching";
import { usePackageOperations } from "./client-detail/package-operations";
import { useCourseOperations } from "./client-detail/course-operations";
import { useWorkoutOperations } from "./client-detail/workout-operations";
import { usePaymentOperations } from "./client-detail/payment-operations";
import { useClientOperations } from "./client-detail/client-operations";
import { ClientFormData } from "./client-detail/types";

export function useClientDetail(clientId?: string, initialClientId?: string) {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [state, dispatch] = useReducer(clientDetailReducer, initialClientDetailState);
  
  const packageOps = usePackageOperations();
  const courseOps = useCourseOperations();
  const workoutOps = useWorkoutOperations();
  const paymentOps = usePaymentOperations();
  const clientOps = useClientOperations();

  useEffect(() => {
    fetchClientData(clientId || initialClientId);
    fetchAvailableData();
  }, [clientId, initialClientId]);

  const fetchClientData = async (id?: string) => {
    if (!id) return;
    
    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const client = await dataFetching.fetchClientData(id);
      
      if (client) {
        dispatch({ type: 'SET_CLIENT', payload: client });
        
        // Imposta i dati del form
        dispatch({ 
          type: 'SET_FORM_DATA', 
          payload: {
            first_name: client.first_name,
            last_name: client.last_name,
            age: client.age ? client.age.toString() : "",
            height: client.height ? client.height.toString() : "",
            weight: client.weight ? client.weight.toString() : "",
            gender: client.gender,
            sports: client.sports ? client.sports.join(', ') : '',
            training_frequency: client.training_frequency,
            general_condition: client.general_condition || 'Sufficiente',
            avatar_url: client.avatar_url,
            email: client.email || "",
            telefono: client.telefono || ""
          }
        });
        
        // Carica tutti i dati associati al cliente in parallelo
        const [packages, courses, workouts, payments] = await Promise.all([
          dataFetching.fetchClientPackages(client.id),
          dataFetching.fetchClientCourses(client.id),
          dataFetching.fetchClientWorkoutsData(client.id),
          dataFetching.fetchClientPayments(client.id)
        ]);
        
        dispatch({ type: 'SET_CLIENT_PACKAGES', payload: packages });
        dispatch({ type: 'SET_CLIENT_COURSES', payload: courses });
        dispatch({ type: 'SET_CLIENT_WORKOUTS', payload: workouts });
        dispatch({ type: 'SET_PAYMENTS', payload: payments });
      }
    } catch (error) {
      console.error('Errore nel recupero dei dati del cliente:', error);
      toast({
        title: "Errore",
        description: "Impossibile caricare i dati del cliente",
        variant: "destructive"
      });
      
      // Se è un errore di ID non valido, redirigi alla lista clienti
      if (error instanceof Error && error.message === "ID cliente non valido") {
        navigate('/admin/clients');
      }
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const fetchAvailableData = async () => {
    try {
      const [packages, courses, workouts] = await Promise.all([
        dataFetching.fetchAvailablePackages(),
        dataFetching.fetchAvailableCourses(),
        dataFetching.fetchAvailableWorkouts()
      ]);
      
      dispatch({ type: 'SET_AVAILABLE_PACKAGES', payload: packages });
      dispatch({ type: 'SET_AVAILABLE_COURSES', payload: courses });
      dispatch({ type: 'SET_AVAILABLE_WORKOUTS', payload: workouts });
    } catch (error) {
      console.error('Errore nel recupero dei dati disponibili:', error);
    }
  };

  // Handler per input form
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    
    if (name.startsWith('newPayment.')) {
      const paymentField = name.split('.')[1];
      dispatch({ 
        type: 'SET_NEW_PAYMENT', 
        payload: { [paymentField]: value } 
      });
    } else {
      dispatch({ 
        type: 'SET_FORM_DATA', 
        payload: { [name]: value } 
      });
    }
  };

  const handleSelectChange = (name: string, value: string) => {
    dispatch({ 
      type: 'SET_FORM_DATA', 
      payload: { [name]: value } 
    });
  };

  const handleImageUpload = (url: string) => {
    dispatch({ 
      type: 'SET_FORM_DATA', 
      payload: { avatar_url: url } 
    });
  };

  // Operazioni wrapper
  const handleAssignPackage = async () => {
    if (!state.client || !state.selectedPackageId) return;
    const success = await packageOps.assignPackage(state.client.id, state.selectedPackageId);
    if (success) {
      const packages = await dataFetching.fetchClientPackages(state.client.id);
      dispatch({ type: 'SET_CLIENT_PACKAGES', payload: packages });
      dispatch({ type: 'SET_PACKAGE_DIALOG_OPEN', payload: false });
      dispatch({ type: 'SET_SELECTED_PACKAGE_ID', payload: "" });
    }
  };

  const handleDeletePackage = async (packageId: string) => {
    if (!state.client) return;
    const success = await packageOps.deletePackage(state.client.id, packageId);
    if (success && state.client) {
      const packages = await dataFetching.fetchClientPackages(state.client.id);
      dispatch({ type: 'SET_CLIENT_PACKAGES', payload: packages });
    }
  };

  const handleEnrollCourse = async () => {
    if (!state.client || !state.selectedCourseId) return;
    const success = await courseOps.enrollCourse(state.client.id, state.selectedCourseId);
    if (success) {
      const courses = await dataFetching.fetchClientCourses(state.client.id);
      dispatch({ type: 'SET_CLIENT_COURSES', payload: courses });
      dispatch({ type: 'SET_COURSE_DIALOG_OPEN', payload: false });
      dispatch({ type: 'SET_SELECTED_COURSE_ID', payload: "" });
    }
  };

  const handleDeleteCourse = async (courseId: string) => {
    if (!state.client) return;
    const success = await courseOps.deleteCourse(state.client.id, courseId);
    if (success && state.client) {
      const courses = await dataFetching.fetchClientCourses(state.client.id);
      dispatch({ type: 'SET_CLIENT_COURSES', payload: courses });
    }
  };

  const handleAssignWorkout = async () => {
    if (!state.client || !state.selectedWorkoutId) return;
    const success = await workoutOps.assignWorkout(state.client.id, state.selectedWorkoutId);
    if (success && state.client) {
      const workouts = await dataFetching.fetchClientWorkoutsData(state.client.id);
      dispatch({ type: 'SET_CLIENT_WORKOUTS', payload: workouts });
      dispatch({ type: 'SET_WORKOUT_DIALOG_OPEN', payload: false });
      dispatch({ type: 'SET_SELECTED_WORKOUT_ID', payload: "" });
    }
  };

  const handleDeleteWorkout = async (workoutId: string) => {
    const success = await workoutOps.deleteWorkout(workoutId);
    if (success && state.client) {
      const workouts = await dataFetching.fetchClientWorkoutsData(state.client.id);
      dispatch({ type: 'SET_CLIENT_WORKOUTS', payload: workouts });
    }
  };

  const handleViewWorkout = (workout: WorkoutTemplate) => {
    workoutOps.viewWorkout(
      workout, 
      (workout) => dispatch({ type: 'SET_SELECTED_WORKOUT', payload: workout }),
      (open) => dispatch({ type: 'SET_WORKOUT_DETAILS_DIALOG_OPEN', payload: open })
    );
  };

  const handleAddPayment = async () => {
    if (!state.client) return;
    
    const success = await paymentOps.addPayment(state.client.id, state.newPayment);
    if (success) {
      dispatch({ type: 'RESET_NEW_PAYMENT' });
      dispatch({ type: 'SET_PAYMENT_DIALOG_OPEN', payload: false });
      
      if (state.client) {
        const payments = await dataFetching.fetchClientPayments(state.client.id);
        dispatch({ type: 'SET_PAYMENTS', payload: payments });
      }
    }
  };

  const handleSaveClient = async (formValues: ClientFormValues) => {
    if (!state.client) return;
    
    dispatch({ type: 'SET_IS_SAVING', payload: true });
    try {
      // Assicuriamoci che i campi numerici siano effettivamente numeri
      const processedFormValues = {
        ...formValues,
        age: formValues.age === null || formValues.age === undefined ? null : Number(formValues.age),
        height: formValues.height === null || formValues.height === undefined ? null : Number(formValues.height),
        weight: formValues.weight === null || formValues.weight === undefined ? null : Number(formValues.weight)
      };
      
      const success = await clientOps.saveClient(state.client.id, processedFormValues);
      
      if (success) {
        // Aggiorna il client con i nuovi valori
        const updatedClient = {
          ...state.client,
          first_name: formValues.first_name,
          last_name: formValues.last_name,
          gender: formValues.gender,
          age: formValues.age === null || formValues.age === undefined ? null : Number(formValues.age),
          height: formValues.height === null || formValues.height === undefined ? null : Number(formValues.height),
          weight: formValues.weight === null || formValues.weight === undefined ? null : Number(formValues.weight),
          training_frequency: formValues.training_frequency || null,
          general_condition: formValues.general_condition || null,
          avatar_url: formValues.avatar_url || null,
          email: formValues.email || null,
          telefono: formValues.telefono || null,
          sports: typeof formValues.sports === 'string' 
            ? formValues.sports.trim().split(',').map(sport => sport.trim()).filter(Boolean)
            : (Array.isArray(formValues.sports) ? formValues.sports : [])
        };
        
        dispatch({ type: 'SET_CLIENT', payload: updatedClient });
        dispatch({ type: 'SET_EDIT_DIALOG_OPEN', payload: false });
      }
    } finally {
      dispatch({ type: 'SET_IS_SAVING', payload: false });
    }
  };

  return {
    // Stato
    client: state.client,
    isLoading: state.isLoading,
    clientPackages: state.clientPackages,
    availablePackages: state.availablePackages,
    selectedPackageId: state.selectedPackageId,
    isPackageDialogOpen: state.isPackageDialogOpen,
    clientCourses: state.clientCourses,
    availableCourses: state.availableCourses,
    selectedCourseId: state.selectedCourseId,
    isCourseDialogOpen: state.isCourseDialogOpen,
    clientWorkouts: state.clientWorkouts,
    availableWorkouts: state.availableWorkouts,
    selectedWorkoutId: state.selectedWorkoutId,
    isWorkoutDialogOpen: state.isWorkoutDialogOpen,
    isWorkoutDetailsDialogOpen: state.isWorkoutDetailsDialogOpen,
    selectedWorkout: state.selectedWorkout,
    payments: state.payments,
    isPaymentDialogOpen: state.isPaymentDialogOpen,
    newPayment: state.newPayment,
    isEditDialogOpen: state.isEditDialogOpen,
    isSaving: state.isSaving,
    formData: state.formData,
    
    // Setter per lo stato dei dialog
    setSelectedPackageId: (id: string) => dispatch({ type: 'SET_SELECTED_PACKAGE_ID', payload: id }),
    setIsPackageDialogOpen: (open: boolean) => dispatch({ type: 'SET_PACKAGE_DIALOG_OPEN', payload: open }),
    setSelectedCourseId: (id: string) => dispatch({ type: 'SET_SELECTED_COURSE_ID', payload: id }),
    setIsCourseDialogOpen: (open: boolean) => dispatch({ type: 'SET_COURSE_DIALOG_OPEN', payload: open }),
    setSelectedWorkoutId: (id: string) => dispatch({ type: 'SET_SELECTED_WORKOUT_ID', payload: id }),
    setIsWorkoutDialogOpen: (open: boolean) => dispatch({ type: 'SET_WORKOUT_DIALOG_OPEN', payload: open }),
    setIsWorkoutDetailsDialogOpen: (open: boolean) => dispatch({ type: 'SET_WORKOUT_DETAILS_DIALOG_OPEN', payload: open }),
    setIsPaymentDialogOpen: (open: boolean) => dispatch({ type: 'SET_PAYMENT_DIALOG_OPEN', payload: open }),
    setIsEditDialogOpen: (open: boolean) => dispatch({ type: 'SET_EDIT_DIALOG_OPEN', payload: open }),
    
    // Handler
    handleInputChange,
    handleSelectChange,
    handleImageUpload,
    handleSaveClient,
    handleAssignPackage,
    handleEnrollCourse,
    handleAssignWorkout,
    handleDeletePackage,
    handleDeleteCourse,
    handleDeleteWorkout,
    handleViewWorkout,
    handleAddPayment
  };
}
