
import { ClientDetailState, ClientDetailAction, NewPayment } from './types';

export const initialClientDetailState: ClientDetailState = {
  client: null,
  isLoading: true,
  clientPackages: [],
  availablePackages: [],
  selectedPackageId: "",
  clientCourses: [],
  availableCourses: [],
  selectedCourseId: "",
  clientWorkouts: [],
  availableWorkouts: [],
  selectedWorkoutId: "",
  isWorkoutDetailsDialogOpen: false,
  selectedWorkout: null,
  payments: [],
  newPayment: {
    amount: 0,
    date: new Date().toISOString().split('T')[0],
    description: '',
    package_id: '',
    course_id: ''
  },
  isEditDialogOpen: false,
  isSaving: false,
  formData: {
    first_name: "",
    last_name: "",
    age: "",
    height: "",
    weight: "",
    gender: "male",
    sports: "",
    training_frequency: "sedentario",
    general_condition: "Sufficiente",
    email: "",
    telefono: ""
  },
  isPackageDialogOpen: false,
  isCourseDialogOpen: false,
  isWorkoutDialogOpen: false,
  isPaymentDialogOpen: false
};

export function clientDetailReducer(state: ClientDetailState, action: ClientDetailAction): ClientDetailState {
  switch (action.type) {
    case 'SET_CLIENT':
      return { ...state, client: action.payload };
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_CLIENT_PACKAGES':
      return { ...state, clientPackages: action.payload };
    case 'SET_AVAILABLE_PACKAGES':
      return { ...state, availablePackages: action.payload };
    case 'SET_SELECTED_PACKAGE_ID':
      return { ...state, selectedPackageId: action.payload };
    case 'SET_CLIENT_COURSES':
      return { ...state, clientCourses: action.payload };
    case 'SET_AVAILABLE_COURSES':
      return { ...state, availableCourses: action.payload };
    case 'SET_SELECTED_COURSE_ID':
      return { ...state, selectedCourseId: action.payload };
    case 'SET_CLIENT_WORKOUTS':
      return { ...state, clientWorkouts: action.payload };
    case 'SET_AVAILABLE_WORKOUTS':
      return { ...state, availableWorkouts: action.payload };
    case 'SET_SELECTED_WORKOUT_ID':
      return { ...state, selectedWorkoutId: action.payload };
    case 'SET_WORKOUT_DETAILS_DIALOG_OPEN':
      return { ...state, isWorkoutDetailsDialogOpen: action.payload };
    case 'SET_SELECTED_WORKOUT':
      return { ...state, selectedWorkout: action.payload };
    case 'SET_PAYMENTS':
      return { ...state, payments: action.payload };
    case 'SET_NEW_PAYMENT':
      return { 
        ...state, 
        newPayment: { ...state.newPayment, ...action.payload } 
      };
    case 'RESET_NEW_PAYMENT':
      return { 
        ...state, 
        newPayment: {
          amount: 0,
          date: new Date().toISOString().split('T')[0],
          description: '',
          package_id: '',
          course_id: ''
        }
      };
    case 'SET_EDIT_DIALOG_OPEN':
      return { ...state, isEditDialogOpen: action.payload };
    case 'SET_IS_SAVING':
      return { ...state, isSaving: action.payload };
    case 'SET_FORM_DATA':
      return { ...state, formData: { ...state.formData, ...action.payload } };
    case 'SET_PACKAGE_DIALOG_OPEN':
      return { ...state, isPackageDialogOpen: action.payload };
    case 'SET_COURSE_DIALOG_OPEN':
      return { ...state, isCourseDialogOpen: action.payload };
    case 'SET_WORKOUT_DIALOG_OPEN':
      return { ...state, isWorkoutDialogOpen: action.payload };
    case 'SET_PAYMENT_DIALOG_OPEN':
      return { ...state, isPaymentDialogOpen: action.payload };
    default:
      return state;
  }
}
