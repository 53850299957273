
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";

export function usePackageOperations() {
  const { toast } = useToast();

  const assignPackage = async (clientId: string, packageId: string) => {
    if (!clientId || !packageId) return false;
    try {
      const newClientPackage = {
        client_id: clientId,
        package_id: packageId,
        hours_used: 0,
        purchase_date: new Date().toISOString()
      };
      const { error } = await supabase.from('client_packages').insert([newClientPackage]);
      if (error) throw error;
      toast({
        title: "Successo",
        description: "Pacchetto assegnato con successo"
      });
      
      // Non è necessario inviare un'email qui poiché questo evento è gestito già dal trigger database
      // che crea una notifica, e non viene richiesto l'invio di un'email quando si assegna un pacchetto
      
      return true;
    } catch (error) {
      console.error('Errore nell\'assegnazione del pacchetto:', error);
      toast({
        title: "Errore",
        description: "Impossibile assegnare il pacchetto",
        variant: "destructive"
      });
      return false;
    }
  };

  const deletePackage = async (clientId: string, packageId: string) => {
    if (!clientId) return false;
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Imposta l'ora a mezzanotte per includere tutti gli eventi di oggi
      
      // Prima ottieni i dati del cliente e il nome del pacchetto per l'email
      const { data: clientData, error: clientError } = await supabase
        .from('clients')
        .select('email, first_name, last_name')
        .eq('id', clientId)
        .single();
      
      if (clientError) {
        console.error('Errore nel recupero dei dati del cliente:', clientError);
      }
      
      const { data: packageData, error: packageError } = await supabase
        .from('client_packages')
        .select('packages(name)')
        .eq('id', packageId)
        .single();
      
      if (packageError) {
        console.error('Errore nel recupero dei dati del pacchetto:', packageError);
      }
      
      const { error: calendarError } = await supabase
        .from('calendar_events')
        .delete()
        .eq('type', 'package')
        .eq('client_id', clientId)
        .gte('start_time', today.toISOString());
      
      if (calendarError) {
        console.error('Errore nell\'eliminazione degli eventi del calendario:', calendarError);
      }
      
      const { error } = await supabase
        .from('client_packages')
        .delete()
        .eq('id', packageId);
      
      if (error) throw error;
      
      toast({
        title: "Successo",
        description: "Pacchetto e sessioni future associate eliminate con successo"
      });
      
      // Invia email al cliente se abbiamo i suoi dati
      if (clientData?.email && packageData?.packages) {
        try {
          const { sendEmail, createPackageHoursRemovedEmail } = await import("@/lib/email-utils");
          
          const clientName = `${clientData.first_name} ${clientData.last_name}`;
          const packageName = packageData.packages.name;
          
          // Notiamo che non sappiamo quante ore erano rimaste, quindi non possiamo dire esattamente
          // quante ore sono state "restituite" ma possiamo notificare che il pacchetto è stato rimosso
          const emailHtml = `
            <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto; padding: 20px; border: 1px solid #eee; border-radius: 5px;">
              <h2 style="color: #333;">Pacchetto rimosso</h2>
              <p>Ciao ${clientName},</p>
              <p>Ti informiamo che il pacchetto "${packageName}" è stato rimosso dal tuo profilo.</p>
              <p>Se hai domande, non esitare a contattarci.</p>
              <p>Cordiali saluti,<br>Il team di FitCoach</p>
            </div>
          `;
          const emailSubject = "Pacchetto rimosso";
          
          await sendEmail(clientData.email, emailSubject, emailHtml);
        } catch (emailError) {
          console.error("Errore nell'invio dell'email di rimozione pacchetto:", emailError);
        }
      }
      
      return true;
    } catch (error) {
      console.error('Errore nell\'eliminazione del pacchetto:', error);
      toast({
        title: "Errore",
        description: "Impossibile eliminare il pacchetto",
        variant: "destructive"
      });
      return false;
    }
  };

  return {
    assignPackage,
    deletePackage
  };
}
