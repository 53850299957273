
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Plus, Trash2, User } from "lucide-react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "@/components/ui/use-toast";
import { supabase } from "@/lib/supabase";
import ImageUploader from "@/components/ImageUploader";

const aboutSchema = z.object({
  about_title: z.string().max(100, {
    message: "Il titolo non può superare i 100 caratteri",
  }),
  about_description: z.array(z.string()).min(1, {
    message: "Devi inserire almeno una descrizione",
  }),
  about_image_url: z.string().optional(),
  about_stats: z.array(z.object({
    title: z.string(),
    value: z.string(),
  })).min(1, {
    message: "Devi inserire almeno una statistica",
  }),
});

interface AboutSettingsTabProps {
  homeSettings: any;
  isLoading: boolean;
  onSaved: () => Promise<void>;
}

const AboutSettingsTab = ({ homeSettings, isLoading, onSaved }: AboutSettingsTabProps) => {
  const [localLoading, setLocalLoading] = useState(false);
  const [aboutDescriptions, setAboutDescriptions] = useState([""]);
  const [aboutStats, setAboutStats] = useState([{ title: "", value: "" }]);
  
  const aboutForm = useForm({
    resolver: zodResolver(aboutSchema),
    defaultValues: {
      about_title: "",
      about_description: [""],
      about_image_url: "",
      about_stats: [{ title: "", value: "" }],
    },
  });
  
  // Aggiorna i valori di default quando cambiano le impostazioni
  useEffect(() => {
    if (homeSettings) {
      const aboutDescArr = Array.isArray(homeSettings.about_description) ? 
        homeSettings.about_description : 
        [homeSettings.about_description || ""];
      
      setAboutDescriptions(aboutDescArr);
      
      const aboutStatsArr = Array.isArray(homeSettings.about_stats) ? 
        homeSettings.about_stats : 
        [{ title: "", value: "" }];
      
      setAboutStats(aboutStatsArr);
      
      aboutForm.reset({
        about_title: homeSettings.about_title || "",
        about_description: aboutDescArr,
        about_image_url: homeSettings.about_image_url || "",
        about_stats: aboutStatsArr,
      });
    }
  }, [homeSettings, aboutForm]);
  
  const saveAboutSettings = async (values) => {
    setLocalLoading(true);
    try {
      const { error } = await supabase
        .from("home_settings")
        .update({
          about_title: values.about_title,
          about_description: values.about_description,
          about_image_url: values.about_image_url,
          about_stats: values.about_stats
        })
        .eq("id", homeSettings.id);
      
      if (error) throw error;
      
      toast({
        title: "Successo",
        description: "Impostazioni About salvate con successo",
      });
      
      await onSaved();
    } catch (error) {
      console.error("Errore nel salvataggio:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore nel salvataggio",
        variant: "destructive",
      });
    } finally {
      setLocalLoading(false);
    }
  };
  
  const addDescription = () => {
    const newDescriptions = [...aboutDescriptions, ""];
    setAboutDescriptions(newDescriptions);
    aboutForm.setValue("about_description", newDescriptions);
  };
  
  const removeDescription = (index) => {
    if (aboutDescriptions.length <= 1) return;
    
    const newDescriptions = aboutDescriptions.filter((_, i) => i !== index);
    setAboutDescriptions(newDescriptions);
    aboutForm.setValue("about_description", newDescriptions);
  };
  
  const updateDescription = (index, value) => {
    const newDescriptions = [...aboutDescriptions];
    newDescriptions[index] = value;
    setAboutDescriptions(newDescriptions);
    aboutForm.setValue("about_description", newDescriptions);
  };
  
  const addStat = () => {
    const newStats = [...aboutStats, { title: "", value: "" }];
    setAboutStats(newStats);
    aboutForm.setValue("about_stats", newStats);
  };
  
  const removeStat = (index) => {
    if (aboutStats.length <= 1) return;
    
    const newStats = aboutStats.filter((_, i) => i !== index);
    setAboutStats(newStats);
    aboutForm.setValue("about_stats", newStats);
  };
  
  const updateStat = (index, field, value) => {
    const newStats = [...aboutStats];
    newStats[index] = { ...newStats[index], [field]: value };
    setAboutStats(newStats);
    aboutForm.setValue("about_stats", newStats);
  };
  
  const handleAboutImageUpload = (url) => {
    aboutForm.setValue("about_image_url", url);
  };
  
  return (
    <Card className="border-0 shadow-md">
      <CardHeader className="border-b bg-gray-50">
        <CardTitle className="text-xl flex items-center gap-2">
          <User size={18} className="text-primary" />
          Configurazione About Section
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6">
        {homeSettings ? (
          <Form {...aboutForm}>
            <form onSubmit={aboutForm.handleSubmit(saveAboutSettings)} className="grid grid-cols-1 gap-8">
              <div className="space-y-6">
                <FormField
                  control={aboutForm.control}
                  name="about_title"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Titolo About</FormLabel>
                      <FormControl>
                        <Input {...field} className="max-w-lg" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                
                <div>
                  <FormLabel>Descrizioni</FormLabel>
                  <div className="space-y-3 mt-2">
                    {aboutDescriptions.map((desc, index) => (
                      <div key={index} className="flex gap-2">
                        <Textarea
                          value={desc}
                          onChange={(e) => updateDescription(index, e.target.value)}
                          rows={2}
                          className="flex-1"
                        />
                        <Button
                          type="button"
                          variant="outline"
                          size="icon"
                          onClick={() => removeDescription(index)}
                          disabled={aboutDescriptions.length <= 1}
                        >
                          <Trash2 size={16} />
                        </Button>
                      </div>
                    ))}
                    
                    <Button
                      type="button"
                      variant="outline"
                      size="sm"
                      onClick={addDescription}
                      className="mt-2"
                    >
                      <Plus size={16} className="mr-2" />
                      Aggiungi Descrizione
                    </Button>
                  </div>
                  {aboutForm.formState.errors.about_description && (
                    <p className="text-sm font-medium text-destructive mt-2">
                      {aboutForm.formState.errors.about_description.message}
                    </p>
                  )}
                </div>
                
                <FormField
                  control={aboutForm.control}
                  name="about_image_url"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Immagine About</FormLabel>
                      <FormControl>
                        <div className="space-y-4">
                          <ImageUploader 
                            onImageUpload={handleAboutImageUpload} 
                            initialImage={field.value}
                          />
                          
                          {field.value && (
                            <div className="w-full max-w-md rounded-lg overflow-hidden mb-2 border">
                              <img src={field.value} alt="About" className="w-full h-auto" />
                            </div>
                          )}
                          
                          <div className="flex items-center gap-2">
                            <Input 
                              {...field} 
                              className="max-w-lg flex-1" 
                              placeholder="URL dell'immagine"
                            />
                          </div>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                
                <div>
                  <FormLabel>Statistiche</FormLabel>
                  <div className="space-y-3 mt-2">
                    {aboutStats.map((stat, index) => (
                      <div key={index} className="grid grid-cols-1 md:grid-cols-2 gap-2">
                        <div>
                          <Input
                            value={stat.title}
                            onChange={(e) => updateStat(index, "title", e.target.value)}
                            placeholder="Titolo"
                          />
                        </div>
                        <div className="flex gap-2">
                          <Input
                            value={stat.value}
                            onChange={(e) => updateStat(index, "value", e.target.value)}
                            placeholder="Valore"
                          />
                          <Button
                            type="button"
                            variant="outline"
                            size="icon"
                            onClick={() => removeStat(index)}
                            disabled={aboutStats.length <= 1}
                          >
                            <Trash2 size={16} />
                          </Button>
                        </div>
                      </div>
                    ))}
                    
                    <Button
                      type="button"
                      variant="outline"
                      size="sm"
                      onClick={addStat}
                      className="mt-2"
                    >
                      <Plus size={16} className="mr-2" />
                      Aggiungi Statistica
                    </Button>
                  </div>
                  {aboutForm.formState.errors.about_stats && (
                    <p className="text-sm font-medium text-destructive mt-2">
                      {aboutForm.formState.errors.about_stats.message}
                    </p>
                  )}
                </div>
              </div>
              
              <div className="pt-4 border-t">
                <Button type="submit" disabled={isLoading || localLoading}>
                  {isLoading || localLoading ? "Salvataggio..." : "Salva Modifiche"}
                </Button>
              </div>
            </form>
          </Form>
        ) : (
          <div className="flex justify-center items-center h-64">
            <p>Caricamento configurazione about...</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default AboutSettingsTab;
