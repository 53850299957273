
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Dumbbell, Trash2 } from "lucide-react";
import { ExerciseInput } from "@/types/workout";

interface ExerciseCardProps {
  exercise: ExerciseInput;
  index: number;
  onChange: (id: string, field: keyof ExerciseInput, value: any) => void;
  onRemove: (id: string) => void;
}

const ExerciseCard = ({ exercise, index, onChange, onRemove }: ExerciseCardProps) => {
  return (
    <Card key={exercise.id} className="p-4 border border-slate-200">
      <div className="flex justify-between items-center mb-4">
        <h4 className="font-medium flex items-center gap-2">
          <Dumbbell className="h-5 w-5 text-primary" />
          <span>Esercizio {index + 1}</span>
        </h4>
        <Button 
          variant="ghost" 
          size="icon" 
          onClick={() => onRemove(exercise.id)}
        >
          <Trash2 className="h-4 w-4 text-destructive" />
        </Button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div className="space-y-2">
          <Label htmlFor={`exercise-name-${exercise.id}`}>Nome esercizio *</Label>
          <Input
            id={`exercise-name-${exercise.id}`}
            value={exercise.name}
            onChange={(e) => onChange(exercise.id, 'name', e.target.value)}
            placeholder="Es. Squat, Panca piana, ecc."
          />
        </div>

        <div className="grid grid-cols-3 gap-2">
          <div className="space-y-2">
            <Label htmlFor={`exercise-sets-${exercise.id}`}>Serie</Label>
            <Input
              id={`exercise-sets-${exercise.id}`}
              type="number"
              min={1}
              value={exercise.sets}
              onChange={(e) => onChange(exercise.id, 'sets', parseInt(e.target.value))}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor={`exercise-reps-${exercise.id}`}>Ripetizioni</Label>
            <Input
              id={`exercise-reps-${exercise.id}`}
              value={exercise.reps}
              onChange={(e) => onChange(exercise.id, 'reps', e.target.value)}
              placeholder="Es. 10 o 8-12"
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor={`exercise-rest-${exercise.id}`}>Riposo (sec)</Label>
            <Input
              id={`exercise-rest-${exercise.id}`}
              value={exercise.rest}
              onChange={(e) => onChange(exercise.id, 'rest', e.target.value)}
              placeholder="Es. 60"
            />
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <Label htmlFor={`exercise-notes-${exercise.id}`}>Note</Label>
        <Textarea
          id={`exercise-notes-${exercise.id}`}
          value={exercise.notes || ''}
          onChange={(e) => onChange(exercise.id, 'notes', e.target.value)}
          placeholder="Note per l'esecuzione..."
          rows={2}
        />
      </div>
    </Card>
  );
};

export default ExerciseCard;
