
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { type Course } from "@/lib/supabase";
import { ChevronRight } from "lucide-react";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
interface CoursesSectionProps {
  loading: boolean;
  courses: Course[];
}
const CoursesSection = ({
  loading,
  courses
}: CoursesSectionProps) => {
  if (!loading && courses.length === 0) return null;
  return <section id="courses" className="py-20 bg-gray-50 overflow-hidden">
      <div className="container mx-auto max-w-6xl">
        <h2 className="text-4xl font-bold mb-4 text-center">
          I Nostri Corsi
        </h2>
        <p className="text-center text-muted-foreground mb-12 max-w-3xl mx-auto">
          Scopri i corsi di gruppo e unisciti alla nostra community fitness.
        </p>
        
        {loading ?
      // Scheletri di caricamento per i corsi
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {Array(3).fill(0).map((_, index) => <div key={index} className="bg-white p-8 rounded-xl shadow-lg h-full">
                <Skeleton className="h-7 w-2/3 mb-3" />
                <Skeleton className="h-4 w-full mb-2" />
                <Skeleton className="h-4 w-5/6 mb-6" />
                <div className="flex justify-between items-center mt-4">
                  <Skeleton className="h-6 w-20" />
                  <Skeleton className="h-10 w-28" />
                </div>
              </div>)}
          </div> : 
          <div className="w-full relative">
            <Carousel opts={{
              align: "start",
              loop: true
            }} className="w-full">
              <CarouselContent className="-ml-6">
                {courses.map(course => <CarouselItem key={course.id} className="pl-6 md:basis-1/2 lg:basis-1/3">
                    <div className="bg-white p-8 rounded-xl h-full flex flex-col">
                      <h3 className="font-bold text-2xl mb-2">{course.name}</h3>
                      {course.description && <p className="text-gray-600 mb-6 line-clamp-3 flex-grow">{course.description}</p>}
                      <div className="mt-auto">
                        <div className="flex justify-between items-center mb-6">
                          <span className="font-bold text-xl">
                            {course.price ? `€${course.price}` : 'Su richiesta'}
                          </span>
                        </div>
                        <Button className="w-full py-6 font-medium text-lg">
                          Prenota ora <ChevronRight className="ml-2 h-5 w-5" />
                        </Button>
                      </div>
                    </div>
                  </CarouselItem>)}
              </CarouselContent>
              <div className="flex items-center justify-end mt-4 gap-2">
                <CarouselPrevious className="relative inline-flex h-10 w-10 left-0" />
                <CarouselNext className="relative inline-flex h-10 w-10 right-0" />
              </div>
            </Carousel>
          </div>}
      </div>
    </section>;
};
export default CoursesSection;
