
import { Skeleton } from "@/components/ui/skeleton";
import { HomeSettings } from "@/lib/supabase";

interface AboutSectionProps {
  content: HomeSettings | null;
  loading?: boolean;
}

const AboutSection = ({ content, loading = false }: AboutSectionProps) => {
  const title = content?.about_title || "";
  const aboutDescription = content?.about_description || [];
  const formattedDescription = Array.isArray(aboutDescription) ? aboutDescription : [aboutDescription];
  const aboutStats = content?.about_stats as Array<{
    value: string;
    title: string;
  }> || [];
  const formattedStats = Array.isArray(aboutStats) ? aboutStats : [];
  const imageUrl = content?.about_image_url || "";

  return (
    <section id="about" className="py-20">
      <div className="container mx-auto max-w-6xl">
        <div className="flex flex-col lg:flex-row items-center gap-12">
          <div className="flex-1 relative order-2 lg:order-1 animate-fade-in">
            {loading ? (
              <Skeleton className="h-96 w-full rounded-3xl" />
            ) : imageUrl ? (
              <>
                <div className="absolute inset-0 bg-primary/20 rounded-3xl blur-3xl opacity-30 -z-10"></div>
                <img 
                  src={imageUrl} 
                  alt={title} 
                  className="w-full h-auto rounded-3xl object-cover shadow-xl"
                  onError={(e) => {
                    console.error("Errore caricamento immagine about:", imageUrl);
                    e.currentTarget.style.display = 'none';
                  }}
                />
              </>
            ) : (
              <div className="bg-gray-100 h-96 rounded-3xl flex items-center justify-center">
                <p className="text-gray-400">Nessuna immagine disponibile</p>
              </div>
            )}
          </div>
          
          <div className="flex-1 space-y-6 text-center lg:text-left order-1 lg:order-2 animate-fade-in">
            {loading ? (
              <>
                <Skeleton className="h-10 w-48 mx-auto lg:mx-0" />
                <Skeleton className="h-24 w-full" />
                <div className="grid grid-cols-2 gap-4 mt-8">
                  {[1, 2].map((i) => (
                    <Skeleton key={i} className="h-20 w-full" />
                  ))}
                </div>
              </>
            ) : (
              <>
                <h2 className="text-3xl font-bold sm:text-4xl">{title}</h2>
                
                {formattedDescription.map((paragraph, index) => (
                  <p key={index} className="text-muted-foreground">{paragraph}</p>
                ))}
                
                {formattedStats.length > 0 && (
                  <div className="grid grid-cols-2 gap-4 mt-8">
                    {formattedStats.map((stat, index) => (
                      <div key={index} className="p-4 rounded-lg bg-secondary/70">
                        <h3 className="text-xl font-bold text-primary">{stat.value}</h3>
                        <p className="text-sm text-muted-foreground">{stat.title}</p>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
