
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog";
import WorkoutForm from "./WorkoutForm";
import WorkoutDetailsDialog from "./WorkoutDetailsDialog";
import { WorkoutTemplate } from "@/types/workout";

interface WorkoutDialogsProps {
  isAddDialogOpen: boolean;
  setIsAddDialogOpen: (open: boolean) => void;
  isEditDialogOpen: boolean;
  setIsEditDialogOpen: (open: boolean) => void;
  isDeleteDialogOpen: boolean;
  setIsDeleteDialogOpen: (open: boolean) => void;
  isViewDialogOpen: boolean;
  setIsViewDialogOpen: (open: boolean) => void;
  workoutTemplate: WorkoutTemplate;
  setWorkoutTemplate: React.Dispatch<React.SetStateAction<WorkoutTemplate>>;
  resetForm: () => void;
  saveWorkout: () => Promise<void>;
  handleEditWorkout: () => Promise<void>;
  handleDeleteWorkout: () => Promise<void>;
  setSelectedTemplateId: (id: string | null) => void;
}

const WorkoutDialogs = ({
  isAddDialogOpen,
  setIsAddDialogOpen,
  isEditDialogOpen,
  setIsEditDialogOpen,
  isDeleteDialogOpen, 
  setIsDeleteDialogOpen,
  isViewDialogOpen,
  setIsViewDialogOpen,
  workoutTemplate,
  setWorkoutTemplate,
  resetForm,
  saveWorkout,
  handleEditWorkout,
  handleDeleteWorkout,
  setSelectedTemplateId
}: WorkoutDialogsProps) => {
  return (
    <>
      {/* Dialog per Aggiungere Allenamento */}
      <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
        <DialogContent className="sm:max-w-[800px]">
          <DialogHeader>
            <DialogTitle>Crea Nuovo Allenamento</DialogTitle>
            <DialogDescription>
              Inserisci i dettagli del nuovo allenamento.
            </DialogDescription>
          </DialogHeader>
          <WorkoutForm 
            workoutTemplate={workoutTemplate} 
            onSubmit={saveWorkout} 
            resetForm={() => {
              resetForm();
              setIsAddDialogOpen(false);
            }}
            setWorkoutTemplate={setWorkoutTemplate}
          />
        </DialogContent>
      </Dialog>

      {/* Dialog per Modificare Allenamento */}
      <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
        <DialogContent className="sm:max-w-[800px]">
          <DialogHeader>
            <DialogTitle>Modifica Allenamento</DialogTitle>
            <DialogDescription>
              Modifica i dettagli dell'allenamento.
            </DialogDescription>
          </DialogHeader>
          <WorkoutForm 
            workoutTemplate={workoutTemplate} 
            onSubmit={handleEditWorkout} 
            resetForm={() => {
              resetForm();
              setIsEditDialogOpen(false);
              setSelectedTemplateId(null);
            }}
            setWorkoutTemplate={setWorkoutTemplate}
            isEdit={true}
          />
        </DialogContent>
      </Dialog>

      {/* Dialog per Eliminare Allenamento */}
      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Conferma Eliminazione</AlertDialogTitle>
            <AlertDialogDescription>
              Sei sicuro di voler eliminare questo allenamento? Questa azione non può essere annullata.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="mt-4">
            <AlertDialogCancel
              onClick={() => {
                setIsDeleteDialogOpen(false);
                setSelectedTemplateId(null);
              }}
            >
              Annulla
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDeleteWorkout}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              Elimina
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* Dialog per Visualizzare Dettagli Allenamento */}
      <WorkoutDetailsDialog 
        isOpen={isViewDialogOpen}
        onOpenChange={setIsViewDialogOpen}
        workout={workoutTemplate}
      />
    </>
  );
};

export default WorkoutDialogs;
