
import PolicyModal from "@/components/PolicyModal";

const FooterSection = () => {
  return (
    <footer className="bg-gray-200 py-4">
      <div className="container mx-auto text-center text-sm text-gray-600">
        <div className="flex flex-wrap justify-center space-x-6">
          <PolicyModal 
            type="privacy" 
            title="Informativa sulla Privacy" 
            className="text-gray-600 hover:text-gray-900"
          />
          <PolicyModal 
            type="cookie" 
            title="Cookie Policy" 
            className="text-gray-600 hover:text-gray-900"
          />
          <PolicyModal 
            type="terms" 
            title="Termini e Condizioni" 
            className="text-gray-600 hover:text-gray-900"
          />
        </div>
        <p className="mt-2">© {new Date().getFullYear()} Il Tuo Nome. Tutti i diritti riservati.</p>
      </div>
    </footer>
  );
};

export default FooterSection;
