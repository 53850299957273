
import { Client } from "@/lib/supabase";

/**
 * Prepara i dati per l'aggiornamento di un cliente esistente.
 * Confronta i dati nuovi con quelli esistenti per determinare quali campi sono stati modificati.
 */
export function prepareClientUpdateData(
  clientData: Partial<Client>, 
  existingClient: Client
): Record<string, any> {
  const updateData: Record<string, any> = {};
  
  const keysToCheck = Object.keys(clientData) as Array<keyof typeof clientData>;
  
  for (const key of keysToCheck) {
    if (key === 'id') continue;
    
    if (clientData[key] === "") {
      updateData[key] = null;
    } 
    else if (clientData[key] !== undefined && 
            JSON.stringify(clientData[key]) !== JSON.stringify(existingClient[key])) {
      // Converti i campi numerici
      if (key === 'age' || key === 'height' || key === 'weight') {
        updateData[key] = clientData[key] === null || clientData[key] === undefined ? null : Number(clientData[key]);
      } else {
        updateData[key] = clientData[key];
      }
    }
  }
  
  // Gestione speciale per il campo sports
  if (updateData.sports !== undefined) {
    const sportsValue = updateData.sports;
    if (typeof sportsValue === 'string' && sportsValue.trim() !== '') {
      updateData.sports = sportsValue.trim().split(',').map((s: string) => s.trim()).filter(Boolean);
    } else if (Array.isArray(sportsValue)) {
      updateData.sports = sportsValue;
    } else if (sportsValue === '' || sportsValue === null) {
      updateData.sports = [];
    }
  }
  
  return updateData;
}

/**
 * Prepara i dati per la creazione di un nuovo cliente.
 */
export function prepareNewClientData(clientData: Partial<Client>): Record<string, any> {
  const newClient: Record<string, any> = {
    first_name: clientData.first_name,
    last_name: clientData.last_name,
    age: clientData.age === null || clientData.age === undefined ? null : Number(clientData.age),
    height: clientData.height === null || clientData.height === undefined ? null : Number(clientData.height),
    weight: clientData.weight === null || clientData.weight === undefined ? null : Number(clientData.weight),
    gender: clientData.gender,
    training_frequency: clientData.training_frequency,
    general_condition: clientData.general_condition || null,
    sports: [],
    avatar_url: clientData.avatar_url || null,
    email: clientData.email || null,
    telefono: clientData.telefono || null,
  };
  
  if (clientData.sports !== undefined) {
    if (typeof clientData.sports === 'string') {
      const sportsString: string = clientData.sports;
      if (sportsString.trim() !== '') {
        newClient.sports = sportsString.trim().split(',').map((s: string) => s.trim()).filter(Boolean);
      }
    } else if (Array.isArray(clientData.sports)) {
      newClient.sports = clientData.sports;
    }
  }
  
  return newClient;
}

/**
 * Filtra i clienti in base al termine di ricerca.
 */
export function filterClientsBySearchTerm(clients: Client[], searchTerm: string): Client[] {
  if (!searchTerm) return clients;
  
  const lowercaseSearchTerm = searchTerm.toLowerCase();
  return clients.filter((client) => {
    const fullName = `${client.first_name || ""} ${
      client.last_name || ""
    }`.toLowerCase();
    return fullName.includes(lowercaseSearchTerm);
  });
}
