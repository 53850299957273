import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { Star } from "lucide-react";
import { type Testimonial } from "@/lib/supabase";
interface TestimonialsSectionProps {
  loading: boolean;
  testimonials: Testimonial[];
}
const TestimonialsSection = ({
  loading,
  testimonials
}: TestimonialsSectionProps) => {
  if (!loading && testimonials.length === 0) return null;
  return <section id="testimonials" className="py-20 bg-white">
      <div className="container mx-auto max-w-6xl">
        <h2 className="text-4xl font-bold mb-4 text-center">
          Cosa Dicono i Nostri Clienti
        </h2>
        <p className="text-center text-gray-600 mb-16 max-w-3xl mx-auto">
          Le opinioni di chi ha scelto il nostro servizio di personal training
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {loading ?
        // Scheletri di caricamento per le testimonianze
        Array(2).fill(0).map((_, index) => <div key={index} className="bg-white/80 backdrop-blur-md p-8 rounded-xl shadow-lg">
                <div className="flex mb-1">
                  {Array(5).fill(0).map((_, i) => <Skeleton key={i} className="h-4 w-4 mr-1" />)}
                </div>
                <Skeleton className="h-4 w-full mb-1 mt-4" />
                <Skeleton className="h-4 w-11/12 mb-1" />
                <Skeleton className="h-4 w-10/12 mb-6" />
                <div className="flex items-center">
                  <Skeleton className="w-12 h-12 rounded-full mr-3" />
                  <div>
                    <Skeleton className="h-5 w-24 mb-1" />
                    <Skeleton className="h-4 w-16" />
                  </div>
                </div>
              </div>) : testimonials.slice(0, 2).map(testimonial => <div key={testimonial.id} className="bg-white/80 backdrop-blur-md p-8 rounded-xl shadow-lg transform transition-all hover:scale-105 hover:shadow-xl">
                <div className="flex mb-3">
                  {Array.from({
              length: 5
            }).map((_, i) => <Star key={i} className={`h-5 w-5 ${i < testimonial.rating ? "text-yellow-500 fill-yellow-500" : "text-gray-300"}`} />)}
                </div>
                <p className="italic text-gray-700 mb-6 text-lg">"{testimonial.content}"</p>
                <div className="flex items-center">
                  {testimonial.avatar_url ? <img src={testimonial.avatar_url} alt={testimonial.name} className="w-12 h-12 rounded-full mr-3 object-cover border-2 border-primary/20" /> : <div className="w-12 h-12 rounded-full mr-3 bg-primary/20 flex items-center justify-center">
                      <span className="text-primary font-bold">
                        {testimonial.name.charAt(0)}
                      </span>
                    </div>}
                  <div>
                    <p className="font-semibold">{testimonial.name}</p>
                    {testimonial.role && <p className="text-sm text-gray-500">{testimonial.role}</p>}
                  </div>
                </div>
              </div>)}
        </div>
        <div className="mt-10 text-center">
          
        </div>
      </div>
    </section>;
};
export default TestimonialsSection;
