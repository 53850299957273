
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";
import { Client } from "@/lib/supabase";
import { ClientFormValues } from "@/hooks/use-clients";

export function useClientOperations() {
  const { toast } = useToast();

  const saveClient = async (clientId: string, formValues: ClientFormValues) => {
    if (!clientId) return false;
    try {
      console.log("handleSaveClient - Valori del form ricevuti:", formValues);
      
      const updatedClient: Partial<Client> = {
        id: clientId,
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        gender: formValues.gender,
        age: formValues.age === null || formValues.age === undefined ? null : Number(formValues.age),
        height: formValues.height === null || formValues.height === undefined ? null : Number(formValues.height),
        weight: formValues.weight === null || formValues.weight === undefined ? null : Number(formValues.weight),
        training_frequency: formValues.training_frequency || null,
        general_condition: formValues.general_condition || null,
        avatar_url: formValues.avatar_url || null,
        email: formValues.email || null,
        telefono: formValues.telefono || null,
        sports: []
      };
      
      if (formValues.sports !== undefined) {
        if (typeof formValues.sports === 'string' && formValues.sports.trim() !== '') {
          updatedClient.sports = formValues.sports.trim().split(',').map(sport => sport.trim()).filter(Boolean);
        } else if (Array.isArray(formValues.sports)) {
          updatedClient.sports = formValues.sports;
        }
      } else {
        updatedClient.sports = [];
      }
      
      console.log("handleSaveClient - Dati preparati per salvataggio:", updatedClient);
      
      const { error } = await supabase
        .from('clients')
        .update(updatedClient)
        .eq('id', clientId);
        
      if (error) throw error;
      
      toast({
        title: "Successo",
        description: "Dati del cliente aggiornati con successo"
      });
      
      return true;
    } catch (error: any) {
      console.error('Errore nell\'aggiornamento del cliente:', error);
      toast({
        title: "Errore",
        description: "Impossibile aggiornare i dati del cliente: " + (error.message || 'Errore sconosciuto'),
        variant: "destructive"
      });
      return false;
    }
  };

  return {
    saveClient
  };
}
