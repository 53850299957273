
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { MessageSquareText, Plus, Trash2, Edit, User } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "@/components/ui/use-toast";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { supabase } from "@/lib/supabase";
import { Textarea } from "@/components/ui/textarea";
import ImageUploader from "@/components/ImageUploader";

const testimonialSchema = z.object({
  name: z.string().min(2, {
    message: "Il nome deve avere almeno 2 caratteri",
  }),
  role: z.string().optional(),
  content: z.string().min(10, {
    message: "Il contenuto deve avere almeno 10 caratteri",
  }),
  rating: z.number().min(1).max(5),
  avatar_url: z.string().optional(),
});

interface TestimonialsTabProps {
  testimonials: any[];
  isLoading: boolean;
  onSaved: () => Promise<void>;
}

const TestimonialsTab = ({ testimonials, isLoading, onSaved }: TestimonialsTabProps) => {
  const [localLoading, setLocalLoading] = useState(false);
  const [testimonialDialogOpen, setTestimonialDialogOpen] = useState(false);
  const [currentTestimonial, setCurrentTestimonial] = useState(null);
  
  const testimonialForm = useForm({
    resolver: zodResolver(testimonialSchema),
    defaultValues: {
      name: "",
      role: "",
      content: "",
      rating: 5,
      avatar_url: "",
    },
  });
  
  const openTestimonialDialog = (testimonial = null) => {
    setCurrentTestimonial(testimonial);
    if (testimonial) {
      testimonialForm.reset(testimonial);
    } else {
      testimonialForm.reset({
        name: "",
        role: "",
        content: "",
        rating: 5,
        avatar_url: "",
      });
    }
    setTestimonialDialogOpen(true);
  };
  
  const saveTestimonial = async (values) => {
    setLocalLoading(true);
    try {
      if (currentTestimonial) {
        const { error } = await supabase
          .from("testimonials")
          .update(values)
          .eq("id", currentTestimonial.id);
        
        if (error) throw error;
        
        toast({
          title: "Successo",
          description: "Recensione aggiornata con successo",
        });
      } else {
        const { error } = await supabase
          .from("testimonials")
          .insert([values]);
        
        if (error) throw error;
        
        toast({
          title: "Successo",
          description: "Recensione aggiunta con successo",
        });
      }
      
      setTestimonialDialogOpen(false);
      await onSaved();
    } catch (error) {
      console.error("Errore nel salvataggio:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore nel salvataggio",
        variant: "destructive",
      });
    } finally {
      setLocalLoading(false);
    }
  };
  
  const deleteTestimonial = async (id) => {
    if (!confirm("Sei sicuro di voler eliminare questa recensione?")) return;
    
    setLocalLoading(true);
    try {
      const { error } = await supabase
        .from("testimonials")
        .delete()
        .eq("id", id);
      
      if (error) throw error;
      
      toast({
        title: "Successo",
        description: "Recensione eliminata con successo",
      });
      
      await onSaved();
    } catch (error) {
      console.error("Errore nell'eliminazione:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore nell'eliminazione",
        variant: "destructive",
      });
    } finally {
      setLocalLoading(false);
    }
  };
  
  return (
    <>
      <Card className="border-0 shadow-md">
        <CardHeader className="border-b bg-gray-50 flex flex-row items-center justify-between">
          <CardTitle className="text-xl flex items-center gap-2">
            <MessageSquareText size={18} className="text-primary" />
            Recensioni
          </CardTitle>
          <Button 
            onClick={() => openTestimonialDialog()} 
            size="sm"
            className="flex items-center gap-1"
          >
            <Plus size={16} />
            <span>Aggiungi</span>
          </Button>
        </CardHeader>
        <CardContent className="p-6">
          {testimonials && testimonials.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {testimonials.map((testimonial) => (
                <div 
                  key={testimonial.id}
                  className="border rounded-lg p-4 relative bg-white shadow-sm"
                >
                  <div className="absolute top-2 right-2 flex gap-1">
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => openTestimonialDialog(testimonial)}
                    >
                      <Edit size={14} />
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => deleteTestimonial(testimonial.id)}
                    >
                      <Trash2 size={14} />
                    </Button>
                  </div>
                  
                  <div className="flex items-center gap-3 mb-3">
                    <Avatar>
                      <AvatarImage src={testimonial.avatar_url} />
                      <AvatarFallback>
                        {testimonial.name?.substring(0, 2).toUpperCase()}
                      </AvatarFallback>
                    </Avatar>
                    <div>
                      <h3 className="font-medium">{testimonial.name}</h3>
                      {testimonial.role && (
                        <p className="text-sm text-gray-600">{testimonial.role}</p>
                      )}
                    </div>
                  </div>
                  
                  <div className="mb-2">
                    <div className="flex text-yellow-400">
                      {Array.from({ length: 5 }).map((_, i) => (
                        <svg
                          key={i}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill={i < testimonial.rating ? "currentColor" : "none"}
                          stroke={i < testimonial.rating ? "none" : "currentColor"}
                          className="w-4 h-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                          />
                        </svg>
                      ))}
                    </div>
                  </div>
                  
                  <p className="text-sm">{testimonial.content}</p>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <MessageSquareText size={48} className="mx-auto text-gray-300 mb-4" />
              <h3 className="text-lg font-medium mb-2">Nessuna recensione</h3>
              <p className="text-gray-500 mb-4">Aggiungi recensioni dei tuoi clienti soddisfatti</p>
              <Button onClick={() => openTestimonialDialog()}>
                <Plus size={16} className="mr-2" />
                Aggiungi Recensione
              </Button>
            </div>
          )}
        </CardContent>
      </Card>

      <Dialog open={testimonialDialogOpen} onOpenChange={setTestimonialDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {currentTestimonial ? "Modifica Recensione" : "Aggiungi Recensione"}
            </DialogTitle>
          </DialogHeader>
          
          <Form {...testimonialForm}>
            <form onSubmit={testimonialForm.handleSubmit(saveTestimonial)} className="space-y-4">
              <FormField
                control={testimonialForm.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nome</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <FormField
                control={testimonialForm.control}
                name="role"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Ruolo/Posizione</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <FormField
                control={testimonialForm.control}
                name="content"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Contenuto</FormLabel>
                    <FormControl>
                      <Textarea {...field} rows={4} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <FormField
                control={testimonialForm.control}
                name="rating"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Valutazione (1-5)</FormLabel>
                    <FormControl>
                      <div className="flex">
                        {[1, 2, 3, 4, 5].map((rating) => (
                          <button
                            key={rating}
                            type="button"
                            onClick={() => field.onChange(rating)}
                            className="focus:outline-none"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              fill={rating <= field.value ? "currentColor" : "none"}
                              stroke={rating <= field.value ? "none" : "currentColor"}
                              className={`w-8 h-8 text-yellow-400`}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M11.48 3.499a.562.562 0 011.04 0l2.125 5.111a.563.563 0 00.475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 00-.182.557l1.285 5.385a.562.562 0 01-.84.61l-4.725-2.885a.563.563 0 00-.586 0L6.982 20.54a.562.562 0 01-.84-.61l1.285-5.386a.562.562 0 00-.182-.557l-4.204-3.602a.563.563 0 01.321-.988l5.518-.442a.563.563 0 00.475-.345L11.48 3.5z"
                              />
                            </svg>
                          </button>
                        ))}
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <FormField
                control={testimonialForm.control}
                name="avatar_url"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Avatar</FormLabel>
                    <FormControl>
                      <div className="space-y-4">
                        <div className="flex items-center gap-4">
                          <Avatar className="w-16 h-16">
                            <AvatarImage src={field.value} />
                            <AvatarFallback>
                              {testimonialForm.getValues("name")?.substring(0, 2).toUpperCase() || "NA"}
                            </AvatarFallback>
                          </Avatar>
                          
                          <div className="flex-1">
                            <ImageUploader 
                              onImageUpload={(url) => field.onChange(url)} 
                              initialImage={field.value}
                            />
                          </div>
                        </div>
                        
                        <Input 
                          {...field} 
                          placeholder="URL dell'avatar" 
                        />
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              
              <DialogFooter>
                <Button 
                  type="button" 
                  variant="outline" 
                  onClick={() => setTestimonialDialogOpen(false)}
                >
                  Annulla
                </Button>
                <Button type="submit" disabled={isLoading || localLoading}>
                  {isLoading || localLoading ? "Salvataggio..." : "Salva"}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TestimonialsTab;
