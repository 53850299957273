
import { Button } from "@/components/ui/button";
import { User } from "lucide-react";

interface ClientsEmptyStateProps {
  onAddClient: () => void;
}

const ClientsEmptyState = ({ onAddClient }: ClientsEmptyStateProps) => {
  return (
    <div className="text-center py-8">
      <User className="mx-auto h-12 w-12 text-muted-foreground/50" />
      <p className="text-muted-foreground mt-4">Nessun cliente trovato</p>
      <Button 
        variant="outline" 
        className="mt-4"
        onClick={onAddClient}
      >
        Crea il tuo primo cliente
      </Button>
    </div>
  );
};

export default ClientsEmptyState;
