import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useToast } from "@/hooks/use-toast";
import { Course, CourseClosureItem, CourseScheduleItem, supabase } from "@/lib/supabase";
import { Plus } from "lucide-react";
import Loading from "@/components/Loading";
import { Sheet, SheetContent } from "@/components/ui/sheet";
import { useIsMobile } from "@/hooks/use-mobile";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog";
import CourseForm from "@/components/courses/CourseForm";
import CoursesList from "@/components/courses/CoursesList";
import { format } from "date-fns";
import { parseClosurePeriodsData, parseScheduleData, prepareCourseCourseData } from "@/utils/courseUtils";

interface CourseFormData {
  name: string;
  description: string;
  max_participants: string;
  price: string;
  visible_on_site: boolean;
  start_date: Date | null;
  end_date: Date | null;
  schedule: CourseScheduleItem[];
  closure_periods: CourseClosureItem[];
  add_to_calendar: boolean;
}

const emptyFormData: CourseFormData = {
  name: "",
  description: "",
  max_participants: "10",
  price: "",
  visible_on_site: false,
  start_date: null,
  end_date: null,
  schedule: [],
  closure_periods: [],
  add_to_calendar: false
};

const weekDays = [
  { value: "Lunedì", label: "Lunedì" },
  { value: "Martedì", label: "Martedì" },
  { value: "Mercoledì", label: "Mercoledì" },
  { value: "Giovedì", label: "Giovedì" },
  { value: "Venerdì", label: "Venerdì" },
  { value: "Sabato", label: "Sabato" },
  { value: "Domenica", label: "Domenica" }
];

const Courses = () => {
  const [courses, setCourses] = useState<Course[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [formData, setFormData] = useState<CourseFormData>(emptyFormData);
  const [selectedCourseId, setSelectedCourseId] = useState<string | null>(null);
  const { toast } = useToast();
  const isMobile = useIsMobile();
  const [newTimeItem, setNewTimeItem] = useState<{day: string, time: string}>({day: "Lunedì", time: "09:00"});
  const [newClosureItem, setNewClosureItem] = useState<{start_date: Date | null, end_date: Date | null}>({start_date: null, end_date: null});

  const fetchCourses = async () => {
    setIsLoading(true);
    try {
      const { data, error } = await supabase
        .from('courses')
        .select('*')
        .order('created_at', { ascending: false });
      
      if (error) {
        throw error;
      }
      
      const formattedData = data?.map(course => {
        return {
          ...course,
          schedule: parseScheduleData(course.schedule),
          closure_periods: parseClosurePeriodsData(course.closure_periods)
        } as Course;
      }) || [];
      
      setCourses(formattedData);
    } catch (error) {
      console.error('Errore nel recupero dei corsi:', error);
      toast({
        title: "Errore",
        description: "Impossibile caricare i corsi",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const resetForm = () => {
    setFormData(emptyFormData);
    setNewTimeItem({day: "Lunedì", time: "09:00"});
    setNewClosureItem({start_date: null, end_date: null});
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target as HTMLInputElement;
    
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value,
    }));
  };

  const handleNewTimeItemChange = (key: 'day' | 'time', value: string) => {
    setNewTimeItem(prev => ({...prev, [key]: value}));
  };

  const handleNewClosureItemChange = (key: 'start_date' | 'end_date', value: Date | null) => {
    setNewClosureItem(prev => ({...prev, [key]: value}));
  };

  const setFormDataValue = <K extends keyof CourseFormData>(key: K, value: CourseFormData[K]) => {
    setFormData(prev => ({...prev, [key]: value}));
  };

  const handleAddScheduleItem = () => {
    const isDuplicate = formData.schedule.some(
      item => item.day === newTimeItem.day && item.time === newTimeItem.time
    );

    if (isDuplicate) {
      toast({
        title: "Errore",
        description: "Questo orario è già stato aggiunto per questo giorno",
        variant: "destructive",
      });
      return;
    }

    setFormData(prev => ({
      ...prev,
      schedule: [...prev.schedule, {...newTimeItem}]
    }));

    setNewTimeItem(prev => ({...prev, time: "09:00"}));
  };

  const handleAddClosureItem = () => {
    if (!newClosureItem.start_date || !newClosureItem.end_date) {
      toast({
        title: "Errore",
        description: "Seleziona sia la data di inizio che di fine del periodo di chiusura",
        variant: "destructive",
      });
      return;
    }

    const newItem = {
      start_date: format(newClosureItem.start_date, 'yyyy-MM-dd'),
      end_date: format(newClosureItem.end_date, 'yyyy-MM-dd')
    };

    const isDuplicate = formData.closure_periods.some(
      item => item.start_date === newItem.start_date && item.end_date === newItem.end_date
    );

    if (isDuplicate) {
      toast({
        title: "Errore",
        description: "Questo periodo di chiusura è già stato aggiunto",
        variant: "destructive",
      });
      return;
    }

    setFormData(prev => ({
      ...prev,
      closure_periods: [...prev.closure_periods, newItem]
    }));

    setNewClosureItem({start_date: null, end_date: null});
  };

  const handleRemoveScheduleItem = (index: number) => {
    setFormData(prev => ({
      ...prev,
      schedule: prev.schedule.filter((_, i) => i !== index)
    }));
  };

  const handleRemoveClosureItem = (index: number) => {
    setFormData(prev => ({
      ...prev,
      closure_periods: prev.closure_periods.filter((_, i) => i !== index)
    }));
  };

  const handleAddCourse = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      if (formData.schedule.length === 0) {
        toast({
          title: "Errore",
          description: "Aggiungi almeno un orario al corso",
          variant: "destructive",
        });
        return;
      }
      
      const newCourse = prepareCourseCourseData(formData);
      
      const { error } = await supabase
        .from('courses')
        .insert([newCourse]);
      
      if (error) {
        throw error;
      }
      
      setIsAddOpen(false);
      resetForm();
      await fetchCourses();
      
      toast({
        title: "Successo",
        description: "Corso aggiunto con successo",
      });
    } catch (error) {
      console.error('Errore nell\'aggiunta del corso:', error);
      toast({
        title: "Errore",
        description: "Impossibile aggiungere il corso",
        variant: "destructive",
      });
    }
  };

  const openEditDialog = async (id: string) => {
    try {
      const { data, error } = await supabase
        .from('courses')
        .select('*')
        .eq('id', id)
        .single();
      
      if (error) {
        throw error;
      }

      const parsedSchedule = parseScheduleData(data.schedule);
      let parsedClosurePeriods: CourseClosureItem[] = [];
      
      if (data.closure_periods) {
        try {
          parsedClosurePeriods = Array.isArray(data.closure_periods) 
            ? data.closure_periods 
            : JSON.parse(data.closure_periods as string);
        } catch (e) {
          console.error('Errore nel parsing dei periodi di chiusura:', e);
          parsedClosurePeriods = [];
        }
      }
      
      setFormData({
        name: data.name,
        description: data.description || "",
        max_participants: data.max_participants.toString(),
        price: data.price ? data.price.toString() : "",
        visible_on_site: data.visible_on_site || false,
        start_date: data.start_date ? new Date(data.start_date) : null,
        end_date: data.end_date ? new Date(data.end_date) : null,
        schedule: parsedSchedule,
        closure_periods: parsedClosurePeriods,
        add_to_calendar: data.add_to_calendar || false
      });
      
      setSelectedCourseId(id);
      setIsEditOpen(true);
    } catch (error) {
      console.error('Errore nel recupero del corso:', error);
      toast({
        title: "Errore",
        description: "Impossibile caricare i dati del corso",
        variant: "destructive",
      });
    }
  };

  const handleEditCourse = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!selectedCourseId) return;
    
    try {
      if (formData.schedule.length === 0) {
        toast({
          title: "Errore",
          description: "Aggiungi almeno un orario al corso",
          variant: "destructive",
        });
        return;
      }
      
      const updatedCourse = prepareCourseCourseData(formData);
      
      const { error } = await supabase
        .from('courses')
        .update(updatedCourse)
        .eq('id', selectedCourseId);
      
      if (error) {
        throw error;
      }
      
      setIsEditOpen(false);
      resetForm();
      setSelectedCourseId(null);
      await fetchCourses();
      
      toast({
        title: "Successo",
        description: "Corso aggiornato con successo",
      });
    } catch (error) {
      console.error('Errore nell\'aggiornamento del corso:', error);
      toast({
        title: "Errore",
        description: "Impossibile aggiornare il corso",
        variant: "destructive",
      });
    }
  };

  const openDeleteDialog = (id: string) => {
    setSelectedCourseId(id);
    setIsDeleteOpen(true);
  };

  const handleDeleteCourse = async () => {
    if (!selectedCourseId) return;
    
    try {
      const { error } = await supabase
        .from('courses')
        .delete()
        .eq('id', selectedCourseId);
      
      if (error) {
        throw error;
      }
      
      setIsDeleteOpen(false);
      setSelectedCourseId(null);
      await fetchCourses();
      
      toast({
        title: "Successo",
        description: "Corso eliminato con successo",
      });
    } catch (error) {
      console.error('Errore nell\'eliminazione del corso:', error);
      toast({
        title: "Errore",
        description: "Impossibile eliminare il corso",
        variant: "destructive",
      });
    }
  };

  const renderAddEditModal = () => {
    const isOpen = isAddOpen || isEditOpen;
    const title = isAddOpen ? "Crea Nuovo Corso" : "Modifica Corso";
    const description = isAddOpen ? "Inserisci i dettagli del nuovo corso" : "Modifica i dettagli del corso";
    const onSubmit = isAddOpen ? handleAddCourse : handleEditCourse;
    const submitText = isAddOpen ? "Crea Corso" : "Salva Modifiche";
    
    const handleCancel = () => {
      resetForm();
      isAddOpen ? setIsAddOpen(false) : setIsEditOpen(false);
      if (isEditOpen) setSelectedCourseId(null);
    };

    if (isMobile) {
      return (
        <Sheet 
          open={isOpen} 
          onOpenChange={(open) => {
            if (!open) {
              setTimeout(() => {
                handleCancel();
              }, 100);
            }
          }}
        >
          <SheetContent className="h-[85vh] overflow-y-auto pt-8 w-full max-w-md sm:max-w-lg">
            <div className="px-1 pb-6">
              <h2 className="text-xl font-semibold">{title}</h2>
              <p className="mt-1 text-sm text-muted-foreground">{description}</p>
              <div className="mt-6">
                <CourseForm 
                  formData={formData}
                  newTimeItem={newTimeItem}
                  newClosureItem={newClosureItem}
                  weekDays={weekDays}
                  onInputChange={handleInputChange}
                  onNewTimeItemChange={handleNewTimeItemChange}
                  onNewClosureItemChange={handleNewClosureItemChange}
                  onAddScheduleItem={handleAddScheduleItem}
                  onAddClosureItem={handleAddClosureItem}
                  onRemoveScheduleItem={handleRemoveScheduleItem}
                  onRemoveClosureItem={handleRemoveClosureItem}
                  onSubmit={onSubmit}
                  submitText={submitText}
                  onCancel={handleCancel}
                  setFormDataValue={setFormDataValue}
                />
              </div>
            </div>
          </SheetContent>
        </Sheet>
      );
    }
    
    return (
      <Dialog 
        open={isOpen} 
        onOpenChange={(open) => {
          if (!open) {
            setTimeout(() => {
              handleCancel();
            }, 100);
          }
        }}
      >
        <DialogContent className="sm:max-w-[800px] md:max-w-[900px]">
          <DialogHeader>
            <DialogTitle>{title}</DialogTitle>
            <DialogDescription>{description}</DialogDescription>
          </DialogHeader>
          <CourseForm 
            formData={formData}
            newTimeItem={newTimeItem}
            newClosureItem={newClosureItem}
            weekDays={weekDays}
            onInputChange={handleInputChange}
            onNewTimeItemChange={handleNewTimeItemChange}
            onNewClosureItemChange={handleNewClosureItemChange}
            onAddScheduleItem={handleAddScheduleItem}
            onAddClosureItem={handleAddClosureItem}
            onRemoveScheduleItem={handleRemoveScheduleItem}
            onRemoveClosureItem={handleRemoveClosureItem}
            onSubmit={onSubmit}
            submitText={submitText}
            onCancel={handleCancel}
            setFormDataValue={setFormDataValue}
          />
        </DialogContent>
      </Dialog>
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-6">
        <div>
          <h1 className="text-2xl font-bold">Corsi</h1>
         
        </div>
        <Button 
          onClick={() => {
            resetForm();
            setIsAddOpen(true);
          }}
          className="shadow-sm"
        >
          <Plus className="mr-2 h-4 w-4" />
          Crea Corso
        </Button>
      </div>
      
      <CoursesList 
        courses={courses}
        onAddCourse={() => {
          resetForm();
          setIsAddOpen(true);
        }}
        onEditCourse={openEditDialog}
        onDeleteCourse={openDeleteDialog}
      />

      {renderAddEditModal()}

      <AlertDialog open={isDeleteOpen} onOpenChange={setIsDeleteOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Conferma Eliminazione</AlertDialogTitle>
            <AlertDialogDescription>
              Sei sicuro di voler eliminare questo corso? Questa azione non può essere annullata.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="mt-4">
            <AlertDialogCancel
              onClick={() => {
                setIsDeleteOpen(false);
                setSelectedCourseId(null);
              }}
            >
              Annulla
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={handleDeleteCourse}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              Elimina
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default Courses;
