
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Client, Gender, TrainingFrequency } from "@/lib/supabase";
import { clientSchema, ClientFormValues } from "@/hooks/use-clients";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/lib/auth";
import { supabase } from "@/integrations/supabase/client";

// Importazione dei componenti rifattorizzati
import AvatarUploadSection from "./forms/AvatarUploadSection";
import BasicInfoFields from "./forms/BasicInfoFields";
import PhysicalInfoFields from "./forms/PhysicalInfoFields";
import TrainingInfoFields from "./forms/TrainingInfoFields";
import DebugButton from "./forms/DebugButton";

interface ClientFormDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  client: Client | null;
  onSave: (values: ClientFormValues, avatarUrl: string | null) => Promise<void>;
  isSaving: boolean;
}

const ClientFormDialog = ({ 
  open, 
  onOpenChange, 
  client, 
  onSave,
  isSaving 
}: ClientFormDialogProps) => {
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const { toast } = useToast();
  const { user } = useAuth();
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  
  useEffect(() => {
    // Verifica se l'utente corrente è un admin
    const checkIsAdmin = async () => {
      if (!user) return;
      try {
        const { data } = await supabase.from('profiles').select('role').eq('id', user.id).single();
        if (data) {
          setIsAdmin(data.role === 'admin');
        }
      } catch (error) {
        console.error("Errore nel controllo del ruolo admin:", error);
      }
    };
    
    checkIsAdmin();
  }, [user]);
  
  const form = useForm<ClientFormValues>({
    resolver: zodResolver(clientSchema),
    defaultValues: {
      first_name: "",
      last_name: "",
      age: 18,
      height: 170,
      weight: 70,
      gender: 'male' as Gender,
      training_frequency: 'sedentario' as TrainingFrequency,
      general_condition: "Sufficiente",
      sports: '',
      avatar_url: null,
      email: "",
      telefono: "",
    },
  });

  useEffect(() => {
    if (client) {
      console.log("Inizializzazione form con cliente:", client);
      setAvatarUrl(client.avatar_url);
      
      // Verifica che gender e training_frequency siano valori validi
      const validGender: Gender = 
        client.gender === 'male' || client.gender === 'female' || client.gender === 'other' 
          ? client.gender as Gender 
          : 'male';
          
      const validTrainingFrequency: TrainingFrequency = 
        client.training_frequency === 'sedentario' || 
        client.training_frequency === '1 volta settimana' || 
        client.training_frequency === '2 volte' || 
        client.training_frequency === '3 volte'
          ? client.training_frequency as TrainingFrequency
          : 'sedentario';
      
      // Reset del form con i valori esistenti, mantenendo i valori nulli come nulli
      form.reset({
        first_name: client.first_name || "",
        last_name: client.last_name || "",
        age: client.age || 18,
        height: client.height || 170,
        weight: client.weight || 70,
        gender: validGender,
        training_frequency: validTrainingFrequency,
        general_condition: client.general_condition || "Sufficiente",
        sports: Array.isArray(client.sports) ? client.sports.join(', ') : '',
        avatar_url: client.avatar_url || null,
        email: client.email || "",
        telefono: client.telefono || "",
      });
    } else {
      console.log("Inizializzazione form per nuovo cliente");
      setAvatarUrl(null);
      form.reset({
        first_name: "",
        last_name: "",
        age: 18,
        height: 170,
        weight: 70,
        gender: 'male',
        training_frequency: 'sedentario',
        general_condition: "Sufficiente",
        sports: '',
        avatar_url: null,
        email: "",
        telefono: "",
      });
    }
  }, [client, open]);

  const handleImageUpload = (url: string) => {
    console.log("ClientFormDialog - Immagine caricata:", url);
    setAvatarUrl(url);
    form.setValue("avatar_url", url);
    setIsUploadingImage(false);
  };

  const handleSubmit = async (values: ClientFormValues) => {
    if (isSubmitting) return;
    
    try {
      setIsSubmitting(true);
      console.log("Form values prima del submit:", values);
      console.log("Avatar URL prima del submit:", avatarUrl);
      
      // Importante: preserviamo i valori originali quando non vengono modificati
      let dataToSave: ClientFormValues = { ...values };
      
      // Se non è admin o il cliente ha già un auth_user_id, mantieni l'email originale
      if (client && (client.auth_user_id || !isAdmin)) {
        dataToSave.email = client.email;
      }
      
      // Preserviamo i valori nulli originali quando i campi non sono stati modificati
      if (client) {
        // Verifica quali campi sono stati effettivamente modificati
        if (form.formState.dirtyFields.first_name === undefined && client.first_name === null) {
          dataToSave.first_name = null;
        }
        
        if (form.formState.dirtyFields.last_name === undefined && client.last_name === null) {
          dataToSave.last_name = null;
        }
        
        if (form.formState.dirtyFields.age === undefined && client.age === null) {
          dataToSave.age = null;
        }
        
        if (form.formState.dirtyFields.height === undefined && client.height === null) {
          dataToSave.height = null;
        }
        
        if (form.formState.dirtyFields.weight === undefined && client.weight === null) {
          dataToSave.weight = null;
        }
      }
      
      // Assicurati che avatar_url sia impostato nei valori del form
      dataToSave.avatar_url = avatarUrl;
      
      console.log("Dati da salvare (dopo preservazione valori originali):", dataToSave);
      await onSave(dataToSave, avatarUrl);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Funzione per il debug
  const logFormState = () => {
    console.log("Current form values:", form.getValues());
    console.log("Current avatar URL:", avatarUrl);
    console.log("Client data:", client);
    console.log("Dirty fields:", form.formState.dirtyFields);
  };

  // Previene la chiusura della dialog durante il salvataggio o il caricamento dell'immagine
  const handleOpenChange = (open: boolean) => {
    if (!isSubmitting && !isSaving && !isUploadingImage) {
      onOpenChange(open);
    } else {
      console.log("Richiesta di chiusura ignorata: operazione in corso");
      // Se si tenta di chiudere durante un'operazione, mostra un messaggio
      if (!open) {
        toast({
          title: "Operazione in corso",
          description: isUploadingImage ? 
            "Attendi il completamento del caricamento dell'immagine" : 
            "Attendi il completamento del salvataggio",
          variant: "warning",
        });
      }
    }
  };

  return (
    <Dialog open={open} onOpenChange={handleOpenChange}>
      <DialogContent 
        className="sm:max-w-[600px] max-h-[90vh] overflow-y-auto"
        onInteractOutside={(e) => {
          // Previene la chiusura quando si clicca fuori durante operazioni
          if (isSubmitting || isSaving || isUploadingImage) {
            e.preventDefault();
          }
        }}
        onClick={(e) => e.stopPropagation()} // Previene la propagazione degli eventi di click
      >
        <DialogHeader>
          <DialogTitle>{client ? 'Modifica Cliente' : 'Aggiungi Nuovo Cliente'}</DialogTitle>
          <DialogDescription>
            {client ? 'Modifica i dettagli del cliente.' : 'Inserisci i dettagli del nuovo cliente.'}
          </DialogDescription>
        </DialogHeader>
        
        <form onSubmit={form.handleSubmit(handleSubmit)}>
          <div className="grid grid-cols-2 gap-4 py-4">
            <AvatarUploadSection 
              initialImage={avatarUrl || undefined}
              onImageUpload={handleImageUpload}
              onUploadStart={() => setIsUploadingImage(true)}
            />
            
            <DebugButton onClick={logFormState} />
            
            <BasicInfoFields form={form} client={client} isAdmin={isAdmin} />
            
            <PhysicalInfoFields form={form} />
            
            <TrainingInfoFields form={form} />
          </div>
          
          <DialogFooter className="mt-6">
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                if (!isSubmitting && !isSaving && !isUploadingImage) {
                  onOpenChange(false);
                }
              }}
              disabled={isSubmitting || isSaving || isUploadingImage}
            >
              Annulla
            </Button>
            <Button 
              type="submit" 
              disabled={isSubmitting || isSaving || isUploadingImage}
            >
              {isSubmitting || isSaving ? 
                "Salvataggio..." : 
                isUploadingImage ? 
                  "Caricamento immagine in corso..." : 
                  client ? "Aggiorna Cliente" : "Salva Cliente"
              }
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ClientFormDialog;
