
import React from "react";
import { Button } from "@/components/ui/button";
import { Settings } from "lucide-react";

interface CalendarHeaderProps {
  isClientView: boolean;
  onOpenSettings: () => void;
}

const CalendarHeader: React.FC<CalendarHeaderProps> = ({
  isClientView,
  onOpenSettings
}) => {
  return (
    <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
      <div>
        <h1 className="text-2xl font-bold tracking-tight">Calendario</h1>
      </div>
      {!isClientView && (
        <Button 
          onClick={onOpenSettings}
          className="shadow-sm"
        >
          <Settings className="mr-2 h-4 w-4" />
          Impostazioni
        </Button>
      )}
    </div>
  );
};

export default CalendarHeader;
