
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { WorkoutTemplate } from "@/types/workout";
import { Label } from "@/components/ui/label";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Dumbbell } from "lucide-react";

interface WorkoutDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  availableWorkouts: WorkoutTemplate[];
  selectedWorkoutId: string;
  onSelectWorkout: (workoutId: string) => void;
  onAssignWorkout: () => void;
}

const WorkoutDialog = ({
  open,
  onOpenChange,
  availableWorkouts,
  selectedWorkoutId,
  onSelectWorkout,
  onAssignWorkout
}: WorkoutDialogProps) => {
  const selectedWorkout = availableWorkouts.find(w => w.id === selectedWorkoutId);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle className="flex items-center">
            <Dumbbell className="mr-2 h-5 w-5" />
            Assegna Allenamento
          </DialogTitle>
          <DialogDescription>
            Seleziona un allenamento da assegnare al cliente.
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-4 py-4">
          <div className="space-y-2">
            <Label htmlFor="workout-select">Allenamento</Label>
            <Select
              value={selectedWorkoutId}
              onValueChange={onSelectWorkout}
            >
              <SelectTrigger id="workout-select" className="w-full">
                <SelectValue placeholder="Seleziona un allenamento" />
              </SelectTrigger>
              <SelectContent>
                {availableWorkouts.map((workout) => (
                  <SelectItem key={workout.id || ""} value={workout.id || ""}>
                    {workout.name} ({workout.difficulty})
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          
          {selectedWorkout && (
            <div className="mt-4 space-y-2 border rounded-md p-3 bg-muted/30">
              <div className="flex justify-between">
                <div>
                  <p className="text-sm font-medium">Difficoltà: <span className="font-normal capitalize">{selectedWorkout.difficulty}</span></p>
                  <p className="text-sm font-medium">Target: <span className="font-normal">{selectedWorkout.target}</span></p>
                </div>
                <div>
                  <p className="text-sm font-medium">Durata: <span className="font-normal">{selectedWorkout.duration}</span></p>
                  <p className="text-sm font-medium">Esercizi: <span className="font-normal">{selectedWorkout.exercises.length}</span></p>
                </div>
              </div>
              
              {selectedWorkout.description && (
                <p className="text-sm mt-2">
                  <span className="font-medium">Descrizione:</span> {selectedWorkout.description}
                </p>
              )}
            </div>
          )}
        </div>

        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Annulla
          </Button>
          <Button onClick={onAssignWorkout} disabled={!selectedWorkoutId}>
            Assegna
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default WorkoutDialog;
