
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Dumbbell, Edit, Eye, Trash2 } from "lucide-react";
import { WorkoutTemplateResponse } from "@/types/workout";

interface WorkoutListProps {
  templates: WorkoutTemplateResponse[];
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
  onView: (id: string) => void;
  onCreateNew: () => void;
}

const WorkoutList = ({ templates, onEdit, onDelete, onView, onCreateNew }: WorkoutListProps) => {
  return (
    <>
      {templates && templates.length > 0 ? (
        <ScrollArea className="w-full">
          <div className="min-w-[800px] md:min-w-full">
            <table className="w-full text-sm">
              <thead>
                <tr className="border-b">
                  <th className="text-left font-medium py-3 px-4">Nome</th>
                  <th className="text-left font-medium py-3 px-4">Difficoltà</th>
                  <th className="text-left font-medium py-3 px-4">Obiettivo</th>
                  <th className="text-left font-medium py-3 px-4">Durata</th>
                  <th className="text-left font-medium py-3 px-4">Esercizi</th>
                  <th className="text-right font-medium py-3 px-4">Azioni</th>
                </tr>
              </thead>
              <tbody>
                {templates.map((template) => (
                  <tr key={template.id} className="border-b hover:bg-muted/50 transition-colors">
                    <td className="py-3 px-4 font-medium">{template.name}</td>
                    <td className="py-3 px-4 capitalize">{template.difficulty}</td>
                    <td className="py-3 px-4">{template.target}</td>
                    <td className="py-3 px-4">{template.duration}</td>
                    <td className="py-3 px-4">{template.exercises?.length || 0}</td>
                    <td className="py-3 px-4 text-right space-x-2">
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => onView(template.id)}
                        title="Visualizza dettagli"
                      >
                        <Eye className="h-4 w-4 text-primary" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => onEdit(template.id)}
                        title="Modifica"
                      >
                        <Edit className="h-4 w-4" />
                      </Button>
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() => onDelete(template.id)}
                        title="Elimina"
                      >
                        <Trash2 className="h-4 w-4 text-destructive" />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </ScrollArea>
      ) : (
        <div className="text-center py-8">
          <Dumbbell className="mx-auto h-12 w-12 text-muted-foreground/50" />
          <p className="text-muted-foreground mt-4">Nessun template di allenamento disponibile</p>
          <Button 
            variant="outline" 
            className="mt-4"
            onClick={onCreateNew}
          >
            Crea il tuo primo allenamento
          </Button>
        </div>
      )}
    </>
  );
};

export default WorkoutList;
