
import { useState, useEffect } from "react";
import { supabase } from "@/lib/supabase";
import { useAuth } from "@/lib/auth";

export const useClientInfo = () => {
  const [clientId, setClientId] = useState<string | null>(null);
  const [isClientView, setIsClientView] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (!user) return;
      
      try {
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('role')
          .eq('id', user.id)
          .single();
          
        if (profileError) throw profileError;
        
        if (profile.role === 'user') {
          setIsClientView(true);
          setIsAdmin(false);
          const { data: clientData, error: clientError } = await supabase
            .from('clients')
            .select('id')
            .eq('auth_user_id', user.id)
            .single();
            
          if (clientError) throw clientError;
          
          if (clientData) {
            setClientId(clientData.id);
          }
        } else {
          setIsClientView(false);
          setIsAdmin(profile.role === 'admin');
        }
      } catch (error) {
        console.error("Errore nel recupero del profilo:", error);
        setIsAdmin(false);
      }
    };
    
    fetchUserProfile();
  }, [user]);

  return {
    clientId,
    isClientView,
    isAdmin,
    user
  };
};
