
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Image } from "lucide-react";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "@/components/ui/use-toast";
import { supabase } from "@/lib/supabase";
import ImageUploader from "@/components/ImageUploader";

const heroSchema = z.object({
  hero_claim: z.string().max(50, {
    message: "Il claim non può superare i 50 caratteri",
  }),
  hero_title: z.string().max(100, {
    message: "Il titolo non può superare i 100 caratteri",
  }),
  hero_description: z.string().max(250, {
    message: "La descrizione non può superare i 250 caratteri",
  }),
  hero_cta: z.string().max(30, {
    message: "Il testo CTA non può superare i 30 caratteri",
  }),
  hero_image_url: z.string().optional(),
});

interface HeroSettingsTabProps {
  homeSettings: any;
  isLoading: boolean;
  onSaved: () => Promise<void>;
}

const HeroSettingsTab = ({ homeSettings, isLoading, onSaved }: HeroSettingsTabProps) => {
  const [localLoading, setLocalLoading] = useState(false);
  
  const heroForm = useForm({
    resolver: zodResolver(heroSchema),
    defaultValues: {
      hero_claim: "",
      hero_title: "",
      hero_description: "",
      hero_cta: "",
      hero_image_url: "",
    },
  });
  
  // Aggiorna i valori di default quando cambiano le impostazioni
  useEffect(() => {
    if (homeSettings) {
      heroForm.reset({
        hero_claim: homeSettings.hero_claim || "",
        hero_title: homeSettings.hero_title || "",
        hero_description: homeSettings.hero_description || "",
        hero_cta: homeSettings.hero_cta || "",
        hero_image_url: homeSettings.hero_image_url || "",
      });
    }
  }, [homeSettings, heroForm]);
  
  const saveHeroSettings = async (values) => {
    setLocalLoading(true);
    try {
      const { error } = await supabase
        .from("home_settings")
        .update(values)
        .eq("id", homeSettings.id);
      
      if (error) throw error;
      
      toast({
        title: "Successo",
        description: "Impostazioni hero salvate con successo",
      });
      
      await onSaved();
    } catch (error) {
      console.error("Errore nel salvataggio:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore nel salvataggio",
        variant: "destructive",
      });
    } finally {
      setLocalLoading(false);
    }
  };
  
  const handleHeroImageUpload = (url) => {
    heroForm.setValue("hero_image_url", url);
  };
  
  return (
    <Card className="border-0 shadow-md">
      <CardHeader className="border-b bg-gray-50">
        <CardTitle className="text-xl flex items-center gap-2">
          <Image size={18} className="text-primary" />
          Configurazione Hero Section
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6">
        {homeSettings ? (
          <Form {...heroForm}>
            <form onSubmit={heroForm.handleSubmit(saveHeroSettings)} className="grid grid-cols-1 gap-8">
              <div className="space-y-6">
                <FormField
                  control={heroForm.control}
                  name="hero_claim"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Claim Principale</FormLabel>
                      <FormControl>
                        <Input {...field} className="max-w-md" />
                      </FormControl>
                      <p className="text-xs text-gray-500 mt-1">Breve frase di impatto (max 50 caratteri)</p>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                
                <FormField
                  control={heroForm.control}
                  name="hero_title"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Titolo Hero</FormLabel>
                      <FormControl>
                        <Input {...field} className="max-w-lg" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                
                <FormField
                  control={heroForm.control}
                  name="hero_description"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Descrizione</FormLabel>
                      <FormControl>
                        <Textarea {...field} rows={3} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                
                <FormField
                  control={heroForm.control}
                  name="hero_cta"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Testo Call-to-Action</FormLabel>
                      <FormControl>
                        <Input {...field} className="max-w-xs" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                
                <FormField
                  control={heroForm.control}
                  name="hero_image_url"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Immagine Hero</FormLabel>
                      <FormControl>
                        <div className="space-y-6">
                          <ImageUploader 
                            onImageUpload={handleHeroImageUpload} 
                            initialImage={field.value}
                          />
                          
                          {field.value && (
                            <div className="w-full max-w-md rounded-lg overflow-hidden mb-2 border">
                              <img src={field.value} alt="Hero" className="w-full h-auto" />
                            </div>
                          )}
                          
                          <div className="flex items-center gap-2">
                            <Input 
                              {...field} 
                              className="max-w-lg flex-1" 
                              placeholder="URL dell'immagine"
                            />
                          </div>
                        </div>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              
              <div className="pt-4 border-t">
                <Button type="submit" disabled={isLoading || localLoading}>
                  {isLoading || localLoading ? "Salvataggio..." : "Salva Modifiche"}
                </Button>
              </div>
            </form>
          </Form>
        ) : (
          <div className="flex justify-center items-center h-64">
            <p>Caricamento configurazione hero...</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default HeroSettingsTab;
