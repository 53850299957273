
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Badge } from "@/components/ui/badge";
import { CalendarDays, Info, CheckCircle, XCircle } from "lucide-react";
import { Course } from "@/lib/supabase";
import { formatDate, getScheduleArray, getClosurePeriods } from "@/utils/courseUtils";

interface CourseDetailsDialogProps {
  course: Course | null;
  isOpen: boolean;
  onClose: () => void;
}

const CourseDetailsDialog = ({ course, isOpen, onClose }: CourseDetailsDialogProps) => {
  if (!course) return null;
  
  // Utilizziamo le funzioni utility per convertire schedule e closure_periods
  const scheduleArray = getScheduleArray(course);
  const closurePeriods = getClosurePeriods(course);
  
  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-[600px] max-h-[85vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-xl font-bold">{course.name}</DialogTitle>
        </DialogHeader>
        
        <div className="space-y-6 py-4">
          {course.description && (
            <div>
              <h3 className="text-sm font-medium text-muted-foreground mb-1">Descrizione</h3>
              <p className="text-sm">{course.description}</p>
            </div>
          )}
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <h3 className="text-sm font-medium text-muted-foreground mb-1">Periodo del corso</h3>
              <p className="text-sm">
                {formatDate(course.start_date)} - {formatDate(course.end_date)}
              </p>
            </div>
            
            <div>
              <h3 className="text-sm font-medium text-muted-foreground mb-1">Partecipanti massimi</h3>
              <p className="text-sm">{course.max_participants}</p>
            </div>
            
            <div>
              <h3 className="text-sm font-medium text-muted-foreground mb-1">Prezzo</h3>
              <p className="text-sm">{course.price ? `€${course.price}` : 'Su richiesta'}</p>
            </div>
            
            <div>
              <h3 className="text-sm font-medium text-muted-foreground mb-1">Stati</h3>
              <div className="flex flex-wrap gap-2">
                <Badge variant={course.visible_on_site ? "default" : "secondary"} className="flex items-center gap-1">
                  {course.visible_on_site ? <CheckCircle className="h-3 w-3" /> : <XCircle className="h-3 w-3" />}
                  {course.visible_on_site ? 'Pubblicato' : 'Bozza'}
                </Badge>
                
                {course.add_to_calendar && (
                  <Badge variant="outline" className="bg-amber-50 text-amber-800 border-amber-200 flex items-center gap-1">
                    <CalendarDays className="h-3 w-3" />
                    Calendario
                  </Badge>
                )}
              </div>
            </div>
          </div>
          
          <div>
            <h3 className="text-sm font-medium text-muted-foreground mb-2">Programmazione</h3>
            {scheduleArray && scheduleArray.length > 0 ? (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                {scheduleArray.map((item, index) => (
                  <div key={index} className="text-sm p-2 bg-muted/50 rounded-md">
                    {item.day} alle {item.time || `${item.startTime || ''} - ${item.endTime || ''}`}
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-sm text-muted-foreground">Nessuna programmazione disponibile</p>
            )}
          </div>
          
          {closurePeriods && closurePeriods.length > 0 && (
            <div>
              <h3 className="text-sm font-medium text-muted-foreground mb-2">Periodi di chiusura</h3>
              <div className="space-y-2">
                {closurePeriods.map((period, index) => (
                  <div key={index} className="text-sm p-2 bg-muted/50 rounded-md">
                    {formatDate(period.start_date || period.startDate)} - {formatDate(period.end_date || period.endDate)}
                    {period.reason && <div className="mt-1 text-xs text-muted-foreground">{period.reason}</div>}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CourseDetailsDialog;
