
import React from "react";
import { Skeleton } from "@/components/ui/skeleton";
import { Loader2 } from "lucide-react";

interface CalendarLoadingProps {
  retryCount?: number;
}

const CalendarLoading: React.FC<CalendarLoadingProps> = ({ retryCount = 0 }) => {
  // Genera il messaggio appropriato in base al conteggio dei tentativi
  const loadingMessage = retryCount > 0 
    ? `Caricamento in corso (tentativo ${retryCount}/3)...` 
    : "Caricamento calendario...";

  return (
    <div className="flex flex-col space-y-6 w-full h-full items-center justify-center">
      <div className="flex items-center justify-center">
        <Loader2 className="h-6 w-6 animate-spin text-primary mr-2" />
        <p className="text-lg font-medium text-primary">{loadingMessage}</p>
      </div>
      
      {/* Header simulato */}
      <div className="w-full max-w-2xl space-y-4">
        <div className="flex items-center justify-between">
          <Skeleton className="h-8 w-40" />
          <Skeleton className="h-8 w-28" />
        </div>
        
        {/* Giorni simulati */}
        <div className="grid grid-cols-7 gap-1 mt-4">
          {Array(7).fill(0).map((_, i) => (
            <Skeleton key={i} className="h-12 w-full" />
          ))}
        </div>
        
        {/* Righe di eventi simulati */}
        <div className="space-y-2 mt-4">
          {Array(5).fill(0).map((_, i) => (
            <div key={i} className="flex">
              <Skeleton className="h-16 w-16 shrink-0" />
              <div className="grid grid-cols-7 gap-1 ml-1 w-full">
                {Array(7).fill(0).map((_, j) => (
                  <Skeleton key={j} className="h-16 w-full" />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CalendarLoading;
