import { Link, useLocation } from "react-router-dom";
import { cn } from "@/lib/utils";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Users, Package, BookOpen, Calendar, DollarSign, Bell, Settings, Home, LayoutDashboard, LogOut, X, Dumbbell } from "lucide-react";
import { useAuth } from "@/lib/auth";
import { Button } from "@/components/ui/button";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { useIsMobile } from "@/hooks/use-mobile";
import { useEffect, useState, useCallback } from "react";
import { supabase, type SiteSettings } from "@/lib/supabase";
import { Badge } from "@/components/ui/badge";

interface AdminSidebarProps {
  onCloseSidebar?: () => void;
}

const SidebarLink = ({
  to,
  icon: Icon,
  label,
  isCurrent,
  badge
}: {
  to: string;
  icon: React.ElementType;
  label: string;
  isCurrent: boolean;
  badge?: React.ReactNode;
}) => <Link to={to} className="w-full block">
    <div className={cn("flex items-center gap-3 px-4 py-3 rounded-lg text-sm font-medium transition-all", isCurrent ? "bg-primary/10 text-primary shadow-sm" : "text-gray-600 hover:text-gray-900 hover:bg-gray-100")}>
      <Icon size={18} className={cn(isCurrent ? "text-primary" : "text-gray-500")} />
      <span>{label}</span>
      {badge}
    </div>
  </Link>;

const AdminSidebar = ({ onCloseSidebar }: AdminSidebarProps) => {
  const location = useLocation();
  const { user, signOut } = useAuth();
  const isMobile = useIsMobile();
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [userName, setUserName] = useState<string>("Utente");
  const [clientId, setClientId] = useState<string | null>(null);
  const [unreadNotifications, setUnreadNotifications] = useState<number>(0);
  const [siteSettings, setSiteSettings] = useState<SiteSettings | null>(null);
  
  const fetchUnreadNotifications = useCallback(async () => {
    if (!isAdmin || !user) return;
    
    try {
      const { count, error } = await supabase
        .from('notifications')
        .select('*', { count: 'exact', head: true })
        .eq('read', false);
      
      if (error) {
        throw error;
      }
      
      setUnreadNotifications(count || 0);
    } catch (error) {
      console.error('Errore nel conteggio delle notifiche non lette:', error);
    }
  }, [isAdmin, user]);

  useEffect(() => {
    const checkUserRole = async () => {
      if (!user) return;
      
      try {
        const { data, error } = await supabase
          .from('profiles')
          .select('role, email')
          .eq('id', user.id)
          .single();
        
        if (error) {
          console.error("Errore nel recupero del profilo:", error);
          setIsAdmin(false);
        } else {
          setIsAdmin(data?.role === 'admin');
          if (data?.email) {
            const emailPrefix = data.email.split('@')[0];
            setUserName(emailPrefix || "Utente");
          }
          
          if (data?.role !== 'admin') {
            const { data: clientData, error: clientError } = await supabase
              .from('clients')
              .select('id')
              .eq('auth_user_id', user.id)
              .single();
              
            if (!clientError && clientData) {
              setClientId(clientData.id);
            }
          }
        }
      } catch (error) {
        console.error("Errore nella verifica del ruolo:", error);
        setIsAdmin(false);
      }
    };
    
    checkUserRole();
  }, [user]);

  useEffect(() => {
    const fetchSiteSettings = async () => {
      const { data } = await supabase
        .from("site_settings")
        .select("*")
        .single();
      
      if (data) {
        setSiteSettings(data);
      }
    };
    
    fetchSiteSettings();
  }, []);

  useEffect(() => {
    if (isAdmin === true) {
      fetchUnreadNotifications();
      
      const interval = setInterval(fetchUnreadNotifications, 10000);
      
      const channel = supabase
        .channel('notification_changes')
        .on('postgres_changes', 
          { 
            event: 'INSERT', 
            schema: 'public', 
            table: 'notifications' 
          }, 
          () => {
            fetchUnreadNotifications();
          }
        )
        .on('postgres_changes', 
          { 
            event: 'UPDATE', 
            schema: 'public', 
            table: 'notifications' 
          }, 
          () => {
            fetchUnreadNotifications();
          }
        )
        .subscribe();
      
      return () => {
        clearInterval(interval);
        supabase.removeChannel(channel);
      };
    }
  }, [isAdmin, fetchUnreadNotifications]);

  interface MenuItem {
    to: string;
    icon: React.ElementType;
    label: string;
    badge?: React.ReactNode;
  }

  const adminItems: MenuItem[] = [
    {
      to: "/admin/dashboard",
      icon: LayoutDashboard,
      label: "Dashboard"
    }, 
    {
      to: "/admin/calendar",
      icon: Calendar,
      label: "Calendario"
    }, 
    {
      to: "/admin/clients",
      icon: Users,
      label: "Clienti"
    }, 
    {
      to: "/admin/packages",
      icon: Package,
      label: "Pacchetti"
    }, 
    {
      to: "/admin/courses",
      icon: BookOpen,
      label: "Corsi"
    }, 
    {
      to: "/admin/workouts",
      icon: Dumbbell,
      label: "Allenamenti"
    },
    {
      to: "/admin/payments",
      icon: DollarSign,
      label: "Pagamenti"
    }, 
    {
      to: "/admin/notifications",
      icon: Bell,
      label: "Notifiche",
      badge: unreadNotifications > 0 ? (
        <div className="ml-auto">
          <div className="h-2 w-2 rounded-full bg-red-500 animate-pulse"></div>
        </div>
      ) : null
    }, 
    {
      to: "/admin/home-options",
      icon: Settings,
      label: "Opzioni Homepage"
    }
  ];

  const userItems: MenuItem[] = [
    {
      to: clientId ? `/admin/clients/${clientId}` : "/admin/clients",
      icon: Users,
      label: "I miei Dati"
    },
    {
      to: "/admin/calendar",
      icon: Calendar,
      label: "Calendario"
    }
  ];

  const items = isAdmin ? adminItems : userItems;

  const handleLogout = async (e: React.MouseEvent) => {
    e.preventDefault();
    try {
      console.log("Tentativo di logout...");
      await signOut();
      console.log("Logout completato");
    } catch (error) {
      console.error("Errore durante il logout:", error);
      window.location.href = "/";
    }
  };

  return <div className="h-screen w-64 bg-white border-r shadow-sm flex flex-col">
      <div className="p-4 border-b flex justify-between items-center">
        <Link to="/" className="flex items-center gap-2">
          {siteSettings?.logo_url ? (
            <img src={siteSettings.logo_url} alt="Logo" className="h-8 w-8 rounded-lg object-cover" />
          ) : (
            <div className="h-8 w-8 rounded-lg bg-primary flex items-center justify-center">
              <span className="text-white font-bold">F</span>
            </div>
          )}
          <span className="font-bold text-xl">{siteSettings?.site_name || "TimeWaste"}</span>
        </Link>
        
        {isMobile && <Button variant="ghost" size="icon" onClick={onCloseSidebar} className="md:hidden">
            <X size={18} />
          </Button>}
      </div>

      <ScrollArea className="flex-1 px-3 py-4">
        <nav className="space-y-6">
          <div className="space-y-1">
            <p className="px-4 text-xs font-medium text-gray-500 uppercase tracking-wider mb-2">
              Menu
            </p>
            {items.map(item => 
              <SidebarLink 
                key={item.to} 
                to={item.to} 
                icon={item.icon} 
                label={item.label}
                badge={item.badge} 
                isCurrent={location.pathname === item.to || (item.to.includes('/clients') && location.pathname.includes('/clients'))} 
              />
            )}
          </div>
        </nav>
      </ScrollArea>

      <div className="p-4 border-t bg-gray-50">
        <div className="flex items-center gap-3 mb-4">
          <Avatar className="h-10 w-10 border-2 border-white shadow-sm">
            <AvatarFallback className="bg-primary/10 text-primary">
              {userName && userName.charAt(0).toUpperCase()}
            </AvatarFallback>
          </Avatar>
          <div>
            <p className="text-sm font-medium">{userName}</p>
            <p className="text-xs text-muted-foreground">
              {isAdmin ? 'Amministratore' : 'Utente'}
            </p>
          </div>
        </div>
        
        <div className="flex flex-col gap-2">
          <button 
            className="flex items-center gap-2 px-3 py-2 rounded-md text-sm font-medium transition-colors text-red-500 hover:text-red-700 hover:bg-red-50" 
            onClick={handleLogout}
          >
            <LogOut size={18} />
            <span>Logout</span>
          </button>
        </div>
      </div>
    </div>;
};

export default AdminSidebar;
