
import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { AlertCircle, ArrowLeft } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Alert, AlertDescription } from "@/components/ui/alert";

interface ForgotPasswordFormProps {
  onBack: () => void;
}

const ForgotPasswordForm = ({ onBack }: ForgotPasswordFormProps) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    
    if (!email) {
      setError("Inserisci un indirizzo email");
      return;
    }
    
    setLoading(true);
    
    try {
      // Otteniamo l'URL base del sito senza hash o parametri di query
      const siteUrl = window.location.origin;
      
      // Utilizziamo il percorso completo e specifico alla pagina di reset
      const resetUrl = `${siteUrl}/reset-password`;
      
      console.log("Inviando email di reset con redirectTo:", resetUrl);
      
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: resetUrl,
      });
      
      if (error) {
        throw error;
      }
      
      setSuccess(true);
      toast({
        title: "Email inviata",
        description: "Se l'indirizzo email è associato a un account, riceverai istruzioni per reimpostare la tua password.",
      });
      
    } catch (error: any) {
      console.error("Errore durante l'invio dell'email di reset:", error);
      setError(error.message || "Si è verificato un errore durante l'invio dell'email di reset");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Reimposta Password</h2>
      <p className="text-muted-foreground">
        Inserisci il tuo indirizzo email e ti invieremo un link per reimpostare la password.
      </p>
      
      {error && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      
      {success ? (
        <div className="space-y-4">
          <Alert className="bg-green-50 text-green-800 border-green-200">
            <AlertDescription>
              Se l'indirizzo email è associato a un account, riceverai istruzioni per reimpostare la tua password.
            </AlertDescription>
          </Alert>
          
          <Button 
            variant="outline" 
            className="w-full flex items-center justify-center gap-2"
            onClick={onBack}
          >
            <ArrowLeft className="h-4 w-4" /> Torna al login
          </Button>
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Inserisci la tua email"
              disabled={loading}
              required
            />
          </div>
          
          <div className="flex flex-col space-y-2">
            <Button 
              type="submit" 
              className="w-full" 
              disabled={loading}
            >
              {loading ? "Invio in corso..." : "Invia link di reset"}
            </Button>
            
            <Button 
              type="button" 
              variant="outline" 
              className="w-full flex items-center justify-center gap-2"
              onClick={onBack}
              disabled={loading}
            >
              <ArrowLeft className="h-4 w-4" /> Torna al login
            </Button>
          </div>
        </form>
      )}
    </div>
  );
};

export default ForgotPasswordForm;
