
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Badge } from "@/components/ui/badge";
import { Card, CardContent } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { Clock, Dumbbell, Target } from "lucide-react";
import { WorkoutTemplate } from "@/types/workout";

interface WorkoutDetailsDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  workout: WorkoutTemplate | null;
}

const WorkoutDetailsDialog = ({ isOpen, onOpenChange, workout }: WorkoutDetailsDialogProps) => {
  if (!workout) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[700px] max-h-[85vh] overflow-hidden flex flex-col">
        <DialogHeader>
          <DialogTitle className="text-xl">{workout.name}</DialogTitle>
        </DialogHeader>
        
        <div className="flex flex-wrap gap-2 my-2">
          <Badge variant="outline" className="flex items-center gap-1.5">
            <Dumbbell className="h-3.5 w-3.5" />
            <span className="capitalize">{workout.difficulty}</span>
          </Badge>
          <Badge variant="outline" className="flex items-center gap-1.5">
            <Target className="h-3.5 w-3.5" />
            <span>{workout.target}</span>
          </Badge>
          <Badge variant="outline" className="flex items-center gap-1.5">
            <Clock className="h-3.5 w-3.5" />
            <span>{workout.duration}</span>
          </Badge>
        </div>
        
        {workout.description && (
          <p className="text-muted-foreground text-sm mb-4">{workout.description}</p>
        )}
        
        <Separator className="my-2" />
        
        <div className="mb-2">
          <h3 className="text-md font-medium mb-2">Lista Esercizi</h3>
        </div>
        
        <ScrollArea className="flex-1 pr-4">
          <div className="space-y-3">
            {workout.exercises && workout.exercises.map((exercise, index) => (
              <Card key={exercise.id} className="overflow-hidden">
                <CardContent className="p-4">
                  <div className="flex items-start justify-between">
                    <div>
                      <h4 className="font-medium text-base">
                        {index + 1}. {exercise.name}
                      </h4>
                      <div className="flex gap-4 mt-1.5 text-sm">
                        <div>
                          <span className="font-semibold">Serie:</span> {exercise.sets}
                        </div>
                        <div>
                          <span className="font-semibold">Ripetizioni:</span> {exercise.reps}
                        </div>
                        <div>
                          <span className="font-semibold">Recupero:</span> {exercise.rest}s
                        </div>
                      </div>
                      {exercise.notes && (
                        <p className="text-muted-foreground text-sm mt-1.5">
                          <span className="font-semibold">Note:</span> {exercise.notes}
                        </p>
                      )}
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
};

export default WorkoutDetailsDialog;
