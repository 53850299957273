
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { type FAQ } from "@/lib/supabase";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

interface FAQSectionProps {
  loading: boolean;
  faqs: FAQ[];
}

const FAQSection = ({ loading, faqs }: FAQSectionProps) => {
  if (!loading && faqs.length === 0) return null;
  
  return (
    <section id="faq" className="py-16">
      <h2 className="text-3xl font-bold mb-8 text-center bg-gradient-to-r from-primary to-primary/70 bg-clip-text text-transparent">
        Domande Frequenti
      </h2>
      <div className="space-y-4 max-w-3xl mx-auto">
        {loading ? (
          // Scheletri di caricamento per le FAQ
          Array(3).fill(0).map((_, index) => (
            <div key={index} className="bg-white/80 backdrop-blur-md p-6 rounded-xl shadow-lg">
              <Skeleton className="h-6 w-3/4 mb-4" />
              <Skeleton className="h-4 w-full mb-1" />
              <Skeleton className="h-4 w-11/12 mb-1" />
              <Skeleton className="h-4 w-10/12" />
            </div>
          ))
        ) : (
          <Accordion type="single" collapsible className="w-full">
            {faqs.slice(0, 3).map((faq) => (
              <AccordionItem key={faq.id} value={faq.id} className="bg-white/80 backdrop-blur-md mb-4 rounded-xl shadow-md border-0">
                <AccordionTrigger className="px-6 py-4 font-medium text-lg hover:no-underline">
                  {faq.question}
                </AccordionTrigger>
                <AccordionContent className="px-6 pb-4 text-gray-700">
                  {faq.answer}
                </AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </div>
      <div className="mt-8 text-center">
        <Button variant="link" className="text-primary hover:text-primary/80 font-medium text-lg">
          Vedi tutte le FAQ
        </Button>
      </div>
    </section>
  );
};

export default FAQSection;
