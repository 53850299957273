import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Plus, CreditCard } from "lucide-react";
import { format } from "date-fns";
import { ExtendedPayment } from "@/lib/supabase";

interface ClientPaymentsTabProps {
  payments: ExtendedPayment[];
  onAddPayment: () => void;
}

const ClientPaymentsTab = ({ payments, onAddPayment }: ClientPaymentsTabProps) => {
  const formatPaymentDate = (dateString: string) => {
    try {
      return format(new Date(dateString), 'dd/MM/yyyy');
    } catch (e) {
      return dateString;
    }
  };

  return (
    <Card className="shadow-sm">
      <CardHeader className="flex flex-row items-center justify-between">
        <div>
          <CardTitle className="flex items-center gap-2">
            <CreditCard size={20} className="text-primary" />
            Pagamenti
          </CardTitle>
          <CardDescription>
            Storico dei pagamenti effettuati dal cliente
          </CardDescription>
        </div>
        <Button size="sm" className="gap-2 shadow-sm" onClick={onAddPayment}>
          <Plus size={16} />
          <span>Registra pagamento</span>
        </Button>
      </CardHeader>
      <CardContent>
        {payments.length > 0 ? (
          <div className="overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Data</TableHead>
                  <TableHead>Importo</TableHead>
                  <TableHead>Descrizione</TableHead>
                  <TableHead>Riferimento</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {payments.map(payment => (
                  <TableRow key={payment.id}>
                    <TableCell>{formatPaymentDate(payment.date || '')}</TableCell>
                    <TableCell>€{payment.amount.toFixed(2)}</TableCell>
                    <TableCell>{payment.description || '-'}</TableCell>
                    <TableCell>
                      {payment.packageInfo?.name && `Pacchetto: ${payment.packageInfo.name}`}
                      {payment.course?.name && `Corso: ${payment.course.name}`}
                      {!payment.packageInfo?.name && !payment.course?.name && '-'}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          <div className="text-center py-12 border border-dashed rounded-lg">
            <CreditCard size={36} className="mx-auto text-muted-foreground mb-2 opacity-50" />
            <h3 className="text-lg font-medium mb-1">Nessun pagamento registrato</h3>
            <p className="text-muted-foreground">Registra il primo pagamento per questo cliente</p>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default ClientPaymentsTab;
