
import { useState, useEffect } from "react";
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";
import { addHours, format } from "date-fns";
import { parseScheduleData, parseClosurePeriodsData } from "@/utils/courseUtils";

export interface CalendarEvent {
  id: string;
  title: string;
  date: Date;
  hour: number;
  minute: number;
  courseId?: string;
  clientId?: string;
  remainingHours?: number;
  type?: string;
  colorCode?: string;
}

interface DbCalendarEvent {
  id: string;
  title: string;
  start_time: string;
  end_time: string;
  client_id?: string;
  course_id?: string;
  type: string;
  remaining_hours?: number;
}

export const useCalendarEvents = (
  currentDate: Date,
  isClientView: boolean,
  clientId: string | null
) => {
  const [calendarEvents, setCalendarEvents] = useState<CalendarEvent[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    const fetchEvents = async () => {
      setIsLoading(true);
      setError(null);
      
      try {
        // Fetch all clients to get their colors
        const { data: clientsData, error: clientsError } = await supabase
          .from("clients")
          .select("id, first_name, last_name, color_code");
          
        if (clientsError) {
          console.error("Errore nel caricamento dei clienti:", clientsError);
          setError(clientsError);
        }
        
        // Create a map of client colors
        const clientColorMap = new Map();
        if (clientsData) { // Verifichiamo che clientsData non sia null o undefined
          clientsData.forEach(client => {
            if (client && client.id && client.color_code) {
              clientColorMap.set(client.id, client.color_code);
            }
          });
        }
      
        let coursesQuery;
        
        if (isClientView && clientId) {
          const { data: clientCoursesData, error: clientCoursesError } = await supabase
            .from("client_courses")
            .select("course_id")
            .eq("client_id", clientId);
            
          if (clientCoursesError) {
            setError(clientCoursesError);
            throw clientCoursesError;
          }
          
          const courseIds = clientCoursesData?.map(cc => cc.course_id) || [];
          
          if (courseIds.length > 0) {
            coursesQuery = supabase
              .from("courses")
              .select("id, name, schedule, closure_periods, color_code, add_to_calendar")
              .eq("add_to_calendar", true)
              .in("id", courseIds);
          } else {
            setCalendarEvents([]);
            setIsLoading(false);
            return;
          }
        } else {
          coursesQuery = supabase
            .from("courses")
            .select("id, name, schedule, closure_periods, color_code, add_to_calendar")
            .eq("add_to_calendar", true);
        }
        
        const { data: coursesData, error: coursesError } = await coursesQuery;
        
        if (coursesError) {
          console.error("Errore nel caricamento dei corsi:", coursesError);
          setError(coursesError);
          toast({
            title: "Errore",
            description: "Impossibile caricare i corsi per il calendario",
            variant: "destructive"
          });
          return;
        }

        // Create a map of course colors
        const courseColorMap = new Map();
        if (coursesData) { // Verifichiamo che coursesData non sia null o undefined
          coursesData.forEach(course => {
            if (course && course.id && course.color_code) {
              courseColorMap.set(course.id, course.color_code);
            }
          });
        }

        let sessionsQuery;
        
        if (isClientView && clientId) {
          sessionsQuery = supabase
            .from("calendar_events")
            .select("*")
            .eq("client_id", clientId);
        } else {
          sessionsQuery = supabase
            .from("calendar_events")
            .select("*");
        }
        
        const { data: sessionsData, error: sessionsError } = await sessionsQuery;

        if (sessionsError) {
          console.error("Errore nel caricamento delle sessioni:", sessionsError);
          setError(sessionsError);
          toast({
            title: "Errore",
            description: "Impossibile caricare le sessioni per il calendario",
            variant: "destructive"
          });
          return;
        }

        const processedCourses = (coursesData || []).map(course => {
          console.log("Corso ricevuto dal DB:", course);
          return {
            ...course,
            schedule: parseScheduleData(course.schedule),
            closure_periods: parseClosurePeriodsData(course.closure_periods)
          };
        });
        
        console.log("Corsi processati:", processedCourses);
        
        const courseEvents = generateEventsFromCourses(processedCourses, currentDate, courseColorMap);
        
        const sessionEvents = (sessionsData || []).map(dbEvent => 
          mapDbEventToCalendarEvent(dbEvent, clientColorMap));
        
        setCalendarEvents([...courseEvents, ...sessionEvents]);
      } catch (error) {
        console.error("Errore imprevisto:", error);
        setError(error instanceof Error ? error : new Error('Errore sconosciuto'));
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvents();
  }, [currentDate, toast, clientId, isClientView]);

  const addCalendarEvent = async (eventData: Omit<CalendarEvent, "id">) => {
    if (isClientView) {
      toast({
        title: "Accesso limitato",
        description: "Non hai i permessi per aggiungere eventi al calendario",
        variant: "destructive"
      });
      return;
    }
    
    try {
      const startTime = new Date(eventData.date);
      startTime.setHours(eventData.hour, eventData.minute || 0, 0, 0);
      
      const endTime = addHours(startTime, 1);
      
      const dbEvent = {
        title: eventData.title,
        start_time: startTime.toISOString(),
        end_time: endTime.toISOString(),
        client_id: eventData.clientId,
        type: eventData.type || "session",
        remaining_hours: eventData.remainingHours
      };
      
      console.log("Inserimento nuovo evento:", dbEvent);
      
      const { data, error } = await supabase
        .from("calendar_events")
        .insert(dbEvent)
        .select("*")
        .single();
        
      if (error) {
        console.error("Errore nell'inserimento dell'evento:", error);
        setError(error);
        throw error;
      }
      
      console.log("Evento inserito con successo:", data);
      
      const newEvent = mapDbEventToCalendarEvent(data, new Map());
      
      setCalendarEvents(prev => [...prev, newEvent]);
      
      if (eventData.clientId) {
        await updatePackageHours(eventData.clientId, data.id, 1);
      }
      
      toast({
        title: "Sessione aggiunta",
        description: "La sessione è stata aggiunta al calendario con successo",
      });
    } catch (error) {
      console.error("Errore nell'aggiunta dell'evento:", error);
      setError(error instanceof Error ? error : new Error('Errore sconosciuto'));
      toast({
        title: "Errore",
        description: "Impossibile aggiungere la sessione al calendario",
        variant: "destructive"
      });
    }
  };

  const deleteCalendarEvent = async (event: CalendarEvent) => {
    if (isClientView) {
      toast({
        title: "Accesso limitato",
        description: "Non hai i permessi per eliminare eventi dal calendario",
        variant: "destructive"
      });
      return;
    }
    
    try {
      if (event.type === "course" && event.id.includes('-')) {
        toast({
          title: "Informazione",
          description: "Gli eventi dei corsi non possono essere eliminati singolarmente. Modifica le impostazioni del corso.",
          variant: "warning"
        });
        return;
      }
      
      console.log("Tentativo di eliminazione dell'evento con ID:", event.id);
      
      // Prima eliminiamo le notifiche associate a questo evento
      const { error: notificationsError } = await supabase
        .from("notifications")
        .delete()
        .eq("session_id", event.id);
        
      if (notificationsError) {
        console.error("Errore nell'eliminazione delle notifiche associate:", notificationsError);
        // Non blocchiamo l'operazione, proviamo comunque a eliminare l'evento
      }
      
      // Ora possiamo eliminare l'evento
      const { error } = await supabase
        .from("calendar_events")
        .delete()
        .eq("id", event.id);
        
      if (error) {
        console.error("Errore nell'eliminazione dell'evento:", error);
        setError(error);
        throw error;
      }
      
      setCalendarEvents(prev => prev.filter(e => e.id !== event.id));
      
      if (event.type === "session" && event.clientId) {
        await returnPackageHours(event.clientId, event.id, 1);
        
        toast({
          title: "Ore restituite",
          description: "La sessione è stata eliminata e le ore sono state restituite al pacchetto del cliente",
        });
      } else {
        toast({
          title: "Evento eliminato",
          description: "L'evento è stato eliminato dal calendario",
        });
      }
    } catch (error) {
      console.error("Errore nell'eliminazione dell'evento:", error);
      setError(error instanceof Error ? error : new Error('Errore sconosciuto'));
      toast({
        title: "Errore",
        description: "Impossibile eliminare l'evento dal calendario",
        variant: "destructive"
      });
    }
  };

  return {
    calendarEvents,
    isLoading,
    error,
    addCalendarEvent,
    deleteCalendarEvent
  };
};

const updatePackageHours = async (clientId: string, eventId: string, hoursToAdd: number) => {
  try {
    console.log(`Aggiornamento delle ore del pacchetto per il cliente ${clientId} e l'evento ${eventId}`);
    
    const { data: eventData, error: eventError } = await supabase
      .from("calendar_events")
      .select("*")
      .eq("id", eventId)
      .single();
    
    if (eventError) {
      console.error("Errore nel recupero dei dettagli dell'evento:", eventError);
      return;
    }
    
    const { data: sessionData, error: sessionError } = await supabase
      .from("client_packages")
      .select("*, packages(id, name, hours)")  // Modificato qui per includere esplicitamente il campo 'hours'
      .eq("client_id", clientId)
      .order("purchase_date", { ascending: false })
      .limit(1);
    
    if (sessionError || !sessionData || sessionData.length === 0) {
      console.error("Errore nel recupero del pacchetto del cliente:", sessionError);
      return;
    }
    
    const packageId = sessionData[0].id;
    const currentHoursUsed = sessionData[0].hours_used || 0;
    const newHoursUsed = currentHoursUsed + hoursToAdd;
    const packageName = sessionData[0].packages?.name || "Pacchetto ore";
    
    console.log(`Aggiornamento delle ore usate per il pacchetto ${packageId}: da ${currentHoursUsed} a ${newHoursUsed}`);
    
    const { data, error } = await supabase
      .from("client_packages")
      .update({ hours_used: newHoursUsed })
      .eq("id", packageId)
      .select();
    
    if (error) {
      console.error("Errore nell'aggiornamento delle ore usate:", error);
      return;
    }
    
    console.log("Ore usate aggiornate con successo:", data);
    
    const { error: notificationError } = await supabase
      .from("notifications")
      .insert({
        type: "package_hours_updated",
        client_id: clientId,
        message: `Sono state utilizzate ${hoursToAdd} ore dal pacchetto per una sessione.`,
        read: false
      });
    
    if (notificationError) {
      console.error("Errore nella creazione della notifica:", notificationError);
    }
    
    // Invio email al cliente
    try {
      const { data: clientData, error: clientError } = await supabase
        .from("clients")
        .select("email, first_name, last_name")
        .eq("id", clientId)
        .single();
      
      if (clientError || !clientData?.email) {
        console.error("Errore nel recupero dei dati del cliente per l'email:", clientError);
        return;
      }
      
      const clientName = `${clientData.first_name} ${clientData.last_name}`;
      const remainingHours = sessionData[0].packages?.hours 
                             ? sessionData[0].packages.hours - newHoursUsed 
                             : undefined;
      
      // Importa le utilità per l'email
      const { sendEmail, createPackageHoursAddedEmail } = await import("@/lib/email-utils");
      
      // Converti la data dell'evento
      const sessionDate = eventData ? new Date(eventData.start_time) : undefined;
      
      const emailHtml = createPackageHoursAddedEmail(
        clientName, 
        packageName, 
        hoursToAdd, 
        sessionDate, 
        remainingHours
      );
      const emailSubject = "Ore utilizzate dal tuo pacchetto";
      
      await sendEmail(clientData.email, emailSubject, emailHtml);
    } catch (emailError) {
      console.error("Errore nell'invio dell'email per aggiornamento ore pacchetto:", emailError);
    }
    
  } catch (error) {
    console.error("Errore generale nell'aggiornamento delle ore del pacchetto:", error);
  }
};

const returnPackageHours = async (clientId: string, eventId: string, hoursToReturn: number) => {
  try {
    console.log(`Restituzione di ${hoursToReturn} ore al pacchetto per il cliente ${clientId}`);
    
    // Recupera informazioni sull'evento per ottenere la data e l'ora
    const { data: eventData, error: eventError } = await supabase
      .from("calendar_events")
      .select("*")
      .eq("id", eventId)
      .single();
    
    if (eventError) {
      console.error("Errore nel recupero dei dettagli dell'evento:", eventError);
    }
    
    const { data: sessionData, error: sessionError } = await supabase
      .from("client_packages")
      .select("*, packages(id, name, hours)")  // Modificato qui per includere esplicitamente il campo 'hours'
      .eq("client_id", clientId)
      .order("purchase_date", { ascending: false })
      .limit(1);
    
    if (sessionError || !sessionData || sessionData.length === 0) {
      console.error("Errore nel recupero del pacchetto del cliente:", sessionError);
      return;
    }
    
    const packageId = sessionData[0].id;
    const currentHoursUsed = sessionData[0].hours_used || 0;
    const packageName = sessionData[0].packages?.name || "Pacchetto ore";
    
    const newHoursUsed = Math.max(0, currentHoursUsed - hoursToReturn);
    
    console.log(`Aggiornamento delle ore usate per il pacchetto ${packageId}: da ${currentHoursUsed} a ${newHoursUsed}`);
    
    const { data, error } = await supabase
      .from("client_packages")
      .update({ hours_used: newHoursUsed })
      .eq("id", packageId)
      .select();
    
    if (error) {
      console.error("Errore nell'aggiornamento delle ore usate:", error);
      return;
    }
    
    console.log("Ore usate aggiornate con successo:", data);
    
    const { error: notificationError } = await supabase
      .from("notifications")
      .insert({
        type: "package_hours_returned",
        client_id: clientId,
        message: `Sono state restituite ${hoursToReturn} ore al pacchetto a seguito dell'eliminazione di una sessione.`,
        read: false
      });
    
    if (notificationError) {
      console.error("Errore nella creazione della notifica:", notificationError);
    }
    
    // Invio email al cliente
    try {
      const { data: clientData, error: clientError } = await supabase
        .from("clients")
        .select("email, first_name, last_name")
        .eq("id", clientId)
        .single();
      
      if (clientError || !clientData?.email) {
        console.error("Errore nel recupero dei dati del cliente per l'email:", clientError);
        return;
      }
      
      const clientName = `${clientData.first_name} ${clientData.last_name}`;
      const remainingHours = sessionData[0].packages?.hours 
                             ? sessionData[0].packages.hours - newHoursUsed 
                             : undefined;
      
      // Importa le utilità per l'email
      const { sendEmail, createPackageHoursRemovedEmail } = await import("@/lib/email-utils");
      
      // Converti la data dell'evento
      const sessionDate = eventData ? new Date(eventData.start_time) : undefined;
      
      const emailHtml = createPackageHoursRemovedEmail(
        clientName, 
        packageName, 
        hoursToReturn,
        sessionDate,
        remainingHours
      );
      const emailSubject = "Ore restituite al tuo pacchetto";
      
      await sendEmail(clientData.email, emailSubject, emailHtml);
    } catch (emailError) {
      console.error("Errore nell'invio dell'email per restituzione ore pacchetto:", emailError);
    }
    
  } catch (error) {
    console.error("Errore generale nella restituzione delle ore al pacchetto:", error);
  }
};

const mapDbEventToCalendarEvent = (dbEvent: DbCalendarEvent, clientColorMap: Map<string, string>): CalendarEvent => {
  const startDate = new Date(dbEvent.start_time);
  
  // Get client color if available
  const colorCode = dbEvent.client_id ? clientColorMap.get(dbEvent.client_id) : undefined;
  
  return {
    id: dbEvent.id,
    title: dbEvent.title,
    date: startDate,
    hour: startDate.getHours(),
    minute: startDate.getMinutes(),
    courseId: dbEvent.course_id,
    clientId: dbEvent.client_id,
    remainingHours: dbEvent.remaining_hours,
    type: dbEvent.type,
    colorCode: colorCode
  };
};

const generateEventsFromCourses = (courses: any[], weekStart: Date, courseColorMap: Map<string, string>): CalendarEvent[] => {
  const events: CalendarEvent[] = [];
  
  courses.forEach(course => {
    console.log("Elaborazione corso per calendario:", course.name, "Schedule:", course.schedule);
    
    if (!course.schedule) {
      console.log("Nessuno schedule trovato per il corso:", course.name);
      return;
    }
    
    const scheduleArray = parseScheduleData(course.schedule);
    
    console.log("Schedule array dopo il parsing:", scheduleArray);
    
    if (scheduleArray.length === 0) {
      console.log("Array di schedule vuoto o non valido per il corso:", course.name);
      return;
    }
    
    scheduleArray.forEach((scheduleItem: any) => {
      console.log("Elaborazione elemento di schedule:", scheduleItem);
      
      const dayMap: { [key: string]: number } = {
        "Lunedì": 1,
        "Martedì": 2,
        "Mercoledì": 3,
        "Giovedì": 4,
        "Venerdì": 5,
        "Sabato": 6,
        "Domenica": 7
      };
      
      const dayIndex = dayMap[scheduleItem.day];
      
      if (!dayIndex) {
        console.log("Giorno non valido:", scheduleItem.day);
        return;
      }
      
      const eventDate = addDays(weekStart, dayIndex - 1);
      
      const [hourStr, minuteStr] = scheduleItem.time.split(":");
      const hour = parseInt(hourStr, 10);
      const minute = parseInt(minuteStr, 10);
      
      events.push({
        id: `${course.id}-${dayIndex}-${hour}-${minute}`,
        title: course.name,
        date: eventDate,
        hour,
        minute,
        courseId: course.id,
        type: "course",
        colorCode: courseColorMap.get(course.id)
      });
    });
  });
  
  console.log("Eventi generati dai corsi:", events);
  return events;
};

function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
