
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useToast } from "@/hooks/use-toast";
import { ArrowLeft, AlertCircle, CheckCircle } from "lucide-react";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [sessionChecked, setSessionChecked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();

  // Estrai i parametri dall'URL (sia dal fragment che dalla query string)
  const getParamsFromUrl = () => {
    // Ottieni parametri dal fragment (#)
    const hashParams = new URLSearchParams(window.location.hash.substring(1));
    
    // Ottieni parametri dalla query string (?)
    const queryParams = new URLSearchParams(location.search);
    
    // Combina i parametri dando priorità al fragment
    const params: Record<string, string> = {};
    
    // Aggiungi i parametri dalla query string
    for (const [key, value] of queryParams.entries()) {
      params[key] = value;
    }
    
    // Sovrascrivi/aggiungi i parametri dal fragment (hanno priorità)
    for (const [key, value] of hashParams.entries()) {
      params[key] = value;
    }
    
    console.log("Parametri estratti dall'URL:", params);
    return params;
  };

  useEffect(() => {
    const checkSession = async () => {
      try {
        console.log("URL completo:", window.location.href);
        console.log("Fragment:", window.location.hash);
        console.log("Query:", location.search);
        
        const params = getParamsFromUrl();
        
        // Verifica se ci sono errori nei parametri
        if (params.error) {
          setError(`Errore: ${params.error_description || params.error}`);
          setSessionChecked(true);
          return;
        }
        
        // Controlla se è presente una sessione
        const { data, error } = await supabase.auth.getSession();
        
        console.log("Sessione corrente:", data.session);
        
        if (error) {
          setError("Errore durante la verifica della sessione: " + error.message);
          setSessionChecked(true);
          return;
        }
        
        if (!data.session) {
          // Se non c'è una sessione valida, verifica se c'è un token nell'URL
          // e se Supabase non ha ancora elaborato il token
          setError("Sessione non valida o token scaduto. Richiedi un nuovo link di reset password.");
          setSessionChecked(true);
          return;
        }
        
        // La sessione è valida, possiamo permettere all'utente di reimpostare la password
        setSessionChecked(true);
        
      } catch (error: any) {
        console.error("Errore durante il controllo della sessione:", error);
        setError(error.message || "Si è verificato un errore durante la verifica della sessione");
        setSessionChecked(true);
      }
    };

    checkSession();
  }, [location]);

  const handleReset = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    
    // Validazione base
    if (password.length < 6) {
      setError("La password deve contenere almeno 6 caratteri");
      return;
    }
    
    if (password !== confirmPassword) {
      setError("Le password non corrispondono");
      return;
    }
    
    setLoading(true);
    
    try {
      // Aggiorniamo la password dell'utente corrente
      const { error } = await supabase.auth.updateUser({
        password: password
      });
      
      if (error) {
        throw error;
      }
      
      setSuccess(true);
      toast({
        title: "Password aggiornata",
        description: "La tua password è stata reimpostata con successo.",
      });
      
      // Redirect automatico dopo 3 secondi
      setTimeout(() => {
        navigate("/login");
      }, 3000);
      
    } catch (error: any) {
      console.error("Errore durante il reset della password:", error);
      setError(error.message || "Si è verificato un errore durante il reset della password");
    } finally {
      setLoading(false);
    }
  };

  // Mostra un loader mentre verifichiamo la sessione
  if (!sessionChecked) {
    return (
      <div className="flex justify-center items-center min-h-screen p-4 bg-gray-50">
        <Card className="w-full max-w-md text-center p-6">
          <div className="flex flex-col items-center justify-center space-y-4">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary"></div>
            <p>Verifica della sessione in corso...</p>
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center min-h-screen p-4 bg-gray-50">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle>Reimposta la password</CardTitle>
          <CardDescription>
            Inserisci una nuova password per il tuo account
          </CardDescription>
        </CardHeader>
        
        <CardContent>
          {error && (
            <Alert variant="destructive" className="mb-4">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}
          
          {success ? (
            <Alert className="mb-4 bg-green-50 text-green-800 border-green-200">
              <CheckCircle className="h-4 w-4 text-green-600" />
              <AlertDescription>
                Password aggiornata con successo! Verrai reindirizzato alla pagina di login in 3 secondi.
              </AlertDescription>
            </Alert>
          ) : (
            <form onSubmit={handleReset}>
              <div className="grid gap-4">
                <div className="space-y-2">
                  <Label htmlFor="password">Nuova Password</Label>
                  <Input
                    id="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Inserisci la nuova password"
                    disabled={loading || !!error}
                    required
                  />
                </div>
                
                <div className="space-y-2">
                  <Label htmlFor="confirm-password">Conferma Password</Label>
                  <Input
                    id="confirm-password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Conferma la nuova password"
                    disabled={loading || !!error}
                    required
                  />
                </div>
              </div>
              
              <Button 
                type="submit" 
                className="w-full mt-4" 
                disabled={loading || !!error}
              >
                {loading ? "Elaborazione..." : "Reimposta Password"}
              </Button>
            </form>
          )}
        </CardContent>
        
        <CardFooter>
          <Button 
            variant="outline" 
            className="w-full flex items-center justify-center gap-2"
            onClick={() => navigate("/login")}
          >
            <ArrowLeft className="h-4 w-4" /> Torna al login
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default ResetPassword;
