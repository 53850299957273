
import { useState, useEffect } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { PersonalSessions } from "./PersonalSessions";
import { CourseSessions } from "./CourseSessions";
import NoSessionsMessage from "./NoSessionsMessage";
import { Session, CourseSession } from "./sessionTypes";
import { fetchClientSessions, fetchClientCourseSessions } from "./sessionUtils";
import { useToast } from "@/hooks/use-toast";

interface ClientSessionsTabProps {
  clientId: string;
  refreshTrigger?: number;
}

const ClientSessionsTab = ({ clientId, refreshTrigger }: ClientSessionsTabProps) => {
  const [personalSessions, setPersonalSessions] = useState<Session[]>([]);
  const [courseSessions, setCourseSessions] = useState<CourseSession[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      try {
        const [personalData, courseData] = await Promise.all([
          fetchClientSessions(clientId),
          fetchClientCourseSessions(clientId)
        ]);
        
        setPersonalSessions(personalData);
        setCourseSessions(courseData);
      } catch (error) {
        console.error("Errore nel caricamento delle sessioni:", error);
        toast({
          title: "Errore",
          description: "Impossibile caricare le sessioni",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [clientId, toast, refreshTrigger]);

  const hasPersonalSessions = personalSessions.length > 0;
  const hasCourseSessions = courseSessions.some(course => course.nextSessions.length > 0);
  const hasAnySessions = hasPersonalSessions || hasCourseSessions;

  if (isLoading) {
    return (
      <div className="flex justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!hasAnySessions) {
    return <NoSessionsMessage />;
  }

  return (
    <Tabs defaultValue="personal" className="w-full">
      <TabsList className="grid grid-cols-2 mb-4">
        <TabsTrigger value="personal">Sessioni Personali</TabsTrigger>
        <TabsTrigger value="courses">Sessioni Corsi</TabsTrigger>
      </TabsList>
      
      <TabsContent value="personal">
        {hasPersonalSessions ? (
          <PersonalSessions sessions={personalSessions} />
        ) : (
          <NoSessionsMessage type="personal" />
        )}
      </TabsContent>
      
      <TabsContent value="courses">
        {hasCourseSessions ? (
          <CourseSessions courseSessions={courseSessions} />
        ) : (
          <NoSessionsMessage type="course" />
        )}
      </TabsContent>
    </Tabs>
  );
};

export default ClientSessionsTab;
