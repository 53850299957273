
import React, { useState, useEffect } from "react";
import { Card, CardContent } from "@/components/ui/card";
import MonthSelector from "@/components/calendar/MonthSelector";
import WeeklyCalendar from "@/components/calendar/WeeklyCalendar";
import CalendarSettingsModal from "@/components/calendar/CalendarSettingsModal";
import CalendarHeader from "@/components/calendar/CalendarHeader";
import CalendarLoading from "@/components/calendar/CalendarLoading";
import { useCalendarSettings } from "@/hooks/use-calendar-settings";
import { useCalendarEvents } from "@/hooks/use-calendar-events";
import { useClientInfo } from "@/hooks/use-client-info";
import { Skeleton } from "@/components/ui/skeleton";
import { useIsMobile } from "@/hooks/use-mobile";
import { toast } from "sonner";

const Calendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isFullyLoaded, setIsFullyLoaded] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const isMobile = useIsMobile();
  
  // Ottieni le informazioni sul cliente
  const { clientId, isClientView, user } = useClientInfo();

  // Ottieni le impostazioni del calendario
  const {
    calendarSettings,
    isLoading: isSettingsLoading,
    saveCalendarSettings
  } = useCalendarSettings(user, isClientView);

  // Ottieni gli eventi del calendario
  const {
    calendarEvents,
    isLoading: isEventsLoading,
    addCalendarEvent,
    deleteCalendarEvent,
    error: eventsError
  } = useCalendarEvents(currentDate, isClientView, clientId);

  // Stato di caricamento combinato
  const isLoading = isSettingsLoading || isEventsLoading;

  // Gestione del caricamento con ritardo di 1.5 secondi
  useEffect(() => {
    if (!isLoading && !isFullyLoaded) {
      const timer = setTimeout(() => {
        setIsFullyLoaded(true);
      }, 1500); // 1.5 secondi di ritardo minimo
      
      return () => clearTimeout(timer);
    }
    
    if (isLoading) {
      setIsFullyLoaded(false);
    }
  }, [isLoading, isFullyLoaded]);

  // Gestione degli errori
  useEffect(() => {
    if (eventsError && retryCount < 3) {
      // Ritenta il caricamento dopo 2 secondi in caso di errore
      const timer = setTimeout(() => {
        setRetryCount(prev => prev + 1);
        setIsFullyLoaded(false);
      }, 2000);
      
      toast.error("Errore nel caricamento degli eventi. Nuovo tentativo in corso...");
      
      return () => clearTimeout(timer);
    }
    
    if (eventsError && retryCount >= 3) {
      toast.error("Impossibile caricare gli eventi del calendario. Ricarica la pagina.");
    }
  }, [eventsError, retryCount]);

  return (
    <div className="space-y-4 animate-fade-in h-full">
      <CalendarHeader 
        isClientView={isClientView}
        onOpenSettings={() => setIsSettingsOpen(true)}
      />

      <Card className="border rounded-lg shadow-sm h-[calc(100vh-200px)] sm:h-[calc(100vh-200px)] overflow-hidden">
        <CardContent className="p-2 sm:p-4 h-full overflow-hidden">
          {!isFullyLoaded ? (
            <CalendarLoading retryCount={retryCount} />
          ) : (
            <div className="h-full flex flex-col">
              <MonthSelector
                currentDate={currentDate}
                onDateChange={setCurrentDate}
              />
              
              <div className="mt-2 sm:mt-4 flex-1 overflow-hidden">
                <WeeklyCalendar
                  currentDate={currentDate}
                  onDateChange={setCurrentDate}
                  onOpenSettings={() => setIsSettingsOpen(true)}
                  settings={calendarSettings}
                  events={calendarEvents}
                  onAddEvent={isClientView ? undefined : addCalendarEvent}
                  onDeleteEvent={isClientView ? undefined : deleteCalendarEvent}
                />
              </div>
            </div>
          )}
          
          {!isClientView && (
            <CalendarSettingsModal
              open={isSettingsOpen}
              onOpenChange={setIsSettingsOpen}
              settings={calendarSettings}
              onSaveSettings={saveCalendarSettings}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Calendar;
