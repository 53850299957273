
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Package, Course } from "@/lib/supabase";

interface PaymentDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  newPayment: {
    amount: number;
    date: string;
    description: string;
    package_id: string;
    course_id: string;
  };
  onInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  availablePackages: Package[];
  availableCourses: Course[];
  onAddPayment: () => void;
}

const PaymentDialog = ({
  open,
  onOpenChange,
  newPayment,
  onInputChange,
  availablePackages,
  availableCourses,
  onAddPayment
}: PaymentDialogProps) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Registra Pagamento</DialogTitle>
          <DialogDescription>
            Inserisci i dettagli del pagamento
          </DialogDescription>
        </DialogHeader>
        
        <div className="grid gap-4 py-4">
          <div>
            <Input
              id="payment-amount"
              name="amount"
              type="number"
              step="0.01"
              value={newPayment.amount}
              onChange={onInputChange}
              placeholder="Importo (€)"
            />
          </div>
          
          <div>
            <Input
              id="payment-date"
              name="date"
              type="date"
              value={newPayment.date}
              onChange={onInputChange}
              placeholder="Data"
            />
          </div>
          
          <div>
            <Textarea
              id="payment-description"
              name="description"
              value={newPayment.description}
              onChange={onInputChange}
              placeholder="Descrizione"
            />
          </div>
          
          <div>
            <Select 
              value={newPayment.package_id} 
              onValueChange={(value) => {
                const event = {
                  target: {
                    name: "package_id",
                    value
                  }
                } as React.ChangeEvent<HTMLSelectElement>;
                onInputChange(event as any);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Seleziona un pacchetto (opzionale)" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="">Nessun pacchetto</SelectItem>
                {availablePackages.map(pkg => (
                  <SelectItem key={pkg.id} value={pkg.id}>
                    {pkg.name} - {pkg.hours} ore
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          
          <div>
            <Select 
              value={newPayment.course_id} 
              onValueChange={(value) => {
                const event = {
                  target: {
                    name: "course_id",
                    value
                  }
                } as React.ChangeEvent<HTMLSelectElement>;
                onInputChange(event as any);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder="Seleziona un corso (opzionale)" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="">Nessun corso</SelectItem>
                {availableCourses.map(course => (
                  <SelectItem key={course.id} value={course.id}>
                    {course.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>Annulla</Button>
          <Button onClick={onAddPayment} disabled={newPayment.amount <= 0}>Registra</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default PaymentDialog;
