import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from "@/components/ui/card";
import { useToast } from "@/hooks/use-toast";
import { Search, Plus, Bug } from "lucide-react";
import { Client } from "@/lib/supabase";
import Loading from "@/components/Loading";
import { useClients, ClientFormValues } from "@/hooks/use-clients";
import ClientsTable from "@/components/clients/ClientsTable";
import ClientsEmptyState from "@/components/clients/ClientsEmptyState";
import ClientFormDialog from "@/components/clients/ClientFormDialog";
import DeleteClientDialog from "@/components/clients/DeleteClientDialog";
import { useAuth } from "@/lib/auth";
import { supabase } from "@/integrations/supabase/client";
const Clients = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [isClientDialogOpen, setIsClientDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isDiagnosing, setIsDiagnosing] = useState(false);
  const {
    toast
  } = useToast();
  const {
    user
  } = useAuth();
  const {
    filteredClients,
    isLoading,
    searchTerm,
    setSearchTerm,
    saveClient,
    deleteClient,
    checkDatabaseIntegrity
  } = useClients();
  useEffect(() => {
    const checkIsAdmin = async () => {
      if (!user) return;
      try {
        const {
          data,
          error
        } = await supabase.from('profiles').select('role').eq('id', user.id).single();
        if (!error && data) {
          console.log("Clients: ruolo utente:", data.role);
          setIsAdmin(data.role === 'admin');
        }
      } catch (error) {
        console.error("Errore nel controllo del ruolo admin:", error);
      }
    };
    checkIsAdmin();
  }, [user]);
  useEffect(() => {
    if (isAdmin && user) {
      const runInitialDiagnostic = async () => {
        console.log("🔍 Esecuzione diagnostica automatica all'avvio...");
        await checkDatabaseIntegrity();
      };
      runInitialDiagnostic();
    }
  }, [isAdmin, user, checkDatabaseIntegrity]);
  const handleRunDiagnostic = async () => {
    setIsDiagnosing(true);
    try {
      await checkDatabaseIntegrity();
      toast({
        title: "Diagnostica completata",
        description: "Controlla la console per i risultati dettagliati"
      });
    } catch (error) {
      console.error("Errore durante la diagnostica:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore durante la diagnostica",
        variant: "destructive"
      });
    } finally {
      setIsDiagnosing(false);
    }
  };
  const handleOpenClientDialog = (client?: Client) => {
    if (client) {
      console.log("Apertura finestra di modifica per cliente:", client);
      setSelectedClient(client);
    } else {
      setSelectedClient(null);
    }
    setIsClientDialogOpen(true);
  };
  const handleSaveClient = async (values: ClientFormValues, avatarUrl: string | null) => {
    setIsSaving(true);
    try {
      console.log("Saving client with values:", values);
      console.log("Avatar URL da salvare:", avatarUrl);
      const clientData: Record<string, any> = {
        ...(selectedClient?.id ? {
          id: selectedClient.id
        } : {}),
        first_name: values.first_name,
        last_name: values.last_name,
        age: values.age,
        height: values.height,
        weight: values.weight,
        gender: values.gender,
        training_frequency: values.training_frequency,
        general_condition: values.general_condition,
        avatar_url: avatarUrl,
        email: values.email || null,
        telefono: values.telefono || null
      };

      // Gestione sicura del campo sports
      if (typeof values.sports === 'string' && values.sports.trim() !== '') {
        clientData.sports = values.sports.trim().split(',').map(s => s.trim()).filter(Boolean);
      } else if (Array.isArray(values.sports)) {
        clientData.sports = values.sports;
      } else {
        clientData.sports = [];
      }
      console.log("Dati cliente finali da salvare:", clientData);
      const success = await saveClient(clientData);
      if (success) {
        setIsClientDialogOpen(false);
        setSelectedClient(null);
      }
    } finally {
      setIsSaving(false);
    }
  };
  const handleDeleteClient = async () => {
    if (!selectedClient) return;
    const success = await deleteClient(selectedClient.id);
    if (success) {
      setIsDeleteDialogOpen(false);
      setSelectedClient(null);
    }
  };
  if (isLoading) {
    return <Loading />;
  }
  return <div className="animate-fade-in">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-6">
        <div>
          <h1 className="text-2xl font-bold">Clienti</h1>
        
        </div>
        <div className="flex gap-2">
          {isAdmin && <>
              
              <Button onClick={() => handleOpenClientDialog()} className="shadow-sm">
                <Plus className="mr-2 h-4 w-4" />
                Aggiungi Cliente
              </Button>
            </>}
        </div>
      </div>
      
      <Card>
        <CardContent className="p-6">
          {isAdmin && <div className="flex items-center mb-6">
              <div className="relative flex-1">
                <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
                <Input placeholder="Cerca clienti..." value={searchTerm} onChange={e => setSearchTerm(e.target.value)} className="pl-9" />
              </div>
            </div>}
          
          {filteredClients.length > 0 ? <ClientsTable clients={filteredClients} onEdit={handleOpenClientDialog} onDelete={client => {
          setSelectedClient(client);
          setIsDeleteDialogOpen(true);
        }} /> : <ClientsEmptyState onAddClient={() => handleOpenClientDialog()} />}
        </CardContent>
        {isAdmin}
      </Card>

      {isAdmin && <>
          <ClientFormDialog open={isClientDialogOpen} onOpenChange={setIsClientDialogOpen} client={selectedClient} onSave={handleSaveClient} isSaving={isSaving} />

          <DeleteClientDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen} onConfirm={handleDeleteClient} />
        </>}
    </div>;
};
export default Clients;