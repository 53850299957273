
import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { 
  Bell, 
  Search, 
  X, 
  BellOff, 
  Check, 
  Clock, 
  UserPlus,
  UserMinus,
  Package,
  BookOpen,
  Calendar,
  UserX,
  AlertTriangle
} from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import type { Notification } from "@/lib/supabase";
import Loading from "@/components/Loading";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";

const Notifications = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [filteredNotifications, setFilteredNotifications] = useState<Notification[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState<string | null>(null);
  const { toast } = useToast();

  const fetchNotifications = async () => {
    setIsLoading(true);
    try {
      console.log("Caricamento notifiche...");
      const { data, error } = await supabase
        .from('notifications')
        .select('*')
        .order('created_at', { ascending: false });
      
      if (error) {
        throw error;
      }
      
      console.log("Notifiche caricate:", data);
      setNotifications(data || []);
      setFilteredNotifications(data || []);
    } catch (error) {
      console.error('Errore caricamento notifiche:', error);
      toast({
        title: "Errore",
        description: "Impossibile caricare le notifiche",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredNotifications(notifications);
    } else {
      const lowercaseQuery = searchQuery.toLowerCase();
      const filtered = notifications.filter((notification) => 
        notification.message.toLowerCase().includes(lowercaseQuery) || 
        notification.type.toLowerCase().includes(lowercaseQuery)
      );
      setFilteredNotifications(filtered);
    }
  }, [searchQuery, notifications]);

  const markAsRead = async (id: string) => {
    try {
      const { error } = await supabase
        .from('notifications')
        .update({ read: true })
        .eq('id', id);
      
      if (error) {
        throw error;
      }
      
      await fetchNotifications();
      
      toast({
        title: "Successo",
        description: "Notifica segnata come letta",
      });
    } catch (error) {
      console.error('Errore aggiornamento notifica:', error);
      toast({
        title: "Errore",
        description: "Impossibile aggiornare la notifica",
        variant: "destructive",
      });
    }
  };

  const markAllAsRead = async () => {
    try {
      const unreadNotifications = notifications.filter(n => !n.read);
      
      if (unreadNotifications.length === 0) {
        toast({
          title: "Informazione",
          description: "Non ci sono notifiche da leggere",
        });
        return;
      }
      
      const { error } = await supabase
        .from('notifications')
        .update({ read: true })
        .eq('read', false);
      
      if (error) {
        throw error;
      }
      
      await fetchNotifications();
      
      toast({
        title: "Successo",
        description: `${unreadNotifications.length} notifiche segnate come lette`,
      });
    } catch (error) {
      console.error('Errore aggiornamento notifiche:', error);
      toast({
        title: "Errore",
        description: "Impossibile aggiornare le notifiche",
        variant: "destructive",
      });
    }
  };

  const openDeleteDialog = (id: string) => {
    setSelectedNotificationId(id);
    setIsDeleteDialogOpen(true);
  };

  const handleDeleteNotification = async () => {
    if (!selectedNotificationId) return;
    
    try {
      const { error } = await supabase
        .from('notifications')
        .delete()
        .eq('id', selectedNotificationId);
      
      if (error) {
        throw error;
      }
      
      setIsDeleteDialogOpen(false);
      setSelectedNotificationId(null);
      await fetchNotifications();
      
      toast({
        title: "Successo",
        description: "Notifica eliminata con successo",
      });
    } catch (error) {
      console.error('Errore eliminazione notifica:', error);
      toast({
        title: "Errore",
        description: "Impossibile eliminare la notifica",
        variant: "destructive",
      });
    }
  };

  const getNotificationIcon = (notification: Notification) => {
    switch (notification.type) {
      case "new_user":
        return { icon: <UserPlus className="h-4 w-4 text-blue-500" />, color: "text-blue-500" };
      case "new_client":
        return { icon: <UserPlus className="h-4 w-4 text-green-500" />, color: "text-green-500" };
      case "deleted_client":
        return { icon: <UserX className="h-4 w-4 text-red-500" />, color: "text-red-500" };
      case "new_package":
        return { icon: <Package className="h-4 w-4 text-blue-500" />, color: "text-blue-500" };
      case "new_course":
        return { icon: <BookOpen className="h-4 w-4 text-violet-500" />, color: "text-violet-500" };
      case "new_session":
        return { icon: <Calendar className="h-4 w-4 text-indigo-500" />, color: "text-indigo-500" };
      case "cancelled_session":
        return { icon: <BellOff className="h-4 w-4 text-red-500" />, color: "text-red-500" };
      case "cancelled_course":
        return { icon: <BellOff className="h-4 w-4 text-red-500" />, color: "text-red-500" };
      case "package_ended":
        return { icon: <AlertTriangle className="h-4 w-4 text-orange-500" />, color: "text-orange-500" };
      default:
        return { icon: <Bell className="h-4 w-4 text-gray-500" />, color: "text-gray-500" };
    }
  };

  const getTimeAgo = (createdAt: string) => {
    const date = new Date(createdAt);
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    
    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      return `${interval} ${interval === 1 ? 'anno' : 'anni'} fa`;
    }
    
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return `${interval} ${interval === 1 ? 'mese' : 'mesi'} fa`;
    }
    
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return `${interval} ${interval === 1 ? 'giorno' : 'giorni'} fa`;
    }
    
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return `${interval} ${interval === 1 ? 'ora' : 'ore'} fa`;
    }
    
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return `${interval} ${interval === 1 ? 'minuto' : 'minuti'} fa`;
    }
    
    return `${Math.floor(seconds)} secondi fa`;
  };

  const getNotificationTypeLabel = (type: string) => {
    switch (type) {
      case "new_user": return "Nuovo utente";
      case "new_client": return "Nuovo cliente";
      case "new_package": return "Nuovo pacchetto";
      case "new_course": return "Nuovo corso";
      case "new_session": return "Nuova sessione";
      case "cancelled_session": return "Sessione cancellata";
      case "cancelled_course": return "Corso cancellato"; 
      case "deleted_client": return "Cliente cancellato";
      case "package_ended": return "Pacchetto terminato";
      default: return type;
    }
  };

  const filterByNotificationType = (type: string) => {
    setSearchQuery(type);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="space-y-8 animate-fade-in">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
        <div>
          <h1 className="text-3xl font-bold tracking-tight">Notifiche</h1>
          
        </div>
        
        <Button onClick={markAllAsRead}>
          <Check className="mr-2 h-4 w-4" />
          Segna tutte come lette
        </Button>
      </div>

      <div className="flex flex-col space-y-4">
        <div className="relative flex-1">
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Cerca notifiche..."
            className="pl-10"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {searchQuery && (
            <button
              className="absolute right-3 top-1/2 -translate-y-1/2"
              onClick={() => setSearchQuery("")}
            >
              <X className="h-4 w-4 text-muted-foreground" />
            </button>
          )}
        </div>

        <div className="flex flex-wrap gap-2">
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => filterByNotificationType("new_user")}
            className="flex items-center"
          >
            <UserPlus className="h-3.5 w-3.5 mr-1 text-blue-500" />
            Nuovi utenti
          </Button>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => filterByNotificationType("new_client")}
            className="flex items-center"
          >
            <UserPlus className="h-3.5 w-3.5 mr-1 text-green-500" />
            Nuovi clienti
          </Button>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => filterByNotificationType("new_package")}
            className="flex items-center"
          >
            <Package className="h-3.5 w-3.5 mr-1 text-blue-500" />
            Nuovi pacchetti
          </Button>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => filterByNotificationType("new_course")}
            className="flex items-center"
          >
            <BookOpen className="h-3.5 w-3.5 mr-1 text-violet-500" />
            Nuovi corsi
          </Button>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => filterByNotificationType("new_session")}
            className="flex items-center"
          >
            <Calendar className="h-3.5 w-3.5 mr-1 text-indigo-500" />
            Nuove sessioni
          </Button>
          <Button 
            variant="outline" 
            size="sm" 
            onClick={() => filterByNotificationType("cancelled")}
            className="flex items-center"
          >
            <BellOff className="h-3.5 w-3.5 mr-1 text-red-500" />
            Cancellazioni
          </Button>
        </div>
      </div>

      <Card>
        <CardHeader className="px-6">
          <div className="flex items-center justify-between">
            <div>
              <CardDescription>
                {filteredNotifications.length} notific{filteredNotifications.length === 1 ? 'a' : 'he'} totali
              </CardDescription>
            </div>
            <Bell className="h-5 w-5 text-muted-foreground" />
          </div>
        </CardHeader>
        <CardContent className="px-6">
          {filteredNotifications.length > 0 ? (
            <div className="space-y-1">
              {filteredNotifications.map((notification) => {
                const { icon, color } = getNotificationIcon(notification);
                const typeLabel = getNotificationTypeLabel(notification.type);
                return (
                  <div 
                    key={notification.id} 
                    className={`flex items-start space-x-3 rounded-md p-3 hover:bg-secondary transition-colors ${notification.read ? 'opacity-70' : ''}`}
                  >
                    <div className="mt-0.5">{icon}</div>
                    <div className="flex-1 space-y-1">
                      <div className="flex justify-between">
                        <div>
                          <span className={`text-xs font-medium px-2 py-0.5 rounded-full bg-secondary ${color}`}>
                            {typeLabel}
                          </span>
                          <p className={`text-sm mt-1 ${notification.read ? '' : 'font-semibold'}`}>
                            {notification.message}
                          </p>
                        </div>
                        <div className="flex items-center space-x-2">
                          {!notification.read && (
                            <Button 
                              variant="ghost" 
                              size="icon" 
                              className="h-7 w-7"
                              onClick={() => markAsRead(notification.id)}
                            >
                              <Check className="h-4 w-4" />
                            </Button>
                          )}
                          <Button 
                            variant="ghost" 
                            size="icon" 
                            className="h-7 w-7 text-destructive"
                            onClick={() => openDeleteDialog(notification.id)}
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                      </div>
                      <div className="flex items-center text-xs text-muted-foreground">
                        <Clock className="mr-1 h-3 w-3" />
                        {getTimeAgo(notification.created_at)}
                      </div>
                    </div>
                    {!notification.read && (
                      <div className="h-2 w-2 rounded-full bg-primary"></div>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="text-center py-8">
              <BellOff className="mx-auto h-12 w-12 text-muted-foreground opacity-50 mb-4" />
              <p className="text-muted-foreground">
                {searchQuery
                  ? "Nessuna notifica trovata con questo filtro"
                  : "Nessuna notifica disponibile"}
              </p>
            </div>
          )}
        </CardContent>
      </Card>

      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Conferma Eliminazione</DialogTitle>
            <DialogDescription>
              Sei sicuro di voler eliminare questa notifica? Questa azione non può essere annullata.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="mt-4">
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                setIsDeleteDialogOpen(false);
                setSelectedNotificationId(null);
              }}
            >
              Annulla
            </Button>
            <Button
              type="button"
              variant="destructive"
              onClick={handleDeleteNotification}
            >
              Elimina
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Notifications;
