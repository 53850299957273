
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/lib/auth";
import LoadingLogo from "@/components/LoadingLogo";
import { AlertCircle } from "lucide-react";
import ForgotPasswordForm from "@/components/auth/ForgotPasswordForm";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  
  const {
    signIn,
    signUp,
    user
  } = useAuth();
  const navigate = useNavigate();
  const {
    toast
  } = useToast();

  useEffect(() => {
    // Se l'utente è già autenticato, reindirizza
    if (user) {
      navigate("/admin/dashboard");
    }
  }, [user, navigate]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      const {
        error
      } = await signIn(email, password);
      if (error) {
        throw error;
      }
      toast({
        title: "Accesso effettuato",
        description: "Hai effettuato l'accesso con successo"
      });
      navigate("/admin/dashboard");
    } catch (error: any) {
      console.error("Errore durante il login:", error);
      let errorMessage = "Credenziali non valide. Controlla email e password.";
      if (error.message.includes("Invalid login credentials")) {
        errorMessage = "Credenziali non valide. Controlla email e password.";
      } else if (error.message.includes("Email not confirmed")) {
        errorMessage = "Email non confermata. Controlla la tua casella di posta e conferma l'email.";
      } else {
        errorMessage = error.message || "Si è verificato un errore durante il login";
      }
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      const userData = {
        firstName,
        lastName
      };
      
      const { error } = await signUp(email, password, userData);
      
      if (error) {
        throw error;
      }
      
      toast({
        title: "Registrazione completata",
        description: "Controlla la tua email per confermare l'account"
      });
      
      // Ripristiniamo la vista di login
      setIsSignUp(false);
      
    } catch (error: any) {
      console.error("Errore durante la registrazione:", error);
      let errorMessage = "Si è verificato un errore durante la registrazione";
      
      if (error.message.includes("User already registered")) {
        errorMessage = "Utente già registrato. Prova ad effettuare il login.";
      } else {
        errorMessage = error.message || "Si è verificato un errore durante la registrazione";
      }
      
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  if (user) {
    return <LoadingLogo />;
  }

  return <div className="flex justify-center items-center min-h-screen p-4 bg-gray-50">
      <Card className="w-full max-w-md">
        <CardHeader>
          <CardTitle>
            {isSignUp ? "Registrati" : "Accedi"}
          </CardTitle>
          <CardDescription>
            {isSignUp 
              ? "Crea un nuovo account per accedere alla piattaforma" 
              : "Inserisci le tue credenziali per accedere alla piattaforma"}
          </CardDescription>
        </CardHeader>
        
        <CardContent className="pt-4">
          {error && <Alert variant="destructive" className="mb-4">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>}
          
          {showForgotPassword ? (
            <ForgotPasswordForm onBack={() => setShowForgotPassword(false)} />
          ) : isSignUp ? (
            <form onSubmit={handleSignUp}>
              <div className="grid gap-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <Label htmlFor="firstName">Nome</Label>
                    <Input
                      id="firstName"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                      placeholder="Inserisci il tuo nome"
                      disabled={loading}
                      required
                    />
                  </div>
                  <div className="space-y-2">
                    <Label htmlFor="lastName">Cognome</Label>
                    <Input
                      id="lastName"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                      placeholder="Inserisci il tuo cognome"
                      disabled={loading}
                      required
                    />
                  </div>
                </div>
                
                <div className="space-y-2">
                  <Label htmlFor="email">Email</Label>
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="nome@esempio.com"
                    disabled={loading}
                    required
                  />
                </div>
                
                <div className="space-y-2">
                  <Label htmlFor="password">Password</Label>
                  <Input
                    id="password"
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    placeholder="••••••••"
                    disabled={loading}
                    required
                  />
                </div>
              </div>
              
              <Button type="submit" className="w-full mt-4" disabled={loading}>
                {loading ? "Registrazione in corso..." : "Registrati"}
              </Button>
              
              <div className="mt-4 text-center">
                <Button
                  variant="link"
                  className="p-0 h-auto text-xs"
                  onClick={() => setIsSignUp(false)}
                  type="button"
                >
                  Hai già un account? Accedi
                </Button>
              </div>
            </form>
          ) : (
            <form onSubmit={handleLogin}>
              <div className="grid gap-4">
                <div className="space-y-2">
                  <Label htmlFor="email">Email</Label>
                  <Input id="email" type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="nome@esempio.com" disabled={loading} required />
                </div>
                
                <div className="space-y-2">
                  <div className="flex items-center justify-between">
                    <Label htmlFor="password">Password</Label>
                    <Button variant="link" className="p-0 h-auto text-xs" onClick={() => setShowForgotPassword(true)} type="button">
                      Password dimenticata?
                    </Button>
                  </div>
                  <Input id="password" type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="••••••••" disabled={loading} required />
                </div>
              </div>
              
              <Button type="submit" className="w-full mt-4" disabled={loading}>
                {loading ? "Accesso in corso..." : "Accedi"}
              </Button>
              
              <div className="mt-4 text-center">
                <Button
                  variant="link"
                  className="p-0 h-auto text-xs"
                  onClick={() => setIsSignUp(true)}
                  type="button"
                >
                  Non hai un account? Registrati
                </Button>
              </div>
            </form>
          )}
        </CardContent>
        
        <CardFooter className="flex justify-center">
          <p className="text-xs text-muted-foreground">
            Accedendo, accetti i nostri Termini di servizio e l'Informativa sulla privacy
          </p>
        </CardFooter>
      </Card>
    </div>;
};
export default Login;
