import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { type Package } from "@/lib/supabase";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
interface PackagesSectionProps {
  loading: boolean;
  packages: Package[];
}
const PackagesSection = ({
  loading,
  packages
}: PackagesSectionProps) => {
  if (!loading && packages.length === 0) return null;
  return <section id="packages" className="py-20">
      <div className="container mx-auto max-w-6xl">
        <h2 className="text-4xl font-bold mb-4 text-center">
          Pacchetti Ore
        </h2>
        <p className="text-center text-muted-foreground mb-12 max-w-3xl mx-auto">
          Scegli il pacchetto più adatto alle tue esigenze e inizia il tuo percorso fitness personalizzato.
        </p>
        
        {loading ?
      // Scheletri di caricamento per i pacchetti
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {Array(3).fill(0).map((_, index) => <div key={index} className="bg-white p-8 rounded-xl shadow-lg">
                <Skeleton className="h-7 w-2/3 mb-3" />
                <Skeleton className="h-5 w-20 mb-6" />
                <div className="flex justify-between items-center mb-6">
                  <Skeleton className="h-6 w-20" />
                </div>
                <Skeleton className="h-5 w-full mb-3" />
                <Skeleton className="h-5 w-2/3 mb-6" />
                <Skeleton className="h-10 w-full" />
              </div>)}
          </div> : <Carousel opts={{
        align: "start",
        loop: true
      }} className="w-full">
            <CarouselContent className="-ml-6">
              {packages.map(pkg => <CarouselItem key={pkg.id} className="pl-6 md:basis-1/3 lg:basis-1/3">
                  <div className="bg-white p-8 rounded-xl  h-full flex flex-col">
                    <h3 className="font-bold text-2xl mb-1">{pkg.hours} {pkg.hours === 1 ? 'ora' : 'ore'}{pkg.hours === 1 ? ' singola' : ''}</h3>
                    <p className="text-gray-600 mb-6">{pkg.hours} {pkg.hours === 1 ? 'ora' : 'ore'} di allenamento</p>
                    
                    <span className="font-bold text-3xl mb-6">
                      {pkg.price ? `€${pkg.price}` : 'Su richiesta'}
                    </span>
                    
                    <p className="text-gray-600 mb-2">Sessioni di allenamento personalizzate</p>
                    <p className="text-gray-600 mb-6">Validità: 12 mesi</p>
                    
                    <div className="mt-auto">
                      <Button className="w-full py-6 font-medium text-lg">
                        Prenota ora <ChevronRight className="ml-2 h-5 w-5" />
                      </Button>
                    </div>
                  </div>
                </CarouselItem>)}
            </CarouselContent>
            <div className="flex items-center justify-end mt-4 gap-2">
              <CarouselPrevious className="relative inline-flex h-10 w-10 left-0" />
              <CarouselNext className="relative inline-flex h-10 w-10 right-0" />
            </div>
          </Carousel>}
      </div>
    </section>;
};
export default PackagesSection;
