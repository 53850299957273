
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Session } from "./sessionTypes";
import { formatSessionDate, formatSessionTime, getSessionStatus } from "./sessionUtils";

interface PersonalSessionsProps {
  sessions: Session[];
}

export const PersonalSessions = ({ sessions }: PersonalSessionsProps) => {
  return (
    <div>
      <h3 className="text-lg font-medium mb-3">Sessioni personalizzate</h3>
      <div className="overflow-x-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Titolo</TableHead>
              <TableHead>Data</TableHead>
              <TableHead>Orario</TableHead>
              <TableHead>Tipo</TableHead>
              <TableHead>Stato</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {sessions.map(session => (
              <TableRow key={session.id}>
                <TableCell className="font-medium">{session.title}</TableCell>
                <TableCell>{formatSessionDate(session.start_time)}</TableCell>
                <TableCell>
                  {formatSessionTime(session.start_time)} - {formatSessionTime(session.end_time)}
                </TableCell>
                <TableCell>{session.type}</TableCell>
                <TableCell>
                  {getSessionStatus(session.start_time) === "completata" ? (
                    <Badge variant="outline" className="bg-green-100 text-green-800 hover:bg-green-100">
                      Completata
                    </Badge>
                  ) : (
                    <Badge variant="outline" className="bg-blue-100 text-blue-800 hover:bg-blue-100">
                      Programmata
                    </Badge>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
