import { Card, CardContent } from "@/components/ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { Client } from "@/lib/supabase";
import { MessageSquare, Mail, Phone, Dumbbell, Activity, User, Ruler, Weight, HeartPulse, Award, MessageCircle } from "lucide-react";
import { Separator } from "@/components/ui/separator";
interface ClientProfileCardProps {
  client: Client;
}
const ClientProfileCard = ({
  client
}: ClientProfileCardProps) => {
  const renderClientStatusBadge = () => {
    return <Badge className="bg-green-500 hover:bg-green-600">Attivo</Badge>;
  };
  const calculateBMI = () => {
    if (client.height && client.weight) {
      const heightInMeters = client.height / 100;
      const bmi = client.weight / (heightInMeters * heightInMeters);
      return bmi.toFixed(1);
    }
    return "N/D";
  };
  const formatPhoneForWhatsApp = (phone: string) => {
    if (!phone) return "";
    const cleanPhone = phone.replace(/[\s\-\(\)]/g, '');
    if (cleanPhone.startsWith('0')) {
      return `+39${cleanPhone}`;
    }
    if (!cleanPhone.startsWith('+')) {
      return `+39${cleanPhone}`;
    }
    return cleanPhone;
  };

  // Gestione sicura dei valori nulli
  const firstName = client.first_name || '';
  const lastName = client.last_name || '';
  const initials = `${firstName.charAt(0) || '?'}${lastName.charAt(0) || '?'}`;
  return <Card className="overflow-hidden shadow-sm">
      <div className="h-24" style={{
      background: client.color_code || '#4b5563'
    }}></div>
      <CardContent className="pt-0 relative">
        <div className="flex flex-col items-center -mt-12">
          <Avatar className="h-24 w-24 border-4 border-white shadow-md mb-4">
            <AvatarImage src={client.avatar_url || undefined} />
            <AvatarFallback className="text-xl">
              {initials}
            </AvatarFallback>
          </Avatar>
          <h2 className="text-xl font-semibold">{`${firstName} ${lastName}`}</h2>
          <div className="flex items-center gap-2 mt-1 mb-3">
            
            {renderClientStatusBadge()}
          </div>
        </div>
        
        <div className="mt-4 space-y-3">
          {/* Contatti */}
          <Card className="p-3 shadow-sm">
            <h3 className="font-medium text-sm mb-2 flex items-center gap-1.5">
              <MessageSquare size={14} className="text-primary" />
              Contatti
            </h3>
            
            <div className="space-y-1.5 text-sm">
              {client.email && <div className="flex items-center justify-between">
                  <a href={`mailto:${client.email}`} className="flex items-center gap-2 hover:text-primary transition-colors">
                    <Mail size={16} />
                    <span className="truncate max-w-[220px]" title={client.email}>
                      {client.email}
                    </span>
                  </a>
                </div>}
              
              {client.telefono && <>
                  <div className="flex items-center justify-between">
                    <a href={`tel:${client.telefono}`} className="flex items-center gap-2 hover:text-primary transition-colors">
                      <Phone size={16} />
                      <span>
                        {client.telefono}
                      </span>
                    </a>
                  </div>
                  
                  <div className="flex items-center justify-between">
                    <a href={`https://wa.me/${formatPhoneForWhatsApp(client.telefono)}`} target="_blank" rel="noopener noreferrer" className="flex items-center gap-2 text-green-600 hover:text-green-700 transition-colors">
                      <MessageCircle size={16} />
                      <span>WhatsApp</span>
                    </a>
                  </div>
                </>}
              
              {!client.email && !client.telefono && <div className="text-center text-muted-foreground text-xs py-1">
                  Nessun contatto
                </div>}
            </div>
          </Card>
          
          <div className="grid grid-cols-2 gap-3">
            <Card className="p-2.5 shadow-sm">
              <div className="flex flex-col items-center">
                <Dumbbell size={18} className="text-blue-500 mb-1" />
                <span className="text-xs text-muted-foreground">Frequenza</span>
                <span className="font-medium text-sm">{client.training_frequency || "N/D"}</span>
              </div>
            </Card>
            
            <Card className="p-2.5 shadow-sm">
              <div className="flex flex-col items-center">
                <Activity size={18} className="text-red-500 mb-1" />
                <span className="text-xs text-muted-foreground">Condizione</span>
                <span className="font-medium text-sm">{client.general_condition || "N/D"}</span>
              </div>
            </Card>
          </div>
          
          <Card className="p-3 shadow-sm">
            <h3 className="font-medium text-sm mb-2 flex items-center gap-1.5">
              <User size={14} className="text-primary" />
              Dati Fisici
            </h3>
            
            <div className="grid grid-cols-3 gap-2 text-sm">
              <div className="flex flex-col items-center">
                <div className="flex items-center gap-1 text-muted-foreground text-xs mb-1">
                  <Ruler size={12} />
                  <span>Altezza</span>
                </div>
                <span className="font-medium">{client.height || "N/D"} {client.height ? "cm" : ""}</span>
              </div>
              
              <div className="flex flex-col items-center">
                <div className="flex items-center gap-1 text-muted-foreground text-xs mb-1">
                  <Weight size={12} />
                  <span>Peso</span>
                </div>
                <span className="font-medium">{client.weight || "N/D"} {client.weight ? "kg" : ""}</span>
              </div>
              
              <div className="flex flex-col items-center">
                <div className="flex items-center gap-1 text-muted-foreground text-xs mb-1">
                  <HeartPulse size={12} />
                  <span>BMI</span>
                </div>
                <span className="font-medium">{calculateBMI()}</span>
              </div>
            </div>
          </Card>
          
          <Card className="p-3 shadow-sm">
            <h3 className="font-medium text-sm mb-2 flex items-center gap-1.5">
              <Award size={14} className="text-primary" />
              Sport
            </h3>
            <div className="flex flex-wrap gap-1">
              {client.sports && client.sports.length > 0 ? client.sports.map((sport, index) => <Badge key={index} variant="secondary" className="px-1.5 py-0.5 text-xs">
                  {sport}
                </Badge>) : <span className="text-muted-foreground text-xs">Nessuno sport</span>}
            </div>
          </Card>
        </div>
      </CardContent>
    </Card>;
};
export default ClientProfileCard;