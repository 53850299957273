
import { supabase } from "@/lib/supabase";
import { format } from "date-fns";
import { it } from "date-fns/locale";
import { Session, CourseSession, NextSession } from "./sessionTypes";

// Funzioni di formattazione per le date
export const formatSessionDate = (dateString: string) => {
  try {
    return format(new Date(dateString), "EEEE d MMMM yyyy", { locale: it });
  } catch (e) {
    return dateString;
  }
};

export const formatSessionTime = (dateString: string) => {
  try {
    return format(new Date(dateString), "HH:mm");
  } catch (e) {
    return dateString;
  }
};

export const formatNextSessionDate = (date: Date) => {
  try {
    return format(date, "EEEE d MMMM", { locale: it });
  } catch (e) {
    return "Data non valida";
  }
};

// Determina lo stato di una sessione in base alla data
export const getSessionStatus = (startTime: string) => {
  const sessionDate = new Date(startTime);
  const now = new Date();
  return sessionDate < now ? "completata" : "programmata";
};

// Carica le sessioni personali del cliente
export const fetchClientSessions = async (clientId: string): Promise<Session[]> => {
  try {
    const { data, error } = await supabase
      .from('calendar_events')
      .select('*')
      .eq('client_id', clientId)
      .order('start_time', { ascending: true });
    
    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Errore nel recupero delle sessioni:', error);
    return [];
  }
};

// Carica le sessioni dei corsi per un cliente
export const fetchClientCourseSessions = async (clientId: string): Promise<CourseSession[]> => {
  try {
    // Recupera i corsi del cliente
    const { data: clientCourses, error: coursesError } = await supabase
      .from('client_courses')
      .select(`
        course_id,
        courses:course_id (
          id,
          name,
          schedule
        )
      `)
      .eq('client_id', clientId);
    
    if (coursesError) throw coursesError;
    
    if (!clientCourses || clientCourses.length === 0) {
      return [];
    }
    
    const processedCourseSessions: CourseSession[] = [];
    
    for (const course of clientCourses) {
      if (!course.courses || !course.courses.schedule) {
        processedCourseSessions.push({
          courseName: course.courses?.name || 'Corso sconosciuto',
          courseId: course.courses?.id || course.course_id,
          nextSessions: []
        });
        continue;
      }

      // Log per debug
      console.log(`Elaborazione del corso: ${course.courses.name}`, {
        schedule: course.courses.schedule
      });

      let schedule;
      try {
        // Gestione sicura dello schedule - potrebbe essere già un array o una stringa JSON
        if (typeof course.courses.schedule === 'string') {
          schedule = JSON.parse(course.courses.schedule);
        } else {
          schedule = course.courses.schedule;
        }
      } catch (e) {
        console.error('Errore nel parsing dello schedule del corso:', e);
        schedule = [];
      }
      
      // Mappa dei giorni da stringa a numero
      const dayToNumber: {[key: string]: number} = {
        'domenica': 0,
        'lunedì': 1, 
        'martedì': 2, 
        'mercoledì': 3, 
        'giovedì': 4, 
        'venerdì': 5, 
        'sabato': 6
      };
      
      // Oggi
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Azzera ore, minuti, secondi per confronto corretto
      
      // Crea un array di tutte le prossime sessioni dei prossimi 28 giorni
      let allSessions: NextSession[] = [];
      
      // Assicuriamoci che schedule sia un array
      if (Array.isArray(schedule)) {
        for (const slot of schedule) {
          // Assicuriamoci che slot sia un oggetto valido
          if (typeof slot === 'object' && slot !== null) {
            // Accediamo alle proprietà in modo sicuro usando l'operatore di accesso condizionale
            const slotObj = slot as any; // Cast temporaneo a any per accesso sicuro
            
            // Verifichiamo la struttura dello slot
            console.log("Struttura dello slot:", JSON.stringify(slotObj));
            
            // Ottieni la proprietà giorno in modo sicuro
            let dayString = '';
            if (typeof slotObj.day === 'string') {
              dayString = slotObj.day.toLowerCase();
            } else if (typeof slotObj.giorno === 'string') {
              dayString = slotObj.giorno.toLowerCase();
            }
            
            // Ottieni gli orari in modo sicuro
            let startTime = '';
            let endTime = '';
            
            if (typeof slotObj.startTime === 'string') {
              startTime = slotObj.startTime;
            } else if (typeof slotObj.time === 'string') {
              startTime = slotObj.time;
              // Se c'è solo 'time', aggiungiamo un'ora per endTime
              const [hours, minutes] = slotObj.time.split(':').map(Number);
              if (!isNaN(hours) && !isNaN(minutes)) {
                endTime = `${(hours + 1) % 24}:${minutes.toString().padStart(2, '0')}`;
              }
            }
            
            if (endTime === '' && typeof slotObj.endTime === 'string') {
              endTime = slotObj.endTime;
            }
            
            // Se non abbiamo un orario di fine, usiamo l'orario di inizio + 1 ora
            if (endTime === '' && startTime !== '') {
              const [hours, minutes] = startTime.split(':').map(Number);
              if (!isNaN(hours) && !isNaN(minutes)) {
                endTime = `${(hours + 1) % 24}:${minutes.toString().padStart(2, '0')}`;
              }
            }
            
            // Assicuriamoci che dayString sia una chiave valida
            if (dayString in dayToNumber) {
              const dayNum = dayToNumber[dayString];
              
              // Calcola le prossime 4 occorrenze di questo giorno della settimana
              for (let i = 0; i < 4; i++) {
                const todayDay = today.getDay();
                let daysUntilNext = (dayNum - todayDay + 7) % 7;
                
                // Se oggi è il giorno e consideriamo anche oggi
                if (daysUntilNext === 0 && i === 0) {
                  // Non saltare alla prossima settimana, mantieni questo giorno
                  daysUntilNext = 0;
                }
                
                const daysToAdd = daysUntilNext + (i * 7);
                
                const nextDate = new Date(today);
                nextDate.setDate(today.getDate() + daysToAdd);
                
                // Aggiungi la sessione all'array con controlli di tipo sicuri
                allSessions.push({
                  day: dayString.charAt(0).toUpperCase() + dayString.slice(1),
                  startTime: startTime,
                  endTime: endTime,
                  date: nextDate
                });
              }
            }
          }
        }
      }
      
      // Debug delle sessioni generate
      console.log(`Sessioni generate per ${course.courses.name}:`, allSessions);
      
      // Ordina tutte le sessioni per data
      allSessions.sort((a, b) => a.date.getTime() - b.date.getTime());
      
      // Prendi solo le prime 3 sessioni
      const nextThreeSessions = allSessions.slice(0, 3);
      
      processedCourseSessions.push({
        courseName: course.courses.name,
        courseId: course.courses.id,
        nextSessions: nextThreeSessions
      });
    }
    
    return processedCourseSessions;
  } catch (error) {
    console.error('Errore nel recupero delle sessioni dei corsi:', error);
    return [];
  }
};
