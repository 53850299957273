
import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { CalendarSettings } from "@/lib/supabase";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface CalendarSettingsModalProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  settings: CalendarSettings;
  onSaveSettings: (settings: CalendarSettings) => void;
}

const weekDays = [
  { id: 1, name: "Lunedì" },
  { id: 2, name: "Martedì" },
  { id: 3, name: "Mercoledì" },
  { id: 4, name: "Giovedì" },
  { id: 5, name: "Venerdì" },
  { id: 6, name: "Sabato" },
  { id: 7, name: "Domenica" },
];

const availableHours = Array.from({ length: 24 }).map((_, i) => i);

// Schema aggiornato per assicurarsi che tutti i campi siano obbligatori
const formSchema = z.object({
  startHour: z.number().min(0).max(23),
  endHour: z.number().min(0).max(23),
  workDays: z.array(z.number().min(1).max(7)).nonempty("Seleziona almeno un giorno"),
});

const CalendarSettingsModal: React.FC<CalendarSettingsModalProps> = ({
  open,
  onOpenChange,
  settings,
  onSaveSettings,
}) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      startHour: settings.startHour,
      endHour: settings.endHour,
      workDays: settings.workDays,
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    // Validazione: ora di inizio deve essere minore dell'ora di fine
    if (values.startHour >= values.endHour) {
      form.setError("endHour", {
        type: "manual",
        message: "L'ora di fine deve essere successiva all'ora di inizio",
      });
      return;
    }

    // Qui ci assicuriamo che il valore rispetti l'interfaccia CalendarSettings
    // Manteniamo l'id e lo user_id esistenti se presenti
    const updatedSettings: CalendarSettings = {
      ...settings, // Mantieni i campi esistenti come id e user_id
      startHour: values.startHour,
      endHour: values.endHour,
      workDays: values.workDays,
    };

    onSaveSettings(updatedSettings);
    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Impostazioni Calendario</DialogTitle>
          <DialogDescription>
            Modifica le ore di lavoro e i giorni della settimana attivi.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="startHour"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Ora di inizio</FormLabel>
                    <Select
                      onValueChange={(value) => field.onChange(parseInt(value))}
                      defaultValue={field.value.toString()}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Seleziona ora" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {availableHours.map((hour) => (
                          <SelectItem key={hour} value={hour.toString()}>
                            {hour}:00
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="endHour"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Ora di fine</FormLabel>
                    <Select
                      onValueChange={(value) => field.onChange(parseInt(value))}
                      defaultValue={field.value.toString()}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Seleziona ora" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {availableHours.map((hour) => (
                          <SelectItem key={hour} value={hour.toString()}>
                            {hour}:00
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="workDays"
              render={() => (
                <FormItem>
                  <FormLabel>Giorni lavorativi</FormLabel>
                  <div className="grid grid-cols-2 gap-2 mt-2">
                    {weekDays.map((day) => (
                      <FormField
                        key={day.id}
                        control={form.control}
                        name="workDays"
                        render={({ field }) => {
                          return (
                            <FormItem
                              key={day.id}
                              className="flex flex-row items-start space-x-3 space-y-0"
                            >
                              <FormControl>
                                <Checkbox
                                  checked={field.value?.includes(day.id)}
                                  onCheckedChange={(checked) => {
                                    return checked
                                      ? field.onChange([...field.value, day.id])
                                      : field.onChange(
                                          field.value?.filter(
                                            (value) => value !== day.id
                                          )
                                        );
                                  }}
                                />
                              </FormControl>
                              <FormLabel className="font-normal">
                                {day.name}
                              </FormLabel>
                            </FormItem>
                          );
                        }}
                      />
                    ))}
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button type="submit">Salva impostazioni</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CalendarSettingsModal;
