import { supabase } from "@/lib/supabase";
import type { Client } from "@/lib/supabase";
import { ClientFormValues } from "@/hooks/use-clients";
import { useToast } from "@/hooks/use-toast";

/**
 * Elimina un client e tutti i suoi dati correlati
 */
export async function deleteClientData(clientId: string): Promise<boolean> {
  if (!clientId) return false;
  try {
    console.log("Iniziata eliminazione dati cliente:", clientId);
    
    // Ottieni i dati del cliente per l'email e auth_user_id
    const { data: clientData, error: clientError } = await supabase
      .from("clients")
      .select("email, first_name, last_name, auth_user_id")
      .eq("id", clientId)
      .single();

    if (clientError) {
      console.error("Errore nel recupero dei dati cliente:", clientError);
    }

    // Se il cliente ha un auth_user_id, elimina anche l'utente associato
    if (clientData?.auth_user_id) {
      try {
        console.log("Eliminazione account utente associato:", clientData.auth_user_id);
        
        const { data, error } = await supabase.functions.invoke("delete-user", {
          body: { userId: clientData.auth_user_id }
        });
        
        if (error) {
          console.error("Errore nella chiamata a delete-user edge function:", error);
        } else {
          console.log("Risposta da delete-user:", data);
        }
      } catch (authError) {
        console.error("Errore durante l'eliminazione dell'utente auth:", authError);
        // Non blocchiamo la cancellazione del cliente se fallisce l'eliminazione dell'account
      }
    }

    // Elimina i pacchetti associati
    const { error: packagesError } = await supabase
      .from("client_packages")
      .delete()
      .eq("client_id", clientId);

    if (packagesError) {
      console.error("Errore nell'eliminazione dei pacchetti del cliente:", packagesError);
    }

    // Elimina i corsi associati
    const { error: coursesError } = await supabase
      .from("client_courses")
      .delete()
      .eq("client_id", clientId);

    if (coursesError) {
      console.error("Errore nell'eliminazione dei corsi del cliente:", coursesError);
    }

    // Elimina gli allenamenti associati
    const { error: workoutsError } = await supabase
      .from("client_workouts")
      .delete()
      .eq("client_id", clientId);

    if (workoutsError) {
      console.error("Errore nell'eliminazione degli allenamenti del cliente:", workoutsError);
    }

    // Elimina gli eventi del calendario
    const { error: calendarError } = await supabase
      .from("calendar_events")
      .delete()
      .eq("client_id", clientId);

    if (calendarError) {
      console.error("Errore nell'eliminazione degli eventi del calendario:", calendarError);
    }

    // Elimina i pagamenti
    const { error: paymentsError } = await supabase
      .from("payments")
      .delete()
      .eq("client_id", clientId);

    if (paymentsError) {
      console.error("Errore nell'eliminazione dei pagamenti:", paymentsError);
    }

    // Elimina le notifiche
    const { error: notificationsError } = await supabase
      .from("notifications")
      .delete()
      .eq("client_id", clientId);

    if (notificationsError) {
      console.error("Errore nell'eliminazione delle notifiche:", notificationsError);
    }

    // Infine elimina il cliente
    const { error: clientDeleteError } = await supabase
      .from("clients")
      .delete()
      .eq("id", clientId);

    if (clientDeleteError) {
      console.error("Errore nell'eliminazione del cliente:", clientDeleteError);
      throw clientDeleteError;
    }

    // Invia email al cliente se abbiamo i suoi dati
    if (clientData?.email) {
      try {
        const { sendEmail, createClientDeletedEmail } = await import("@/lib/email-utils");
        
        const clientName = `${clientData.first_name} ${clientData.last_name}`;
        const emailHtml = createClientDeletedEmail(clientName);
        const emailSubject = "Conferma eliminazione profilo";
        
        await sendEmail(clientData.email, emailSubject, emailHtml);
      } catch (emailError) {
        console.error("Errore nell'invio dell'email di conferma eliminazione:", emailError);
      }
    }

    console.log("Eliminazione dati cliente completata con successo:", clientId);
    return true;
  } catch (error) {
    console.error("Errore nell'eliminazione dei dati del cliente:", error);
    return false;
  }
}

/**
 * Aggiorna i dati di un client
 */
export async function updateClient(clientId: string, data: Partial<Client>): Promise<boolean> {
  if (!clientId) return false;
  try {
    // Assicuriamoci che i campi numerici siano effettivamente numeri
    if (data.age !== undefined && data.age !== null) {
      data.age = Number(data.age);
    }
    
    if (data.height !== undefined && data.height !== null) {
      data.height = Number(data.height);
    }
    
    if (data.weight !== undefined && data.weight !== null) {
      data.weight = Number(data.weight);
    }
    
    const { error } = await supabase
      .from('clients')
      .update(data)
      .eq('id', clientId);
    
    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Errore nell\'aggiornamento del client:', error);
    return false;
  }
}

/**
 * Crea un nuovo client
 */
export async function createClient(clientData: Partial<Client>) {
  try {
    // Assicuriamoci che i campi numerici siano effettivamente numeri
    if (clientData.age !== undefined && clientData.age !== null) {
      clientData.age = Number(clientData.age);
    }
    
    if (clientData.height !== undefined && clientData.height !== null) {
      clientData.height = Number(clientData.height);
    }
    
    if (clientData.weight !== undefined && clientData.weight !== null) {
      clientData.weight = Number(clientData.weight);
    }
    
    const { data, error } = await supabase
      .from('clients')
      .insert([clientData])
      .select()
      .single();
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Errore nella creazione del client:', error);
    return null;
  }
}

/**
 * Recupera tutti i client dal database
 */
export async function fetchAllClients() {
  const { data, error } = await supabase
    .from('clients')
    .select('*')
    .order('last_name', { ascending: true });
  
  if (error) {
    console.error('Errore nel recupero dei client:', error);
    return [];
  }
  
  return data || [];
}

/**
 * Verifica l'integrità del database
 */
export async function checkDatabaseIntegrity() {
  try {
    // Verifica la presenza delle tabelle principali facendo una query
    const { error: clientsError } = await supabase
      .from('clients')
      .select('id')
      .limit(1);
    
    if (clientsError) throw clientsError;
    
    return true;
  } catch (error) {
    console.error('Errore nella verifica dell\'integrità del database:', error);
    return false;
  }
}

/**
 * Hook per operazioni sui clienti
 */
export function useClientOperations() {
  const { toast } = useToast();

  const saveClient = async (clientId: string, formValues: ClientFormValues) => {
    if (!clientId) return false;
    try {
      console.log("handleSaveClient - Valori del form ricevuti:", formValues);
      
      const updatedClient: Partial<Client> = {
        id: clientId,
        first_name: formValues.first_name,
        last_name: formValues.last_name,
        gender: formValues.gender,
        age: formValues.age === null || formValues.age === undefined ? null : Number(formValues.age),
        height: formValues.height === null || formValues.height === undefined ? null : Number(formValues.height),
        weight: formValues.weight === null || formValues.weight === undefined ? null : Number(formValues.weight),
        training_frequency: formValues.training_frequency || null,
        general_condition: formValues.general_condition || null,
        avatar_url: formValues.avatar_url || null,
        email: formValues.email || null,
        telefono: formValues.telefono || null,
        sports: []
      };
      
      if (formValues.sports !== undefined) {
        if (typeof formValues.sports === 'string' && formValues.sports.trim() !== '') {
          updatedClient.sports = formValues.sports.trim().split(',').map(sport => sport.trim()).filter(Boolean);
        } else if (Array.isArray(formValues.sports)) {
          updatedClient.sports = formValues.sports;
        }
      } else {
        updatedClient.sports = [];
      }
      
      console.log("handleSaveClient - Dati preparati per salvataggio:", updatedClient);
      
      const result = await updateClient(clientId, updatedClient);
      
      if (result) {
        toast({
          title: "Successo",
          description: "Dati del cliente aggiornati con successo"
        });
      }
      
      return result;
    } catch (error: any) {
      console.error('Errore nell\'aggiornamento del cliente:', error);
      toast({
        title: "Errore",
        description: "Impossibile aggiornare i dati del cliente: " + (error.message || 'Errore sconosciuto'),
        variant: "destructive"
      });
      return false;
    }
  };

  const deleteClient = async (clientId: string) => {
    try {
      const result = await deleteClientData(clientId);
      
      if (result) {
        toast({
          title: "Successo",
          description: "Cliente eliminato con successo"
        });
      }
      
      return result;
    } catch (error: any) {
      console.error('Errore nell\'eliminazione del cliente:', error);
      toast({
        title: "Errore",
        description: "Impossibile eliminare il cliente: " + (error.message || 'Errore sconosciuto'),
        variant: "destructive"
      });
      return false;
    }
  };

  return {
    saveClient,
    deleteClient
  };
}
