
import { useState, useEffect } from "react";

export function useLocalStorage<T>(key: string, initialValue: T) {
  // Funzione di stato che inizializza il valore
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    
    try {
      // Prova a ottenere il valore dal localStorage
      const item = window.localStorage.getItem(key);
      // Restituisci il valore parsato o il valore iniziale
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error("Errore nel recupero dal localStorage:", error);
      return initialValue;
    }
  });
  
  // Aggiorna il localStorage quando lo stato cambia
  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    
    try {
      // Salva il valore nel localStorage
      window.localStorage.setItem(key, JSON.stringify(storedValue));
    } catch (error) {
      console.error("Errore nel salvataggio su localStorage:", error);
    }
  }, [key, storedValue]);
  
  return [storedValue, setStoredValue] as const;
}
