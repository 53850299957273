
import React from "react";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage, FormDescription } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import ImageUploader from "@/components/ImageUploader";
import { SiteSettings } from "@/lib/supabase";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const siteSettingsSchema = z.object({
  site_name: z.string().min(2, {
    message: "Il nome del sito deve avere almeno 2 caratteri",
  }),
  logo_url: z.string().nullable(),
  contact_email: z.string().email({
    message: "Inserisci un indirizzo email valido",
  }),
  contact_phone: z.string().min(5, {
    message: "Il numero di telefono deve avere almeno 5 caratteri",
  }),
  primary_font: z.string().optional(),
  heading_color: z.string().optional(),
  paragraph_color: z.string().optional(),
  button_color: z.string().optional(),
});

interface SiteSettingsFormProps {
  siteSettings: SiteSettings;
  onSave: (values: any) => Promise<void>;
  isLoading: boolean;
}

const fontOptions = [
  { value: "Inter, sans-serif", label: "Inter (Default)" },
  { value: "Roboto, sans-serif", label: "Roboto" },
  { value: "Montserrat, sans-serif", label: "Montserrat" },
  { value: "Poppins, sans-serif", label: "Poppins" },
  { value: "Open Sans, sans-serif", label: "Open Sans" },
  { value: "Lato, sans-serif", label: "Lato" },
  { value: "Playfair Display, serif", label: "Playfair Display" },
  { value: "Merriweather, serif", label: "Merriweather" }
];

const SiteSettingsForm = ({ siteSettings, onSave, isLoading }: SiteSettingsFormProps) => {
  const siteSettingsForm = useForm({
    resolver: zodResolver(siteSettingsSchema),
    defaultValues: {
      site_name: siteSettings?.site_name || "TimeWaste",
      logo_url: siteSettings?.logo_url,
      contact_email: siteSettings?.contact_email || "info@fitcoach.it",
      contact_phone: siteSettings?.contact_phone || "+39123456789",
      primary_font: siteSettings?.primary_font || "Inter, sans-serif",
      heading_color: siteSettings?.heading_color || "#1A1F2C",
      paragraph_color: siteSettings?.paragraph_color || "#4B5563",
      button_color: siteSettings?.button_color || "#8B5CF6",
    },
  });
  
  const handleLogoUpload = (url: string) => {
    siteSettingsForm.setValue("logo_url", url);
  };
  
  return (
    <Form {...siteSettingsForm}>
      <form onSubmit={siteSettingsForm.handleSubmit(onSave)} className="space-y-8">
        <Tabs defaultValue="general" className="w-full">
          <TabsList className="grid grid-cols-2 w-full mb-6">
            <TabsTrigger value="general">Informazioni Generali</TabsTrigger>
            <TabsTrigger value="appearance">Aspetto Grafico</TabsTrigger>
          </TabsList>
          
          <TabsContent value="general" className="space-y-6">
            <FormField
              control={siteSettingsForm.control}
              name="site_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Nome Sito</FormLabel>
                  <FormControl>
                    <Input {...field} className="max-w-md" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <FormField
              control={siteSettingsForm.control}
              name="logo_url"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Logo Sito</FormLabel>
                  <FormControl>
                    <div className="space-y-4">
                      <ImageUploader 
                        onImageUpload={handleLogoUpload} 
                        initialImage={field.value}
                      />
                      
                      {field.value && (
                        <div className="w-full max-w-md rounded-lg overflow-hidden mb-2 border p-4 bg-white flex justify-center">
                          <img src={field.value} alt="Logo" className="max-h-24 w-auto" />
                        </div>
                      )}
                      
                      <div className="flex items-center gap-2">
                        <Input 
                          {...field} 
                          value={field.value || ""}
                          className="max-w-lg flex-1" 
                          placeholder="URL del logo"
                        />
                      </div>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <FormField
              control={siteSettingsForm.control}
              name="contact_email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email Contatto</FormLabel>
                  <FormControl>
                    <Input {...field} type="email" className="max-w-md" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <FormField
              control={siteSettingsForm.control}
              name="contact_phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Telefono Contatto</FormLabel>
                  <FormControl>
                    <Input {...field} className="max-w-md" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </TabsContent>
          
          <TabsContent value="appearance" className="space-y-6">
            <FormField
              control={siteSettingsForm.control}
              name="primary_font"
              render={({ field }) => (
                <FormItem className="max-w-md">
                  <FormLabel>Font Principale</FormLabel>
                  <FormDescription>
                    Seleziona il font da utilizzare in tutto il sito
                  </FormDescription>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Seleziona un font" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {fontOptions.map((font) => (
                        <SelectItem key={font.value} value={font.value}>
                          <span style={{ fontFamily: font.value }}>{font.label}</span>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <FormField
              control={siteSettingsForm.control}
              name="heading_color"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Colore Titoli (H1-H6)</FormLabel>
                  <FormDescription>
                    Seleziona il colore per tutti i titoli
                  </FormDescription>
                  <div className="flex items-center gap-2 max-w-md">
                    <FormControl>
                      <Input {...field} type="text" placeholder="#000000" />
                    </FormControl>
                    <Input
                      type="color"
                      value={field.value || "#1A1F2C"}
                      onChange={(e) => field.onChange(e.target.value)}
                      className="w-14 p-1 h-10"
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <FormField
              control={siteSettingsForm.control}
              name="paragraph_color"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Colore Paragrafi</FormLabel>
                  <FormDescription>
                    Seleziona il colore per tutti i testi dei paragrafi
                  </FormDescription>
                  <div className="flex items-center gap-2 max-w-md">
                    <FormControl>
                      <Input {...field} type="text" placeholder="#000000" />
                    </FormControl>
                    <Input
                      type="color"
                      value={field.value || "#4B5563"}
                      onChange={(e) => field.onChange(e.target.value)}
                      className="w-14 p-1 h-10"
                    />
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            
            <FormField
              control={siteSettingsForm.control}
              name="button_color"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Colore Pulsanti</FormLabel>
                  <FormDescription>
                    Seleziona il colore principale per i pulsanti
                  </FormDescription>
                  <div className="flex items-center gap-2 max-w-md">
                    <FormControl>
                      <Input {...field} type="text" placeholder="#000000" />
                    </FormControl>
                    <Input
                      type="color"
                      value={field.value || "#8B5CF6"}
                      onChange={(e) => field.onChange(e.target.value)}
                      className="w-14 p-1 h-10"
                    />
                    <div 
                      className="ml-2 p-2 rounded text-white flex items-center justify-center" 
                      style={{ backgroundColor: field.value || "#8B5CF6", width: "100px", height: "40px" }}
                    >
                      Anteprima
                    </div>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </TabsContent>
        </Tabs>
        
        <div className="pt-4 border-t">
          <Button type="submit" disabled={isLoading}>
            {isLoading ? "Salvataggio..." : "Salva Modifiche"}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default SiteSettingsForm;
