import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { MessageCircle, Mail, Send, Phone } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { toast } from "sonner";
import { supabase, type SiteSettings } from "@/lib/supabase";

interface ContactSectionProps {
  loading: boolean;
}

const contactFormSchema = z.object({
  name: z.string().min(2, { message: "Il nome deve contenere almeno 2 caratteri" }),
  email: z.string().email({ message: "Inserisci un indirizzo email valido" }),
  message: z.string().min(10, { message: "Il messaggio deve contenere almeno 10 caratteri" }),
});

type ContactFormValues = z.infer<typeof contactFormSchema>;

const ContactSection = ({ loading }: ContactSectionProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [siteSettings, setSiteSettings] = useState<SiteSettings | null>(null);
  
  const form = useForm<ContactFormValues>({
    resolver: zodResolver(contactFormSchema),
    defaultValues: {
      name: "",
      email: "",
      message: "",
    },
  });

  useEffect(() => {
    const fetchSiteSettings = async () => {
      if (!loading) {
        const { data } = await supabase
          .from("site_settings")
          .select("*")
          .single();
        
        if (data) {
          setSiteSettings(data);
        }
      }
    };
    
    fetchSiteSettings();
  }, [loading]);

  const handleWhatsAppClick = () => {
    if (siteSettings?.contact_phone) {
      window.open(`https://wa.me/${siteSettings.contact_phone.replace(/\D/g, '')}`, '_blank');
    }
  };

  const handleEmailClick = () => {
    if (siteSettings?.contact_email) {
      window.open(`mailto:${siteSettings.contact_email}`, '_blank');
    }
  };
  
  const onSubmit = (data: ContactFormValues) => {
    setIsSubmitting(true);
    // Simuliamo l'invio del messaggio
    setTimeout(() => {
      toast.success("Messaggio inviato con successo! Ti contatteremo presto.");
      form.reset();
      setIsSubmitting(false);
    }, 1200);
  };

  return (
    <section id="contact" className="py-16 px-4">
      <div className="container mx-auto max-w-6xl">
        <h2 className="text-3xl md:text-4xl font-bold mb-10 text-center bg-gradient-to-r from-primary to-primary/70 bg-clip-text text-transparent">
          {loading ? <Skeleton className="h-10 w-48 mx-auto" /> : "Mettiti in contatto"}
        </h2>
        
        <div className="grid md:grid-cols-5 gap-8">
          {/* Form di contatto (occupa 3/5 su desktop) */}
          <div className="md:col-span-3 bg-white/80 backdrop-blur-md p-8 rounded-2xl shadow-xl">
            {loading ? (
              <div className="space-y-4">
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-8 w-full" />
                <Skeleton className="h-24 w-full" />
                <div className="flex justify-center mt-6">
                  <Skeleton className="h-12 w-40" />
                </div>
              </div>
            ) : (
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <FormField
                      control={form.control}
                      name="name"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Nome</FormLabel>
                          <FormControl>
                            <Input placeholder="Il tuo nome" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    
                    <FormField
                      control={form.control}
                      name="email"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Email</FormLabel>
                          <FormControl>
                            <Input placeholder="La tua email" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  
                  <FormField
                    control={form.control}
                    name="message"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Messaggio</FormLabel>
                        <FormControl>
                          <Textarea 
                            placeholder="Scrivi qui il tuo messaggio" 
                            className="min-h-[120px]" 
                            {...field} 
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  
                  <div className="flex justify-center md:justify-start">
                    <Button 
                      type="submit" 
                      size="lg" 
                      disabled={isSubmitting}
                      className="py-6 px-8 rounded-xl bg-gradient-to-r from-primary to-primary/80 hover:from-primary/90 hover:to-primary/70 transition-all duration-300 shadow-md hover:shadow-xl"
                    >
                      {isSubmitting ? (
                        <>Invio in corso...</>
                      ) : (
                        <>
                          <Send className="mr-2 h-5 w-5" />
                          <span>Invia messaggio</span>
                        </>
                      )}
                    </Button>
                  </div>
                </form>
              </Form>
            )}
          </div>
          
          {/* Info contatti (occupa 2/5 su desktop) */}
          <div className="md:col-span-2 flex flex-col gap-6">
            {/* WhatsApp */}
            <div className="bg-white/80 backdrop-blur-md p-6 rounded-2xl shadow-xl">
              {loading ? (
                <Skeleton className="h-20 w-full" />
              ) : (
                <>
                  <h3 className="text-lg font-semibold mb-4 text-primary">WhatsApp</h3>
                  <p className="text-gray-700 mb-4">
                    Preferisci chattare? Scrivici rapidamente su WhatsApp!
                  </p>
                  <Button 
                    onClick={handleWhatsAppClick} 
                    className="w-full bg-[#25D366] hover:bg-[#20BD5C] text-white"
                  >
                    <MessageCircle className="mr-2 h-5 w-5" />
                    <span>Chatta con noi</span>
                  </Button>
                </>
              )}
            </div>
            
            {/* Email */}
            <div className="bg-white/80 backdrop-blur-md p-6 rounded-2xl shadow-xl">
              {loading ? (
                <Skeleton className="h-20 w-full" />
              ) : (
                <>
                  <h3 className="text-lg font-semibold mb-4 text-primary">Email</h3>
                  <p className="text-gray-700 mb-4">
                    Scrivici una mail per informazioni o richieste.
                  </p>
                  <Button 
                    variant="outline" 
                    onClick={handleEmailClick}
                    className="w-full border-primary text-primary hover:bg-primary/10"
                  >
                    <Mail className="mr-2 h-5 w-5" />
                    <span>{siteSettings?.contact_email || "info@fitcoach.it"}</span>
                  </Button>
                </>
              )}
            </div>
            
            {/* Telefono */}
            <div className="bg-white/80 backdrop-blur-md p-6 rounded-2xl shadow-xl">
              {loading ? (
                <Skeleton className="h-20 w-full" />
              ) : (
                <>
                  <h3 className="text-lg font-semibold mb-4 text-primary">Telefono</h3>
                  <p className="text-gray-700 mb-4">
                    Chiamaci per parlare direttamente con un coach.
                  </p>
                  <Button 
                    variant="outline"
                    onClick={() => {
                      if (siteSettings?.contact_phone) {
                        window.open(`tel:${siteSettings.contact_phone.replace(/\D/g, '')}`);
                      }
                    }}
                    className="w-full border-primary text-primary hover:bg-primary/10"
                  >
                    <Phone className="mr-2 h-5 w-5" />
                    <span>{siteSettings?.contact_phone || "+39123456789"}</span>
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
