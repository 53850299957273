
import { UseFormReturn } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Client, Gender } from "@/lib/supabase";
import { ClientFormValues } from "@/hooks/use-clients";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";

interface PhysicalInfoFieldsProps {
  form: UseFormReturn<ClientFormValues>;
}

const PhysicalInfoFields = ({ form }: PhysicalInfoFieldsProps) => {
  return (
    <div className="space-y-2 col-span-2 grid grid-cols-4 gap-2">
      <div>
        <Label htmlFor="age">Età</Label>
        <Input 
          id="age" 
          type="number" 
          {...form.register("age", {
            setValueAs: (value: string) => value === '' ? null : Number(value)
          })}
        />
        {form.formState.errors.age && (
          <p className="text-sm text-red-500">{form.formState.errors.age.message}</p>
        )}
      </div>
      
      <div>
        <Label htmlFor="gender">Sesso *</Label>
        <Select 
          defaultValue={form.getValues("gender")}
          onValueChange={(value) => form.setValue("gender", value as Gender)}
        >
          <SelectTrigger id="gender">
            <SelectValue placeholder="Seleziona" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="male">Uomo</SelectItem>
            <SelectItem value="female">Donna</SelectItem>
            <SelectItem value="other">Altro</SelectItem>
          </SelectContent>
        </Select>
        {form.formState.errors.gender && (
          <p className="text-sm text-red-500">{form.formState.errors.gender.message}</p>
        )}
      </div>
      
      <div>
        <Label htmlFor="height">Altezza (cm)</Label>
        <Input 
          id="height" 
          type="number" 
          step="0.1"
          {...form.register("height", {
            setValueAs: (value: string) => value === '' ? null : Number(value)
          })}
        />
        {form.formState.errors.height && (
          <p className="text-sm text-red-500">{form.formState.errors.height.message}</p>
        )}
      </div>
      
      <div>
        <Label htmlFor="weight">Peso (kg)</Label>
        <Input 
          id="weight" 
          type="number" 
          step="0.1"
          {...form.register("weight", {
            setValueAs: (value: string) => value === '' ? null : Number(value)
          })}
        />
        {form.formState.errors.weight && (
          <p className="text-sm text-red-500">{form.formState.errors.weight.message}</p>
        )}
      </div>
    </div>
  );
};

export default PhysicalInfoFields;
