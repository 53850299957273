
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { ImageIcon } from "lucide-react";

interface AvatarDisplayProps {
  imageUrl: string | null;
  onClick?: () => void;
  size?: "sm" | "md" | "lg";
  className?: string;
}

export default function AvatarDisplay({
  imageUrl,
  onClick,
  size = "md",
  className = ""
}: AvatarDisplayProps) {
  // Determina le dimensioni dell'avatar in base alla proprietà size
  const getSize = () => {
    switch (size) {
      case "sm":
        return "w-16 h-16";
      case "lg":
        return "w-32 h-32";
      case "md":
      default:
        return "w-24 h-24";
    }
  };

  // Funzione per garantire che l'URL dell'immagine sia valido
  const getImageUrl = () => {
    if (!imageUrl) return null;
    
    // Verifica che l'URL sia completo (includa http:// o https://)
    if (imageUrl.startsWith('http://') || imageUrl.startsWith('https://')) {
      return imageUrl;
    }

    // In caso di URL relativo, verifica che inizi con /
    return imageUrl.startsWith('/') ? imageUrl : `/${imageUrl}`;
  };

  return (
    <Avatar 
      className={`${getSize()} cursor-pointer ${className}`} 
      onClick={onClick}
    >
      {imageUrl ? (
        <AvatarImage src={getImageUrl() || ''} alt="Avatar" />
      ) : (
        <AvatarFallback className="bg-primary/10 text-primary text-xl">
          <ImageIcon className="h-8 w-8 text-muted-foreground" />
        </AvatarFallback>
      )}
    </Avatar>
  );
}
