
import { useEffect, useState } from "react";
import { supabase } from "@/lib/supabase";
import { 
  Dialog, 
  DialogContent, 
  DialogHeader, 
  DialogTitle, 
  DialogTrigger 
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";

interface PolicyModalProps {
  type: "privacy" | "cookie" | "terms";
  title: string;
  trigger?: React.ReactNode;
  className?: string;
}

const PolicyModal = ({ type, title, trigger, className }: PolicyModalProps) => {
  const [content, setContent] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchPolicy = async () => {
      if (!open) return;
      
      setIsLoading(true);
      try {
        const { data, error } = await supabase
          .from("policies")
          .select("content")
          .eq("type", type)
          .maybeSingle();

        if (error) throw error;
        setContent(data?.content || `Il contenuto della ${title} non è ancora disponibile.`);
      } catch (error) {
        console.error(`Errore nel caricamento della ${title}:`, error);
        setContent(`Si è verificato un errore nel caricamento della ${title}.`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPolicy();
  }, [type, title, open]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {trigger || <Button variant="link" className={className}>{title}</Button>}
      </DialogTrigger>
      <DialogContent className="sm:max-w-3xl max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        {isLoading ? (
          <div className="space-y-2">
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-3/4" />
          </div>
        ) : (
          <div className="prose prose-sm max-w-none dark:prose-invert">
            <div dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, '<br />') }} />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PolicyModal;
