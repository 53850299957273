
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Plus, Award, Trash } from "lucide-react";
import { format } from "date-fns";
import { ExtendedClientPackage } from "@/lib/supabase";
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog";
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/lib/auth";

interface ClientPackagesTabProps {
  clientPackages: ExtendedClientPackage[];
  onAddPackage: () => void;
  onDeletePackage?: (packageId: string) => Promise<void>;
}

const ClientPackagesTab = ({ clientPackages, onAddPackage, onDeletePackage }: ClientPackagesTabProps) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [packageToDelete, setPackageToDelete] = useState<string | null>(null);
  const [packageToDeleteInfo, setPackageToDeleteInfo] = useState<{ name: string, hoursRemaining: number } | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const { user } = useAuth();

  useEffect(() => {
    const checkAdminStatus = async () => {
      if (!user) {
        setIsAdmin(false);
        return;
      }
      
      try {
        const { data, error } = await supabase
          .from('profiles')
          .select('role')
          .eq('id', user.id)
          .single();
        
        if (error) {
          console.error("Errore nella verifica del ruolo:", error);
          setIsAdmin(false);
          return;
        }
        
        setIsAdmin(data?.role === 'admin');
      } catch (error) {
        console.error("Errore nella verifica del ruolo:", error);
        setIsAdmin(false);
      }
    };
    
    checkAdminStatus();
  }, [user]);

  const handleDeleteClick = (packageId: string) => {
    // Trova le informazioni sul pacchetto da eliminare
    const packageInfo = clientPackages.find(cp => cp.id === packageId);
    if (packageInfo) {
      const hoursRemaining = packageInfo.packages.hours - (packageInfo.hours_used || 0);
      setPackageToDeleteInfo({
        name: packageInfo.packages.name,
        hoursRemaining: hoursRemaining > 0 ? hoursRemaining : 0
      });
    }
    
    setPackageToDelete(packageId);
    setIsDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (packageToDelete && onDeletePackage) {
      await onDeletePackage(packageToDelete);
      setIsDeleteDialogOpen(false);
      setPackageToDelete(null);
      setPackageToDeleteInfo(null);
    }
  };

  return (
    <>
      <Card className="shadow-sm">
        <CardHeader className="flex flex-row items-center justify-between">
          <div>
            <CardTitle className="flex items-center gap-2">
              <Award size={20} className="text-primary" />
              Pacchetti Acquistati
            </CardTitle>
            <CardDescription>
              Elenco dei pacchetti di allenamento acquistati dal cliente
            </CardDescription>
          </div>
          {isAdmin && (
            <Button size="sm" className="gap-2 shadow-sm" onClick={onAddPackage}>
              <Plus size={16} />
              <span>Aggiungi pacchetto</span>
            </Button>
          )}
        </CardHeader>
        <CardContent>
          {clientPackages.length > 0 ? (
            <div className="overflow-x-auto">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Pacchetto</TableHead>
                    <TableHead>Ore Totali</TableHead>
                    <TableHead>Ore Utilizzate</TableHead>
                    <TableHead>Data Acquisto</TableHead>
                    <TableHead>Stato</TableHead>
                    {isAdmin && <TableHead className="text-right">Azioni</TableHead>}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {clientPackages.map(clientPackage => {
                    const packageData = clientPackage.packages;
                    const hoursRemaining = packageData.hours - (clientPackage.hours_used || 0);
                    return (
                      <TableRow key={clientPackage.id}>
                        <TableCell className="font-medium">{packageData.name}</TableCell>
                        <TableCell>{packageData.hours} ore</TableCell>
                        <TableCell>{clientPackage.hours_used} ore</TableCell>
                        <TableCell>{clientPackage.purchase_date ? format(new Date(clientPackage.purchase_date), 'dd/MM/yyyy') : '-'}</TableCell>
                        <TableCell>
                          {hoursRemaining > 0 ? (
                            <Badge className="bg-green-500 hover:bg-green-600">
                              Attivo ({hoursRemaining} ore rimaste)
                            </Badge>
                          ) : (
                            <Badge variant="secondary">Esaurito</Badge>
                          )}
                        </TableCell>
                        {isAdmin && (
                          <TableCell className="text-right">
                            <Button 
                              variant="ghost" 
                              size="icon" 
                              className="text-red-500 hover:text-red-700 hover:bg-red-100"
                              onClick={() => handleDeleteClick(clientPackage.id)}
                            >
                              <Trash size={18} />
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          ) : (
            <div className="text-center py-12 border border-dashed rounded-lg">
              <Award size={36} className="mx-auto text-muted-foreground mb-2 opacity-50" />
              <h3 className="text-lg font-medium mb-1">Nessun pacchetto</h3>
              <p className="text-muted-foreground">
                {isAdmin ? "Aggiungi il primo pacchetto per questo cliente" : "Non ci sono pacchetti attivi"}
              </p>
            </div>
          )}
        </CardContent>
      </Card>

      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Sei sicuro di voler eliminare questo pacchetto?</AlertDialogTitle>
            <AlertDialogDescription>
              {packageToDeleteInfo && (
                <>
                  <p className="mb-2">
                    Stai per eliminare il pacchetto <strong>{packageToDeleteInfo.name}</strong>.
                  </p>
                  {packageToDeleteInfo.hoursRemaining > 0 && (
                    <p className="mb-2 text-amber-600 font-medium">
                      Attenzione: questo pacchetto ha ancora {packageToDeleteInfo.hoursRemaining} ore residue che verranno perse.
                    </p>
                  )}
                  <p>
                    Questa azione cancellerà anche tutte le sessioni future associate a questo pacchetto.
                  </p>
                </>
              )}
              <p className="mt-2">
                Questa azione non può essere annullata. Il pacchetto verrà rimosso permanentemente dall'account del cliente.
              </p>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => {
              setPackageToDelete(null);
              setPackageToDeleteInfo(null);
            }}>
              Annulla
            </AlertDialogCancel>
            <AlertDialogAction onClick={handleConfirmDelete} className="bg-red-500 hover:bg-red-600">
              Elimina
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ClientPackagesTab;
