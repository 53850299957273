import { useState, useEffect, useCallback } from "react";
import { useToast } from "@/hooks/use-toast";
import { Client } from "@/lib/supabase";
import { supabase } from "@/integrations/supabase/client";
import { 
  fetchAllClients, 
  deleteClientData, 
  updateClient, 
  createClient,
  checkDatabaseIntegrity as checkDbIntegrity
} from "@/lib/clients";
import { 
  prepareClientUpdateData, 
  prepareNewClientData,
  filterClientsBySearchTerm
} from "@/lib/client-utils";

export { clientSchema, type ClientFormValues } from "@/schemas/client-schema";

export const useClients = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [filteredClients, setFilteredClients] = useState<Client[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const { toast } = useToast();

  const fetchClients = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await fetchAllClients();
      setClients(data);
      console.log("Clienti recuperati:", data?.length || 0);
    } catch (error: any) {
      console.error("Errore nel recupero dei clienti:", error);
      toast({
        title: "Errore",
        description: "Impossibile caricare i clienti: " + (error.message || "Errore sconosciuto"),
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  }, [toast]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  useEffect(() => {
    const filtered = filterClientsBySearchTerm(clients, searchTerm);
    setFilteredClients(filtered);
  }, [clients, searchTerm]);

  const deleteClient = async (clientId: string) => {
    try {
      const { data: clientPackages, error: packagesError } = await supabase
        .from("client_packages")
        .select("id")
        .eq("client_id", clientId);

      if (packagesError) throw packagesError;

      const { data: clientCourses, error: coursesError } = await supabase
        .from("client_courses")
        .select("id")
        .eq("client_id", clientId);

      if (coursesError) throw coursesError;

      if (clientPackages?.length || clientCourses?.length) {
        const hasPackages = !!clientPackages?.length;
        const hasCourses = !!clientCourses?.length;

        let warningMessage = "Il cliente ha ";
        if (hasPackages && hasCourses) {
          warningMessage += `${clientPackages.length} pacchetti e ${clientCourses.length} corsi associati che verranno eliminati.`;
        } else if (hasPackages) {
          warningMessage += `${clientPackages.length} pacchetti associati che verranno eliminati.`;
        } else {
          warningMessage += `${clientCourses.length} corsi associati che verranno eliminati.`;
        }

        if (!confirm(`Sei sicuro di voler eliminare questo cliente? ${warningMessage}`)) {
          console.log("Eliminazione cliente annullata dall'utente");
          return false;
        }
      } else if (!confirm("Sei sicuro di voler eliminare questo cliente?")) {
        console.log("Eliminazione cliente annullata dall'utente");
        return false;
      }

      await deleteClientData(clientId);

      toast({
        title: "Successo",
        description: "Cliente eliminato con successo",
      });

      setClients((prevClients) =>
        prevClients.filter((client) => client.id !== clientId)
      );

      return true;
    } catch (error: any) {
      console.error("Errore nell'eliminazione del cliente:", error);
      toast({
        title: "Errore",
        description: "Impossibile eliminare il cliente: " + (error.message || "Errore sconosciuto"),
        variant: "destructive",
      });
      return false;
    }
  };

  const saveClient = async (clientData: Partial<Client>) => {
    try {
      console.log("Saving client with data:", clientData);
      
      if (clientData.id) {
        console.log("Aggiornamento cliente esistente:", clientData.id);
        
        const { data: existingClient, error: fetchError } = await supabase
          .from('clients')
          .select('*')
          .eq('id', clientData.id)
          .single();
          
        if (fetchError) {
          console.error("Errore nel recupero del cliente:", fetchError);
          throw fetchError;
        }
        
        console.log("Dati cliente esistenti:", existingClient);
        
        if (clientData.age !== undefined) {
          clientData.age = clientData.age === null || clientData.age === undefined ? null : Number(clientData.age);
        }
        
        if (clientData.height !== undefined) {
          clientData.height = clientData.height === null || clientData.height === undefined ? null : Number(clientData.height);
        }
        
        if (clientData.weight !== undefined) {
          clientData.weight = clientData.weight === null || clientData.weight === undefined ? null : Number(clientData.weight);
        }
        
        const updateData = prepareClientUpdateData(clientData, existingClient);
        
        console.log("Dati da aggiornare:", updateData);
        
        if (Object.keys(updateData).length === 0) {
          toast({
            title: "Nessun cambiamento",
            description: "I dati del cliente non sono stati modificati",
          });
          return true;
        }
        
        await updateClient(clientData.id, updateData);
        
        toast({
          title: "Successo",
          description: "Cliente aggiornato con successo",
        });
        
        setClients(prevClients => 
          prevClients.map(client => 
            client.id === clientData.id 
              ? { ...client, ...updateData } 
              : client
          )
        );
        
        return true;
      } 
      else {
        console.log("Creazione nuovo cliente");
        
        if (!clientData.first_name || !clientData.last_name) {
          toast({
            title: "Errore",
            description: "Nome e cognome sono campi obbligatori",
            variant: "destructive",
          });
          return false;
        }
        
        const newClient = prepareNewClientData(clientData);
        
        const createdClient = await createClient(newClient);
        
        toast({
          title: "Successo",
          description: "Nuovo cliente creato con successo",
        });
        
        if (createdClient) {
          setClients(prevClients => [createdClient, ...prevClients]);
        }
        
        return true;
      }
    } catch (error: any) {
      console.error("Errore nel salvataggio del cliente:", error);
      toast({
        title: "Errore",
        description: "Impossibile salvare il cliente: " + (error.message || 'Errore sconosciuto'),
        variant: "destructive",
      });
      return false;
    }
  };

  const checkDatabaseIntegrity = async () => {
    try {
      return await checkDbIntegrity();
    } catch (error) {
      console.error("Errore durante la verifica dell'integrità del database:", error);
      throw error;
    }
  };

  return {
    clients,
    filteredClients,
    isLoading,
    searchTerm,
    setSearchTerm,
    fetchClients,
    saveClient,
    deleteClient,
    checkDatabaseIntegrity
  };
};
