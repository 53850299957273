import { format, addDays, setHours, setMinutes, parseISO } from 'date-fns';
import { Course, CourseScheduleItem, CourseClosureItem } from '@/lib/supabase';

// Helper per convertire il nome del giorno al numero corrispondente (0 = domenica, 1 = lunedì, ecc.)
export const getDayNumber = (dayName: string): number => {
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  return days.indexOf(dayName.toLowerCase());
};

// Helper per ottenere il nome del giorno dal numero (0 = domenica, 1 = lunedì, ecc.)
export const getDayName = (dayNumber: number): string => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[dayNumber];
};

// Helper per convertire l'orario in formato HH:MM a un oggetto Date
export const parseTime = (timeString: string): { hours: number, minutes: number } => {
  const [hoursStr, minutesStr] = timeString.split(':');
  return {
    hours: parseInt(hoursStr, 10),
    minutes: parseInt(minutesStr, 10)
  };
};

// Controlla se un corso si tiene in un determinato giorno della settimana
export const courseHasSessionOnDay = (course: Course, dayNumber: number): boolean => {
  if (!course.schedule) return false;
  
  const scheduleArray = getScheduleArray(course);
  return scheduleArray.some(item => {
    const itemDay = getDayNumber(item.day);
    return itemDay === dayNumber;
  });
};

// Ottiene gli orari di un corso per un determinato giorno
export const getCourseTimesForDay = (course: Course, dayNumber: number): { start: Date, end: Date }[] => {
  if (!course.schedule) return [];
  
  const scheduleArray = getScheduleArray(course);
  const dayName = getDayName(dayNumber).toLowerCase();
  
  const timesForDay = scheduleArray.filter(item => 
    item.day.toLowerCase() === dayName
  );
  
  return timesForDay.map(item => {
    let startTime, endTime;
    
    // Gestisci sia il vecchio formato (time) che il nuovo formato (startTime/endTime)
    if (item.startTime && item.endTime) {
      startTime = parseTime(item.startTime);
      endTime = parseTime(item.endTime);
    } else if (item.time) {
      // In caso di vecchio formato, considera il time come inizio e aggiungi 1 ora per la fine
      startTime = parseTime(item.time);
      endTime = { hours: startTime.hours + 1, minutes: startTime.minutes };
    } else {
      // Default fallback
      startTime = { hours: 9, minutes: 0 };
      endTime = { hours: 10, minutes: 0 };
    }
    
    const today = new Date();
    const start = setMinutes(setHours(today, startTime.hours), startTime.minutes);
    const end = setMinutes(setHours(today, endTime.hours), endTime.minutes);
    
    return { start, end };
  });
};

// Converte il campo schedule JSON in un array fortemente tipizzato
export const getScheduleArray = (course: Course): CourseScheduleItem[] => {
  if (!course.schedule) return [];
  
  try {
    if (typeof course.schedule === 'string') {
      return JSON.parse(course.schedule);
    }
    
    // Compatibilità: trasforma array di oggetti con day e time in CourseScheduleItem
    return (course.schedule as any[]).map(item => ({
      day: item.day,
      time: item.time,
      startTime: item.startTime || (item.time || ''),
      endTime: item.endTime || ''
    })) as CourseScheduleItem[];
  } catch (error) {
    console.error('Error parsing course schedule:', error);
    return [];
  }
};

// Converte il campo closure_periods JSON in un array fortemente tipizzato
export const getClosurePeriods = (course: Course): CourseClosureItem[] => {
  if (!course.closure_periods) return [];
  
  try {
    if (typeof course.closure_periods === 'string') {
      return JSON.parse(course.closure_periods);
    }
    
    // Compatibilità: trasforma array di oggetti con start_date e end_date in CourseClosureItem
    return (course.closure_periods as any[]).map(item => ({
      start_date: item.start_date,
      end_date: item.end_date,
      startDate: item.startDate || item.start_date || '',
      endDate: item.endDate || item.end_date || '',
      reason: item.reason || ''
    })) as CourseClosureItem[];
  } catch (error) {
    console.error('Error parsing course closure periods:', error);
    return [];
  }
};

// Parse schedule data from JSON format
export const parseScheduleData = (scheduleData: any): CourseScheduleItem[] => {
  if (!scheduleData) return [];
  
  try {
    // Se è già un array di CourseScheduleItem, restituiscilo direttamente
    if (Array.isArray(scheduleData) && scheduleData.length > 0) {
      // Verifica che gli elementi dell'array abbiano la struttura corretta
      if (typeof scheduleData[0] === 'object' && 'day' in scheduleData[0]) {
        return scheduleData as CourseScheduleItem[];
      }
    }
    
    // Se è una stringa JSON, parsala
    if (typeof scheduleData === 'string') {
      try {
        const parsed = JSON.parse(scheduleData);
        if (Array.isArray(parsed)) {
          // Verifica che gli elementi dell'array abbiano la struttura corretta
          if (parsed.length > 0 && typeof parsed[0] === 'object' && 'day' in parsed[0]) {
            return parsed as CourseScheduleItem[];
          }
        }
      } catch (e) {
        console.error("Errore nel parsing della stringa JSON:", e);
        return [];
      }
    }
    
    // Se è un oggetto JSON da Supabase con valore, estrai il valore
    if (scheduleData && typeof scheduleData === 'object' && 'value' in scheduleData) {
      if (Array.isArray(scheduleData.value)) {
        return scheduleData.value.map((item: any) => ({
          day: item.day || '',
          time: item.time || ''
        }));
      }
    }
    
    // Tenta di convertire in array se possibile
    if (scheduleData && typeof scheduleData === 'object') {
      try {
        const stringified = JSON.stringify(scheduleData);
        const parsed = JSON.parse(stringified);
        if (Array.isArray(parsed)) {
          return parsed.map((item: any) => ({
            day: item.day || '',
            time: item.time || ''
          }));
        }
      } catch (e) {
        console.error("Errore nella conversione dell'oggetto in array:", e);
      }
    }
    
    console.warn("Formato di schedule non riconosciuto:", scheduleData);
    return [];
  } catch (error) {
    console.error("Errore generale nel parsing dei dati di schedule:", error);
    return [];
  }
};

// Parse closure periods data from JSON format
export const parseClosurePeriodsData = (closureData: any): CourseClosureItem[] => {
  if (!closureData) return [];
  
  try {
    // Se è già un array di CourseClosureItem, restituiscilo direttamente
    if (Array.isArray(closureData) && closureData.length > 0) {
      // Verifica che gli elementi dell'array abbiano la struttura corretta
      if (typeof closureData[0] === 'object' && ('start_date' in closureData[0] || 'startDate' in closureData[0])) {
        return closureData as CourseClosureItem[];
      }
    }
    
    // Se è una stringa JSON, parsala
    if (typeof closureData === 'string') {
      try {
        const parsed = JSON.parse(closureData);
        if (Array.isArray(parsed)) {
          return parsed.map((item: any) => ({
            start_date: item.start_date || item.startDate || '',
            end_date: item.end_date || item.endDate || '',
            reason: item.reason || ''
          }));
        }
      } catch (e) {
        console.error("Errore nel parsing della stringa JSON:", e);
        return [];
      }
    }
    
    // Se è un oggetto JSON da Supabase con valore, estrai il valore
    if (closureData && typeof closureData === 'object' && 'value' in closureData) {
      if (Array.isArray(closureData.value)) {
        return closureData.value.map((item: any) => ({
          start_date: item.start_date || item.startDate || '',
          end_date: item.end_date || item.endDate || '',
          reason: item.reason || ''
        }));
      }
    }
    
    // Tenta di convertire in array se possibile
    if (closureData && typeof closureData === 'object') {
      try {
        const stringified = JSON.stringify(closureData);
        const parsed = JSON.parse(stringified);
        if (Array.isArray(parsed)) {
          return parsed.map((item: any) => ({
            start_date: item.start_date || item.startDate || '',
            end_date: item.end_date || item.endDate || '',
            reason: item.reason || ''
          }));
        }
      } catch (e) {
        console.error("Errore nella conversione dell'oggetto in array:", e);
      }
    }
    
    console.warn("Formato di closure periods non riconosciuto:", closureData);
    return [];
  } catch (error) {
    console.error("Errore generale nel parsing dei periodi di chiusura:", error);
    return [];
  }
};

// Format date for display
export const formatDate = (dateString: string | null | undefined): string => {
  if (!dateString) return "-";
  
  try {
    return format(new Date(dateString), 'dd/MM/yyyy');
  } catch (error) {
    console.error("Errore nel formato della data:", error);
    return "-";
  }
};

// Format schedule for display
export const formatSchedule = (schedule: CourseScheduleItem[] | any): string => {
  if (!schedule) return "-";
  
  try {
    // Se schedule è già un array
    if (Array.isArray(schedule)) {
      return schedule.map(item => 
        `${item.day} ${item.startTime || item.time} - ${item.endTime || ""}`
      ).join(", ");
    }
    
    // Se schedule è in formato JSON string
    let scheduleArray;
    if (typeof schedule === 'string') {
      scheduleArray = JSON.parse(schedule);
    } else {
      scheduleArray = schedule;
    }
    
    if (!Array.isArray(scheduleArray)) return "-";
    
    return scheduleArray.map(item => 
      `${item.day} ${item.startTime || item.time} - ${item.endTime || ""}`
    ).join(", ");
  } catch (e) {
    console.error('Error formatting schedule:', e);
    return "-";
  }
};

// Funzione per calcolare le ore rimanenti in un pacchetto
export const calculateRemainingHours = (totalHours: number, usedHours: number): number => {
  const remaining = totalHours - usedHours;
  return remaining > 0 ? remaining : 0;
};

// Funzione per verificare se un pacchetto ha ore sufficienti
export const hasEnoughHours = (totalHours: number, usedHours: number, requiredHours: number = 1): boolean => {
  return calculateRemainingHours(totalHours, usedHours) >= requiredHours;
};

// Prepare course data for storage
export const prepareCourseCourseData = (formData: {
  name: string;
  description: string;
  max_participants: string;
  price: string;
  visible_on_site: boolean;
  start_date: Date | null;
  end_date: Date | null;
  schedule: CourseScheduleItem[];
  closure_periods: CourseClosureItem[];
  add_to_calendar: boolean;
}) => {
  // Create an array of frequency based on schedule
  const frequency = [...new Set(formData.schedule.map(item => item.day))];
  
  return {
    name: formData.name,
    description: formData.description,
    frequency,
    visible_on_site: formData.visible_on_site,
    max_participants: parseInt(formData.max_participants),
    price: formData.price ? parseFloat(formData.price) : null,
    start_date: formData.start_date ? format(formData.start_date, 'yyyy-MM-dd') : null,
    end_date: formData.end_date ? format(formData.end_date, 'yyyy-MM-dd') : null,
    schedule: JSON.stringify(formData.schedule),
    closure_periods: JSON.stringify(formData.closure_periods),
    add_to_calendar: formData.add_to_calendar
  };
};
