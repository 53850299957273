
import { useEffect, useState } from "react";
import { Badge } from "@/components/ui/badge";
import { supabase } from "@/lib/supabase";
import { BookOpen } from "lucide-react";

interface ClientCoursesBadgeProps {
  clientId: string;
}

export default function ClientCoursesBadge({ clientId }: ClientCoursesBadgeProps) {
  const [courses, setCourses] = useState<Array<{id: string, name: string}>>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchClientCourses() {
      try {
        setIsLoading(true);
        // Recupera i corsi del cliente
        const { data: clientCourses, error } = await supabase
          .from('client_courses')
          .select(`
            course_id,
            courses (
              id,
              name
            )
          `)
          .eq('client_id', clientId);

        if (error) {
          console.error("Errore nel recupero dei corsi:", error);
          return;
        }

        // Estrai i dati dei corsi
        const coursesData = clientCourses
          .filter(cc => cc.courses) // filtra eventuali null
          .map(cc => ({
            id: cc.courses.id,
            name: cc.courses.name
          }));
        
        setCourses(coursesData);
      } catch (error) {
        console.error("Errore nel recupero dei corsi:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchClientCourses();
  }, [clientId]);

  if (isLoading) {
    return <div className="h-6 w-8 animate-pulse bg-muted rounded"></div>;
  }

  if (courses.length === 0) {
    return null;
  }

  // Crea gli avatar per i corsi
  return (
    <div className="flex flex-wrap gap-1">
      {courses.map(course => {
        // Crea un acronimo dal nome del corso (es. "Corso Boxe" -> "CB")
        const acronym = course.name
          .split(' ')
          .map(word => word[0])
          .join('')
          .toUpperCase()
          .substring(0, 2);
          
        return (
          <Badge key={course.id} variant="outline" className="bg-primary/10 text-primary" title={course.name}>
            {acronym}
          </Badge>
        );
      })}
    </div>
  );
}
