
import { useRef } from "react";
import { Button } from "@/components/ui/button";
import { DialogFooter } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { Separator } from "@/components/ui/separator";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { CalendarIcon, Trash2 } from "lucide-react";
import { format } from "date-fns";
import { it } from 'date-fns/locale';
import { Calendar as CalendarComponent } from "@/components/ui/calendar";
import { Checkbox } from "@/components/ui/checkbox";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/lib/utils";
import { CourseClosureItem, CourseScheduleItem } from "@/lib/supabase";
import { Textarea } from "@/components/ui/textarea";

interface CourseFormProps {
  formData: {
    name: string;
    description: string;
    max_participants: string;
    price: string;
    visible_on_site: boolean;
    start_date: Date | null;
    end_date: Date | null;
    schedule: CourseScheduleItem[];
    closure_periods: CourseClosureItem[];
    add_to_calendar: boolean;
  };
  newTimeItem: { day: string, time: string };
  newClosureItem: { start_date: Date | null, end_date: Date | null };
  weekDays: { value: string, label: string }[];
  onInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onNewTimeItemChange: (key: 'day' | 'time', value: string) => void;
  onNewClosureItemChange: (key: 'start_date' | 'end_date', value: Date | null) => void;
  onAddScheduleItem: () => void;
  onAddClosureItem: () => void;
  onRemoveScheduleItem: (index: number) => void;
  onRemoveClosureItem: (index: number) => void;
  onSubmit: (e: React.FormEvent) => Promise<void>;
  submitText: string;
  onCancel: () => void;
  setFormDataValue: <K extends keyof CourseFormProps["formData"]>(key: K, value: CourseFormProps["formData"][K]) => void;
}

const CourseForm = ({
  formData,
  newTimeItem,
  newClosureItem,
  weekDays,
  onInputChange,
  onNewTimeItemChange,
  onNewClosureItemChange,
  onAddScheduleItem,
  onAddClosureItem,
  onRemoveScheduleItem,
  onRemoveClosureItem,
  onSubmit,
  submitText,
  onCancel,
  setFormDataValue
}: CourseFormProps) => {
  const formRef = useRef<HTMLFormElement>(null);

  const formatDate = (date: Date | null) => {
    if (!date) return "";
    return format(date, "d MMMM yyyy", { locale: it });
  };

  return (
    <form ref={formRef} onSubmit={onSubmit} className="overflow-hidden">
      <ScrollArea className="pr-4 h-[70vh] md:h-[60vh]">
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-1 gap-4">
            {/* Nome Corso */}
            <div className="space-y-2">
              <Label htmlFor="name">Nome Corso</Label>
              <Input
                id="name"
                name="name"
                value={formData.name}
                onChange={onInputChange}
                required
              />
            </div>
            
            {/* Descrizione */}
            <div className="space-y-2">
              <Label htmlFor="description">Descrizione</Label>
              <Textarea
                id="description"
                name="description"
                value={formData.description}
                onChange={onInputChange}
                rows={3}
                placeholder="Inserisci una descrizione del corso..."
              />
            </div>
            
            {/* Riga con Partecipanti e Prezzo */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="space-y-2">
                <Label htmlFor="max_participants">Numero Massimo Partecipanti</Label>
                <Input
                  id="max_participants"
                  name="max_participants"
                  type="number"
                  min="1"
                  value={formData.max_participants}
                  onChange={onInputChange}
                  required
                />
              </div>
              
              <div className="space-y-2">
                <Label htmlFor="price">Prezzo (€) - Opzionale</Label>
                <Input
                  id="price"
                  name="price"
                  type="number"
                  step="0.01"
                  min="0"
                  value={formData.price}
                  onChange={onInputChange}
                />
              </div>
            </div>
          </div>
          
          <Separator className="my-2" />
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Colonna sinistra - Periodo del corso */}
            <div className="space-y-4">
              <h3 className="text-sm font-medium">Periodo del corso</h3>
              
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label>Data Inizio</Label>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        className={cn(
                          "w-full justify-start text-left font-normal",
                          !formData.start_date && "text-muted-foreground"
                        )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {formData.start_date ? formatDate(formData.start_date) : "Seleziona"}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <CalendarComponent
                        mode="single"
                        selected={formData.start_date || undefined}
                        onSelect={(date) => 
                          setFormDataValue('start_date', date)
                        }
                        initialFocus
                        className="pointer-events-auto"
                      />
                    </PopoverContent>
                  </Popover>
                </div>
                
                <div className="space-y-2">
                  <Label>Data Fine</Label>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        className={cn(
                          "w-full justify-start text-left font-normal",
                          !formData.end_date && "text-muted-foreground"
                        )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {formData.end_date ? formatDate(formData.end_date) : "Seleziona"}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <CalendarComponent
                        mode="single"
                        selected={formData.end_date || undefined}
                        onSelect={(date) => 
                          setFormDataValue('end_date', date)
                        }
                        initialFocus
                        disabled={date => formData.start_date ? date < formData.start_date : false}
                        className="pointer-events-auto"
                      />
                    </PopoverContent>
                  </Popover>
                </div>
              </div>

              <div className="space-y-2 mt-4">
                <h4 className="text-sm font-medium">Periodi di chiusura</h4>
                <div className="space-y-2">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <Label>Data Inizio</Label>
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant="outline"
                            className={cn(
                              "w-full justify-start text-left font-normal",
                              !newClosureItem.start_date && "text-muted-foreground"
                            )}
                          >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {newClosureItem.start_date ? formatDate(newClosureItem.start_date) : "Seleziona"}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <CalendarComponent
                            mode="single"
                            selected={newClosureItem.start_date || undefined}
                            onSelect={(date) => onNewClosureItemChange('start_date', date)}
                            initialFocus
                            className="pointer-events-auto"
                          />
                        </PopoverContent>
                      </Popover>
                    </div>
                    
                    <div className="space-y-2">
                      <Label>Data Fine</Label>
                      <Popover>
                        <PopoverTrigger asChild>
                          <Button
                            variant="outline"
                            className={cn(
                              "w-full justify-start text-left font-normal",
                              !newClosureItem.end_date && "text-muted-foreground"
                            )}
                          >
                            <CalendarIcon className="mr-2 h-4 w-4" />
                            {newClosureItem.end_date ? formatDate(newClosureItem.end_date) : "Seleziona"}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                          <CalendarComponent
                            mode="single"
                            selected={newClosureItem.end_date || undefined}
                            onSelect={(date) => onNewClosureItemChange('end_date', date)}
                            initialFocus
                            disabled={date => newClosureItem.start_date ? date < newClosureItem.start_date : false}
                            className="pointer-events-auto"
                          />
                        </PopoverContent>
                      </Popover>
                    </div>
                  </div>

                  <Button 
                    type="button" 
                    onClick={onAddClosureItem}
                    className="w-full mt-2"
                  >
                    Aggiungi Periodo
                  </Button>
                </div>

                {formData.closure_periods.length > 0 ? (
                  <div className="h-32 border rounded-md p-3 overflow-auto mt-2">
                    <div className="space-y-2">
                      {formData.closure_periods.map((item, index) => (
                        <div key={index} className="flex justify-between items-center py-1 px-2 bg-muted/50 rounded">
                          <span>
                            Dal {format(new Date(item.start_date), "dd/MM/yyyy")} 
                            al {format(new Date(item.end_date), "dd/MM/yyyy")}
                          </span>
                          <Button
                            type="button"
                            variant="ghost"
                            size="sm"
                            onClick={() => onRemoveClosureItem(index)}
                            className="h-7 w-7 p-0 text-destructive"
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <div className="text-center py-2 text-muted-foreground bg-muted/20 rounded-md">
                    Nessun periodo di chiusura
                  </div>
                )}
              </div>
            </div>
            
            {/* Colonna destra - Orari del corso */}
            <div className="space-y-4">
              <h3 className="text-sm font-medium">Orari del corso</h3>
              
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-5">
                  <Label htmlFor="day">Giorno</Label>
                  <select 
                    className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                    value={newTimeItem.day}
                    onChange={(e) => onNewTimeItemChange('day', e.target.value)}
                  >
                    {weekDays.map(day => (
                      <option key={day.value} value={day.value}>{day.label}</option>
                    ))}
                  </select>
                </div>
                
                <div className="col-span-4">
                  <Label htmlFor="time">Orario</Label>
                  <Input
                    type="time"
                    value={newTimeItem.time}
                    onChange={(e) => onNewTimeItemChange('time', e.target.value)}
                  />
                </div>
                
                <div className="flex items-end col-span-3">
                  <Button 
                    type="button" 
                    onClick={onAddScheduleItem}
                    className="w-full"
                  >
                    Aggiungi
                  </Button>
                </div>
              </div>
              
              {formData.schedule.length > 0 ? (
                <div className="h-[calc(100%-80px)] min-h-[150px] border rounded-md p-3 overflow-auto">
                  <div className="space-y-2">
                    {formData.schedule.map((item, index) => (
                      <div key={index} className="flex justify-between items-center py-1 px-2 bg-muted/50 rounded">
                        <span>{item.day} alle {item.time}</span>
                        <Button
                          type="button"
                          variant="ghost"
                          size="sm"
                          onClick={() => onRemoveScheduleItem(index)}
                          className="h-7 w-7 p-0 text-destructive"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="text-center py-4 text-muted-foreground bg-muted/20 rounded-md">
                  Nessun orario aggiunto
                </div>
              )}
            </div>
          </div>
          
          <Separator className="my-2" />
          
          {/* Impostazioni aggiuntive */}
          <div className="space-y-4">
            <div className="flex items-center space-x-2">
              <Checkbox
                id="add_to_calendar"
                checked={formData.add_to_calendar}
                onCheckedChange={(checked) => 
                  setFormDataValue('add_to_calendar', checked === true)
                }
              />
              <Label htmlFor="add_to_calendar">Aggiungi automaticamente al calendario</Label>
            </div>
            <p className="text-xs text-muted-foreground">
              Se selezionato, le lezioni del corso verranno aggiunte automaticamente al calendario
            </p>
          
            <div className="flex items-center space-x-2">
              <Switch
                id="visible_on_site"
                name="visible_on_site"
                checked={formData.visible_on_site}
                onCheckedChange={(checked) => 
                  setFormDataValue('visible_on_site', checked)
                }
              />
              <Label htmlFor="visible_on_site">Visibile sul sito</Label>
            </div>
          </div>
        </div>
      </ScrollArea>
      
      <DialogFooter className="mt-6">
        <Button
          type="button"
          variant="outline"
          onClick={onCancel}
        >
          Annulla
        </Button>
        <Button type="submit">{submitText}</Button>
      </DialogFooter>
    </form>
  );
};

export default CourseForm;
