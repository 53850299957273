
import { UseFormReturn } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Mail, Phone } from "lucide-react";
import { ClientFormValues } from "@/hooks/use-clients";
import { useAuth } from "@/lib/auth";

interface BasicInfoFieldsProps {
  form: UseFormReturn<ClientFormValues>;
  client?: any;
  isAdmin?: boolean;
}

const BasicInfoFields = ({ form, client, isAdmin = false }: BasicInfoFieldsProps) => {
  const { user } = useAuth();
  
  // Determina se l'email è modificabile (solo per admin e solo se non ha auth_user_id)
  const isEmailEditable = isAdmin && (!client || !client.auth_user_id);

  return (
    <>
      <div className="space-y-2">
        <Label htmlFor="first_name">Nome *</Label>
        <Input 
          id="first_name" 
          {...form.register("first_name")}
          required
        />
        {form.formState.errors.first_name && (
          <p className="text-sm text-red-500">{form.formState.errors.first_name.message}</p>
        )}
      </div>
      
      <div className="space-y-2">
        <Label htmlFor="last_name">Cognome *</Label>
        <Input 
          id="last_name" 
          {...form.register("last_name")}
          required
        />
        {form.formState.errors.last_name && (
          <p className="text-sm text-red-500">{form.formState.errors.last_name.message}</p>
        )}
      </div>
      
      <div className="space-y-2">
        <Label htmlFor="email">Email</Label>
        <div className="relative">
          <Mail className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input 
            id="email" 
            type="email" 
            className="pl-9"
            {...form.register("email")}
            disabled={!isEmailEditable}
          />
        </div>
        {!isEmailEditable && client?.auth_user_id && (
          <p className="text-xs text-muted-foreground mt-1">
            Email non modificabile per utenti con account attivo
          </p>
        )}
        {form.formState.errors.email && (
          <p className="text-sm text-red-500">{form.formState.errors.email.message}</p>
        )}
      </div>
      
      <div className="space-y-2">
        <Label htmlFor="telefono">Telefono</Label>
        <div className="relative">
          <Phone className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input 
            id="telefono" 
            type="tel" 
            className="pl-9"
            {...form.register("telefono")}
          />
        </div>
        {form.formState.errors.telefono && (
          <p className="text-sm text-red-500">{form.formState.errors.telefono.message}</p>
        )}
      </div>
    </>
  );
};

export default BasicInfoFields;
