import { Skeleton } from "@/components/ui/skeleton";
import { type Service } from "@/lib/supabase";
interface ServiceSectionProps {
  loading: boolean;
  services: Service[];
}
const ServiceSection = ({
  loading,
  services
}: ServiceSectionProps) => {
  return <section id="services" className="py-20 bg-white">
      <div className="container mx-auto max-w-6xl">
        <h2 className="text-4xl font-bold mb-4 text-center">
          I Nostri Servizi
        </h2>
        <p className="text-center text-gray-600 mb-16 max-w-3xl mx-auto">
          Soluzioni fitness complete su misura per le tue esigenze e obiettivi.
        </p>
        
        {loading ?
      // Scheletri di caricamento per i servizi
      <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
            {Array(3).fill(0).map((_, index) => <div key={index} className="flex flex-col items-center text-center">
                <Skeleton className="h-16 w-16 mb-4 rounded-full" />
                <Skeleton className="h-6 w-44 mb-4" />
                <Skeleton className="h-4 w-full mb-1" />
                <Skeleton className="h-4 w-5/6 mb-1" />
                <Skeleton className="h-4 w-4/6" />
              </div>)}
          </div> : services.length > 0 ? <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
            {services.map(service => <div key={service.id} className="flex flex-col items-start text-center">
                <div className="text-4xl mb-4">{service.icon}</div>
                <h3 className="font-bold text-2xl mb-3">{service.title}</h3>
                <p className="text-gray-600 text-left">{service.description}</p>
              </div>)}
          </div> : null}
      </div>
    </section>;
};
export default ServiceSection;
