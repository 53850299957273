
import { Button } from "@/components/ui/button";
import { Bug } from "lucide-react";

interface DebugButtonProps {
  onClick: () => void;
}

const DebugButton = ({
  onClick
}: DebugButtonProps) => {
  return (
    <div className="col-span-2 text-center">
      <Button 
        variant="outline" 
        size="sm" 
        className="flex items-center gap-1 text-xs opacity-70 hover:opacity-100 transition-opacity"
        onClick={onClick}
      >
        <Bug size={14} />
        Debug Animations
      </Button>
    </div>
  );
};

export default DebugButton;
