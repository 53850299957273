
import { useState, useRef } from "react";
import { Button } from "@/components/ui/button";
import { Upload } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/lib/auth";

// Componenti separati
import ImageCropper from "./image-uploader/ImageCropper";
import SupabaseUploader from "./image-uploader/SupabaseUploader";
import AvatarDisplay from "./image-uploader/AvatarDisplay";

interface ImageUploaderProps {
  onImageUpload: (url: string) => void;
  initialImage?: string;
  className?: string;
  userRequired?: boolean;
  onUploadStart?: () => void;
}

export default function ImageUploader({ 
  onImageUpload, 
  initialImage, 
  className,
  userRequired = true,
  onUploadStart
}: ImageUploaderProps) {
  const [image, setImage] = useState<string | null>(initialImage || null);
  const [cropDialogOpen, setCropDialogOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { toast } = useToast();
  const { user } = useAuth();

  // Apre il selettore di file
  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  // Gestisce la selezione del file
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validazione dimensione file (5MB)
    if (file.size > 5 * 1024 * 1024) {
      toast({
        title: "Errore",
        description: "L'immagine deve essere inferiore a 5MB",
        variant: "destructive",
      });
      return;
    }

    // Validazione tipo file
    if (!file.type.startsWith("image/")) {
      toast({
        title: "Errore",
        description: "Il file selezionato non è un'immagine",
        variant: "destructive",
      });
      return;
    }

    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      setCroppedImage(reader.result as string);
      setCropDialogOpen(true);
    };
    reader.readAsDataURL(file);
    
    // Reset input per consentire la selezione dello stesso file
    if (e.target) {
      e.target.value = '';
    }
  };

  // Gestisce il risultato del ritaglio
  const handleCropComplete = async (blob: Blob) => {
    if (!selectedFile) return;
    
    // Verifica se l'utente è autenticato solo se richiesto
    if (userRequired && !user) {
      toast({
        title: "Errore di autorizzazione",
        description: "Devi essere autenticato per caricare immagini",
        variant: "destructive",
      });
      return;
    }
    
    // Crea un nuovo File dall'immagine ritagliata
    const croppedFile = new File([blob], selectedFile.name || "cropped-image.webp", {
      type: "image/webp",
    });
    
    // Notifica inizio upload se è stata fornita la funzione
    if (onUploadStart) {
      onUploadStart();
    }
    
    setUploading(true);
    
    // Ottieni l'uploader Supabase (ora senza hooks)
    const uploader = SupabaseUploader({
      onUploadStart: () => {
        console.log("Upload iniziato");
      },
      onUploadComplete: (url) => {
        console.log("Upload completato con URL:", url);
        setImage(url);
        setUploading(false);
        onImageUpload(url);
        toast({
          title: "Successo",
          description: "Immagine caricata con successo",
        });
      },
      onUploadError: (error) => {
        console.error("Errore di upload:", error);
        setUploading(false);
        toast({
          title: "Errore",
          description: error.message,
          variant: "destructive",
        });
      },
      userRequired,
      userId: user?.id
    });
    
    // Carica il file
    await uploader.upload(croppedFile);
  };

  return (
    <div className={className} onClick={(e) => e.stopPropagation()}>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="image/*"
        className="hidden"
      />

      <div className="flex flex-col items-center gap-4">
        <AvatarDisplay 
          imageUrl={image} 
          onClick={handleButtonClick}
          size="lg"
        />

        <Button 
          variant="outline" 
          onClick={handleButtonClick}
          disabled={uploading}
          className="flex items-center gap-2"
          type="button" // Importante: specifica button type per evitare submit accidentali
        >
          <Upload className="h-4 w-4" />
          {uploading ? "Caricamento..." : "Carica immagine"}
        </Button>
      </div>

      <ImageCropper
        open={cropDialogOpen}
        onOpenChange={setCropDialogOpen}
        imageUrl={croppedImage}
        onCrop={handleCropComplete}
        selectedFile={selectedFile}
      />
    </div>
  );
}
