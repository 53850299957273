
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { CourseSession } from "./sessionTypes";
import { formatNextSessionDate } from "./sessionUtils";

interface CourseSessionsProps {
  courseSessions: CourseSession[];
}

export const CourseSessions = ({ courseSessions }: CourseSessionsProps) => {
  return (
    <div>
      <h3 className="text-lg font-medium mb-3">Prossime sessioni dei corsi</h3>
      <div className="overflow-x-auto">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Corso</TableHead>
              <TableHead>Data</TableHead>
              <TableHead>Giorno</TableHead>
              <TableHead>Orario</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {courseSessions.flatMap((courseSession, courseIndex) => 
              courseSession.nextSessions.length > 0 ? 
                courseSession.nextSessions.map((session, sessionIndex) => (
                  <TableRow key={`${courseSession.courseId}-${sessionIndex}`}>
                    {sessionIndex === 0 ? (
                      <TableCell rowSpan={courseSession.nextSessions.length} className="font-medium">
                        {courseSession.courseName}
                      </TableCell>
                    ) : null}
                    <TableCell>{formatNextSessionDate(session.date)}</TableCell>
                    <TableCell>{session.day}</TableCell>
                    <TableCell>
                      {session.startTime} 
                      {session.endTime && ` - ${session.endTime}`}
                    </TableCell>
                  </TableRow>
                ))
              : 
                <TableRow key={`${courseSession.courseId}-empty`}>
                  <TableCell className="font-medium">{courseSession.courseName}</TableCell>
                  <TableCell colSpan={3}>Nessuna sessione programmata</TableCell>
                </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};
