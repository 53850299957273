
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { 
  Image, 
  MessageSquareText, 
  HelpCircle, 
  FileText, 
  User,
  LayoutGrid,
  Settings
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { useState, useEffect } from "react";
import { supabase, type SiteSettings } from "@/lib/supabase";
import { toast } from "@/components/ui/use-toast";

import HeroSettingsTab from "./tabs/HeroSettingsTab";
import AboutSettingsTab from "./tabs/AboutSettingsTab";
import TestimonialsTab from "./tabs/TestimonialsTab";
import FAQTab from "./tabs/FAQTab";
import PoliciesTab from "./tabs/PoliciesTab";
import ServicesTab from "./tabs/ServicesTab";
import SiteSettingsDialog from "@/components/SiteSettingsDialog";

const HomeOptions = () => {
  const [activeTab, setActiveTab] = useState("hero");
  const [isLoading, setIsLoading] = useState(false);
  const [isSiteSettingsOpen, setIsSiteSettingsOpen] = useState(false);
  
  const [homeSettings, setHomeSettings] = useState(null);
  const [testimonials, setTestimonials] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [services, setServices] = useState([]);
  const [siteSettings, setSiteSettings] = useState<SiteSettings | null>(null);
  
  useEffect(() => {
    fetchAllData();
  }, []);
  
  const fetchAllData = async () => {
    setIsLoading(true);
    try {
      await Promise.all([
        fetchHomeSettings(),
        fetchTestimonials(),
        fetchFaqs(),
        fetchPolicies(),
        fetchServices(),
        fetchSiteSettings()
      ]);
    } catch (error) {
      console.error("Errore nel caricamento dei dati:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore nel caricamento dei dati",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  const fetchHomeSettings = async () => {
    const { data, error } = await supabase
      .from("home_settings")
      .select("*")
      .single();
    
    if (error) throw error;
    setHomeSettings(data);
  };
  
  const fetchTestimonials = async () => {
    const { data, error } = await supabase
      .from("testimonials")
      .select("*")
      .order("created_at", { ascending: false });
    
    if (error) throw error;
    setTestimonials(data || []);
  };
  
  const fetchFaqs = async () => {
    const { data, error } = await supabase
      .from("faqs")
      .select("*")
      .order("order", { ascending: true });
    
    if (error) throw error;
    setFaqs(data || []);
  };
  
  const fetchPolicies = async () => {
    const { data, error } = await supabase
      .from("policies")
      .select("*");
    
    if (error) throw error;
    setPolicies(data || []);
  };
  
  const fetchServices = async () => {
    const { data, error } = await supabase
      .from("services")
      .select("*")
      .order("display_order", { ascending: true });
    
    if (error) throw error;
    setServices(data || []);
  };
  
  const fetchSiteSettings = async () => {
    const { data, error } = await supabase
      .from("site_settings")
      .select("*")
      .single();
    
    if (error) {
      console.error("Errore nel caricamento delle impostazioni del sito:", error);
      return;
    }
    
    setSiteSettings(data);
  };

  const handleOpenSiteSettings = () => {
    setIsSiteSettingsOpen(true);
  };
  
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold">Opzioni Homepage</h1>
        </div>
        
        <Button onClick={handleOpenSiteSettings} className="flex items-center gap-2">
          <Settings size={16} />
          <span>Opzioni Sito</span>
        </Button>
      </div>
      
      <Tabs 
        defaultValue="hero" 
        value={activeTab} 
        onValueChange={setActiveTab}
        className="space-y-4"
      >
        <div className="bg-white rounded-lg p-1 shadow-sm border">
          <TabsList className="grid grid-cols-2 md:grid-cols-6 gap-2 w-full">
            <TabsTrigger value="hero" className="flex items-center gap-2">
              <Image size={16} />
              <span className="hidden sm:inline">Hero Section</span>
              <span className="sm:hidden">Hero</span>
            </TabsTrigger>
            <TabsTrigger value="about" className="flex items-center gap-2">
              <User size={16} />
              <span className="hidden sm:inline">About Section</span>
              <span className="sm:hidden">About</span>
            </TabsTrigger>
            <TabsTrigger value="services" className="flex items-center gap-2">
              <LayoutGrid size={16} />
              <span className="hidden sm:inline">Servizi</span>
              <span className="sm:hidden">Servizi</span>
            </TabsTrigger>
            <TabsTrigger value="testimonials" className="flex items-center gap-2">
              <MessageSquareText size={16} />
              <span className="hidden sm:inline">Recensioni</span>
              <span className="sm:hidden">Recensioni</span>
            </TabsTrigger>
            <TabsTrigger value="faq" className="flex items-center gap-2">
              <HelpCircle size={16} />
              <span>FAQ</span>
            </TabsTrigger>
            <TabsTrigger value="policies" className="flex items-center gap-2">
              <FileText size={16} />
              <span className="hidden sm:inline">Policy</span>
              <span className="sm:hidden">Policy</span>
            </TabsTrigger>
          </TabsList>
        </div>
        
        <TabsContent value="hero">
          <HeroSettingsTab 
            homeSettings={homeSettings}
            isLoading={isLoading}
            onSaved={fetchHomeSettings}
          />
        </TabsContent>
        
        <TabsContent value="about">
          <AboutSettingsTab 
            homeSettings={homeSettings}
            isLoading={isLoading}
            onSaved={fetchHomeSettings}
          />
        </TabsContent>
        
        <TabsContent value="services">
          <ServicesTab 
            services={services}
            isLoading={isLoading}
            onSaved={fetchServices}
          />
        </TabsContent>
        
        <TabsContent value="testimonials">
          <TestimonialsTab 
            testimonials={testimonials}
            isLoading={isLoading}
            onSaved={fetchTestimonials}
          />
        </TabsContent>
        
        <TabsContent value="faq">
          <FAQTab 
            faqs={faqs}
            isLoading={isLoading}
            onSaved={fetchFaqs}
          />
        </TabsContent>
        
        <TabsContent value="policies">
          <PoliciesTab 
            policies={policies}
            isLoading={isLoading}
            onSaved={fetchPolicies}
          />
        </TabsContent>
      </Tabs>

      <SiteSettingsDialog
        open={isSiteSettingsOpen}
        onOpenChange={setIsSiteSettingsOpen}
        siteSettings={siteSettings}
        onSaved={fetchSiteSettings}
      />
    </div>
  );
};

export default HomeOptions;
