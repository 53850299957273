
import { useEffect, useState } from "react";
import { Loader2 } from "lucide-react";
import { supabase, type SiteSettings } from "@/lib/supabase";

interface LoadingLogoProps {
  onLoadingComplete?: () => void;
  duration?: number;
}

const LoadingLogo = ({ onLoadingComplete, duration = 2000 }: LoadingLogoProps) => {
  const [isVisible, setIsVisible] = useState(true);
  const [siteSettings, setSiteSettings] = useState<SiteSettings | null>(null);

  useEffect(() => {
    const fetchSiteSettings = async () => {
      const { data } = await supabase
        .from("site_settings")
        .select("*")
        .single();
      
      if (data) {
        setSiteSettings(data);
      }
    };
    
    fetchSiteSettings();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      if (onLoadingComplete) onLoadingComplete();
    }, duration);

    return () => clearTimeout(timer);
  }, [onLoadingComplete, duration]);

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex flex-col items-center justify-center bg-background z-50">
      <div className="flex flex-col items-center gap-6 animate-fade-in">
        <div className="relative">
          <div className="absolute inset-0 bg-primary/20 rounded-full blur-xl opacity-70 animate-pulse"></div>
          {siteSettings?.logo_url ? (
            <img 
              src={siteSettings.logo_url} 
              alt="Logo" 
              className="h-16 w-auto relative animate-pulse"
            />
          ) : (
            <div className="text-4xl md:text-5xl font-bold text-primary relative animate-pulse">
              {siteSettings?.site_name || "TimeWaste"}
            </div>
          )}
        </div>
        <Loader2 className="h-12 w-12 text-primary animate-spin" />
      </div>
    </div>
  );
};

export default LoadingLogo;
