
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";
import { NewPayment } from "./types";

export function usePaymentOperations() {
  const { toast } = useToast();

  const addPayment = async (clientId: string, newPayment: NewPayment) => {
    if (!clientId) return false;
    
    try {
      if (newPayment.amount <= 0) {
        toast({
          title: "Errore",
          description: "L'importo deve essere maggiore di zero",
          variant: "destructive"
        });
        return false;
      }
      
      const paymentData = {
        client_id: clientId,
        amount: newPayment.amount,
        date: newPayment.date,
        description: newPayment.description,
        package_id: newPayment.package_id || null,
        course_id: newPayment.course_id || null
      };
      
      const { error } = await supabase
        .from('payments')
        .insert([paymentData]);
      
      if (error) throw error;
      
      toast({
        title: "Successo",
        description: "Pagamento registrato con successo"
      });
      
      return true;
    } catch (error) {
      console.error('Errore nella registrazione del pagamento:', error);
      toast({
        title: "Errore",
        description: "Impossibile registrare il pagamento",
        variant: "destructive"
      });
      return false;
    }
  };

  return {
    addPayment
  };
}
