
import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Settings } from "lucide-react";
import { SiteSettings, supabase } from "@/lib/supabase";
import { toast } from "@/components/ui/use-toast";
import SiteSettingsForm from "./SiteSettingsForm";
import { useClientInfo } from "@/hooks/use-client-info";

interface SiteSettingsDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  siteSettings: SiteSettings | null;
  onSaved: () => Promise<void>;
}

const SiteSettingsDialog = ({
  open,
  onOpenChange,
  siteSettings,
  onSaved,
}: SiteSettingsDialogProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isAdmin } = useClientInfo();
  const [showUnauthorized, setShowUnauthorized] = useState(false);
  
  useEffect(() => {
    if (open && !isAdmin) {
      setShowUnauthorized(true);
      setTimeout(() => {
        onOpenChange(false);
      }, 2000);
    } else {
      setShowUnauthorized(false);
    }
  }, [open, isAdmin, onOpenChange]);

  const handleSaveSettings = async (values: any) => {
    if (!isAdmin) {
      toast({
        title: "Accesso negato",
        description: "Solo gli amministratori possono modificare queste impostazioni",
        variant: "destructive",
      });
      onOpenChange(false);
      return;
    }
    
    setIsLoading(true);
    try {
      const { error } = await supabase
        .from("site_settings")
        .update(values)
        .eq("id", siteSettings?.id);
      
      if (error) throw error;
      
      toast({
        title: "Successo",
        description: "Impostazioni del sito salvate con successo",
      });
      
      await onSaved();
      onOpenChange(false);
    } catch (error) {
      console.error("Errore nel salvataggio:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore nel salvataggio",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-3xl max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2 text-xl">
            <Settings size={18} className="text-primary" />
            Configurazione Sito
          </DialogTitle>
        </DialogHeader>
        
        {showUnauthorized ? (
          <div className="flex flex-col items-center justify-center h-64 text-center">
            <p className="text-destructive text-lg mb-2">Accesso negato</p>
            <p className="text-muted-foreground">Solo gli amministratori possono modificare le impostazioni del sito.</p>
          </div>
        ) : isAdmin ? (
          siteSettings ? (
            <SiteSettingsForm 
              siteSettings={siteSettings} 
              onSave={handleSaveSettings} 
              isLoading={isLoading} 
            />
          ) : (
            <div className="flex justify-center items-center h-64">
              <p>Caricamento configurazione sito...</p>
            </div>
          )
        ) : (
          <div className="flex flex-col items-center justify-center h-64 text-center">
            <p className="text-destructive text-lg mb-2">Accesso negato</p>
            <p className="text-muted-foreground">Solo gli amministratori possono modificare le impostazioni del sito.</p>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SiteSettingsDialog;
