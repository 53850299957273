
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { FileText } from "lucide-react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { toast } from "@/components/ui/use-toast";
import { supabase } from "@/lib/supabase";
import { Textarea } from "@/components/ui/textarea";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const policySchema = z.object({
  type: z.enum(["privacy", "cookie", "terms"]),
  content: z.string().min(10, {
    message: "Il contenuto deve avere almeno 10 caratteri",
  }),
});

interface PoliciesTabProps {
  policies: any[];
  isLoading: boolean;
  onSaved: () => Promise<void>;
}

const PoliciesTab = ({ policies, isLoading, onSaved }: PoliciesTabProps) => {
  const [localLoading, setLocalLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("privacy");
  
  const privacyForm = useForm({
    resolver: zodResolver(policySchema),
    defaultValues: {
      type: "privacy",
      content: "",
    },
  });
  
  const cookieForm = useForm({
    resolver: zodResolver(policySchema),
    defaultValues: {
      type: "cookie",
      content: "",
    },
  });
  
  const termsForm = useForm({
    resolver: zodResolver(policySchema),
    defaultValues: {
      type: "terms",
      content: "",
    },
  });
  
  useEffect(() => {
    if (policies && policies.length > 0) {
      const privacy = policies.find(p => p.type === "privacy");
      const cookie = policies.find(p => p.type === "cookie");
      const terms = policies.find(p => p.type === "terms");
      
      if (privacy) privacyForm.reset(privacy);
      if (cookie) cookieForm.reset(cookie);
      if (terms) termsForm.reset(terms);
    }
  }, [policies]);
  
  const savePolicy = async (values, type) => {
    setLocalLoading(true);
    try {
      const existingPolicy = policies.find(p => p.type === type);
      
      if (existingPolicy) {
        const { error } = await supabase
          .from("policies")
          .update({ content: values.content, updated_at: new Date().toISOString() })
          .eq("id", existingPolicy.id);
        
        if (error) throw error;
      } else {
        const { error } = await supabase
          .from("policies")
          .insert([{ type, content: values.content }]);
        
        if (error) throw error;
      }
      
      toast({
        title: "Successo",
        description: `${type.charAt(0).toUpperCase() + type.slice(1)} policy salvata con successo`,
      });
      
      await onSaved();
    } catch (error) {
      console.error("Errore nel salvataggio:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore nel salvataggio",
        variant: "destructive",
      });
    } finally {
      setLocalLoading(false);
    }
  };
  
  const getTitleByType = (type: string) => {
    switch (type) {
      case "privacy":
        return "Privacy Policy";
      case "cookie":
        return "Cookie Policy";
      case "terms":
        return "Termini e Condizioni";
      default:
        return type.charAt(0).toUpperCase() + type.slice(1);
    }
  };
  
  return (
    <Card className="border-0 shadow-md">
      <CardHeader className="border-b bg-gray-50">
        <CardTitle className="text-xl flex items-center gap-2">
          <FileText size={18} className="text-primary" />
          Gestione Policy
        </CardTitle>
      </CardHeader>
      <CardContent className="p-6">
        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="grid grid-cols-3 w-full mb-4">
            <TabsTrigger value="privacy">Privacy Policy</TabsTrigger>
            <TabsTrigger value="cookie">Cookie Policy</TabsTrigger>
            <TabsTrigger value="terms">Termini e Condizioni</TabsTrigger>
          </TabsList>
          
          <TabsContent value="privacy">
            <Form {...privacyForm}>
              <form onSubmit={privacyForm.handleSubmit((values) => savePolicy(values, "privacy"))} className="space-y-4">
                <FormField
                  control={privacyForm.control}
                  name="content"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Contenuto Privacy Policy</FormLabel>
                      <FormControl>
                        <Textarea {...field} rows={15} className="font-mono text-sm" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                
                <Button type="submit" disabled={isLoading || localLoading}>
                  {isLoading || localLoading ? "Salvataggio..." : "Salva Privacy Policy"}
                </Button>
              </form>
            </Form>
          </TabsContent>
          
          <TabsContent value="cookie">
            <Form {...cookieForm}>
              <form onSubmit={cookieForm.handleSubmit((values) => savePolicy(values, "cookie"))} className="space-y-4">
                <FormField
                  control={cookieForm.control}
                  name="content"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Contenuto Cookie Policy</FormLabel>
                      <FormControl>
                        <Textarea {...field} rows={15} className="font-mono text-sm" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                
                <Button type="submit" disabled={isLoading || localLoading}>
                  {isLoading || localLoading ? "Salvataggio..." : "Salva Cookie Policy"}
                </Button>
              </form>
            </Form>
          </TabsContent>
          
          <TabsContent value="terms">
            <Form {...termsForm}>
              <form onSubmit={termsForm.handleSubmit((values) => savePolicy(values, "terms"))} className="space-y-4">
                <FormField
                  control={termsForm.control}
                  name="content"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Contenuto Termini e Condizioni</FormLabel>
                      <FormControl>
                        <Textarea {...field} rows={15} className="font-mono text-sm" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                
                <Button type="submit" disabled={isLoading || localLoading}>
                  {isLoading || localLoading ? "Salvataggio..." : "Salva Termini e Condizioni"}
                </Button>
              </form>
            </Form>
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default PoliciesTab;
