
import { Button } from "@/components/ui/button";
import { ChevronRight } from "lucide-react";
import { HomeSettings } from "@/lib/supabase";
import { Skeleton } from "@/components/ui/skeleton";

interface HeroProps {
  settings: HomeSettings | null;
  loading?: boolean;
}

const Hero = ({ settings, loading = false }: HeroProps) => (
  <section className="pt-32 pb-20">
    <div className="container mx-auto max-w-6xl">
      <div className="flex flex-col lg:flex-row items-center gap-12">
        <div className="flex-1 space-y-8 text-center lg:text-left animate-fade-in">
          {loading ? (
            <>
              <Skeleton className="h-6 w-64 mx-auto lg:mx-0" />
              <Skeleton className="h-20 w-full" />
              <Skeleton className="h-16 w-full max-w-xl mx-auto lg:mx-0" />
              <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
                <Skeleton className="h-12 w-48" />
              </div>
            </>
          ) : (
            <>
              <p className="inline-block px-4 py-1.5 text-sm font-medium bg-primary/10 text-primary rounded-full">
                {settings?.hero_claim || ""}
              </p>
              
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold leading-tight tracking-tight hero-text-gradient">
                {settings?.hero_title || ""}
              </h1>
              
              <p className="text-lg text-muted-foreground max-w-xl mx-auto lg:mx-0">
                {settings?.hero_description || ""}
              </p>
              
              <div className="flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
                <Button size="lg" className="group">
                  {settings?.hero_cta || ""} 
                  <ChevronRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
                </Button>
              </div>
            </>
          )}
        </div>
        
        <div className="flex-1 relative animate-fade-in">
          {loading ? (
            <Skeleton className="h-96 w-full rounded-3xl" />
          ) : settings?.hero_image_url ? (
            <>
              <div className="absolute inset-0 bg-primary/20 rounded-3xl blur-3xl opacity-30 -z-10 animate-float"></div>
              <img 
                src={settings.hero_image_url} 
                alt="Hero" 
                className="w-full h-auto rounded-3xl object-cover shadow-xl" 
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
