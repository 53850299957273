import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useToast } from "@/hooks/use-toast";
import { Package, supabase } from "@/lib/supabase";
import { Edit, Package as PackageIcon, Trash2, Plus } from "lucide-react";
import Loading from "@/components/Loading";
interface PackageFormData {
  name: string;
  hours: string;
  visible_on_site: boolean;
  price: string;
}
const emptyFormData: PackageFormData = {
  name: "",
  hours: "",
  visible_on_site: false,
  price: ""
};
const Packages = () => {
  const [packages, setPackages] = useState<Package[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [formData, setFormData] = useState<PackageFormData>(emptyFormData);
  const [selectedPackageId, setSelectedPackageId] = useState<string | null>(null);
  const {
    toast
  } = useToast();
  const fetchPackages = async () => {
    setIsLoading(true);
    try {
      const {
        data,
        error
      } = await supabase.from('packages').select('*').order('created_at', {
        ascending: false
      });
      if (error) {
        throw error;
      }
      setPackages(data || []);
    } catch (error) {
      console.error('Errore nel recupero dei pacchetti:', error);
      toast({
        title: "Errore",
        description: "Impossibile caricare i pacchetti",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchPackages();
  }, []);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      name,
      value,
      type,
      checked
    } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };
  const resetForm = () => {
    setFormData(emptyFormData);
  };
  const handleAddPackage = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const newPackage = {
        name: formData.name,
        hours: parseInt(formData.hours),
        visible_on_site: formData.visible_on_site,
        price: formData.price ? parseFloat(formData.price) : null
      };
      const {
        error
      } = await supabase.from('packages').insert([newPackage]);
      if (error) {
        throw error;
      }
      setIsAddDialogOpen(false);
      resetForm();
      await fetchPackages();
      toast({
        title: "Successo",
        description: "Pacchetto aggiunto con successo"
      });
    } catch (error) {
      console.error('Errore nell\'aggiunta del pacchetto:', error);
      toast({
        title: "Errore",
        description: "Impossibile aggiungere il pacchetto",
        variant: "destructive"
      });
    }
  };
  const openEditDialog = async (id: string) => {
    try {
      const {
        data,
        error
      } = await supabase.from('packages').select('*').eq('id', id).single();
      if (error) {
        throw error;
      }
      setFormData({
        name: data.name,
        hours: data.hours.toString(),
        visible_on_site: data.visible_on_site,
        price: data.price ? data.price.toString() : ""
      });
      setSelectedPackageId(id);
      setIsEditDialogOpen(true);
    } catch (error) {
      console.error('Errore nel recupero del pacchetto:', error);
      toast({
        title: "Errore",
        description: "Impossibile caricare i dati del pacchetto",
        variant: "destructive"
      });
    }
  };
  const handleEditPackage = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedPackageId) return;
    try {
      const updatedPackage = {
        name: formData.name,
        hours: parseInt(formData.hours),
        visible_on_site: formData.visible_on_site,
        price: formData.price ? parseFloat(formData.price) : null
      };
      const {
        error
      } = await supabase.from('packages').update(updatedPackage).eq('id', selectedPackageId);
      if (error) {
        throw error;
      }
      setIsEditDialogOpen(false);
      resetForm();
      setSelectedPackageId(null);
      await fetchPackages();
      toast({
        title: "Successo",
        description: "Pacchetto aggiornato con successo"
      });
    } catch (error) {
      console.error('Errore nell\'aggiornamento del pacchetto:', error);
      toast({
        title: "Errore",
        description: "Impossibile aggiornare il pacchetto",
        variant: "destructive"
      });
    }
  };
  const openDeleteDialog = (id: string) => {
    setSelectedPackageId(id);
    setIsDeleteDialogOpen(true);
  };
  const handleDeletePackage = async () => {
    if (!selectedPackageId) return;
    try {
      const {
        error
      } = await supabase.from('packages').delete().eq('id', selectedPackageId);
      if (error) {
        throw error;
      }
      setIsDeleteDialogOpen(false);
      setSelectedPackageId(null);
      await fetchPackages();
      toast({
        title: "Successo",
        description: "Pacchetto eliminato con successo"
      });
    } catch (error) {
      console.error('Errore nell\'eliminazione del pacchetto:', error);
      toast({
        title: "Errore",
        description: "Impossibile eliminare il pacchetto",
        variant: "destructive"
      });
    }
  };
  if (isLoading) {
    return <Loading />;
  }
  return <div>
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4 mb-6">
        <div>
          <h1 className="text-2xl font-bold">Pacchetti</h1>
          <p className="text-muted-foreground mt-1">Crea, modifica e gestisci i pacchetti che offri ai clienti</p>
        </div>
        <Button onClick={() => {
        resetForm();
        setIsAddDialogOpen(true);
      }} className="shadow-sm">
          <Plus className="mr-2 h-4 w-4" />
          Crea Pacchetto
        </Button>
      </div>
      
      <Card>
        <CardContent className="p-2">
          
          {packages.length > 0 ? <div className="overflow-x-auto">
              <table className="w-full text-sm">
                <thead>
                  <tr className="border-b">
                    <th className="text-left font-medium py-3 px-4">Nome</th>
                    <th className="text-left font-medium py-3 px-4">Ore</th>
                    <th className="text-left font-medium py-3 px-4">Prezzo</th>
                    <th className="text-left font-medium py-3 px-4">Visibile sul sito</th>
                    <th className="text-right font-medium py-3 px-4">Azioni</th>
                  </tr>
                </thead>
                <tbody>
                  {packages.map(pkg => <tr key={pkg.id} className="border-b hover:bg-muted/50 transition-colors">
                      <td className="py-3 px-4 font-medium">{pkg.name}</td>
                      <td className="py-3 px-4">{pkg.hours} ore</td>
                      <td className="py-3 px-4">{pkg.price ? `€${pkg.price}` : '-'}</td>
                      <td className="py-3 px-4">{pkg.visible_on_site ? 'Sì' : 'No'}</td>
                      <td className="py-3 px-4 text-right space-x-2">
                        <Button variant="ghost" size="icon" onClick={() => openEditDialog(pkg.id)}>
                          <Edit className="h-4 w-4" />
                        </Button>
                        <Button variant="ghost" size="icon" onClick={() => openDeleteDialog(pkg.id)}>
                          <Trash2 className="h-4 w-4 text-destructive" />
                        </Button>
                      </td>
                    </tr>)}
                </tbody>
              </table>
            </div> : <div className="text-center py-8">
              <PackageIcon className="mx-auto h-12 w-12 text-muted-foreground/50" />
              <p className="text-muted-foreground mt-4">Nessun pacchetto disponibile</p>
              <Button variant="outline" className="mt-4" onClick={() => {
            resetForm();
            setIsAddDialogOpen(true);
          }}>
                Crea il tuo primo pacchetto
              </Button>
            </div>}
        </CardContent>
      </Card>

      {/* Dialog per Aggiungere Pacchetto */}
      <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle>Crea Nuovo Pacchetto</DialogTitle>
            <DialogDescription>
              Inserisci i dettagli del nuovo pacchetto.
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleAddPackage}>
            <div className="grid gap-4 py-4">
              <div className="space-y-2">
                <Label htmlFor="name">Nome Pacchetto</Label>
                <Input id="name" name="name" value={formData.name} onChange={handleInputChange} required />
              </div>
              <div className="space-y-2">
                <Label htmlFor="hours">Numero Ore</Label>
                <Input id="hours" name="hours" type="number" min="1" value={formData.hours} onChange={handleInputChange} required />
              </div>
              <div className="space-y-2">
                <Label htmlFor="price">Prezzo (€) - Opzionale</Label>
                <Input id="price" name="price" type="number" step="0.01" min="0" value={formData.price} onChange={handleInputChange} />
              </div>
              <div className="flex items-center space-x-2">
                <Switch id="visible_on_site" name="visible_on_site" checked={formData.visible_on_site} onCheckedChange={checked => setFormData(prev => ({
                ...prev,
                visible_on_site: checked
              }))} />
                <Label htmlFor="visible_on_site">Visibile sul sito</Label>
              </div>
            </div>
            <DialogFooter>
              <Button type="button" variant="outline" onClick={() => {
              resetForm();
              setIsAddDialogOpen(false);
            }}>
                Annulla
              </Button>
              <Button type="submit">Crea Pacchetto</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      {/* Dialog per Modificare Pacchetto */}
      <Dialog open={isEditDialogOpen} onOpenChange={setIsEditDialogOpen}>
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle>Modifica Pacchetto</DialogTitle>
            <DialogDescription>
              Modifica i dettagli del pacchetto.
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleEditPackage}>
            <div className="grid gap-4 py-4">
              <div className="space-y-2">
                <Label htmlFor="edit_name">Nome Pacchetto</Label>
                <Input id="edit_name" name="name" value={formData.name} onChange={handleInputChange} required />
              </div>
              <div className="space-y-2">
                <Label htmlFor="edit_hours">Numero Ore</Label>
                <Input id="edit_hours" name="hours" type="number" min="1" value={formData.hours} onChange={handleInputChange} required />
              </div>
              <div className="space-y-2">
                <Label htmlFor="edit_price">Prezzo (€) - Opzionale</Label>
                <Input id="edit_price" name="price" type="number" step="0.01" min="0" value={formData.price} onChange={handleInputChange} />
              </div>
              <div className="flex items-center space-x-2">
                <Switch id="edit_visible_on_site" name="visible_on_site" checked={formData.visible_on_site} onCheckedChange={checked => setFormData(prev => ({
                ...prev,
                visible_on_site: checked
              }))} />
                <Label htmlFor="edit_visible_on_site">Visibile sul sito</Label>
              </div>
            </div>
            <DialogFooter>
              <Button type="button" variant="outline" onClick={() => {
              resetForm();
              setIsEditDialogOpen(false);
              setSelectedPackageId(null);
            }}>
                Annulla
              </Button>
              <Button type="submit">Salva Modifiche</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      {/* Dialog per Eliminare Pacchetto */}
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Conferma Eliminazione</DialogTitle>
            <DialogDescription>
              Sei sicuro di voler eliminare questo pacchetto? Questa azione non può essere annullata.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="mt-4">
            <Button type="button" variant="outline" onClick={() => {
            setIsDeleteDialogOpen(false);
            setSelectedPackageId(null);
          }}>
              Annulla
            </Button>
            <Button type="button" variant="destructive" onClick={handleDeletePackage}>
              Elimina
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>;
};
export default Packages;