
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Course } from "@/lib/supabase";

interface CourseDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  availableCourses: Course[];
  selectedCourseId: string;
  onSelectCourse: (id: string) => void;
  onEnrollCourse: () => void;
}

const CourseDialog = ({
  open,
  onOpenChange,
  availableCourses,
  selectedCourseId,
  onSelectCourse,
  onEnrollCourse
}: CourseDialogProps) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Iscrivi a Corso</DialogTitle>
          <DialogDescription>
            Seleziona un corso a cui iscrivere il cliente
          </DialogDescription>
        </DialogHeader>
        
        <div className="py-4">
          <Label htmlFor="course-select">Corso</Label>
          <Select value={selectedCourseId} onValueChange={onSelectCourse}>
            <SelectTrigger id="course-select">
              <SelectValue placeholder="Seleziona un corso" />
            </SelectTrigger>
            <SelectContent>
              {availableCourses.map(course => (
                <SelectItem key={course.id} value={course.id}>
                  {course.name} {course.price ? `(€${course.price})` : ''}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        
        <DialogFooter>
          <Button variant="outline" onClick={() => onOpenChange(false)}>Annulla</Button>
          <Button onClick={onEnrollCourse} disabled={!selectedCourseId}>Iscrivi</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CourseDialog;
