
import { useEffect, useState } from "react";
import { Badge } from "@/components/ui/badge";
import { Progress } from "@/components/ui/progress";
import { supabase } from "@/lib/supabase";
import { Award } from "lucide-react";

interface ClientPackageHoursBadgeProps {
  clientId: string;
}

export default function ClientPackageHoursBadge({ clientId }: ClientPackageHoursBadgeProps) {
  const [hoursRemaining, setHoursRemaining] = useState<number | null>(null);
  const [totalHours, setTotalHours] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [progressPercentage, setProgressPercentage] = useState(0);

  useEffect(() => {
    async function fetchPackageHours() {
      try {
        setIsLoading(true);
        // Recupera i pacchetti del cliente con le ore residue
        const { data: clientPackages, error } = await supabase
          .from('client_packages')
          .select(`
            id,
            hours_used,
            packages (
              id,
              name,
              hours
            )
          `)
          .eq('client_id', clientId);

        if (error) {
          console.error("Errore nel recupero dei pacchetti:", error);
          return;
        }

        // Filtra i pacchetti attivi (con ore rimanenti)
        const activePackages = clientPackages.filter(pkg => {
          const packageData = pkg.packages;
          const remaining = packageData.hours - (pkg.hours_used || 0);
          return remaining > 0;
        });

        if (activePackages.length > 0) {
          // Calcola il totale delle ore e delle ore rimanenti
          let totalPackageHours = 0;
          let totalRemainingHours = 0;
          
          activePackages.forEach(pkg => {
            const packageData = pkg.packages;
            const remaining = packageData.hours - (pkg.hours_used || 0);
            
            totalPackageHours += packageData.hours;
            totalRemainingHours += remaining;
          });
          
          setHoursRemaining(totalRemainingHours);
          setTotalHours(totalPackageHours);
          setProgressPercentage(Math.round((totalRemainingHours / totalPackageHours) * 100));
        } else {
          setHoursRemaining(null);
          setTotalHours(null);
          setProgressPercentage(0);
        }
      } catch (error) {
        console.error("Errore nel recupero delle ore:", error);
      } finally {
        setIsLoading(false);
      }
    }

    fetchPackageHours();
  }, [clientId]);

  if (isLoading) {
    return <div className="h-6 w-24 animate-pulse bg-muted rounded"></div>;
  }

  if (hoursRemaining === null || totalHours === null) {
    return null;
  }

  return (
    <div className="flex items-center gap-2">
      <Award className="h-4 w-4 text-primary" />
      <div className="w-24">
        <Progress value={progressPercentage} className="h-2" />
        <div className="text-xs mt-1">{hoursRemaining} ore</div>
      </div>
    </div>
  );
}
