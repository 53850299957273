
import ImageUploader from "@/components/ImageUploader";

interface AvatarUploadSectionProps {
  initialImage?: string;
  onImageUpload: (url: string) => void;
  onUploadStart?: () => void;
}

const AvatarUploadSection = ({ 
  initialImage, 
  onImageUpload,
  onUploadStart
}: AvatarUploadSectionProps) => {
  return (
    <div className="space-y-2 col-span-2 flex justify-center" onClick={(e) => e.stopPropagation()}>
      <ImageUploader 
        onImageUpload={onImageUpload}
        initialImage={initialImage}
        onUploadStart={onUploadStart}
      />
    </div>
  );
};

export default AvatarUploadSection;
