import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Menu, X, LogOut, LayoutDashboard } from "lucide-react";
import { cn } from "@/lib/utils";
import { useAuth } from "@/lib/auth";
import { supabase, type SiteSettings } from "@/lib/supabase";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [siteSettings, setSiteSettings] = useState<SiteSettings | null>(null);
  const location = useLocation();
  const { user, signOut } = useAuth();
  
  const navLinks = [{
    name: "Home",
    href: "/"
  }, {
    name: "Servizi",
    href: "/#services"
  }, {
    name: "Chi Sono",
    href: "/#about"
  }, {
    name: "Testimonianze",
    href: "/#testimonials"
  }, {
    name: "Pacchetti",
    href: "/#packages"
  }, {
    name: "Corsi",
    href: "/#courses"
  }, {
    name: "Contatti",
    href: "/#contact"
  }];

  useEffect(() => {
    const fetchSiteSettings = async () => {
      const { data } = await supabase
        .from("site_settings")
        .select("*")
        .single();
      
      if (data) {
        setSiteSettings(data);
      }
    };
    
    fetchSiteSettings();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  const handleNavLinkClick = (e: React.MouseEvent<HTMLAnchorElement>, href: string) => {
    if (href.startsWith('/#')) {
      e.preventDefault();
      const targetId = href.split('#')[1];
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        setIsOpen(false);
        const navbarHeight = 80;
        const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - navbarHeight;
        window.scrollTo({
          top: targetPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  return <header className={cn("fixed top-0 w-full z-50 transition-all duration-300 py-4", isScrolled ? "bg-white/80 dark:bg-gray-900/80 backdrop-blur-md shadow-sm" : "bg-transparent")}>
      <div className="container mx-auto px-6 flex items-center justify-between">
        <Link to="/" className="text-2xl font-bold text-primary transition-transform hover:scale-105 flex items-center gap-2">
          {siteSettings?.logo_url && (
            <img 
              src={siteSettings.logo_url} 
              alt="Logo" 
              className="h-8 w-auto"
            />
          )}
          {siteSettings?.site_name || "TimeWaste"}
        </Link>

        <nav className="hidden md:flex items-center justify-center absolute left-1/2 transform -translate-x-1/2">
          <div className="flex items-center space-x-8">
            {navLinks.map(link => <a key={link.name} href={link.href} onClick={e => handleNavLinkClick(e, link.href)} className={cn("text-sm font-medium transition-colors relative", "after:absolute after:bottom-0 after:left-0 after:w-full after:h-0.5 after:bg-primary", "after:scale-x-0 after:origin-left after:transition-transform after:duration-300", "hover:text-primary hover:after:scale-x-100")}>
                {link.name}
              </a>)}
          </div>
        </nav>

        <div className="hidden md:flex items-center space-x-3">
          {user ? <>
              <Link to="/admin/dashboard">
                <Button variant="ghost" className="bg-primary/10 hover:bg-primary/20 text-primary">
                  <LayoutDashboard className="mr-2 h-4 w-4" />
                  Dashboard
                </Button>
              </Link>
              <Button variant="outline" onClick={signOut} className="text-red-500 hover:text-red-600 hover:bg-red-50">
                <LogOut className="mr-2 h-4 w-4" />
                Logout
              </Button>
            </> : <Link to="/login">
              <Button variant="ghost" className="bg-primary/10 hover:bg-primary/20 text-primary">Login</Button>
            </Link>}
        </div>

        <button className="md:hidden p-2 focus:outline-none" onClick={() => setIsOpen(!isOpen)} aria-label="Toggle menu">
          {isOpen ? <X className="h-6 w-6 text-primary" /> : <Menu className="h-6 w-6 text-primary" />}
        </button>
      </div>

      {isOpen && <div className="md:hidden absolute top-16 inset-x-0 p-4 bg-white/95 dark:bg-gray-900/95 backdrop-blur-md shadow-lg animate-fade-in">
          <nav className="flex flex-col space-y-4 py-4">
            {navLinks.map(link => <a key={link.name} href={link.href} onClick={e => handleNavLinkClick(e, link.href)} className="text-lg font-medium py-2 px-4 hover:bg-primary/10 rounded-md transition-colors">
                {link.name}
              </a>)}
            {user ? <>
                <Link to="/admin/dashboard" className="mt-2">
                  <Button className="w-full flex items-center justify-center" variant="default">
                    <LayoutDashboard className="mr-2 h-4 w-4" />
                    Dashboard
                  </Button>
                </Link>
                <Button onClick={signOut} className="w-full flex items-center justify-center bg-red-500 hover:bg-red-600 text-white">
                  <LogOut className="mr-2 h-4 w-4" />
                  Logout
                </Button>
              </> : <Link to="/login" className="mt-2">
                <Button className="w-full">Admin Login</Button>
              </Link>}
          </nav>
        </div>}
    </header>;
};

export default Navbar;
