import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { ExerciseInput, WorkoutTemplate, WorkoutTemplateResponse } from "@/types/workout";
import { Json } from "@/integrations/supabase/types";

export const useWorkouts = () => {
  const { toast } = useToast();
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);
  
  const defaultWorkout: WorkoutTemplate = {
    name: "",
    description: "",
    difficulty: "principiante",
    target: "",
    duration: "",
    exercises: [
      {
        id: crypto.randomUUID(),
        name: "",
        sets: 3,
        reps: "10",
        rest: "60",
        notes: ""
      }
    ]
  };
  
  const [workoutTemplate, setWorkoutTemplate] = useState<WorkoutTemplate>(defaultWorkout);

  const { data: templates, isLoading, refetch } = useQuery({
    queryKey: ["workout-templates"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('workout_templates')
        .select('*')
        .order('created_at', { ascending: false }) as { 
          data: WorkoutTemplateResponse[] | null; 
          error: Error | null 
        };
      
      if (error) throw error;
      return data;
    }
  });

  const resetForm = () => {
    setWorkoutTemplate(defaultWorkout);
  };

  const saveWorkout = async () => {
    try {
      if (!workoutTemplate.name || !workoutTemplate.target || !workoutTemplate.duration) {
        toast({
          title: "Errore",
          description: "Compila tutti i campi obbligatori",
          variant: "destructive"
        });
        return;
      }

      if (workoutTemplate.exercises.some(ex => !ex.name)) {
        toast({
          title: "Errore",
          description: "Tutti gli esercizi devono avere un nome",
          variant: "destructive"
        });
        return;
      }

      const { data, error } = await supabase
        .from('workout_templates')
        .insert({
          name: workoutTemplate.name,
          description: workoutTemplate.description,
          difficulty: workoutTemplate.difficulty,
          target: workoutTemplate.target,
          duration: workoutTemplate.duration,
          exercises: workoutTemplate.exercises as unknown as Json
        })
        .select() as {
          data: WorkoutTemplateResponse[] | null;
          error: Error | null;
        };

      if (error) throw error;

      toast({
        title: "Successo",
        description: "Allenamento salvato con successo",
      });

      setIsAddDialogOpen(false);
      resetForm();
      await refetch();
    } catch (error) {
      console.error("Errore durante il salvataggio:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore durante il salvataggio",
        variant: "destructive"
      });
    }
  };

  const handleEditWorkout = async () => {
    try {
      if (!workoutTemplate.name || !workoutTemplate.target || !workoutTemplate.duration) {
        toast({
          title: "Errore",
          description: "Compila tutti i campi obbligatori",
          variant: "destructive"
        });
        return;
      }

      if (workoutTemplate.exercises.some(ex => !ex.name)) {
        toast({
          title: "Errore",
          description: "Tutti gli esercizi devono avere un nome",
          variant: "destructive"
        });
        return;
      }

      if (!selectedTemplateId) return;

      const { error } = await supabase
        .from('workout_templates')
        .update({
          name: workoutTemplate.name,
          description: workoutTemplate.description,
          difficulty: workoutTemplate.difficulty,
          target: workoutTemplate.target,
          duration: workoutTemplate.duration,
          exercises: workoutTemplate.exercises as unknown as Json
        })
        .eq('id', selectedTemplateId) as {
          data: WorkoutTemplateResponse[] | null;
          error: Error | null;
        };

      if (error) throw error;

      toast({
        title: "Successo",
        description: "Allenamento aggiornato con successo",
      });

      setIsEditDialogOpen(false);
      resetForm();
      setSelectedTemplateId(null);
      await refetch();
    } catch (error) {
      console.error("Errore durante l'aggiornamento:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore durante l'aggiornamento",
        variant: "destructive"
      });
    }
  };

  const loadTemplate = async (id: string) => {
    try {
      const { data, error } = await supabase
        .from('workout_templates')
        .select('*')
        .eq('id', id)
        .single() as {
          data: WorkoutTemplateResponse | null;
          error: Error | null;
        };
      
      if (error) throw error;
      if (!data) throw new Error("Template non trovato");

      const templateData: WorkoutTemplate = {
        id: data.id,
        name: data.name,
        description: data.description || "",
        difficulty: data.difficulty,
        target: data.target,
        duration: data.duration,
        exercises: data.exercises
      };

      setWorkoutTemplate(templateData);
      setSelectedTemplateId(id);
      setIsEditDialogOpen(true);
    } catch (error) {
      console.error("Errore durante il caricamento:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore durante il caricamento del template",
        variant: "destructive"
      });
    }
  };

  const viewWorkoutDetails = async (id: string) => {
    try {
      const { data, error } = await supabase
        .from('workout_templates')
        .select('*')
        .eq('id', id)
        .single() as {
          data: WorkoutTemplateResponse | null;
          error: Error | null;
        };
      
      if (error) throw error;
      if (!data) throw new Error("Template non trovato");

      const templateData: WorkoutTemplate = {
        id: data.id,
        name: data.name,
        description: data.description || "",
        difficulty: data.difficulty,
        target: data.target,
        duration: data.duration,
        exercises: data.exercises
      };

      setWorkoutTemplate(templateData);
      setSelectedTemplateId(id);
      setIsViewDialogOpen(true);
    } catch (error) {
      console.error("Errore durante il caricamento dei dettagli:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore durante il caricamento dei dettagli",
        variant: "destructive"
      });
    }
  };

  const handleDeleteWorkout = async () => {
    if (!selectedTemplateId) return;
    
    try {
      const { error } = await supabase
        .from('workout_templates')
        .delete()
        .eq('id', selectedTemplateId);
      
      if (error) throw error;
      
      setIsDeleteDialogOpen(false);
      setSelectedTemplateId(null);
      await refetch();
      
      toast({
        title: "Successo",
        description: "Allenamento eliminato con successo",
      });
    } catch (error) {
      console.error("Errore durante l'eliminazione:", error);
      toast({
        title: "Errore",
        description: "Si è verificato un errore durante l'eliminazione",
        variant: "destructive"
      });
    }
  };

  const openDeleteDialog = (id: string) => {
    setSelectedTemplateId(id);
    setIsDeleteDialogOpen(true);
  };

  return {
    templates,
    isLoading,
    workoutTemplate,
    setWorkoutTemplate,
    isAddDialogOpen,
    setIsAddDialogOpen,
    isEditDialogOpen,
    setIsEditDialogOpen,
    isDeleteDialogOpen,
    setIsDeleteDialogOpen,
    isViewDialogOpen,
    setIsViewDialogOpen,
    selectedTemplateId,
    setSelectedTemplateId,
    resetForm,
    saveWorkout,
    handleEditWorkout,
    handleDeleteWorkout,
    loadTemplate,
    openDeleteDialog,
    viewWorkoutDetails
  };
};
