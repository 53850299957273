
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { Plus, Save } from "lucide-react";
import { WorkoutTemplate, ExerciseInput } from "@/types/workout";
import ExerciseCard from "./ExerciseCard";
import { useToast } from "@/hooks/use-toast";

interface WorkoutFormProps {
  workoutTemplate: WorkoutTemplate;
  onSubmit: () => Promise<void>;
  resetForm: () => void;
  setWorkoutTemplate: React.Dispatch<React.SetStateAction<WorkoutTemplate>>;
  isEdit?: boolean;
}

const WorkoutForm = ({ workoutTemplate, onSubmit, resetForm, setWorkoutTemplate, isEdit = false }: WorkoutFormProps) => {
  const { toast } = useToast();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setWorkoutTemplate({
      ...workoutTemplate,
      [name]: value
    });
  };

  const handleExerciseChange = (id: string, field: keyof ExerciseInput, value: any) => {
    setWorkoutTemplate({
      ...workoutTemplate,
      exercises: workoutTemplate.exercises.map(exercise => 
        exercise.id === id ? { ...exercise, [field]: value } : exercise
      )
    });
  };

  const addExercise = () => {
    setWorkoutTemplate({
      ...workoutTemplate,
      exercises: [
        ...workoutTemplate.exercises,
        {
          id: crypto.randomUUID(),
          name: "",
          sets: 3,
          reps: "10",
          rest: "60",
          notes: ""
        }
      ]
    });
  };

  const removeExercise = (id: string) => {
    if (workoutTemplate.exercises.length <= 1) {
      toast({
        title: "Attenzione",
        description: "Un allenamento deve contenere almeno un esercizio",
        variant: "destructive"
      });
      return;
    }

    setWorkoutTemplate({
      ...workoutTemplate,
      exercises: workoutTemplate.exercises.filter(ex => ex.id !== id)
    });
  };

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <Label htmlFor="name">Nome allenamento *</Label>
          <Input
            id="name"
            name="name"
            value={workoutTemplate.name}
            onChange={handleInputChange}
            placeholder="Es. Full Body Principianti"
          />
        </div>
        
        <div className="space-y-2">
          <Label htmlFor="difficulty">Difficoltà *</Label>
          <Select
            value={workoutTemplate.difficulty}
            onValueChange={(value) => setWorkoutTemplate({...workoutTemplate, difficulty: value as WorkoutTemplate['difficulty']})}
          >
            <SelectTrigger>
              <SelectValue placeholder="Seleziona difficoltà" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="principiante">Principiante</SelectItem>
              <SelectItem value="intermedio">Intermedio</SelectItem>
              <SelectItem value="avanzato">Avanzato</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-2">
          <Label htmlFor="target">Obiettivo *</Label>
          <Input
            id="target"
            name="target"
            value={workoutTemplate.target}
            onChange={handleInputChange}
            placeholder="Es. Forza, Ipertrofia, Dimagrimento"
          />
        </div>
        
        <div className="space-y-2">
          <Label htmlFor="duration">Durata stimata *</Label>
          <Input
            id="duration"
            name="duration"
            value={workoutTemplate.duration}
            onChange={handleInputChange}
            placeholder="Es. 45 minuti"
          />
        </div>
      </div>

      <div className="space-y-2">
        <Label htmlFor="description">Descrizione</Label>
        <Textarea
          id="description"
          name="description"
          value={workoutTemplate.description}
          onChange={handleInputChange}
          placeholder="Descrivi questo allenamento..."
          rows={3}
        />
      </div>

      <Separator />

      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h3 className="text-lg font-medium">Esercizi</h3>
          <Button 
            onClick={addExercise} 
            variant="outline" 
            size="sm"
            className="flex items-center gap-1"
          >
            <Plus size={16} />
            <span>Aggiungi esercizio</span>
          </Button>
        </div>

        {workoutTemplate.exercises.map((exercise, index) => (
          <ExerciseCard 
            key={exercise.id} 
            exercise={exercise} 
            index={index}
            onChange={handleExerciseChange}
            onRemove={removeExercise}
          />
        ))}
      </div>

      <div className="flex justify-end gap-2 pt-4">
        <Button
          type="button"
          variant="outline"
          onClick={resetForm}
        >
          Annulla
        </Button>
        <Button 
          type="button" 
          onClick={onSubmit}
          className="flex items-center gap-2"
        >
          <Save size={16} />
          <span>{isEdit ? "Salva Modifiche" : "Salva Allenamento"}</span>
        </Button>
      </div>
    </div>
  );
};

export default WorkoutForm;
