
import { useState, useRef, useEffect } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Slider } from "@/components/ui/slider";
import { Crop, ZoomIn, ZoomOut } from "lucide-react";

interface ImageCropperProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  imageUrl: string | null;
  onCrop: (croppedBlob: Blob) => void;
  selectedFile: File | null;
}

export default function ImageCropper({
  open,
  onOpenChange,
  imageUrl,
  onCrop,
  selectedFile
}: ImageCropperProps) {
  const [cropSize, setCropSize] = useState(200);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef<HTMLImageElement>(null);
  const cropAreaRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // Centra inizialmente l'area di ritaglio quando l'immagine è caricata
  useEffect(() => {
    if (open && imageRef.current && containerRef.current) {
      // Aspetta che l'immagine sia completamente caricata
      const img = imageRef.current;
      const updatePosition = () => {
        const containerRect = containerRef.current?.getBoundingClientRect();
        if (containerRect) {
          // Calcola la posizione centrale
          const centerX = (containerRect.width - cropSize) / 2;
          const centerY = (containerRect.height - cropSize) / 2;
          setPosition({ x: centerX, y: centerY });
        }
      };

      if (img.complete) {
        updatePosition();
      } else {
        img.onload = updatePosition;
      }

      return () => {
        img.onload = null;
      };
    }
  }, [open, cropSize, imageUrl]);

  // Gestisce il ridimensionamento dell'area di ritaglio
  const handleResizeSlider = (value: number[]) => {
    setCropSize(value[0]);
    
    // Aggiorna la posizione per mantenere centrato
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const centerX = (containerRect.width - value[0]) / 2;
      const centerY = (containerRect.height - value[0]) / 2;
      
      setPosition(prev => ({
        x: Math.max(0, Math.min(centerX, containerRect.width - value[0])),
        y: Math.max(0, Math.min(centerY, containerRect.height - value[0]))
      }));
    }
  };

  // Gestisce il crop dell'immagine
  const handleCrop = async () => {
    if (!imageRef.current || !cropAreaRef.current || !selectedFile) {
      return;
    }

    try {
      console.log("Inizio processo di ritaglio immagine");
      const imageRect = imageRef.current.getBoundingClientRect();
      const cropRect = cropAreaRef.current.getBoundingClientRect();

      // Calcola le proporzioni tra l'immagine visualizzata e l'immagine originale
      const scaleX = imageRef.current.naturalWidth / imageRect.width;
      const scaleY = imageRef.current.naturalHeight / imageRect.height;

      // Calcola le coordinate del ritaglio relative all'immagine
      const cropX = Math.max(0, (cropRect.left - imageRect.left) * scaleX);
      const cropY = Math.max(0, (cropRect.top - imageRect.top) * scaleY);
      const cropWidth = cropRect.width * scaleX;
      const cropHeight = cropRect.height * scaleY;

      console.log("Coordinate ritaglio:", { cropX, cropY, cropWidth, cropHeight });

      // Crea un canvas per il ritaglio
      const canvas = document.createElement("canvas");
      canvas.width = cropWidth;
      canvas.height = cropHeight;
      const ctx = canvas.getContext("2d");

      if (ctx && imageRef.current) {
        ctx.drawImage(
          imageRef.current,
          cropX,
          cropY,
          cropWidth,
          cropHeight,
          0,
          0,
          cropWidth,
          cropHeight
        );

        // Converte il canvas in blob
        canvas.toBlob(async (blob) => {
          if (blob) {
            console.log("Canvas convertito in blob, dimensione:", blob.size);
            onCrop(blob);
            onOpenChange(false);
          } else {
            console.error("Errore: blob non generato");
          }
        }, "image/webp", 0.9); // Qualità dell'immagine 0.9 (90%)
      }
    } catch (error) {
      console.error("Errore durante il ritaglio:", error);
    }
  };

  // Gestisce il movimento dell'area di ritaglio
  const handleMouseDown = (e: React.MouseEvent) => {
    // Salviamo la posizione iniziale del mouse e dell'area
    const startX = e.clientX;
    const startY = e.clientY;
    const startPositionX = position.x;
    const startPositionY = position.y;

    const handleMouseMove = (moveEvent: MouseEvent) => {
      if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        
        // Calcola lo spostamento
        const deltaX = moveEvent.clientX - startX;
        const deltaY = moveEvent.clientY - startY;
        
        // Calcola la nuova posizione con limiti
        const newX = Math.max(0, Math.min(startPositionX + deltaX, containerRect.width - cropSize));
        const newY = Math.max(0, Math.min(startPositionY + deltaY, containerRect.height - cropSize));
        
        setPosition({ x: newX, y: newY });
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Ritaglia immagine</DialogTitle>
        </DialogHeader>
        
        <div 
          ref={containerRef} 
          className="relative w-full h-[400px] overflow-hidden bg-black flex items-center justify-center"
        >
          {imageUrl && (
            <>
              <img
                ref={imageRef}
                src={imageUrl}
                alt="Preview"
                className="max-w-full max-h-full"
              />
              <div
                ref={cropAreaRef}
                className="absolute bg-transparent border-2 border-white rounded-full cursor-move"
                style={{
                  left: `${position.x}px`,
                  top: `${position.y}px`,
                  width: `${cropSize}px`,
                  height: `${cropSize}px`
                }}
                onMouseDown={handleMouseDown}
              />
            </>
          )}
        </div>
        
        <div className="flex items-center gap-2 mt-4">
          <ZoomOut className="h-4 w-4 text-muted-foreground" />
          <Slider
            value={[cropSize]}
            min={50}
            max={350}
            step={10}
            onValueChange={handleResizeSlider}
            className="flex-1"
          />
          <ZoomIn className="h-4 w-4 text-muted-foreground" />
        </div>
        
        <DialogFooter className="mt-4">
          <Button variant="outline" onClick={() => onOpenChange(false)}>
            Annulla
          </Button>
          <Button onClick={handleCrop} className="flex items-center gap-2">
            <Crop className="h-4 w-4" />
            Ritaglia
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
