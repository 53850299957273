
import { supabase } from "@/lib/supabase";
import { WorkoutTemplate } from "@/types/workout";
import { useToast } from "@/hooks/use-toast";

/**
 * Recupera gli allenamenti di un client
 */
export async function fetchClientWorkouts(clientId: string) {
  if (!clientId) return [];
  
  try {
    const { data, error } = await supabase
      .from('client_workouts')
      .select(`
        *,
        workout_templates:workout_id (
          id,
          name,
          description,
          duration,
          target,
          difficulty,
          exercises
        )
      `)
      .eq('client_id', clientId)
      .order('assigned_date', { ascending: false });
    
    if (error) throw error;
    
    return data || [];
  } catch (error) {
    console.error('Errore nel recupero degli allenamenti del cliente:', error);
    return [];
  }
}

/**
 * Assegna un allenamento a un client
 */
export async function assignWorkoutToClient(clientId: string, workoutId: string, note?: string): Promise<boolean> {
  if (!clientId || !workoutId) return false;
  
  try {
    // Recupera prima i dati del cliente e il nome dell'allenamento per l'email
    const { data: workoutData, error: workoutError } = await supabase
      .from("workout_templates")
      .select("name, target")
      .eq("id", workoutId)
      .maybeSingle();

    if (workoutError) {
      console.error("Errore nel recupero dei dettagli dell'allenamento:", workoutError);
      return false;
    }
    
    if (!workoutData) {
      console.error("Template di allenamento non trovato");
      return false;
    }
    
    const { data: clientData, error: clientError } = await supabase
      .from('clients')
      .select('email, first_name, last_name')
      .eq('id', clientId)
      .maybeSingle();
    
    if (clientError) {
      console.error('Errore nel recupero dei dati del cliente:', clientError);
    }
    
    // Crea l'assegnazione dell'allenamento
    const { error } = await supabase.from("client_workouts").insert([
      {
        client_id: clientId,
        workout_id: workoutId,
        status: "active",
        note: note || null
      },
    ]);
    
    if (error) {
      console.error("Errore nell'assegnazione dell'allenamento:", error);
      return false;
    }
    
    // Invia un'email al cliente se abbiamo i suoi dati
    if (clientData?.email && workoutData) {
      try {
        const { sendEmail, createWorkoutAssignedEmail } = await import("@/lib/email-utils");
        
        const clientName = `${clientData.first_name} ${clientData.last_name}`;
        const emailHtml = createWorkoutAssignedEmail(
          clientName, 
          workoutData.name, 
          workoutData.target
        );
        const emailSubject = "Nuovo allenamento assegnato";
        
        await sendEmail(clientData.email, emailSubject, emailHtml);
      } catch (emailError) {
        console.error("Errore nell'invio dell'email di assegnazione allenamento:", emailError);
      }
    }
    
    return true;
  } catch (error) {
    console.error('Errore nell\'assegnazione dell\'allenamento:', error);
    return false;
  }
}

/**
 * Elimina un allenamento assegnato a un client
 */
export async function deleteClientWorkout(workoutId: string): Promise<boolean> {
  try {
    // Recupera informazioni sul workout e sul cliente prima di eliminarlo
    const { data: workoutRelation, error: relationError } = await supabase
      .from("client_workouts")
      .select("client_id, workout_id")
      .eq("id", workoutId)
      .maybeSingle();

    if (relationError) {
      console.error("Errore nel recupero della relazione cliente-allenamento:", relationError);
      return false;
    }
    
    if (!workoutRelation) {
      console.error("Relazione cliente-allenamento non trovata");
      return false;
    }

    const clientId = workoutRelation.client_id;
    const templateId = workoutRelation.workout_id;

    // Recupera dettagli dell'allenamento
    const { data: workoutTemplate, error: templateError } = await supabase
      .from("workout_templates")
      .select("name")
      .eq("id", templateId)
      .maybeSingle();

    if (templateError) {
      console.error("Errore nel recupero dei dettagli dell'allenamento:", templateError);
    }

    // Esegui l'eliminazione
    const { error } = await supabase
      .from("client_workouts")
      .delete()
      .eq("id", workoutId);

    if (error) {
      console.error("Errore nella rimozione dell'allenamento:", error);
      return false;
    }

    // Invia email al cliente
    try {
      if (!clientId) return true;
      
      const { data: clientData, error: clientError } = await supabase
        .from("clients")
        .select("email, first_name, last_name")
        .eq("id", clientId)
        .maybeSingle();

      if (clientError || !clientData?.email) {
        console.error("Errore nel recupero dei dati del cliente per l'email:", clientError);
      } else if (workoutTemplate) {
        const { sendEmail, createWorkoutRemovedEmail } = await import("@/lib/email-utils");
        
        const clientName = `${clientData.first_name} ${clientData.last_name}`;
        const emailHtml = createWorkoutRemovedEmail(clientName, workoutTemplate.name);
        const emailSubject = "Allenamento rimosso";
        
        await sendEmail(clientData.email, emailSubject, emailHtml);
      }
    } catch (emailError) {
      console.error("Errore nell'invio dell'email di rimozione allenamento:", emailError);
    }

    return true;
  } catch (error) {
    console.error("Errore nella rimozione dell'allenamento del cliente:", error);
    return false;
  }
}

/**
 * Recupera un template di allenamento
 */
export async function fetchWorkoutTemplate(workoutId: string) {
  if (!workoutId) return null;
  
  try {
    const { data, error } = await supabase
      .from('workout_templates')
      .select('*')
      .eq('id', workoutId)
      .maybeSingle();
    
    if (error) throw error;
    return data;
  } catch (error) {
    console.error('Errore nel recupero del template di allenamento:', error);
    return null;
  }
}

/**
 * Recupera tutti i template di allenamento
 */
export async function fetchAllWorkoutTemplates() {
  try {
    const { data, error } = await supabase
      .from('workout_templates')
      .select('*')
      .order('name', { ascending: true });
    
    if (error) throw error;
    return data || [];
  } catch (error) {
    console.error('Errore nel recupero dei template di allenamento:', error);
    return [];
  }
}

/**
 * Hook per operazioni sugli allenamenti
 */
export function useWorkoutOperations() {
  const { toast } = useToast();

  const assignWorkout = async (clientId: string, workoutId: string) => {
    if (!clientId || !workoutId) return false;
    try {
      const success = await assignWorkoutToClient(clientId, workoutId);
      
      if (success) {
        toast({
          title: "Successo",
          description: "Allenamento assegnato con successo"
        });
      }
      
      return success;
    } catch (error) {
      console.error('Errore nell\'assegnazione dell\'allenamento:', error);
      toast({
        title: "Errore",
        description: "Impossibile assegnare l'allenamento",
        variant: "destructive"
      });
      return false;
    }
  };

  const deleteWorkout = async (workoutId: string) => {
    try {
      const success = await deleteClientWorkout(workoutId);
      
      if (success) {
        toast({
          title: "Successo",
          description: "Allenamento rimosso con successo"
        });
      }
      
      return success;
    } catch (error) {
      console.error('Errore nella rimozione dell\'allenamento:', error);
      toast({
        title: "Errore",
        description: "Impossibile rimuovere l'allenamento",
        variant: "destructive"
      });
      return false;
    }
  };

  const viewWorkout = (workout: WorkoutTemplate, setSelectedWorkout: (workout: WorkoutTemplate) => void, setIsWorkoutDetailsDialogOpen: (open: boolean) => void) => {
    setSelectedWorkout(workout);
    setIsWorkoutDetailsDialogOpen(true);
  };

  return {
    assignWorkout,
    deleteWorkout,
    viewWorkout
  };
}
