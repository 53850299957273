
import React, { useMemo } from "react";
import { format, addMonths, isSameMonth } from "date-fns";
import { it } from "date-fns/locale";
import { Button } from "@/components/ui/button";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";

interface MonthSelectorProps {
  currentDate: Date;
  onDateChange: (date: Date) => void;
}

const MonthSelector: React.FC<MonthSelectorProps> = ({ currentDate, onDateChange }) => {
  const isMobile = useIsMobile();
  
  // Genera i mesi visibili (ridotti per mobile)
  const visibleMonths = useMemo(() => {
    const months = [];
    const range = isMobile ? 0 : 2; // Ridotto a 0 per mobile (solo mese corrente)
    
    for (let i = -range; i <= range + 1; i++) {
      const date = addMonths(currentDate, i);
      months.push({
        date,
        label: format(date, "MMMM yyyy", { locale: it }),
        shortLabel: format(date, "MMM", { locale: it }),
        isCurrent: isSameMonth(date, currentDate),
      });
    }
    return months;
  }, [currentDate, isMobile]);

  // Naviga al mese precedente
  const goToPrevMonth = () => {
    onDateChange(addMonths(currentDate, -1));
  };

  // Naviga al mese successivo
  const goToNextMonth = () => {
    onDateChange(addMonths(currentDate, 1));
  };

  return (
    <div className="flex items-center justify-between border-b pb-2 mb-4">
      <Button variant="ghost" size="icon" onClick={goToPrevMonth} className="shrink-0">
        <ChevronLeft className="h-4 w-4" />
      </Button>
      
      <div className="flex overflow-x-auto hide-scrollbar">
        {visibleMonths.map((month, index) => (
          <Button
            key={index}
            variant={month.isCurrent ? "default" : "ghost"}
            className="mx-1 whitespace-nowrap"
            onClick={() => onDateChange(month.date)}
          >
            <span className="hidden sm:inline">{month.label}</span>
            <span className="sm:hidden">{month.shortLabel}</span>
          </Button>
        ))}
      </div>
      
      <Button variant="ghost" size="icon" onClick={goToNextMonth} className="shrink-0">
        <ChevronRight className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default MonthSelector;
