
import { Calendar } from "lucide-react";

interface NoSessionsMessageProps {
  type?: "personal" | "course";
}

const NoSessionsMessage = ({ type }: NoSessionsMessageProps) => {
  const getMessage = () => {
    if (type === "personal") {
      return "Non ci sono sessioni personali programmate";
    } else if (type === "course") {
      return "Non ci sono sessioni di corsi programmate";
    } else {
      return "Gestisci le sessioni dal calendario";
    }
  };

  return (
    <div className="text-center py-12 border border-dashed rounded-lg">
      <Calendar size={36} className="mx-auto text-muted-foreground mb-2 opacity-50" />
      <h3 className="text-lg font-medium mb-1">
        {getMessage()}
      </h3>
      <p className="text-muted-foreground">
        Vai alla pagina calendario per visualizzare e programmare le sessioni
      </p>
    </div>
  );
};

export default NoSessionsMessage;
