
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Suspense, useState, useEffect, lazy } from "react";
import { SidebarProvider } from "@/components/ui/sidebar";
import Loading from "@/components/Loading";
import Layout from "@/components/Layout";
import AdminRoute from "@/components/AdminRoute";
import { AuthProvider } from "@/lib/auth";

// Importiamo direttamente tutti i componenti invece di usare lazy loading
import Home from "@/pages/Home";
import Login from "@/pages/Login";
import ResetPassword from "@/pages/ResetPassword";
import Dashboard from "@/pages/admin/Dashboard";
import Clients from "@/pages/admin/Clients";
import ClientDetail from "@/pages/admin/ClientDetail";
import Packages from "@/pages/admin/Packages";
import Courses from "@/pages/admin/Courses";
import Calendar from "@/pages/admin/Calendar";
import Payments from "@/pages/admin/Payments";
import Notifications from "@/pages/admin/Notifications";
import HomeOptions from "@/pages/admin/HomeOptions";
import Workouts from "@/pages/admin/Workouts";
import NotFound from "@/pages/NotFound";

// Configurazione del client React Query
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    }
  }
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <TooltipProvider>
        <SidebarProvider>
          <Toaster />
          <Sonner />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="login" element={<Login />} />
                <Route path="reset-password" element={<ResetPassword />} />
                
                {/* Admin Routes */}
                <Route path="admin" element={<AdminRoute />}>
                  {/* Queste route saranno accessibili solo agli admin grazie al controllo in AdminRoute */}
                  <Route path="dashboard" element={<Dashboard />} />
                  <Route path="clients" element={<Clients />} />
                  <Route path="clients/:slug" element={<ClientDetail />} />
                  <Route path="packages" element={<Packages />} />
                  <Route path="courses" element={<Courses />} />
                  <Route path="calendar" element={<Calendar />} />
                  <Route path="workouts" element={<Workouts />} />
                  <Route path="payments" element={<Payments />} />
                  <Route path="notifications" element={<Notifications />} />
                  <Route path="home-options" element={<HomeOptions />} />
                </Route>
                
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </SidebarProvider>
      </TooltipProvider>
    </AuthProvider>
  </QueryClientProvider>
);

export default App;
