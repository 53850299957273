
import { WorkoutTemplate } from "@/types/workout";
import { useToast } from "@/hooks/use-toast";
import { assignWorkoutToClient, deleteClientWorkout } from "@/lib/clients";

export function useWorkoutOperations() {
  const { toast } = useToast();

  const assignWorkout = async (clientId: string, workoutId: string) => {
    if (!clientId || !workoutId) return false;
    try {
      await assignWorkoutToClient(clientId, workoutId);
      
      toast({
        title: "Successo",
        description: "Allenamento assegnato con successo"
      });
      
      return true;
    } catch (error) {
      console.error('Errore nell\'assegnazione dell\'allenamento:', error);
      toast({
        title: "Errore",
        description: "Impossibile assegnare l'allenamento",
        variant: "destructive"
      });
      return false;
    }
  };

  const deleteWorkout = async (workoutId: string) => {
    try {
      await deleteClientWorkout(workoutId);
      
      toast({
        title: "Successo",
        description: "Allenamento rimosso con successo"
      });
      
      return true;
    } catch (error) {
      console.error('Errore nella rimozione dell\'allenamento:', error);
      toast({
        title: "Errore",
        description: "Impossibile rimuovere l'allenamento",
        variant: "destructive"
      });
      return false;
    }
  };

  const viewWorkout = (workout: WorkoutTemplate | null, setSelectedWorkout: (workout: WorkoutTemplate | null) => void, setIsWorkoutDetailsDialogOpen: (open: boolean) => void) => {
    if (!workout) {
      toast({
        title: "Errore",
        description: "Dettagli dell'allenamento non disponibili",
        variant: "destructive"
      });
      return;
    }
    
    setSelectedWorkout(workout);
    setIsWorkoutDetailsDialogOpen(true);
  };

  return {
    assignWorkout,
    deleteWorkout,
    viewWorkout
  };
}
