
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Users, Package, Calendar, DollarSign, Activity, Bell, UserPlus } from "lucide-react";
import { supabase, type Client, type CalendarEvent, type Payment, type Notification } from "@/lib/supabase";
import Loading from "@/components/Loading";
import { Skeleton } from "@/components/ui/skeleton";

interface StatCardProps {
  title: string;
  value: string | number;
  description: string;
  icon: React.ReactNode;
  linkTo?: string;
}

const StatCard = ({ title, value, description, icon, linkTo }: StatCardProps) => {
  const cardContent = (
    <Card className="hover-card-effect overflow-hidden transition-all">
      <CardHeader className="pb-2 flex flex-row items-center justify-between">
        <CardTitle className="text-lg font-medium">{title}</CardTitle>
        <div className="h-8 w-8 rounded-full bg-primary/10 flex items-center justify-center">
          {icon}
        </div>
      </CardHeader>
      <CardContent>
        <div className="text-3xl font-bold">{value}</div>
        <CardDescription>{description}</CardDescription>
      </CardContent>
    </Card>
  );

  if (linkTo) {
    return <Link to={linkTo}>{cardContent}</Link>;
  }

  return cardContent;
};

const RecentNotification = ({ notification }: { notification: Notification }) => {
  const getIcon = () => {
    switch (notification.type) {
      case "new_client":
        return <UserPlus className="h-4 w-4 text-green-500" />;
      case "new_package":
        return <Package className="h-4 w-4 text-blue-500" />;
      case "new_course":
        return <Activity className="h-4 w-4 text-violet-500" />;
      case "new_session":
        return <Calendar className="h-4 w-4 text-indigo-500" />;
      case "cancelled_session":
        return <Calendar className="h-4 w-4 text-red-500" />;
      case "cancelled_course":
        return <Activity className="h-4 w-4 text-red-500" />;
      case "package_ended":
        return <Package className="h-4 w-4 text-orange-500" />;
      default:
        return <Bell className="h-4 w-4 text-gray-500" />;
    }
  };

  const getTimeAgo = (createdAt: string) => {
    const date = new Date(createdAt);
    const now = new Date();
    const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
    
    let interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      return `${interval} ${interval === 1 ? 'anno' : 'anni'} fa`;
    }
    
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return `${interval} ${interval === 1 ? 'mese' : 'mesi'} fa`;
    }
    
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return `${interval} ${interval === 1 ? 'giorno' : 'giorni'} fa`;
    }
    
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return `${interval} ${interval === 1 ? 'ora' : 'ore'} fa`;
    }
    
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return `${interval} ${interval === 1 ? 'minuto' : 'minuti'} fa`;
    }
    
    return `${Math.floor(seconds)} secondi fa`;
  };

  return (
    <div className="flex items-start space-x-3 rounded-md p-3 hover:bg-secondary transition-colors">
      <div className="mt-0.5">{getIcon()}</div>
      <div className="flex-1 space-y-1">
        <p className="text-sm font-medium">{notification.message}</p>
        <p className="text-xs text-muted-foreground">{getTimeAgo(notification.created_at)}</p>
      </div>
      {!notification.read && (
        <div className="h-2 w-2 rounded-full bg-primary"></div>
      )}
    </div>
  );
};

const UpcomingSession = ({ event, client }: { event: CalendarEvent; client?: Client }) => {
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('it-IT', {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  };

  return (
    <div className="flex items-start space-x-3 rounded-md p-3 hover:bg-secondary transition-colors">
      <div className="h-10 w-10 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
        <span className="text-primary font-medium">
          {client ? client.first_name.charAt(0) + client.last_name.charAt(0) : 'U'}
        </span>
      </div>
      <div className="flex-1">
        <div className="flex justify-between items-start">
          <p className="text-sm font-medium">
            {client ? `${client.first_name} ${client.last_name}` : 'Cliente'}
          </p>
          <span className="text-xs bg-primary/10 text-primary px-2 py-0.5 rounded-full">
            {event.type === 'session' ? 'PT' : 'Corso'}
          </span>
        </div>
        <p className="text-xs text-muted-foreground">
          {formatDate(event.start_time)}
        </p>
        <p className="text-xs text-muted-foreground mt-1">
          {event.title}
        </p>
      </div>
    </div>
  );
};

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({
    totalClients: 0,
    activePackages: 0,
    activeCourses: 0,
    totalRevenue: 0,
  });
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [upcomingSessions, setUpcomingSessions] = useState<{event: CalendarEvent, client?: Client}[]>([]);
  const [recentClients, setRecentClients] = useState<Client[]>([]);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        setIsLoading(true);
        console.log("Iniziando il caricamento dei dati dashboard");

        const { count: clientsCount } = await supabase
          .from('clients')
          .select('*', { count: 'exact', head: true });

        const { count: packagesCount } = await supabase
          .from('client_packages')
          .select('*', { count: 'exact', head: true });

        const { count: coursesCount } = await supabase
          .from('client_courses')
          .select('*', { count: 'exact', head: true });

        const { data: paymentsData } = await supabase
          .from('payments')
          .select('amount');
        
        const totalRevenue = paymentsData ? paymentsData.reduce((sum, payment) => sum + payment.amount, 0) : 0;

        const { data: notificationsData } = await supabase
          .from('notifications')
          .select('*')
          .order('created_at', { ascending: false })
          .limit(5);

        const now = new Date().toISOString();
        const { data: eventsData } = await supabase
          .from('calendar_events')
          .select('*')
          .gt('start_time', now)
          .order('start_time', { ascending: true })
          .limit(5);

        const upcomingSessionsWithClients = await Promise.all(
          (eventsData || []).map(async (event) => {
            if (event.client_id) {
              const { data: clientData } = await supabase
                .from('clients')
                .select('*')
                .eq('id', event.client_id)
                .single();
              
              return { event, client: clientData as Client };
            }
            return { event };
          })
        );

        const { data: recentClientsData } = await supabase
          .from('clients')
          .select('*')
          .order('created_at', { ascending: false })
          .limit(5);

        setStats({
          totalClients: clientsCount || 0,
          activePackages: packagesCount || 0,
          activeCourses: coursesCount || 0,
          totalRevenue,
        });
        
        setNotifications(notificationsData || []);
        setUpcomingSessions(upcomingSessionsWithClients);
        setRecentClients(recentClientsData || []);

        console.log("Dati dashboard caricati con successo");
      } catch (error) {
        console.error('Error fetching dashboard data:', error);
      } finally {
        setIsLoading(false);
        console.log("Loading impostato a false");
      }
    };

    fetchDashboardData();
  }, []);

  if (isLoading) {
    return (
      <div className="space-y-8 animate-fade-in">
        <div>
          <h1 className="text-3xl font-bold tracking-tight">Dashboard</h1>
        </div>
        
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
          {[1, 2, 3, 4].map((i) => (
            <Card key={i}>
              <CardHeader className="pb-2">
                <Skeleton className="h-5 w-28 mb-1" />
              </CardHeader>
              <CardContent>
                <Skeleton className="h-8 w-16 mb-2" />
                <Skeleton className="h-4 w-24" />
              </CardContent>
            </Card>
          ))}
        </div>
        
        <div className="grid gap-4 grid-cols-1 lg:grid-cols-2">
          {[1, 2].map((i) => (
            <Card key={i}>
              <CardHeader>
                <Skeleton className="h-6 w-36 mb-1" />
                <Skeleton className="h-4 w-48" />
              </CardHeader>
              <CardContent>
                {[1, 2, 3].map((j) => (
                  <Skeleton key={j} className="h-16 w-full mb-2" />
                ))}
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    );
  }

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR'
    }).format(amount);
  };

  return (
    <div className="space-y-8 animate-fade-in">
      <div>
        <h1 className="text-3xl font-bold tracking-tight">Dashboard</h1>
      </div>

      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
        <StatCard
          title="Clienti Totali"
          value={stats.totalClients}
          description="Clienti registrati"
          icon={<Users className="h-4 w-4 text-primary" />}
          linkTo="/admin/clients"
        />
        <StatCard
          title="Pacchetti Attivi"
          value={stats.activePackages}
          description="Pacchetti in uso"
          icon={<Package className="h-4 w-4 text-primary" />}
          linkTo="/admin/packages"
        />
        <StatCard
          title="Corsi Attivi"
          value={stats.activeCourses}
          description="Corsi in programma"
          icon={<Activity className="h-4 w-4 text-primary" />}
          linkTo="/admin/courses"
        />
        <StatCard
          title="Fatturato Totale"
          value={formatCurrency(stats.totalRevenue)}
          description="Ricavi complessivi"
          icon={<DollarSign className="h-4 w-4 text-primary" />}
          linkTo="/admin/payments"
        />
      </div>

      <div className="grid gap-4 grid-cols-1 lg:grid-cols-2">
        <Card>
          <CardHeader>
            <CardTitle className="text-xl">Prossime Sessioni</CardTitle>
            <CardDescription>Le prossime sessioni di allenamento in agenda</CardDescription>
          </CardHeader>
          <CardContent className="space-y-1">
            {upcomingSessions.length > 0 ? (
              upcomingSessions.map(({ event, client }) => (
                <UpcomingSession 
                  key={event.id} 
                  event={event} 
                  client={client}
                />
              ))
            ) : (
              <p className="text-sm text-muted-foreground text-center py-4">
                Nessuna sessione programmata
              </p>
            )}
            {upcomingSessions.length > 0 && (
              <div className="pt-2">
                <Link 
                  to="/admin/calendar" 
                  className="text-primary text-sm font-medium hover:underline"
                >
                  Vedi tutte le sessioni →
                </Link>
              </div>
            )}
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <CardTitle className="text-xl">Notifiche Recenti</CardTitle>
            <CardDescription>Aggiornamenti e avvisi importanti</CardDescription>
          </CardHeader>
          <CardContent className="space-y-1">
            {notifications.length > 0 ? (
              notifications.map((notification) => (
                <RecentNotification 
                  key={notification.id} 
                  notification={notification} 
                />
              ))
            ) : (
              <p className="text-sm text-muted-foreground text-center py-4">
                Nessuna notifica recente
              </p>
            )}
            {notifications.length > 0 && (
              <div className="pt-2">
                <Link 
                  to="/admin/notifications" 
                  className="text-primary text-sm font-medium hover:underline"
                >
                  Vedi tutte le notifiche →
                </Link>
              </div>
            )}
          </CardContent>
        </Card>
      </div>

      <Card>
        <CardHeader>
          <CardTitle className="text-xl">Clienti Recenti</CardTitle>
          <CardDescription>I clienti aggiunti più di recente</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="overflow-x-auto">
            <table className="w-full text-sm">
              <thead>
                <tr className="border-b">
                  <th className="text-left font-medium py-3 px-4">Cliente</th>
                  <th className="text-left font-medium py-3 px-4">Età</th>
                  <th className="text-left font-medium py-3 px-4">Frequenza</th>
                  <th className="text-left font-medium py-3 px-4">Condizione</th>
                  <th className="text-left font-medium py-3 px-4">Data Creazione</th>
                </tr>
              </thead>
              <tbody>
                {recentClients.length > 0 ? (
                  recentClients.map((client) => {
                    // Gestione sicura di first_name e last_name potenzialmente null
                    const firstName = client.first_name || '';
                    const lastName = client.last_name || '';
                    
                    return (
                      <tr key={client.id} className="border-b hover:bg-muted/50 transition-colors">
                        <td className="py-3 px-4">
                          <Link 
                            to={`/admin/clients/${client.id}`}
                            className="font-medium text-primary hover:underline flex items-center gap-2"
                          >
                            <div className="h-8 w-8 rounded-full bg-primary/10 flex items-center justify-center flex-shrink-0">
                              <span className="text-primary text-xs font-medium">
                                {firstName.charAt(0) || '?'}{lastName.charAt(0) || '?'}
                              </span>
                            </div>
                            <span>{firstName} {lastName}</span>
                          </Link>
                        </td>
                        <td className="py-3 px-4">{client.age ? `${client.age} anni` : 'N/D'}</td>
                        <td className="py-3 px-4">{client.training_frequency || 'N/D'}</td>
                        <td className="py-3 px-4">{client.general_condition || 'N/D'}</td>
                        <td className="py-3 px-4">
                          {new Date(client.created_at).toLocaleDateString('it-IT')}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5} className="py-4 text-center text-muted-foreground">
                      Nessun cliente registrato
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {recentClients.length > 0 && (
            <div className="pt-4">
              <Link 
                to="/admin/clients" 
                className="text-primary text-sm font-medium hover:underline"
              >
                Vedi tutti i clienti →
              </Link>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default Dashboard;
