
import { supabase } from "@/lib/supabase";
import { useToast } from "@/hooks/use-toast";

export function useCourseOperations() {
  const { toast } = useToast();

  const enrollCourse = async (clientId: string, courseId: string) => {
    if (!clientId || !courseId) return false;
    try {
      // Prima ottieni i dati del cliente e del corso per l'email
      const { data: clientData, error: clientError } = await supabase
        .from('clients')
        .select('email, first_name, last_name')
        .eq('id', clientId)
        .single();
      
      if (clientError) {
        console.error('Errore nel recupero dei dati del cliente:', clientError);
      }
      
      const { data: courseData, error: courseError } = await supabase
        .from('courses')
        .select('name')
        .eq('id', courseId)
        .single();
      
      if (courseError) {
        console.error('Errore nel recupero dei dati del corso:', courseError);
      }
      
      const newClientCourse = {
        client_id: clientId,
        course_id: courseId,
        start_date: new Date().toISOString()
      };
      const { error } = await supabase.from('client_courses').insert([newClientCourse]);
      if (error) throw error;
      toast({
        title: "Successo",
        description: "Cliente iscritto al corso con successo"
      });
      
      // Invia email al cliente se abbiamo i suoi dati
      if (clientData?.email && courseData) {
        try {
          const { sendEmail, createCourseEnrollmentEmail } = await import("@/lib/email-utils");
          
          const clientName = `${clientData.first_name} ${clientData.last_name}`;
          const emailHtml = createCourseEnrollmentEmail(clientName, courseData.name);
          const emailSubject = "Iscrizione al corso confermata";
          
          await sendEmail(clientData.email, emailSubject, emailHtml);
        } catch (emailError) {
          console.error("Errore nell'invio dell'email di iscrizione corso:", emailError);
        }
      }
      
      return true;
    } catch (error) {
      console.error('Errore nell\'iscrizione al corso:', error);
      toast({
        title: "Errore",
        description: "Impossibile iscrivere il cliente al corso",
        variant: "destructive"
      });
      return false;
    }
  };

  const deleteCourse = async (clientId: string, courseId: string) => {
    if (!clientId) return false;
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Imposta l'ora a mezzanotte per includere tutti gli eventi di oggi
      
      // Prima ottieni i dati del cliente e del corso per l'email
      const { data: clientData, error: clientError } = await supabase
        .from('clients')
        .select('email, first_name, last_name')
        .eq('id', clientId)
        .single();
      
      if (clientError) {
        console.error('Errore nel recupero dei dati del cliente:', clientError);
      }
      
      const { data: clientCourse, error: fetchError } = await supabase
        .from('client_courses')
        .select('course_id, courses(name)')
        .eq('id', courseId)
        .single();
      
      if (fetchError) {
        console.error('Errore nel recupero del corso del cliente:', fetchError);
      }
      
      if (clientCourse) {
        const { error: calendarError } = await supabase
          .from('calendar_events')
          .delete()
          .eq('type', 'course')
          .eq('client_id', clientId)
          .eq('course_id', clientCourse.course_id)
          .gte('start_time', today.toISOString());
        
        if (calendarError) {
          console.error('Errore nell\'eliminazione degli eventi del calendario:', calendarError);
        }
      }
      
      const { error } = await supabase
        .from('client_courses')
        .delete()
        .eq('id', courseId);
      
      if (error) throw error;
      
      toast({
        title: "Successo",
        description: "Iscrizione al corso e sessioni future associate eliminate con successo"
      });
      
      // Invia email al cliente se abbiamo i suoi dati
      if (clientData?.email && clientCourse?.courses) {
        try {
          const { sendEmail, createCourseCancelledEmail } = await import("@/lib/email-utils");
          
          const clientName = `${clientData.first_name} ${clientData.last_name}`;
          const courseName = clientCourse.courses.name;
          const emailHtml = createCourseCancelledEmail(clientName, courseName);
          const emailSubject = "Iscrizione al corso cancellata";
          
          await sendEmail(clientData.email, emailSubject, emailHtml);
        } catch (emailError) {
          console.error("Errore nell'invio dell'email di cancellazione corso:", emailError);
        }
      }
      
      return true;
    } catch (error) {
      console.error('Errore nell\'eliminazione dell\'iscrizione al corso:', error);
      toast({
        title: "Errore",
        description: "Impossibile eliminare l'iscrizione al corso",
        variant: "destructive"
      });
      return false;
    }
  };

  return {
    enrollCourse,
    deleteCourse
  };
}
